import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import vocabulary from 'vocabulary/vocabularyPassport'
import { ServiceInfoModal } from 'components/ServiceInfo/__Modal/ServiceInfoModal'
import { itemRenderer } from 'components/ServiceInfo/itemRenderer'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'

export function PassportDifferences() {
  const [searchParams, setSearchParams] = useSearchParams()
  const isOpened = searchParams.get('open') === 'passportDifferences'

  const handleClose = () => {
    const { open, ...rest } = searchParams

    setSearchParams(rest)
  }

  const content = vocabulary.differencesBetweenOldAndNew

  return (
    <DialogModalWindow open={isOpened}>
      <ServiceInfoModal onClose={handleClose} clearDataBeforeClose={false}>
        {content.map((item, index) => itemRenderer(item, index))}
      </ServiceInfoModal>
    </DialogModalWindow>
  )
}
