import HeaderMobile from '../components/Header/HeaderMobile'
import LeftDrawerSmallScreen from '../components/LeftDrawer/LeftDrawerSmallScreen'

import { useTheme } from '@mui/material/styles'

import Footer600 from '../components/Footer/Footer600'
import Box from '@mui/material/Box'
import SpeedDialButton from '../components/SpeedDialButton'
import WorkFlowMain from '../components/Workflow/WorkFlowMain'
import { Page } from 'components/Page/Page'

export default function Layout819({ children, stateMenu, setStateMenu, main }) {
  const theme = useTheme()

  return (
    <Page>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.primary.surface,
          pt: '20px',
          flexGrow: 1,
        }}
      >
        <LeftDrawerSmallScreen
          stateMenu={stateMenu}
          setStateMenu={setStateMenu}
        />

        <HeaderMobile displaySize='small' setStateMenu={setStateMenu} />

        <SpeedDialButton />
        <WorkFlowMain smallScreen={true} />
        <Box
          style={{
            marginLeft: !main ? `20px` : null,
            marginRight: !main ? '20px' : null,
            minHeight: '70vh',
          }}
        >
          {children}
        </Box>

        <Footer600 />
      </Box>
    </Page>
  )
}
