import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  TextField,
  MenuItem,
  LinearProgress,
  Stack,
  InputAdornment,
} from '@mui/material/'

import { v4 as uuidv4 } from 'uuid'

import { useTheme } from '@mui/material/styles'

import vocabularyPowerOfAttorney from '../../../vocabulary/vocabularyPowerOfAttorney'
import GlobalContext from '../../../GlobalContext'
import leftArrow from '../../../images/icon/arrow-left.png'
import crossClose from '../../../images/icon/cross-close.png'
import vocabularyWorkflow from '../../../vocabulary/vocabularyWorkflow'
import { LanguageSharp } from '@mui/icons-material'

export default function ThirdScreenUserFlowMain({ setNextWindow }) {
  const theme = useTheme()
  const { langSelected, GlobalActions } = React.useContext(GlobalContext)

  const [countrySelected, setCountrySelected] = React.useState(
    Object.keys(vocabularyWorkflow.countries[langSelected])[0],
  )

  const [cities, setCities] = React.useState(
    vocabularyWorkflow.countries[langSelected][countrySelected],
  )

  React.useEffect(() => {
    setCitySelected(
      vocabularyWorkflow.countries[langSelected][countrySelected][0],
    )
    setCities(vocabularyWorkflow.countries[langSelected][countrySelected])
  }, [countrySelected])

  const [citySelected, setCitySelected] = React.useState(
    vocabularyWorkflow.countries[langSelected][countrySelected][0],
  )

  const handleClose = () => {
    GlobalActions.userFlowMainOpen(false)
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ mx: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '76px',
          }}
        >
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setNextWindow('second')
            }}
          >
            <img src={leftArrow} alt='left arrow' width={'24px'} />
          </Box>

          <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
            <img src={crossClose} alt='cross' width={'24px'} />
          </Box>
        </Box>

        <Typography component='p' variant='titleLarge' sx={{ mb: '32px' }}>
          {vocabularyWorkflow.chooseConsulate[langSelected]}
        </Typography>

        <TextField
          value={countrySelected}
          placeholder={vocabularyWorkflow.country[langSelected]}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <LanguageSharp />
              </InputAdornment>
            ),
          }}
          onChange={event => {
            setCountrySelected(event.target.value)
          }}
          sx={{
            minWidth: 1,
            height: 56,
            mr: '16px',
            borderRadius: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },

            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
              color: theme.palette.primary.onSurfaceVariant,
              opacity: 1,
            },
          }}
          variant='outlined'
          margin='dense'
          select
          label={vocabularyWorkflow.country[langSelected]}
        >
          {Object.keys(vocabularyWorkflow.countries[langSelected]).map(
            (val, index) => (
              <MenuItem
                key={uuidv4()}
                sx={{ display: 'flex', alignItems: 'center' }}
                value={val}
              >
                {val}
              </MenuItem>
            ),
          )}
        </TextField>

        <TextField
          value={citySelected}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <LanguageSharp />
              </InputAdornment>
            ),
          }}
          onChange={event => {
            setCitySelected(event.target.value)
          }}
          sx={{
            minWidth: 1,
            height: 56,
            mr: '16px',
            borderRadius: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },

            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
              color: theme.palette.primary.onSurfaceVariant,
              opacity: 1,
            },
          }}
          variant='outlined'
          margin='dense'
          select
          label={vocabularyWorkflow.country[langSelected]}
        >
          {cities.map((val, index) => (
            <MenuItem
              key={uuidv4()}
              sx={{ display: 'flex', alignItems: 'center' }}
              value={val}
            >
              {val}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box>
        <Stack>
          <LinearProgress
            color='progressBar'
            variant='determinate'
            value={60}
            sx={{ backgroundColor: theme.palette.primary.primaryContainer }}
          />
        </Stack>
        <Box
          sx={{
            pb: '16px',
            pt: '24px',
            mt: '8px',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            variant='outlined'
            size='medium'
            sx={{ border: 'none', height: '40px' }}
            color='blueButton'
            onClick={() => {
              setNextWindow('third')
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyWorkflow.skip[langSelected]}
            </Typography>
          </Button>

          <Button
            variant='outlined'
            size='medium'
            sx={{ border: 'none', height: '40px', mr: '16px' }}
            color='blueButton'
            onClick={() => {
              setNextWindow('second')
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyPowerOfAttorney.buttonAsk[langSelected]}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
