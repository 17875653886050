import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyPassport'

export const adultHasPassport = langSelected => {
  const { title, options } = vocabulary.workflow.adultHasPassport

  return createStepSchema(
    null,
    [
      {
        name: 'title.hasForeignPassport',
        type: 'title',
      },
      {
        name: 'serviceDetails.hasForeignPassport',
        type: 'radio',
        componentProps: {
          options: createOptions(options, langSelected),
        },
      },
      {
        name: 'title.hasInternalPassport',
        type: 'title',
      },
      {
        name: 'serviceDetails.hasInternalPassport',
        type: 'radio',
        componentProps: {
          options: createOptions(options, langSelected),
        },
      },
    ],
    ({ serviceDetails }) => ({
      serviceDetails: {
        hasInternalPassport: serviceDetails?.hasInternalPassport,
        hasForeignPassport: serviceDetails?.hasForeignPassport,
      },
      title: {
        hasForeignPassport: title.hasForeignPassport[langSelected],
        hasInternalPassport: title.hasInternalPassport[langSelected],
      },
    }),
    ({ serviceDetails }) => ({ serviceDetails }),
  )
}
