import vocabularyPowerOfAttorney from 'vocabulary/vocabularyPowerOfAttorney'
import vocabularyPassport from 'vocabulary/vocabularyPassport'
import {
  serviceTypes as consentServiceTypes,
  applicationTypes as consentApplicationTypes,
  agreementTypes as consentAgreementTypes,
  consentTypes,
} from 'vocabulary/vocabularyConsent'
import vocabularyCitizenship from './vocabularyCitizenship'
import vocabularyZags from './vocabularyZags'
import vocabularyOther from './vocabularyOther'
import vocabularyConsulting from './vocabularyConsulting'
import vocabularyCertificates from 'vocabulary/vocabularyCertificates'

/**
 * Функция для объединения нескольких массивов подмассивов в один объект.
 * Ключом объекта становится второй элемент каждого подмассива, а значением - сам подмассив.
 *
 * @param {Array[]} arrays - Массив массивов подмассивов, которые нужно объединить в объект.
 * @returns {Object} Объект, где ключами являются вторые элементы подмассивов, а значениями - подмассивы.
 */
const mergeArraysToObject = arrays => {
  return arrays.reduce((acc, array) => {
    array.forEach(subArray => {
      acc[subArray[1]] = subArray
    })

    return acc
  }, {})
}

export const vocabulary = {
  application: ['Заявка', 'Application'],
  allApplications: ['Все заявки', 'All applications'],
  tabs: [
    ['Общее', 'Summary'],
    ['Детали', 'Details'],
    ['Документы', 'Documents'],
  ],
  edit: ['Редактировать', 'Edit'],
  сreatedDate: ['Дата создания', 'Created date'],
  consulation: ['Консульство', 'Consultation'],
  tariff: ['Выбранный тариф', 'Selected tariff'],
  sumToPaid: ['Сумма к оплате', 'Sum to paid'],
  paymentStatus: ['Статус оплаты', 'Payment status'],
  requestStatus: ['Статус заявки', 'Status'],
  place: {
    Australia: ['Австралия', 'Australia'],
    Sydney: ['Сидней', 'Sydney'],
    Austria: ['Австрия', 'Austria'],
    Salzburg: ['Зальцбург', 'Salzburg'],
    Algeria: ['Алжир', 'Algeria'],
    Annaba: ['Аннаба', 'Annaba'],
    Armenia: ['Армения', 'Armenia'],
    Gyumri: ['Гюмри', 'Gyumri'],
    Afghanistan: ['Афганистан', 'Afghanistan'],
    'Mazar-i-Sharif': ['Мазари-Шариф', 'Mazar-i-Sharif'],
    Bangladesh: ['Бангладеш', 'Bangladesh'],
    Chittagong: ['Читтагонг', 'Chittagong'],
    Belarus: ['Белоруссия', 'Belarus'],
    Brest: ['Брест', 'Brest'],
    Belgium: ['Бельгия', 'Belgium'],
    Antwerp: ['Антверпен', 'Antwerp'],
    Bulgaria: ['Болгария', 'Bulgaria'],
    Varna: ['Варна', 'Varna'],
    Ruse: ['Русе', 'Ruse'],
    Brazil: ['Бразилия', 'Brazil'],
    'Rio de Janeiro': ['Рио-де-Жанейро', 'Rio de Janeiro'],
    'São Paulo': ['Сан-Паулу', 'São Paulo'],
    'United Kingdom': ['Великобритания', 'United Kingdom'],
    Edinburgh: ['Эдинбург', 'Edinburgh'],
    Hungary: ['Венгрия', 'Hungary'],
    Debrecen: ['Дебрецен', 'Debrecen'],
    Vietnam: ['Вьетнам', 'Vietnam'],
    'Da Nang': ['Дананг', 'Da Nang'],
    'Ho Chi Minh City': ['Хошимин', 'Ho Chi Minh City'],
    Germany: ['Германия', 'Germany'],
    Bonn: ['Бонн', 'Bonn'],
    Hamburg: ['Гамбург', 'Hamburg'],
    Leipzig: ['Лейпциг', 'Leipzig'],
    Munich: ['Мюнхен', 'Munich'],
    Frankfurt: ['Франкфурт-на-Майне', 'Frankfurt'],
    Greece: ['Греция', 'Greece'],
    Thessaloniki: ['Салоники', 'Thessaloniki'],
    Egypt: ['Египет', 'Egypt'],
    Alexandria: ['Александрия', 'Alexandria'],
    Israel: ['Израиль', 'Israel'],
    Haifa: ['Хайфа', 'Haifa'],
    India: ['Индия', 'India'],
    Kolkata: ['Калькутта', 'Kolkata'],
    Mumbai: ['Мумбаи', 'Mumbai'],
    Chennai: ['Ченнаи', 'Chennai'],
    Iraq: ['Ирак', 'Iraq'],
    Basra: ['Басра', 'Basra'],
    Erbil: ['Эрбиль', 'Erbil'],
    Iran: ['Иран', 'Iran'],
    Isfahan: ['Исфахан', 'Isfahan'],
    Rasht: ['Решт', 'Rasht'],
    Spain: ['Испания', 'Spain'],
    Barcelona: ['Барселона', 'Barcelona'],
    Italy: ['Италия', 'Italy'],
    Genoa: ['Генуя', 'Genoa'],
    Milan: ['Милан', 'Milan'],
    Palermo: ['Палермо', 'Palermo'],
    Yemen: ['Йемен', 'Yemen'],
    Aden: ['Аден', 'Aden'],
    Kazakhstan: ['Казахстан', 'Kazakhstan'],
    Almaty: ['Алма-Ата', 'Almaty'],
    Uralsk: ['Уральск', 'Uralsk'],
    'Ust-Kamenogorsk': ['Усть-Каменогорск', 'Ust-Kamenogorsk'],
    Canada: ['Канада', 'Canada'],
    Montreal: ['Монреаль', 'Montreal'],
    Toronto: ['Торонто', 'Toronto'],
    Kyrgyzstan: ['Кыргызстан', 'Kyrgyzstan'],
    Osh: ['Ош', 'Osh'],
    China: ['Китай', 'China'],
    'Hong Kong': ['Гонконг', 'Hong Kong'],
    Guangzhou: ['Гуанчжоу', 'Guangzhou'],
    Harbin: ['Харбин', 'Harbin'],
  },
  statusValue: {
    sent: ['Отправлена', 'Sent'],
    ready: ['Готовo', 'Ready'],
    processing: ['В процессе', 'In process'],
  },
  tariffValue: {
    withAppointment: ['С записью на приём в консульство', 'With appointment'],
    withoutAppointment: [
      'Без записи на приём в консульство',
      'Without appointment',
    ],
  },
  marriageStatus: [
    'Состояли ли родители ребёнка в браке на момент его рождения:',
    'Did the parents of the child in marriage at the time of its birth:',
  ],
  paternityBase: ['Основание для установления отцовства:', 'Paternity base:'],
  isThisMarriageFirst: [
    'Является ли этот брак первым для обоих:',
    'Is this marriage the first for both:',
  ],
  divorceBase: ['Основание для расторжения брака:', 'Divorce base:'],
  powerOfAttorney: {
    // service
    subtitle: mergeArraysToObject([vocabularyPowerOfAttorney.typesOfPoa]),
  },
  passport: {
    subtitle: {
      '10 year': vocabularyPassport.newPassportVariant,
      '5 year': vocabularyPassport.oldPassportVariant,
    },
  },
  consent: {
    summaryTitle: mergeArraysToObject([consentServiceTypes]),
    subtitle: mergeArraysToObject([
      consentApplicationTypes,
      consentAgreementTypes,
      consentTypes,
    ]),
  },
  certificates: {
    subtitle: {
      'Consular certificate': ['Консульская справка', 'Consular certificate'],
      'Request a document from Russia': [
        'Истребование документа из России',
        'Request a document from Russia',
      ],
    },
  },
  section: {
    poa: ['Доверенность', 'Power of attorney'],
    passport: ['Загранпаспорт', 'International passport'],
    certificates: vocabularyCertificates.certificates,
    consent: [
      'Согласие, Заявление, Договор, соглашение',
      'Agreement, Application, Consent',
    ],
    legalEntities: ['Документы для юр. лиц', 'Legal entities documents'],
    citizenship: vocabularyCitizenship.citizenship,
    zags: vocabularyZags.zagsServices,
    other: vocabularyOther.otherServices,
    consulting: vocabularyConsulting.consulting,
  },

  labels: {
    whoGives: ['Доверители', 'Trustors'],
    whomGives: ['Представители', 'Representatives'],
    whatGives: ['Полномочия', 'Powers'],
    transferRights: ['Право передоверия', 'Right to Delegate'],
    timePoa: ['Срок действия', 'Duration'],
  },
  yes: ['Да', 'Yes'],
  no: ['Нет', 'No'],
  until: ['до', 'until'],
  notChosen: ['Не выбрано', 'Not chosen'],
}
