const vocabularyConsulting = {
  consulting: ['Консультационные услуги', 'Consulting'],
  text1: [
    'Вы можете запросить персональную консультацию по вопросам подготовки документов, получения справок и прочим услугам в Российских зарубежных консульских учреждениях.',
    'You can request personal advice on issues of preparing documents, obtaining certificates and other services at the Russian foreign consular offices.',
  ],
  text2: [
    'Консультация платная. Стоимость услуги будет рассчитана в соответствии с запросом.',
    'Consultation is subject to a free. The price will be calculated according to your request.',
  ],
  workflow: {
    requestDetails: {
      title: [
        'Опишите своими словами вашу проблему',
        'Describe your problem in your own words',
      ],
      additionalInfo: ['Опишите вашу ситуацию', 'Describe your situation'],
    },
  },
}
export default vocabularyConsulting
