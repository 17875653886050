import React from 'react'
import { useTheme } from '@mui/material/styles'
import { RadioGroup } from './RadioGroup'
import MUITextField from '@mui/material/TextField'
import Grow from '@mui/material/Grow'

const textFieldStyles = theme => ({
  width: 'calc(100% - 30px)',
  ml: '30px',
  borderRadius: 0,
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    color: theme.palette.primary.onSurface,
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
    color: theme.palette.primary.onSurfaceVariant,
    opacity: 1,
  },
})

export const RadioGroupWithText = ({
  options,
  name,
  onChange,
  sx = {},
  value = null,
  placeholder,
  ...props
}) => {
  const theme = useTheme()
  const textFieldSx = textFieldStyles(theme)

  const onRadioChange = e => {
    onChange({ ...value, chosen: e.target.value })
  }

  const onTextChange = e => {
    onChange({ ...value, text: e.target.value })
  }

  const lastItemChecked = value.chosen === options.at(-1).value

  return (
    <>
      <RadioGroup
        name={name}
        value={value.chosen}
        options={options}
        onChange={onRadioChange}
      />
      {lastItemChecked ? (
        <Grow
          in={lastItemChecked}
          style={{ transformOrigin: '0 0 0' }}
          {...(lastItemChecked ? { timeout: 1000 } : {})}
        >
          <MUITextField
            value={value.text}
            placeholder={placeholder}
            minRows='3'
            maxRows='3'
            onChange={onTextChange}
            fullWidth
            margin='dense'
            sx={textFieldSx}
            multiline
          />
        </Grow>
      ) : null}
    </>
  )
}
