import { Typography, useTheme } from '@mui/material'
import { Link as BaseLink } from 'components/Link/Link'

export const Link = ({ text, to }) => {
  const theme = useTheme()

  return (
    <BaseLink to={to}>
      <Typography
        variant='labelLarge'
        component='p'
        sx={{ my: '16px', color: theme.palette.primary.primary }}
      >
        {text}
      </Typography>
    </BaseLink>
  )
}
