import React from 'react'
import { List, ListItemButton, Typography } from '@mui/material'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Tabs } from 'components/Tabs/Tabs'

export const RequestTabs = ({ selected, onChange, items }) => {
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'))

  if (isDesktop) {
    return <MenuList items={items} active={selected} onChange={onChange} />
  }

  return (
    <Tabs
      items={items.map(item => ({ text: item }))}
      active={selected}
      onChange={onChange}
    />
  )
}

const MenuList = ({ items, active, onChange }) => {
  const { palette } = useTheme()

  return (
    <List>
      {items.map((text, index) => (
        <ListItemButton
          key={text}
          selected={active === index}
          onClick={() => onChange(index)}
          sx={{
            backgroundColor:
              active === index ? palette.primary.primaryContainer : null,
            borderRadius: '100px',
            color: palette.primary.main,
            py: '10px',
            mb: 1,
          }}
        >
          <Typography variant='labelLarge'>{text}</Typography>
        </ListItemButton>
      ))}
    </List>
  )
}
