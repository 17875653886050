import vocabulary from 'vocabulary/vocabularyConsent'
import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'

export const requestGuarantorAndBorrower = langSelected => {
  const { titleGuarantor, titleBorrower, options } =
    vocabulary.workflow.requestGuarantorAndBorrower

  return createStepSchema(null, [
    {
      name: 'title',
      type: 'title',
      componentProps: {
        value: titleGuarantor[langSelected],
      },
    },
    {
      name: 'serviceDetails.guarantor',
      type: 'radio',
      componentProps: {
        options: createOptions(options, langSelected),
      },
    },
    {
      name: 'title',
      type: 'title',
      componentProps: {
        value: titleBorrower[langSelected],
      },
    },
    {
      name: 'serviceDetails.borrower',
      type: 'radio',
      componentProps: {
        options: createOptions(options, langSelected),
      },
    },
  ])
}
