import { useEffect, useRef, useState } from 'react'

export function useOnHover(callback) {
  const elementRef = useRef(null)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    isHovered && callback?.()
  }, [isHovered])

  useEffect(() => {
    const element = elementRef.current

    if (!element) {
      return
    }

    const handleMouseEnter = () => setIsHovered(true)
    const handleMouseLeave = () => setIsHovered(false)

    element.addEventListener('mouseenter', handleMouseEnter)
    element.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      element.removeEventListener('mouseenter', handleMouseEnter)
      element.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [elementRef.current])

  return { isHovered, elementRef }
}
