const vocabularyOther = {
  otherServices: ['Другие консульские услуги', 'Other consular services'],
  legalization: ['Легализация документов', 'Documents legalization'],
  infoText1: [
    'Для того, что иностранный документ имел юридическую силу на территории России, необходимо пройти процедуру легализации. Это означает подтвердить подлинность подписи и печати выдавшего его учреждения.',
    'In order for a foreign document to have legal force on the territory of Russia, it is necessary to go through the legalization procedure. This means confirming the authenticity of the signature and seal of the issuing institution.',
  ],
  infoText2: [
    'Если документ представлен на иностранном языке, дополнительно необходимо сделать нотариально заверенный перевод на русский язык.',
    'If the document is in a foreign language, it is additionally necessary to make a notarized translation into Russian.,',
  ],
  infoText3: [
    'То, насколько сложной, дорогой и длительной по времени будет эта процедура, зависит от типа документа и страны его получения.',
    'How complex, expensive and time-consuming this procedure will be depends on the type of document and the country in which it was obtained.',
  ],
  infoText4: [
    'Мы проконсультируем вас, как необходимо поступить в вашем конкретном случае, сколько по времени может занять весь процесс, как подготовить документы для легализации и поможем заполнить заявление на консульском портале.',
    'We will advise you on what to do in your particular case, how long the whole process may take, how to prepare documents for legalization and will help you fill out an application on the consulate portal.',
  ],
  certificateReturn: [
    'Свидетельство на возвращение в Россию',
    'Certificate for return to Russia',
  ],
  infoText5: [
    'Если у вас нет действующего загранпаспорта, для срочного въезда на территорию России вы можете оформить свидетельство на возвращение в Россию.',
    'If you do not have a valid passport, for urgent entry into the territory of Russia you can obtain a certificate for return to Russia.',
  ],

  whoCanApply: ['Кто может обратиться за получением:', 'Who can apply:'],
  certificateReturnList: [
    [
      'граждане РФ, у кого загранпаспорт был утерян, испорчен или истёк срок его действия',
      'citizens of the Russian Federation whose passport has been lost, damaged or has expired',
    ],
    [
      'граждане РФ, проживающие за рубежом, которым не могут выдать загранпаспорт из-за ограничений на выезд из РФ (например, из-за доступа к государственной тайне, военной службы, наличия судимости и других причин)',
      'citizens of the Russian Federation living abroad who cannot be issued a foreign passport due to restrictions on leaving the Russian Federation (for example, due to access to state secrets, military service, criminal record and other reasons)',
    ],
    [
      'лица без гражданства, проживающие в РФ, либо беженцы, утратившие документ, подтверждающий их статус',
      'stateless persons living in the Russian Federation, or refugees who have lost a document confirming their status',
    ],
  ],
  infoText6: [
    'Если у гражданина России, находящегося за границей, никогда не было собственного загранпаспорта, он не может получить свидетельство на возвращение. В этом случае потребуется оформлять загранпаспорт.',
    'If a Russian citizen who is abroad has never had his own foreign passport, he/she cannot obtain a certificate for return. In this case, you will need to apply for a foreign passport.',
  ],
  infoText7: [
    'Свидетельство на возвращение может быть, например, выдано на основании внутреннего паспорта РФ или письменного показания двух других граждан РФ, подтверждающих вашу личность. При отсутствии обоих действующий паспортов и свидетелей может потребоваться предварительно пройти процедуру проверки принадлежности к гражданству РФ.',
    'A certificate for return can, for example, be issued on an internal passport of the Russian Federation or an affidavit of two other citizens of the Russian Federation confirming your identity. If you do not have both valid passports and witnesses, you may need to first go through a procedure to verify your Russian citizenship.',
  ],
  infoText8: [
    'Для подачи заявления предварительная запись в консульство не требуется.',
    'To submit an application, the prior registration at the consulate is not required.',
  ],
  infoText9: [
    'Срок оформления — от 2 до 27 дней, срок действия документа — 14 дней.',
    'The processing time is from 2 to 27 days, the document is valid for 14 days.',
  ],
  infoText10: [
    'Свидетельство на возвращение не заменяет собой загранпаспорт и предназначено исключительно для однократного въезда на территорию России.',
    'The certificate for return does not replace a foreign passport and is intended exclusively for a single entry into the territory of Russia.',
  ],
  infoText11: [
    'В случае отсутствия прямых рейсов в РФ из страны консульства, рекомендуется заранее уточнить у авиакомпании, пустят ли вас с данным документом на рейс.',
    'If there are no direct flights to the Russian Federation from the country of the consulate, it is recommended to check with the airline in advance whether they will allow you on the flight with this document.',
  ],
  infoText12: [
    'В течение 3 дней после прибытия в Россию свидетельство необходимо сдать в территориальный орган ФМС по месту жительства.',
    'Within 3 days after arrival to Russia, the certificate must be submitted to the territorial body of the Federal Migration Service at your place of residence.',
  ],

  otherTypes: [
    {
      userName: ['Легализация документов', 'Legalization of documents'],

      desc: [
        ['заявление', 'Aplication'],
        [
          'внутренний или заграничный паспорт РФ)',
          'internal or foreign passport of the Russian Federation',
        ],
        [
          'документ, подлежащий легализации',
          'document subject to legalization',
        ],
      ],
    },
    {
      userName: [
        'Свидетельство на возвращение в Россию',
        'A certificate of return to Russia',
      ],

      desc: [
        ['заявление', 'Aplication'],
        [
          'внутренний паспорт РФ, свидетельство о рождении (для детей до 14 лет) или письменные заявления двух свидетелей (граждан России, имеющих действительные загранпаспорта)',
          'internal passport of the Russian Federation, birth certificate (for children under 14 years of age) or written statements of two witnesses (Russian citizens with valid international passports)',
        ],
        [
          'просроченный или испорченный загранпаспорт (при наличии)',
          'expired or damaged passport (if available)',
        ],
        [
          'документы, подтверждающие родство или права законного представителя (для детей или недееспособных граждан)',
          'documents confirming kinship or the rights of a legal representative (for children or incapacitated citizens)',
        ],
        ['2 фотографии 3,5 × 4,5 см', '2 photo 3.5x4.5 cm'],
      ],
      ps: ['Смотреть требования к фотографии', 'View photo requirements'],
    },
  ],
  priceText: [
    'Стоимость услуги зависит от страны обращения и ваших индивидуальных обстоятельств. Точную сумму можно узнать после консультации с юристом.',
    'The cost of the service depends on the country of application and your individual circumstances. The exact amount can be determined after consultation with a lawyer.',
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Запишем вас на приём в консульство',
      ],
      value: '60',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'We will consult you on the list of documents required for notarization',
        'We will schedule an appointment at the consulate for you',
      ],
      value: '60',
    },
  ],

  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов,',
        'Проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '80',
    },
    {
      header: 'Without an appointment at the consulate',
      list: [
        'We will prepare a draft document',
        'We will submit the document on the consulate portal',
        'Provide consultation on scheduling an appointment at the consulate independently',
      ],
      value: '80',
    },
  ],
  faq: [
    {
      userName: [
        'Что такое апостиль и зачем он нужен?',
        'What is an apostille and why is it needed?',
      ],
      desc: [
        [
          'Апостиль — это специальный штамп, который подтверждает подлинность официального документа для использования за границей.',
          'One of the child’s parents (legal representative) who has Russian citizenship.',
        ],
        [
          'Россия подписала Гаагскую конвенцию 1961 года, которая упростила процесс признания документов в других странах. Вместо сложных процедур дипломатической или нотариальной легализации теперь достаточно проставить апостиль. Документы с апостилем признаются действительными во всех странах, подписавших эту конвенцию, без дополнительной проверки.',
          'Russia signed the 1961 Hague Convention, which simplified the process of document recognition in other countries. Instead of complex procedures for diplomatic or notarial legalization, it is now enough to affix an apostille. Documents with an apostille are recognized as valid in all countries that have signed this convention, without additional verification.',
        ],
      ],
    },
    {
      userName: [
        'Где можно поставить апостиль?',
        'Where can I affix an apostille?',
      ],
      desc: [
        [
          'Апостиль проставляется непосредственно в ведомстве, выдавшем документ.',
          'The apostille is affixed directly at the institution that issued the document.',
        ],
      ],
    },
    {
      userName: [
        'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',
        'Can I affix an apostille to a registry office document at the consulate?',
      ],
      desc: [
        [
          'Нет, консульство не может заверять апостилем выданные в РФ документы.',
          'No, the consulate cannot certify the documents issued in the Russian Federation with an apostile.',
        ],

        [
          'Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем.',
          'However, through the consulate you can request a duplicate civil registry office document from Russia with an apostille already affixed to it.',
        ],
      ],
    },
  ],

  faqDesc: [
    [
      {
        question: 'Что такое апостиль и зачем он нужен?',
        answer:
          'Апостиль — это специальный штамп, который подтверждает подлинность официального документа для использования за границей.',
        answer2:
          'Россия подписала Гаагскую конвенцию 1961 года, которая упростила процесс признания документов в других странах. Вместо сложных процедур дипломатической или нотариальной легализации теперь достаточно проставить апостиль. Документы с апостилем признаются действительными во всех странах, подписавших эту конвенцию, без дополнительной проверки.',
      },
      {
        question: 'Где можно поставить апостиль?',
        answer:
          'Апостиль проставляется непосредственно в ведомстве, выдавшем документ.',
      },
      {
        question:
          'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',

        answer:
          'Нет, консульство не может заверять апостилем выданные в РФ документы.',
        answer2:
          'Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем.',
      },
    ],
    [
      {
        question: 'What is an apostille and why is it needed?',
        answer:
          'An apostille is a special stamp that confirms the authenticity of an official document for use abroad.',
        answer2:
          'Russia signed the Hague Convention of 1961, which simplified the process of recognizing documents in other countries. Instead of complex procedures of diplomatic or notarial legalization, it is now enough to place an apostille. Documents with an apostille are recognized as valid in all countries that signed this convention without additional verification.',
      },
      {
        question: 'Where can I get an apostille?',
        answer:
          'An apostille is placed directly at the agency that issued the document.',
      },
      {
        question:
          'Can I get an apostille on a civil registry document at the consulate?',
        answer:
          'No, the consulate cannot apostille documents issued in the Russian Federation.',
        answer2:
          'However, through the consulate, you can request a duplicate civil registry document from Russia with an apostille already placed on it.',
      },
    ],
  ],
  workflow: {
    requestServiceType: {
      title: [
        'Какая консульская услуга вам необходима?',
        'What consular service do you need?',
      ],
      options: [
        [
          'Свидетельство на возвращение в Россию',
          'Certificate for return to Russia',
        ],
        ['Легализация документов', 'Legalization of documents'],
        ['Другая', 'Other'],
      ],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
      ],
    },
    requestDocumentsDisponible: {
      title: [
        'Какие документы у вас есть в наличии?',
        'What documents do you have?',
      ],
      additionalInfo: [
        'Например. внутренний паспорт, загранпаспорт с истекшим сроком действия, испорченный загранпаспорт, водительские права',
        "For example, internal passport, expired foreign passport, damaged foreign passport, driver's license",
      ],
    },
    requestDocumentToLegalize: {
      title: [
        'Какой документ нужно легализовать?',
        'What document needs to be legalized?',
      ],
      additionalInfo: [
        'Опишите своими словами, что за документ, где и когда был выдан',
        'Describe in your own words what the document is, where and when it was issued',
      ],
    },
    requestDetails: {
      title: [
        'Опишите своими словами вашу проблему',
        'Describe your problem in your own words',
      ],
      additionalInfo: ['Опишите вашу ситуацию', 'Describe your situation'],
    },
  },
}

export default vocabularyOther
