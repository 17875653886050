import { Check } from '@phosphor-icons/react'
import { useTheme } from '@mui/material/styles'
import { Box, Typography, useMediaQuery } from '@mui/material'

export const TabsItem = ({ text, active, onClick, icon, first, last }) => {
  const theme = useTheme()
  const borderRadius = first
    ? {
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px',
        borderRight: 'none',
      }
    : last
      ? {
          borderTopRightRadius: '20px',
          borderBottomRightRadius: '20px',
          borderLeft: 'none',
        }
      : {}

  return (
    <Box
      onClick={onClick}
      sx={{
        textTransform: 'none',
        border: '1px solid',
        ...borderRadius,
        py: '10px',
        flex: '1 0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: active
          ? theme.palette.primary.secondaryContainer
          : null,
      }}
    >
      <Typography
        variant='labelLarge'
        component='div'
        sx={{
          color: theme.palette.primary.onSurface,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TabIcon icon={icon} active={active} />
        <span style={{ padding: '0 8px' }}>{text}</span>
      </Typography>
    </Box>
  )
}

const TabIcon = ({ icon, active }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (isMobile) {
    return null
  }

  const tabIcon = icon || <Box pr='18px' ml='-18px' />

  return active ? (
    <Check size={18} weight='light' style={{ marginLeft: '-18px' }} />
  ) : (
    tabIcon
  )
}
