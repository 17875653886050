import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material/'

import GlobalContext from 'GlobalContext'

import vocabularyWorkflow from 'vocabulary/vocabularyWorkflow'
import { usePutUserRequest } from 'hooks/requests/usePutUserRequest'
import { WorkflowContainer } from 'components/Workflow/WorkflowContainer'

export default function Payment({ price }) {
  const { langSelected, GlobalActions, state } = React.useContext(GlobalContext)

  const [payNow, setPayNow] = React.useState('no')

  const handleClose = () => {
    GlobalActions.clearDataToSend()
  }

  const onSendUserRequestSuccess = () => {
    const nextStepAdditionalValue = payNow === 'yes' ? 1 : 2

    GlobalActions.setDialogServiceOpen({
      serviceIndex: state.dialogService.serviceIndex,
      step: state.dialogService.step + nextStepAdditionalValue,
    })
  }

  const { sendUserRequest } = usePutUserRequest({
    onSuccess: onSendUserRequestSuccess,
  })

  return (
    <WorkflowContainer
      onClose={handleClose}
      footer={<Footer onConfirm={sendUserRequest} payNow={payNow} />}
    >
      <Typography component='p' variant='titleLarge' sx={{ mb: 4 }}>
        {vocabularyWorkflow.payment[langSelected]}
      </Typography>

      <Typography component='p' variant='bodySmall' sx={{ mb: 2 }}>
        {vocabularyWorkflow.paymentText[langSelected]}
      </Typography>

      <FormControl>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          value={payNow}
          onChange={event => {
            setPayNow(event.target.value)
          }}
          name='radio-buttons-group'
          sx={{ mb: 4 }}
        >
          <FormControlLabel
            sx={{ mb: 1 }}
            value='no'
            control={<Radio />}
            label={vocabularyWorkflow.payLater[langSelected]}
          />

          <FormControlLabel
            value='yes'
            control={<Radio />}
            label={vocabularyWorkflow.payNow[langSelected]}
          />
        </RadioGroup>
      </FormControl>
      {payNow === 'yes' ? <RequestWithPayment price={price} /> : null}
    </WorkflowContainer>
  )
}

const RequestWithPayment = props => {
  const { langSelected, state } = React.useContext(GlobalContext)
  const [paymentMethod, setPaymentMethod] = React.useState('card')
  // const servicePrice = prices?.[state.section]
  console.log(state)
  const priceToShow = state.dataToSend.appointment
    ? props.price?.withAppointment
    : props.price?.withoutAppointment

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box>
          <Typography component='p' variant='bodySmall' sx={{ mb: 2 }}>
            {vocabularyWorkflow.selectedPrice[langSelected]}
          </Typography>

          <Typography
            component='p'
            variant='labelLarge'
            color='primary'
            sx={{ mb: 2, width: '50%' }}
          >
            {state.dataToSend.appointment
              ? vocabularyWorkflow.withAppointment[langSelected]
              : vocabularyWorkflow.withoutAppointment[langSelected]}
          </Typography>
        </Box>

        <Box>
          <Typography
            component='p'
            variant='h2Large'
            color='primary'
            sx={{ mb: 2, width: '50%', mt: 5 }}
          >
            {priceToShow ? `${priceToShow}$` : 'unknown'}
          </Typography>
        </Box>
      </Box>

      <Typography component='p' variant='bodySmall' sx={{ mb: 2, mt: 4 }}>
        {vocabularyWorkflow.paymentVariant[langSelected]}
      </Typography>

      <FormControl>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          value={paymentMethod}
          onChange={event => {
            setPaymentMethod(event.target.value)
          }}
          name='radio-buttons-group'
        >
          <FormControlLabel
            sx={{ mb: 1 }}
            value='card'
            control={<Radio />}
            label={vocabularyWorkflow.card[langSelected]}
          />

          {/*     <FormControlLabel
            sx={{ mb: 1 }}
            value='apple'
            control={<Radio />}
            label={'Apple pay'}
          />
          <FormControlLabel
            value='google'
            control={<Radio />}
            label={'Google pay'}
        />*/}
        </RadioGroup>
      </FormControl>

      <Typography component='p' variant='bodySmall' sx={{ mt: 2 }}>
        {vocabularyWorkflow.paymentDetail[langSelected]}
      </Typography>
    </>
  )
}

const Footer = ({ onConfirm, payNow }) => {
  const { langSelected } = React.useContext(GlobalContext)
  const [paymentInCourse, setPaymentInCourse] = React.useState(false)

  return (
    <Box
      sx={{
        pb: 4,
        pt: '24px',
        mt: '8px',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',

        mx: 2,
      }}
    >
      <Button
        variant='contained'
        size='medium'
        sx={{ border: 'none', height: '40px', mb: 2 }}
        color='blueButton'
        disabled={paymentInCourse}
        onClick={() => {
          setPaymentInCourse(/*payNow === 'yes'*/ true)

          onConfirm()
        }}
      >
        <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
          {payNow !== 'yes'
            ? vocabularyWorkflow.sendRequest[langSelected]
            : vocabularyWorkflow.goToPayment[langSelected]}
        </Typography>
      </Button>

      {/*payNow !== 'yes' ? (
        <Button
          variant='outlined'
          size='medium'
          sx={{
            width: 1,
            height: '40px',
            mr: '16px',
            borderColor: 'fff',
          }}
          color='blueButton'
          onClick={() => {
            console.log('save')
          }}
        >
          <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
            {vocabularyWorkflow.saveRequest[langSelected]}
          </Typography>
        </Button>
      ) : null*/}
    </Box>
  )
}
