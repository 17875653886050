import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'

export default function CardServiceProcess600({
  cardText,
  headerText,
  cardImage,
  about,
  details,
  last,
  navigate,
}) {
  const theme = useTheme()

  return (
    <Card
      sx={{
        //   margin: 'auto',
        backgroundColor: theme.palette.primary.surface,
        display: 'flex',
        width: '100%',
        zIndex: 1,
        position: 'relative',
      }}
      variant='inlined'
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 0,
          mb: 4,
          mr: 2,
        }}
      >
        <Box
          style={{
            position: 'relative',
            width: '70px',
            height: '70px',
          }}
        >
          <Box
            style={{
              width: '54px',
              height: '54px',
              borderRadius: '50%',
              backgroundColor: !about
                ? theme.palette.primary.tertiaryContainer
                : theme.palette.primary.surface,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderStyle: about ? 'solid' : null,
              borderColor: '#C2C7CF',
              borderWidth: '1px',
              zIndex: 3,
              position: 'relative',
            }}
          >
            <img
              src={cardImage}
              alt='logo'
              style={{ width: '54px', height: '54px' }}
            />
          </Box>
          {!last && !about ? (
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '27px',
                height: '200%',
                width: '2px',
                backgroundColor: theme.palette.primary.tertiaryContainer,
                zIndex: 2,
              }}
            />
          ) : null}
        </Box>

        <Box sx={{ ml: '16px' }}>
          <Typography component='div' variant='titleSmall'>
            {headerText}
          </Typography>

          <Typography variant='bodySmall'>{cardText}</Typography>
          <Typography
            variant='labelLarge'
            sx={{
              mt: 1,
              mr: 2,
              display: 'flex',
              justifyContent: 'flex-end',
              color: theme.palette.primary.primary,
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/privacy-policy')
            }}
          >
            {details ? <>{details} → </> : null}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
