import * as React from 'react'
import vocabulary from 'vocabulary/vocabularyLegalEntities'
import GlobalContext from 'GlobalContext'
import { getStepByChosenService } from './steps/index'

import ServiceInfo from 'components/ServiceInfo/ServiceInfo'

export default function LegalEntitiesInfo() {
  const { state, GlobalActions } = React.useContext(GlobalContext)
  let serviceIndex = state.dialogService.index

  const onSubmit = event => {
    const step = getStepByChosenService(state.dataToSend)
    GlobalActions.setDialogServiceOpen({
      step,
    })
  }

  const handleClose = () => {
    GlobalActions.clearDataToSend()
  }

  const content = vocabulary.interactuation[serviceIndex]

  return (
    <ServiceInfo onClose={handleClose} onSubmit={onSubmit} content={content} />
  )
}
