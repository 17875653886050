import { useCallback, useState } from 'react'

export const useOnKeyDown = ({ values, onEnterPressed }) => {
  const [active, setActive] = useState(0)

  const onKeyDown = useCallback(
    event => {
      if (values.length === 0) {
        return
      }

      if (event.code === 'ArrowDown') {
        setActive(active => (active + 1) % values.length)

        event.preventDefault()
      }

      if (event.code === 'ArrowUp') {
        setActive(active => (active > 0 ? active - 1 : values.length - 1))

        event.preventDefault()
      }

      if (event.code === 'Enter' && values.length) {
        onEnterPressed(values[active])

        event.preventDefault()
      }
    },
    [active, values],
  )

  return { active, setActive, onKeyDown }
}
