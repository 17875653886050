import * as React from 'react'
import { v4 as uuid } from 'uuid'
import { Dialog } from '@mui/material/'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useDialogModalContext } from '../../contexts/DialogModalContext'

const DEFAULT_SMALL_SCREEN_BREAKPOINT = 819

/**
 *  A common component that renders a dialog modal window according to
 *  window size
 */
export function DialogModalWindow({
  children,
  open,
  breakpoint = DEFAULT_SMALL_SCREEN_BREAKPOINT,
  disableEnforceFocus = false,
  height = 'auto',
  minHeight = '90%',
  width = 550,
}) {
  const id = React.useMemo(() => uuid(), [])
  const { openModal, closeModal, modals } = useDialogModalContext()
  const smallScreen = useMediaQuery(`(max-width:${breakpoint}px)`)
  const theme = useTheme()

  const primaryColorRGB = theme.palette.primary.main.substring(1)
  const backgroundColorWithOpacity = `rgba(${parseInt(primaryColorRGB.substring(0, 2), 16)}, ${parseInt(primaryColorRGB.substring(2, 4), 16)}, ${parseInt(primaryColorRGB.substring(4, 6), 16)}, 0.5)`

  React.useEffect(() => {
    open ? openModal(id) : closeModal(id)

    if (smallScreen) {
      const bodyStyle = open
        ? { overflow: 'hidden', position: 'fixed' }
        : { overflow: 'auto', position: 'static' }

      document.body.style.overflow = bodyStyle.overflow
      document.body.style.position = bodyStyle.position
    }

    return () => closeModal(id)
  }, [open])

  const customMuiDialogPaperStyles = smallScreen
    ? {}
    : {
        height,
        minHeight,
        width,
      }

  const { scale, zIndex } = modals?.find(modal => modal.id === id) || {}

  return (
    <Dialog
      fullScreen={smallScreen ? true : false}
      open={open}
      keepMounted
      hideBackdrop
      scroll='body'
      disableEnforceFocus={disableEnforceFocus}
      PaperProps={{
        sx: {
          transition:
            'box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          transform: `scale(${scale})`,
        },
      }}
      sx={{
        zIndex,
        '& .MuiDialog-paper': {
          ...customMuiDialogPaperStyles,
          display: 'inline-flex',
        },
        '& .MuiDialog-container': {
          backgroundColor: backgroundColorWithOpacity,
        },
      }}
    >
      {children}
    </Dialog>
  )
}
