import Layout819 from '../Layouts/Layout819'
import { PrivacyPolicy } from 'pages/components/PrivacyPolicy/PrivacyPolicy'

export default function PrivacyPolicy819() {
  return (
    <Layout819>
      <PrivacyPolicy />
    </Layout819>
  )
}
