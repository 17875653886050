import * as React from 'react'
import { Button, Typography, Box } from '@mui/material/'

import { useTheme } from '@mui/material/styles'
import GlobalContext from '../../../GlobalContext'

import crossClose from '../../../images/icon/cross-close.png'

import vocabularyWorkflow from '../../../vocabulary/vocabularyWorkflow'

import { Smiley } from '@phosphor-icons/react'

export default function RequestSuccessSent(props) {
  const theme = useTheme()
  const { langSelected, GlobalActions } = React.useContext(GlobalContext)

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: props.height || '100%',

        flex: 1,
      }}
    >
      <Box sx={{ mx: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '76px',
          }}
        >
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              GlobalActions.setDialogServiceOpen(false)
              GlobalActions.clearDataToSend()
            }}
          >
            <img src={crossClose} alt='cross' width={'24px'} />
          </Box>
        </Box>
        <Smiley
          style={{ color: theme.palette.primary.onTertiaryContainer }}
          size={75}
          weight='light'
        />

        <Typography component='p' variant='titleLarge' sx={{ my: 2 }}>
          {vocabularyWorkflow.applicationSubmitted[langSelected]}
        </Typography>

        <Typography component='p' variant='bodyLarge' sx={{ mb: 2 }}>
          {vocabularyWorkflow.applicationSubmittedText1[langSelected]}
        </Typography>

        <Typography component='p' variant='bodyLarge' sx={{ mb: 2 }}>
          {vocabularyWorkflow.applicationSubmittedText2[langSelected]}
        </Typography>

        <Typography component='p' variant='bodyLarge'>
          {vocabularyWorkflow.applicationSubmittedText3[langSelected]}
        </Typography>
      </Box>

      <Button
        variant='outlined'
        size='medium'
        sx={{ height: '40px', width: '40%', ml: 2, mt: 4 }}
        color='blueButton'
        onClick={() => {
          GlobalActions.setDialogServiceOpen(false)
          GlobalActions.clearDataToSend()
        }}
      >
        <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
          {vocabularyWorkflow.perfect[langSelected]}
        </Typography>
      </Button>
    </Box>
  )
}
