import * as React from 'react'
import vocabularyConsent from 'vocabulary/vocabularyConsent'
import { serviceTypes } from 'vocabulary/vocabularyConsent'
import GlobalContext from 'GlobalContext'

import ServiceInfo from 'components/ServiceInfo/ServiceInfo'

export default function ConsentInfo() {
  const { state, GlobalActions } = React.useContext(GlobalContext)
  let dialogOpenVariant = state.dialogService.index

  const onSubmit = event => {
    const step = getConsentRequestStep(state.dataToSend.service)

    GlobalActions.setDialogServiceOpen({
      step,
    })
  }

  const handleClose = () => {
    GlobalActions.clearDataToSend()
  }

  const content = vocabularyConsent.interactuation[dialogOpenVariant]

  return (
    <ServiceInfo onClose={handleClose} onSubmit={onSubmit} content={content} />
  )
}

const getConsentRequestStep = service => {
  if (service === serviceTypes[0][1]) {
    return 2
  }

  if (service === serviceTypes[1][1]) {
    return 3
  }

  if (service === serviceTypes[2][1]) {
    return 4
  }

  return 0
}
