import { useContext, useEffect } from 'react'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import vocabulary from '../../vocabulary/vocabulary'
import Layout947 from '../Layouts/Layout947'
import GlobalContext from '../../GlobalContext'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import vocabularyConsulting from 'vocabulary/vocabularyConsulting'
import { useOpenDialogService } from 'pages/helpers/useOpenDialogService'
import { Workflow } from 'pages/components/DialogServices/Consulting/Workflow'
import { useNavigate } from 'react-router-dom'

export default function Consulting947() {
  const { langSelected } = useContext(GlobalContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const openDialogService = useOpenDialogService({
    section: 'consulting',
  })
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <Layout947 theme={theme} menu='true'>
      <Box sx={{ mx: '16px', w: 588 }}>
        <Box sx={{ mt: '32px', mb: '48px' }}>
          <Typography variant='h1Small' component='p'>
            {vocabularyConsulting.consulting[langSelected]}
          </Typography>
        </Box>
        <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
          {vocabularyConsulting.text1[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
          {vocabularyConsulting.text2[langSelected]}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            w: 1,
            mb: 6.5,
          }}
        >
          <Button
            variant='contained'
            size='medium'
            sx={{ width: 228, height: '40px', mt: 6.5, mb: 2 }}
            color='blueButton'
            onClick={openDialogService}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabulary.applicationSubmit[langSelected]}
            </Typography>
          </Button>

          <Button
            variant='outlined'
            size='medium'
            sx={{
              width: 228,
              borderColor: 'black',
              height: '40px',
            }}
            color='blueButton'
            onClick={() => {
              navigate(`${window.location.pathname}?open=chat`)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabulary.ask[langSelected]}
            </Typography>
          </Button>
        </Box>
      </Box>
      <Workflow />
    </Layout947>
  )
}
