import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'

export const serviceTypes = [
  ['Пройти проверку на гражданство', 'Pass citizenship check'],
  ['Оформить гражданство РФ', 'Obtain Russian citizenship'],
  ['Выйти из гражданства РФ', 'Renounce Russian citizenship'],
]

const vocabularyCitizenship = {
  citizenship: ['Гражданство', 'Citizenship'],
  infoText1: [
    'В консульстве или посольстве России возможно пройти проверку на наличие гражданства РФ, которая требуется при утрате или истечении срока действия обоих паспортов, оформить гражданство некоторым категориям граждан, либо пройти процедуру выхода из гражданства.',
    'At the Russian consulate or embassy, ​​it is possible to undergo a check of the Russian citizenship, which is required if both passports are lost or expired, to obtain citizenship for certain categories of citizens, or to go through the procedure for renouncing citizenship.',
  ],
  infoText2: [
    'Мы будем рады помочь вам на каждом этапе взаимодействия с консульством: подготовим за вас заявление (и опросный лист, если требуется) в нужном формате на консульском портале, проконсультируем по вашей ситуации и перечню документов, которые необходимо будет взять с собой, при необходимости окажем помощь в записи на приём в консульство.',
    'We will be happy to help you at every stage of interaction with the consulate: we will prepare an application for you (and a questionnaire, if required) in the required format on the consulate portal, we will advise you on your situation and the list of documents that you will have to take with you, and if necessary, we will provide assistance in making an appointment at the consulate.',
  ],
  checkCitizenship: [
    'Проверка наличия гражданства РФ',
    'Checking the Russian citizenship',
  ],
  infoText3: [
    'В случае одновременной утери (кражи) или истечении срока внутреннего и заграничного паспортов, для оформления новых документов необходимо пройти процедуру проверки принадлежности к гражданству РФ.',
    'In case of the simultaneous loss (theft) or expiration of internal and foreign passports, in order to issue new documents, it is necessary to go through the procedure of verifying the citizenship of the Russian Federation.',
  ],
  infoText4: [
    'Для этого требуется выяснить обстоятельства приобретения российского гражданства и указание конкретной статьи, в соответствии с которой заявитель является гражданином РФ.',
    'To do this, you need to find out the circumstances of acquiring Russian citizenship and indicate the specific article in accordance with which the applicant is a citizen of the Russian Federation.',
  ],
  infoText5: [
    'Сроки рассмотрения запроса не регламентированы и зависят от способа и места приобретения гражданства.',
    'The timeframe for satisfying a request is not regulated and depends on the method and place of acquisition of citizenship.',
  ],
  receivingCitizenship: [
    'Получение гражданства РФ',
    'Obtaining the Russian citizenship',
  ],
  infoText6: [
    'Оформить гражданство РФ в большинстве случаев возможно только в органах МВД на территории России. Тем не менее есть ряд случаев, когда это возможно сделать в консульстве или посольстве.',
    'In most cases, obtaining the Russian citizenship is possible only at the Ministry of Internal Affairs on the Russian territory. However, there are a number of cases when this can be done at a consulate or embassy.',
  ],
  userTypes: [
    {
      userName: ['Детям', 'For children'],
      desc: [
        [
          'Если один из родителей (опекунов, усыновителей) является гражданином РФ, то и ребёнок имеет право на получение гражданства России.',
          'If one of the parents (guardians, adoptive parents) is a citizen of the Russian Federation, then the child also has the right to receive Russian citizenship.',
        ],
        [
          'В зависимости от места рождения ребёнка, гражданства второго родителя и наличия / отсутствия у ребёнка гражданства другой страны он может получить гражданство РФ по рождению или быть принят в него, что влияет на сроки рассмотрения заявления.',
          'Depending on the child’s place of birth, the citizenship of the second parent and the presence/absence of citizenship of another country, the child may obtain Russian citizenship by birth or be admitted to it, which affects the time frame for consideration of the application.',
        ],
        [
          'В некоторых случаях подать заявление об оформлении гражданства ребёнку можно одновременно с заявлениями об оформлении свидетельства о рождении и первичного пятилетнего заграничного паспорта ребёнку. Потребуется только одна запись на приём.',
          'In some cases, you can submit an application for citizenship for a child at the same time as an application for a birth certificate and an initial five-year foreign passport for the child. Only one appointment will be required.',
        ],
        [
          'Решение о приёме в гражданство РФ ребёнка в возрасте до 14 лет принимается Посольством на месте. Решение о приёме в гражданство ребёнка в возрасте от 14 до 18 лет принимается только после предварительного согласования в России. Срок рассмотрения такого заявления — до 6 месяцев.',
          'The decision to grant Russian citizenship to a child under the age of 14 is made by the Embassy on the spot. The decision to grant citizenship to a child between the ages of 14 and 18 is made only after prior approval in Russia. The period for consideration of such an application is up to 6 months.',
        ],
      ],
    },
  ],
  userTypes1: [
    {
      userName: ['Бывшим гражданам СССР', 'Former citizens of the USSR'],
      headerDesc: [
        [
          'Граждане бывшего СССР имеют право на получения гражданства России, если:',
          'Citizens of the former USSR have the right to receive Russian citizenship if:',
        ],
      ],

      desc: [
        [
          'на момент 06.02.1992 г. они постоянно проживали на территории России и могут документированно подтвердить данный факт',
          'at the time of 02/06/1992 they were permanently residing on the territory of Russia and can document this fact',
        ],
        [
          'они проживали и проживают в государствах, входивших в состав СССР, и не получили гражданство этих государств (есть временные ограничения действия закона: до 26.10.2024)',
          'they lived and live in states that were part of the USSR, and did not receive citizenship of these states (there are temporary restrictions on the validity of the law: until October 26, 2024)',
        ],
        [
          'имеют статус ветерана Великой Отечественной войны',
          'have the status of a veteran of the Great World War II ',
        ],
      ],
    },
  ],

  citizenshipExit: [
    'Выход из гражданства РФ',
    'Renouncing the Russian citizenship',
  ],
  infoText7: [
    'Гражданин имеет право отказаться от гражданства России для себя и своих детей в случае, если выполнены следующие условия:',
    'A citizen has the right to renounce the Russian citizenship for himself and their children if the following conditions are met:',
  ],
  citizenshipExitList: [
    [
      'у него имеется действующий загранпаспорт гражданина РФ',
      'he/she has a valid foreign passport of a citizen of the Russian Federation',
    ],
    [
      'он находится в стране консульства легально, имеет гражданство этой страны или гарантии предоставления ему иного гражданства в случае выхода из гражданства России',
      'he/she is in the country of the consulate legally, has citizenship of this country or guarantees that he/she will be granted another citizenship in the event of renunciation of the Russian citizenship',
    ],
    [
      'не имеет задолженности по уплате налогов в РФ',
      'has no tax arrears in the Russian Federation',
    ],
    [
      'не имеет неоконченного исполнительного производства в РФ',
      'does not have pending enforcement proceedings in the Russian Federation',
    ],
    [
      'не имеет оснований для признания гражданина не прошедшим военную службу по призыву, не имея на то законных оснований (для мужчин от 18 до 30 лет)',
      'there are no grounds for recognizing a citizen as having not completed military service by conscription, without having legal grounds for this (for men from 18 to 30 years old)',
    ],
  ],
  infoText8: [
    'Базовые комплекты документов, представляемые в консульство / посольство России по вопросам гражданства:',
    'Basic sets of documents submitted to the Russian consulate/embassy on citizenship issues:',
  ],

  userTypes2: [
    {
      userName: ['Все категории граждан', 'All categories of citizens'],
      headerDesc: [
        ['Заявление и опросный лист', 'Application and questionnaire'],
      ],

      desc: [
        [
          'Оригинал и/или копия свидетельства о рождении, браке, разводе, перемены имени (при наличии)',
          'Original and/or copy of birth, marriage, divorce, name change certificate (if available)',
        ],
        [
          'Иностранный документ, удостоверяющий личность (если имеется второе гражданство или ВНЖ)',
          'Foreign identification document (if you have a second citizenship or residence permit)',
        ],
        ['Фотография 3,5 × 4,5 см', 'Photo 3.5x4.5 cm'],
      ],
      ps: ['Смотреть требования к фотографии', 'View photo requirements'],
    },
  ],
  userTypes3: [
    {
      userName: ['Дети', 'Children'],
      desc: [
        [
          'Заявление от имени родителя (иного законного представителя), имеющего гражданство РФ',
          'Application on behalf of a parent (other legal representative) who has Russian citizenship',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт родителя (иного законного представителя), имеющего гражданство РФ. Если имеются оба паспорта, необходимо взять оба паспорта.',
          'Foreign passport and/or internal passport of a parent (other legal representative) with Russian citizenship. If you have both passports, you must take both passports.',
        ],
        [
          'Свидетельство о рождении или другой документ, подтверждающий полномочия законного представителя',
          'Birth certificate or other document confirming the authority of the legal representative',
        ],
        ['Паспорт ребёнка (если имеется)', "Child's passport (if available)"],
        [
          'Свидетельство о браке / разводе / перемене имени, если родитель менял ФИО',
          'Certificate of marriage/divorce/change of name, if the parent changed their full name',
        ],
        [
          'Документ, удостоверяющий личность и гражданство второго родителя (при наличии)',
          'Document proving the identity and citizenship of the second parent (if available)',
        ],
        [
          'Документ, подтверждающий законные основания нахождения ребёнка в стране консульства (удостоверение личности другой страны, внж, виза или прописка)',
          'A document confirming the legal grounds for the child’s presence in the country of the consulate (identity card of another country, residence permit, visa or registration)',
        ],
      ],
      comments: [
        [
          'Согласие второго родителя, имеющего иностранное гражданство, не требуется.',
          'The consent of the second parent who has foreign citizenship is not required.',
        ],
        [
          'Любой иностранный документ должен быть легализован в стране выдачи документа, переведён на русский язык и заверен. Переводы заблаговременно заверяются непосредственно в консульстве или при подаче документов на оформление гражданства ребёнка (Порядок заверения переводов зависит от процедуры оформления гражданства и страны обращения).',
          'Any foreign document must be legalized in the country where the document was issued, translated into Russian and certified. Translations are certified in advance directly at the consulate or when submitting documents for registration of the child’s citizenship (The procedure for certification of translations depends on the procedure for obtaining citizenship and the country of application).',
        ],
      ],
      ps: ['Смотреть требования к фотографии', 'View photo requirements'],
    },
    {
      userName: [
        'Бывшие граждане СССР, проживавшие на начало 1992 года на территории РФ',
        'Former citizens of the USSR who lived on the territory of the Russian Federation at the beginning of 1992',
      ],
      desc: [
        [
          'Заявление о признании гражданином',
          'Application for recognition as a citizen',
        ],
        [
          'Документ, удостоверяющий личность. В случае отсутствия такого документа до подачи заявления о приёме в гражданство проводится установление личности заявителя',
          'Identity document. In the absence of such a document, before submitting an application for admission to citizenship, the identity of the applicant is established',
        ],
        [
          'Документы, подтверждающие наличие или отсутствие гражданства',
          'Documents confirming the presence or absence of citizenship',
        ],

        [
          'Документ или сведения о месте жительства',
          'Document or information about place of residence',
        ],
        [
          'Свидетельство о рождении (при наличии)',
          'Birth certificate (if available)',
        ],
        [
          'Свидетельство о браке / разводе / смене имени (если имеется) при смене ФИО',
          'Certificate of marriage/divorce/change of name (if available) when changing your full name',
        ],
        [
          'Документ, подтверждающий законное нахождение в стране консульства',
          'Document confirming legal presence in the country of the consulate',
        ],
        [
          'Документ, подтверждающий наличие в прошлом гражданства СССР',
          'A document confirming previous USSR citizenship',
        ],
        [
          'Документ, подтверждающий проживание в РФ по состоянию на 06.02.1992',
          'Document confirming residence in the Russian Federation as of 02/06/1992',
        ],
        ['3 фотографии 3,5 × 4,5 см', '3 photos 3.5x4.5 cm'],
      ],
      ps: ['Смотреть требования к фотографии', 'View photo requirements'],
    },

    {
      userName: [
        'Бывшие граждане СССР, проживавшие на начало 1992 года и по сей день на территории других республик, но не получившие новое гражданство',
        'Former citizens of the USSR who lived at the beginning of 1992 and to this day on the territory of other republics, but did not receive new citizenship',
      ],
      desc: [
        [
          'Заявление о приёме в гражданство',
          'Application for admission to citizenship',
        ],
        [
          'Документ, удостоверяющий личность лица без гражданства',
          'Document proving the identity of a stateless person',
        ],
        [
          'Документ, подтверждающий отсутствие гражданства',
          'Document confirming the absence of citizenship',
        ],
        [
          'Документ или сведения о месте жительства',
          'Document or information about the place of residence',
        ],
        [
          'Свидетельство о рождении (при наличии)',
          'Birth certificate (if available)',
        ],
        [
          'Свидетельство о браке / разводе / смене имени (если имеется) при смене ФИО',
          'Marriage / divorce / name change certificate (if applicable) upon name change',
        ],
        [
          'Документ, подтверждающий законное нахождение в стране консульства',
          "Document confirming legal residence in the consulate's country",
        ],
        [
          'Документ, подтверждающий наличие в прошлом гражданства СССР',
          'Document confirming previous citizenship of the USSR',
        ],
        [
          'Документ, подтверждающий владение русским языком (кроме лиц старше 70 лет и инвалидов 1 группы)',
          'Document confirming proficiency in the Russian language (except for persons over 70 years old and Group 1 disabled persons)',
        ],
        ['3 фотографии 3,5 × 4,5 см', '3 photographs 3.5 × 4.5 cm'],
      ],
      ps: ['Смотреть требования к фотографии', 'View photo requirements'],
    },
  ],

  userTypes4: [
    {
      userName: ['Лица старше 18 лет', 'Persons over 18 years of age'],
      desc: [
        ['Заявление', 'Application'],
        [
          'Документ, удостоверяющий личность. В случае отсутствия такого документа до подачи заявления о приёме в гражданство проводится установление личности заявителя',
          'Identity document. In the absence of such a document, before submitting an application for admission to citizenship, the identity of the applicant is established',
        ],
        [
          'Загранпаспорт РФ, при наличии представляется также оригинал внутреннего паспорта РФ',
          'International passport of the Russian Federation, if available, the original internal passport of the Russian Federation must also be presented',
        ],
        [
          'Документ, подтверждающий место жительства',
          'Document confirming place of residence',
        ],
        [
          'Свидетельство о браке / разводе / перемене имени, если была смена ФИО',
          'Certificate of marriage/divorce/change of name, if there was a change of name',
        ],
        [
          'Документ, подтверждающий законные основания нахождения в стране консульства',
          'A document confirming the legal grounds for staying in the country of the consulate',
        ],
        [
          'Документ, подтверждающий наличие гражданства этой страны или гарантий предоставления заявителю иного гражданства в случае выхода из гражданства РФ',
          'A document confirming citizenship of this country or guarantees that the applicant will be granted another citizenship in the event of renunciation of Russian citizenship',
        ],
        [
          'Подтверждение отсутствия задолженности по уплате налогов в РФ',
          'Confirmation of the absence of debt on payment of taxes in the Russian Federation',
        ],
        [
          'Подтверждение отсутствия неоконченного исполнительного производства в отношении заявителя',
          'Confirmation of the absence of pending enforcement proceedings against the applicant',
        ],
        [
          'Подтверждение отсутствия оснований для признания гражданина не прошедшим военную службу по призыву, не имея на то законных оснований (для мужчин от 18 до 30 лет)',
          'Confirmation of the absence of grounds for recognizing a citizen as having not completed military service by conscription without having legal grounds (for men from 18 to 30 years old)',
        ],
        ['3 фотографии 3,5 × 4,5 см', '3 photos 3.5x4.5 cm'],
      ],
      ps: ['Смотреть требования к фотографии', 'View photo requirements'],
    },

    {
      userName: ['Дети', 'Children'],
      desc: [
        [
          'Заявление от имени родителя (иного законного представителя), имеющего гражданство РФ',
          'Application on behalf of a parent (other legal representative) who has Russian citizenship',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт родителя (иного законного представителя), имеющего гражданство РФ. Если имеются оба паспорта, необходимо взять оба паспорта.',
          'Foreign passport and/or internal passport of a parent (other legal representative) with Russian citizenship. If you have both passports, you must take both passports.',
        ],
        [
          'Свидетельство о рождении или другой документ, подтверждающий полномочия законного представителя',
          'Birth certificate or other document confirming the authority of the legal representative',
        ],
        ['Паспорт ребёнка (если имеется)', "Child's passport (if available)"],
        [
          'Свидетельство о браке / разводе / перемене имени, если родитель менял ФИО',
          'Certificate of marriage/divorce/change of name, if the parent changed their full name',
        ],
        [
          'Документ, удостоверяющий личность и гражданство второго родителя (при наличии)',
          'Document proving the identity and citizenship of the second parent (if available)',
        ],
        [
          'Документ, подтверждающий законные основания нахождения ребёнка в стране консульства (удостоверение личности другой страны, внж, виза или прописка)',
          'A document confirming the legal grounds for the child’s presence in the country of the consulate (identity card of another country, residence permit, visa or registration)',
        ],
        [
          'Документ, подтверждающий наличие гражданства этой страны или гарантий предоставления заявителю иного гражданства в случае выхода из гражданства РФ',
          'A document confirming citizenship of this country or guarantees that the applicant will be granted another citizenship in the event of renunciation of Russian citizenship',
        ],

        [
          'Согласие ребёнка (для детей старше 14 лет)',
          'Child consent (for children over 14 years old)',
        ],
        ['Фотографии 3,5 × 4,5 см', 'Photos 3.5x4.5 cm'],
      ],
      ps: ['Смотреть требования к фотографии', 'View photo requirements'],
    },
  ],

  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'заполним заявление (и опросный лист) на консульском портале',
        'проконсультируем по списку документов, которые потребуется предоставить, согласно вашей ситуации',
        'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '60-100',
    },
    {
      header: 'Without appointment at the consulate',
      list: [
        'we will fill out the application (and questionnaire) on the consulate portal',
        'we will advise you on the list of documents that will have to be provided, according to your situation',
        'we will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '60-100',
    },
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'заполним заявление (и опросный лист) на консульском портале',
        'проконсультируем по списку документов, которые потребуется предоставить, согласно вашей ситуации',

        'Запишем вас на приём в консульство',
      ],
      value: '80-120',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'we will fill out the application (and questionnaire) on the consulate portal',
        'we will advise you on the list of documents that will have to be provided, according to your situation',

        'we will schedule an appointment at the consulate for you',
      ],
      value: '80-120',
    },
  ],

  faq: [
    {
      userName: [
        'Кто может обратиться в консульство для оформления гражданства ребёнку?',
        'Who can contact the consulate to obtain citizenship for a child?',
      ],
      desc: [
        [
          'Один из родителей ребёнка (законный представитель), который имеет гражданство РФ.',
          'One of the child’s parents (legal representative) who has the Russian citizenship.',
        ],
      ],
    },
    {
      userName: [
        'Нужно ли согласие от второго родителя-иностранца на приобретение ребёнком гражданства РФ?',
        'Is consent required from the second foreign parent for a child to acquire Russian citizenship?',
      ],
      desc: [
        [
          'Нет, согласие второго родителя, имеющего иностранное гражданство, не требуется.',
          'No, the consent of the second parent who has foreign citizenship is not required.',
        ],
      ],
    },
    {
      userName: [
        'В каком случае считается, что у ребёнка имеется единственный родитель?',
        'In what case is it considered that a child has a single parent?',
      ],
      desc: [
        [
          'Так происходит в ряде случаев, когда второй родитель умер, пропал без вести, лишён родительских прав, либо запись о нем в свидетельстве о рождении ребёнка отсутствует или внесена со слов матери.',
          'This happens in a number of cases when the second parent has died, gone missing, been deprived of parental rights, or there is no entry about him in the child’s birth certificate or it was made according to the words of the mother.',
        ],
      ],
    },
    {
      userName: [
        'Может ли гражданин России оформить гражданство другой страны и какие могут быть для него последствия?',
        'Can a Russian citizen obtain citizenship of another country and what might be the consequences for him/her?',
      ],
      desc: [
        [
          'Да, может, законодательством РФ это не запрещено. Однако о факте получения ВНЖ или гражданства другого государства необходимо уведомить МВД в срок до 60 дней с момента въезда на территорию России (можно онлайн через Госуслуги). ',
          'Yes, maybe the legislation of the Russian Federation does not prohibit this. However, the fact of obtaining a residence permit or citizenship of another state must be notified to the Ministry of Internal Affairs within 60 days from the date of entry into the territory of Russia (this can be done online through State Services).',
        ],
      ],
    },
    {
      userName: [
        'Могут ли граждане Украины, ранее проживавшие на территории Крыма, ДНР, ЛНР, Запорожской и Херсонской области, подать на российское гражданство в консульствах РФ?',
        'Can citizens of Ukraine who previously lived in the territory of Crimea, DPR, LPR, Zaporozhye and Kherson regions apply for Russian citizenship at the consulates of the Russian Federation?',
      ],
      desc: [
        [
          'Нет, оформить гражданство РФ для данной категории лиц возможно только на территории России в органах МВД.',
          'No, it is possible to obtain Russian citizenship for this category of persons only on the territory of Russia through the Ministry of Internal Affairs.',
        ],
      ],
    },
  ],

  faqDesc: [
    [
      {
        question:
          'Кто может обратиться в консульство для оформления гражданства ребёнку?',
        answer:
          'Один из родителей ребёнка (законный представитель), который имеет гражданство РФ.',
      },
      {
        question:
          'Нужно ли согласие от второго родителя-иностранца на приобретение ребёнком гражданства РФ?',
        answer:
          'Нет, согласие второго родителя, имеющего иностранное гражданство, не требуется.',
      },
      {
        question:
          'В каком случае считается, что у ребёнка имеется единственный родитель?',
        answer:
          'Так происходит в ряде случаев, когда второй родитель умер, пропал без вести, лишён родительских прав, либо запись о нем в свидетельстве о рождении ребёнка отсутствует или внесена со слов матери.',
      },
      {
        question:
          'Может ли гражданин России оформить гражданство другой страны и какие могут быть для него последствия?',
        answer:
          'Да, может, законодательством РФ это не запрещено. Однако о факте получения ВНЖ или гражданства другого государства необходимо уведомить МВД в срок до 60 дней с момента въезда на территорию России (можно онлайн через Госуслуги).',
      },
      {
        question:
          'Могут ли граждане Украины, ранее проживавшие на территории Крыма, ДНР, ЛНР, Запорожской и Херсонской области, подать на российское гражданство в консульствах РФ?',
        answer:
          'Нет, оформить гражданство РФ для данной категории лиц возможно только на территории России в органах МВД.',
      },
    ],
    [
      {
        question:
          'Who can contact the consulate to obtain citizenship for a child?',
        answer:
          'One of the child’s parents (legal representative) who has Russian citizenship.',
      },
      {
        question:
          'Is consent required from the second foreign parent for a child to acquire Russian citizenship?',
        answer:
          'No, the consent of the second parent who has foreign citizenship is not required.',
      },
      {
        question:
          'In what case is it considered that a child has a single parent?',
        answer:
          'This happens in a number of cases when the second parent has died, gone missing, been deprived of parental rights, or there is no entry about him in the child’s birth certificate or was made according to the words of the mother.',
      },
      {
        question:
          'Can a Russian citizen obtain citizenship of another country and what might be the consequences for him?',
        answer:
          'Yes, the legislation of the Russian Federation does not prohibit this. However, the fact of obtaining a residence permit or citizenship of another state must be notified to the Ministry of Internal Affairs within 60 days from the date of entry into the territory of Russia (this can be done online through State Services).',
      },
      {
        question:
          'Can citizens of Ukraine who previously lived in the territory of Crimea, DPR, LPR, Zaporozhye and Kherson regions apply for Russian citizenship at the consulates of the Russian Federation?',
        answer:
          'No, it is possible to obtain Russian citizenship for this category of persons only on the territory of Russia through the Ministry of Internal Affairs.',
      },
    ],
  ],
  workflow: {
    requestServiceType: {
      title: ['Что необходимо сделать?', 'What needs to be done?'],
      options: serviceTypes,
    },
    requestPersonWhoObtainCitizenship: {
      title: [
        'Кому нужно оформить гражданство?',
        'Who needs to obtain citizenship?',
      ],
      options: [
        ['Мне (бывшему гражданину СССР)', 'Me (former USSR citizen)'],
        ['Ребенку', 'Child'],
      ],
    },
    requestCase: {
      title: [
        'Выберите ситуацию, подходящую под ваш случай',
        'Select the situation that applies to your case',
      ],
      options: [
        [
          'На момент 06.02.1992 г. я проживал на территории РФ, но не был официально зарегистрирован по месту жительства',
          'As of 02.06.1992, I was residing in the territory of the Russian Federation but was not officially registered at my place of residence',
        ],
        [
          'Я проживал и проживаю в другом государстве, входившим в состав СССР, и не получил гражданство этого государства',
          'I was and am residing in another state that was part of the USSR and did not acquire citizenship of that state',
        ],
        [
          'Я - ветеран Великой Отечественной войны, афганской войны или войны в Чечне',
          'I am a veteran of the Great Patriotic War, the Afghan War, or the Chechen War',
        ],
      ],
    },
    requestPersonWhoRenounceCitizenship: {
      title: [
        'Кому нужно выйти из гражданства РФ?',
        'Who needs to renounce Russian citizenship?',
      ],
      options: [
        ['Мне', 'Me'],
        ['Мне и моему ребенку', 'Me and my child'],
        ['Только моему ребенку', 'Only my child'],
      ],
    },
    requestBirthDate: {
      title: ['Укажите дату вашего рождения', 'Enter the date of your birth'],
      format: ['ДД.ММ.ГГГГ', 'DD.MM.YYYY'],
      label: ['Дата рождения', 'Birth date'],
    },
    requestChildsBirthDate: {
      title: [
        'Укажите дату рождения ребёнка',
        'Enter the date of your child’s birth',
      ],
      label: ['Дата рождения ребёнка', 'Child’s birth date'],
    },
    requestChildsPlaceOfBirth: {
      title: ['Где родился ребенок?', 'Where was the child born?'],
      options: [
        ['В России', 'In Russia'],
        ['За пределами России', 'Outside Russia'],
      ],
    },
    requestParentsCitizenship: {
      title: {
        default: [
          'Гражданство родителей (законных представителей) ребёнка',
          'Citizenship of parents (legal guardians) of the child',
        ],
        onChildBirth: [
          'Гражданство родителей (законных представителей) на момент рождения ребёнка',
          "Citizenship of parents (legal guardians) at the time of the child's birth",
        ],
      },
      options: [
        [
          'Оба родителя имели гражданство РФ',
          'Both parents had Russian citizenship',
        ],
        [
          'Единственный родитель ребёнка имел гражданство РФ',
          'The only parent of the child had Russian citizenship',
        ],
        [
          'Один из родителей имел гражданство РФ, а другой родитель являлся лицом без гражданства, или был признан безвестно отсутствующим, или место его нахождения было неизвестно',
          'One parent had Russian citizenship, and the other parent was stateless, or was declared missing, or their whereabouts were unknown',
        ],
        [
          'Один родитель имеет гражданство РФ, второй — иностранный гражданин',
          'One parent has Russian citizenship, the other is a foreign citizen',
        ],
      ],
    },
    requestCitizenshipInfo: {
      title: [
        'Где и как вы получили гражданство РФ?',
        'Where and how did you obtain Russian citizenship?',
      ],
      options: {
        previousPeriod: [
          [
            'На момент 06.02.1992 г. я проживал и был зарегистрирован на территории РФ и приобрёл гражданство автоматически',
            'As of 02.06.1992, I was residing and registered in the territory of the Russian Federation and acquired citizenship automatically',
          ],
          [
            'В посольстве или консульстве РФ за рубежом',
            'At the Russian embassy or consulate abroad',
          ],
          [
            'В органах ФМС/МВД на территории России',
            'At the FMS/MVD offices in the territory of Russia',
          ],
          [
            'Во время службы в частях Российской армии',
            'During service in the Russian army',
          ],
          ['Не знаю', "I don't know"],
        ],
        nowadays: [
          [
            'Я родился в России и получил гражданство по рождению',
            'I was born in Russia and acquired citizenship by birth',
          ],
          [
            'В посольстве или консульстве РФ за рубежом',
            'At the Russian embassy or consulate abroad',
          ],
          [
            'В органах ФМС/МВД на территории России',
            'At the FMS/MVD offices in the territory of Russia',
          ],
          ['Не знаю', "I don't know"],
        ],
      },
    },
  },
  orderDescription: [
    [
      {
        headerText: 'Оформление заявки',
        cardText:
          'Опишите вашу ситуацию. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Заполнение заявления (и опросного листа)',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали и заполнит онлайн-заявление на консульском портале. Вы получите заполненный документ, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Подача документов в консульство',
        cardText:
          'В день записи приходите в консульство с пакетом документов для подачи на получение, выход или проверку гражданства',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submitting an application',
        cardText:
          'Describe your situation. You can send us the required documents either simultaneously with the application or directly to the lawyer after consultation with him/her',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Filling out the application (and questionnaire)',
        cardText:
          'Our lawyer will contact you, discuss the details and fill out an online application on the consulate portal. You will receive a completed document that you will have to take with you to the consulate. The service completion time is 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Scheduling an appointment at the consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Submitting documents to the consulate',
        cardText:
          'On the day of your appointment, arrive to the consulate with a package of documents to obtain, renounce, or verify the citizenship',
        cardImage: stampImage,
      },
    ],
  ],
  priceText1: [
    'Стоимость услуги зависит от индивидуальных обстоятельств заявителя и будет уточнена после консультации с юристом.',
    'The cost of the service depends on the individual circumstances of the applicant and will be clarified after consultation with a lawyer.',
  ],
}

export default vocabularyCitizenship
