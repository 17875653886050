import LeftDrawerSmallScreen from '../components/LeftDrawer/LeftDrawerSmallScreen'
import HeaderMobileMobile from '../components/Header/HeaderMobile'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Footer599 from '../components/Footer/Footer599'
import SpeedDialButton from '../components/SpeedDialButton'
import WorkFlowMain from '../components/Workflow/WorkFlowMain'
import { Page } from 'components/Page/Page'

export default function Layout599({ children, footer, chat }) {
  const theme = useTheme()

  return (
    <Page>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 1,
          backgroundColor: theme.palette.primary.surface,
          height: footer === 'no' ? '100vh' : null,
        }}
      >
        <LeftDrawerSmallScreen />

        <HeaderMobileMobile smallScreen={true} />

        {chat !== 'no' ? <SpeedDialButton /> : null}
        <WorkFlowMain smallScreen={true} />
        {children}

        {footer !== 'no' ? <Footer599 /> : null}
      </Box>
    </Page>
  )
}
