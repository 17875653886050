import serviceAxios from './serviceAxios'

export async function putUserRequest(data, langSelected) {
  let response = null
  data.lang = langSelected

  try {
    response = await serviceAxios.put(`private/put-user-request`, data)
  } catch (error) {
    console.error('Request error:', error.response)

    return error.response
  }

  return response
}
