import React from 'react'
import GlobalContext from 'GlobalContext'

export const useOpenDialogService = ({ section, step = 0 }) => {
  const { GlobalActions } = React.useContext(GlobalContext)

  return React.useCallback(() => {
    GlobalActions.setDataToSend({
      section,
    })
    GlobalActions.setDialogServiceOpen({
      section,
      step,
      serviceIndex: null,
    })
  }, [GlobalActions, section, step])
}
