import { Divider, Box } from '@mui/material'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'

export default function Faq12391439({ value }) {
  const theme = useTheme()

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        height: 'fit-content',
      }}
    >
      {value?.map(val => (
        <Box
          sx={{
            backgroundColor: theme.palette.primary.surfaceContainerLow,
          }}
          key={uuidv4()}
        >
          <Divider variant='large' sx={{ mb: '24px' }} />
          <Typography variant='labelLarge'>{val.question}</Typography>
          <Typography
            variant='bodySmall'
            component='div'
            sx={{ mb: '24px', mt: '12px' }}
          >
            {val.answer}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
