const vocabularyAppointment = {
  getAppointment: ['Запись в консульство', 'Get an appointment'],
  text1: [
    'Для всех наших услуг существует два варианта тарифов:',
    'There are two tariff options for all our services:',
  ],
  withoutAppointment: [
    'Без записи в консульство',
    'Without an appointment at the consulate',
  ],
  withAppointment: [
    'С записью в консульство',
    'With an appointment at the consulate',
  ],
  withoutAppointmentText: [
    'Вы записываетесь на приём самостоятельно, при этом мы со своей стороны готовы консультировать вас на каждом этапе',
    'You make an appointment yourself, and we, for our part, are ready to advise you at every stage',
  ],
  withAppointmentText: [
    'Мы полностью берём на себя процесс записи на приём, вам достаточно просто указать местонахождение консульств, которое вы планируете посетить',
    'We completely take care of the process of making an appointment; you just need to indicate the location of the consulates you plan to visit',
  ],
  text2: [
    'Отдельной услуги по записи на приём в консульство мы не предоставляем.',
    'We do not provide a separate service for making an appointment at the consulate.',
  ],
  howToGetAnAppointment: [
    'Как записаться на приём самостоятельно',
    'How can i receive an appointment by myself',
  ],
  text3: [
    'Записаться на приём в Консульство России можно через электронную систему на официальном сайте нужного вам Посольства или Консульства России.',
    'You can make an appointment at the Russian Consulate through the electronic system on the official website of the Russian Embassy or Consulate you need.',
  ],
  appointmentList: [
    [
      'Зайдите на сайт нужного вам Посольства или Консульства России',
      'Go to the website of the Russian Embassy or Consulate you need',
    ],
    [
      'В разделе «Консульские услуги» или «Консульский отдел» выберите «Запись на приём»',
      'In the “Consular Services” or “Consular Section” section, select “Make an Appointment”',
    ],
    [
      'Перейдите в систему электронной записи и выберите нужную вам услугу',
      'Go to the electronic recording system and select the service you need',
    ],
    [
      'Зарегистрируйтесь, чтобы получить доступ к календарю с доступными датами и временем',
      'Register to access a calendar with available dates and time slots',
    ],
    [
      'Если выбрать день невозможно, это значит, что свободных слотов нет. В таком случае добавьте себя в список ожидания',
      'If it is impossible to select a day, this means that there are no free slots. If so, please add yourself to the waiting list.',
    ],
    [
      'На почту, которую вы указали при регистрации, придёт письмо со ссылкой, по которой нужно переходить каждый день (ровно один раз в день) для повышения рейтинга заявки. Ожидание может занять от 10 до 30 дней, в зависимости от вашей последовательности, выбранной услуги и местонахождения консульства',
      'An email with a link will be sent to the email address you provided during registration, which you must click on every day (exactly once a day) to increase your application rating. The wait may take from 10 to 30 days, depending on your sequence, the chosen service and the location of the consulate',
    ],
  ],
}
export default vocabularyAppointment
