import { useCallback, useContext } from 'react'
import GlobalContext from 'GlobalContext'

export const useLoginFlowOpen = window => {
  const { GlobalActions } = useContext(GlobalContext)

  return useCallback(
    () => GlobalActions.setLoginFlowOpened({ window }),
    [GlobalActions],
  )
}

export const useLoginFlowClose = () => {
  const { GlobalActions } = useContext(GlobalContext)

  return useCallback(() => GlobalActions.setLoginFlowClosed(), [GlobalActions])
}
