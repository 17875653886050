import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import GlobalContext from 'GlobalContext'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { v4 as uuidv4 } from 'uuid'
import { useTheme } from '@mui/material/styles'
import { itemRenderer } from 'components/ServiceInfo/itemRenderer'

export function ServiceInfoAccordion({ title, content, first }) {
  const theme = useTheme()
  const { langSelected } = React.useContext(GlobalContext)

  return (
    <Accordion
      key={uuidv4()}
      disableGutters
      sx={{
        borderTop: first
          ? '1px solid ' + theme.palette.primary.outlineVariant
          : 'none',
        borderBottom: '1px solid ' + theme.palette.primary.outlineVariant,
        backgroundColor: theme.palette.primary.surface,
      }}
      elevation={0}
      square={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1-content'
        id='panel1-header'
      >
        <Typography variant='labelLarge'>{title[langSelected]}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {content?.map((item, index) => itemRenderer(item, index))}
      </AccordionDetails>
    </Accordion>
  )
}
