import React from 'react'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import { useTheme } from '@mui/material/styles'

export const DatePicker = props => {
  const [value, setValue] = React.useState(props.value)
  const theme = useTheme()

  const onChange = value => {
    props.onChange?.(value)

    setValue(value)
  }

  return (
    <MUIDatePicker
      label={props.label}
      value={value}
      onChange={onChange}
      slotProps={{
        textField: {
          variant: 'outlined',
          sx: {
            width: '100%',
            mb: 2,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },
          },
        },
      }}
      format={'DD/MM/YYYY'}
    />
  )
}
