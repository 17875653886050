import { requestConsentType } from './requestConsentType'
import { requestDocumentType } from './requestDocumentType'
import { requestPoaType } from './requestPoaType'
import { requestApplicationType } from './requestApplicationType'

import { getTranslation } from 'pages/helpers/workflowStepSchema'

import { serviceTypes } from 'vocabulary/vocabularyLegalEntities'

export const getStepByChosenService = ({ service }) => {
  const [powerOfAttorney, spousalConsent, application] = serviceTypes.map(
    getTranslation('eng'),
  )

  const nextStep = {
    [powerOfAttorney]: 2,
    [spousalConsent]: 3,
    [application]: 4,
  }[service]

  return nextStep ? nextStep : 5
}

export const getSteps = langSelected => [
  {
    step: 1,
    progress: 20,
    nextStep: getStepByChosenService,
    ...requestDocumentType(langSelected),
  },
  {
    step: 2,
    progress: 50,
    nextStep: 5,
    ...requestPoaType(langSelected),
  },
  {
    step: 3,
    progress: 50,
    nextStep: 5,
    ...requestConsentType(langSelected),
  },
  {
    step: 4,
    progress: 50,
    nextStep: 5,
    ...requestApplicationType(langSelected),
  },
]
