import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyPassport'

const citizenshipConfirmation =
  ({ initialIssue }) =>
  langSelected => {
    const { title, variants } = vocabulary.workflow.citizenshipConfirmation
    const { description, checkbox } = initialIssue
      ? variants.initialIssue
      : variants.other

    return createStepSchema(
      title[langSelected],
      [
        {
          name: 'paragraph.description',
          type: 'paragraph',
          componentProps: {
            text: description[langSelected],
          },
        },
        {
          name: 'serviceDetails.citizenshipConfirmation',
          type: 'checkbox',
          componentProps: {
            label: checkbox[langSelected],
          },
        },
      ],
      ({ serviceDetails }) => ({
        serviceDetails: {
          citizenshipConfirmation:
            serviceDetails?.citizenshipConfirmation || false,
        },
        paragraph: {
          description: description[langSelected],
        },
      }),
      ({ serviceDetails }) => ({
        serviceDetails: {
          citizenshipConfirmation: serviceDetails?.citizenshipConfirmation,
        },
      }),
    )
  }

export const citizenshipConfirmationForInitialIssue = citizenshipConfirmation({
  initialIssue: true,
})
export const citizenshipConfirmationForOtherReasons = citizenshipConfirmation({
  initialIssue: false,
})
