import React from 'react'
import { v4 as uuid } from 'uuid'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import MUIRadioGroup from '@mui/material/RadioGroup'

export const RadioGroup = ({
  options,
  name,
  onChange,
  sx = {},
  value = null,
  ...props
}) => {
  return (
    <FormControl>
      <MUIRadioGroup value={value} onChange={onChange} name={name} {...props}>
        {options.map(({ value, label }) => (
          <FormControlLabel
            sx={{ mb: 1, mx: 0 }}
            value={value}
            control={<Radio />}
            label={label}
            key={uuid()}
          />
        ))}
      </MUIRadioGroup>
    </FormControl>
  )
}
