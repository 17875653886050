import React, { useContext, useState } from 'react'
import { useTheme, Box, Typography, Card } from '@mui/material/'
import Layout1239 from '../Layouts/Layout1239'
import GlobalContext from '../../GlobalContext'
import { useNavigate } from 'react-router-dom'
import CookiePolicy from 'pages/components/CookiePolicy'
import vocabulary from 'vocabulary/vocabulary'

export default function CookiePolicy1239() {
  const { langSelected } = useContext(GlobalContext)
  const [stateMenu, setMenu] = useState(false)
  const navigate = useNavigate()

  function setStateMenu(val) {
    setMenu(val)
  }

  const theme = useTheme()

  return (
    <Layout1239
      stateMenu={stateMenu}
      setStateMenu={setStateMenu}
      langSelected={langSelected}
      theme={theme}
      menu='true'
    >
      <Box sx={{ mt: 8, mx: 2 }}>
        <Typography variant='h1Small' component='p'>
          {vocabulary.cookiePolicy[langSelected]}
        </Typography>
        <CookiePolicy />
      </Box>
    </Layout1239>
  )
}
