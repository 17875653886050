import * as React from 'react'
import { Button, Typography, Box, Stack, LinearProgress } from '@mui/material/'
import { useClearWorkflowSearchParams } from 'pages/helpers/useClearWorkflowSearchParams'

import { v4 as uuidv4 } from 'uuid'

import { useTheme } from '@mui/material/styles'

import vocabularyPowerOfAttorney from 'vocabulary/vocabularyPowerOfAttorney'
import GlobalContext from 'GlobalContext'
import squareImg from 'images/brown-square.png'
import leftArrow from 'images/icon/arrow-left.png'
import crossClose from 'images/icon/cross-close.png'

import vocabularyWorkflow from 'vocabulary/vocabularyWorkflow'

export default function DialogPoaWorkflow2() {
  const { langSelected, state, GlobalActions } = React.useContext(GlobalContext)
  let dialogOpenVariant = state.dialogService.index

  const clearSearchParams = useClearWorkflowSearchParams()
  const theme = useTheme()

  const handleSendData = event => {
    event.preventDefault()
    GlobalActions.setDataToSend({
      service: vocabularyPowerOfAttorney.typesOfPoa[dialogOpenVariant][1],
    })
    GlobalActions.setDialogServiceOpen({
      step: 2,
      index: state.dialogService.index,
    })

    clearSearchParams()
  }

  const handleClose = () => {
    GlobalActions.setDialogServiceOpen(false)
    GlobalActions.clearDataToSend()

    clearSearchParams()
  }

  return (
    <React.Fragment key={uuidv4()}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <Box sx={{ mx: '16px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '76px',
            }}
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                handleClose()
              }}
            >
              <img src={leftArrow} alt='left arrow' width={'24px'} />
            </Box>

            <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
              <img src={crossClose} alt='cross' width={'24px'} />
            </Box>
          </Box>

          <Box sx={{ mb: '32px' }}>
            <Typography
              variant='titleLarge'
              component='div'
              sx={{ mb: '32px' }}
            >
              {
                vocabularyPowerOfAttorney.typesOfPoa[dialogOpenVariant][
                  langSelected
                ]
              }
            </Typography>

            {vocabularyPowerOfAttorney.PoatServicesInteractuation[
              dialogOpenVariant
            ].list ? (
              <>
                {vocabularyPowerOfAttorney.PoatServicesInteractuation[
                  dialogOpenVariant
                ].list.headers.map((val, index) => {
                  return (
                    <React.Fragment key={uuidv4()}>
                      <Box>
                        <Typography
                          variant='titleSmall'
                          component='div'
                          sx={{ mt: index === 0 ? null : '20px' }}
                        >
                          {val[langSelected]}
                        </Typography>

                        {vocabularyPowerOfAttorney.PoatServicesInteractuation[
                          dialogOpenVariant
                        ].list.body[index].map(val => {
                          return (
                            <React.Fragment key={uuidv4()}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  mt: index === 0 ? '20px' : '16px',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <img
                                  src={squareImg}
                                  alt='logo'
                                  width={20}
                                  style={{ marginRight: '16px' }}
                                />

                                <Typography variant='bodySmall'>
                                  {val[langSelected]}
                                </Typography>
                              </Box>
                            </React.Fragment>
                          )
                        })}
                      </Box>
                    </React.Fragment>
                  )
                })}
              </>
            ) : null}
          </Box>

          {vocabularyPowerOfAttorney.PoatServicesInteractuation[
            dialogOpenVariant
          ]?.text?.map(value => {
            return (
              <Typography
                variant='bodySmall'
                component='p'
                sx={{ mb: '32px' }}
                key={uuidv4()}
              >
                {value[langSelected]}
              </Typography>
            )
          })}
        </Box>
        <Box>
          <Stack>
            <LinearProgress
              color='progressBar'
              variant='determinate'
              value={10}
              sx={{ backgroundColor: theme.palette.primary.primaryContainer }}
            />
          </Stack>
          <Box
            sx={{
              pb: '16px',
              pt: '24px',
              mt: '8px',
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              variant='text'
              disabled={true}
              size='medium'
              sx={{ border: 'none', height: '40px' }}
              color='blueButton'
              onClick={handleSendData}
            >
              <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                {vocabularyWorkflow.skip[langSelected]}
              </Typography>
            </Button>

            <Button
              variant='text'
              size='medium'
              sx={{
                border: 'none',
                height: '40px',
                mr: '16px',
                '&.Mui-disabled': {
                  border: 'none', // Remove border when the button is disabled
                },
              }}
              color='blueButton'
              onClick={handleSendData}
            >
              <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                {vocabularyWorkflow.forward[langSelected]}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}
