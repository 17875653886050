import React from 'react'
import MUIButton from '@mui/material/Button'

const prposByType = {
  primary: {
    variant: 'contained',
    sx: { height: '40px', py: '10px', px: '24px' },
  },
  secondary: {
    variant: 'outlined',
    sx: { borderColor: 'black', height: '40px', py: '10px', px: '24px' },
  },
}

export const Button = ({ children, type = 'primary', ...props }) => {
  const { sx, variant } = prposByType[type]

  return (
    <MUIButton
      variant={props.variant || variant}
      size='medium'
      color='blueButton'
      disableElevation={true}
      {...props}
      sx={{ ...sx, ...props.sx }}
    >
      {children}
    </MUIButton>
  )
}
