import Layout947 from '../Layouts/Layout947'
import { PrivacyPolicy } from 'pages/components/PrivacyPolicy/PrivacyPolicy'

export default function PrivacyPolicy947() {
  return (
    <Layout947>
      <PrivacyPolicy />
    </Layout947>
  )
}
