import React from 'react'
import { Typography, Box, Link, Container, Paper } from '@mui/material'
import { useContext } from 'react'
import GlobalContext from '../../GlobalContext'
import vocabularyCookiePolicy from 'vocabulary/vocabularyCookiePolicy'

const CookiePolicy = () => {
  const { langSelected } = useContext(GlobalContext)
  const policyContent = vocabularyCookiePolicy[langSelected]
  return (
    <Box sx={{ p: 1 }}>
      <Container maxWidth='xl'>
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          {policyContent.map((section, index) => (
            <div key={index}>
              <Typography variant='h5' gutterBottom>
                {section.title}
              </Typography>
              {section.paragraphs.map((paragraph, idx) => (
                <Typography key={idx} paragraph>
                  {paragraph}
                </Typography>
              ))}
              {section.subSections &&
                section.subSections.map((subSection, idx) => (
                  <div key={idx}>
                    <Typography variant='subtitle1' gutterBottom>
                      {subSection.title}
                    </Typography>
                    <ul>
                      {subSection.items.map((item, i) => (
                        <li key={i}>
                          <Typography paragraph>{item}</Typography>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          ))}
        </Paper>
      </Container>
    </Box>
  )
}

export default CookiePolicy
