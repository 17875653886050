const vocabularyCookiePolicy = [
  [
    {
      title: 'Общие положения',
      paragraphs: [
        'При посещении сайта https://consul.online/ (далее — «Сайт»), Пользователь предоставляет согласие на использование cookie-файлов в соответствии с настоящей политикой в отношении данного типа файлов.',
      ],
    },
    {
      title: 'Что такое файлы cookie',
      paragraphs: [
        'Cookie-файл — это небольшой текстовый файл, который сайт сохраняет на компьютере или мобильном устройстве Пользователя при посещении сайта. Использование данных файлов позволяет обеспечить корректную работу сайта, повысить его эффективность, а также получить информацию о посещении страниц пользователями и их предпочтениях в интернете. Файл cookie не идентифицирует личность, а представляет собой комбинацию информации об устройстве, браузере и Пользователе. В нашем случае эта информация является анонимной и зашифрованной.',
      ],
    },
    {
      title: 'Классификация',
      paragraphs: [
        'Файлы cookie можно классифицировать по следующим параметрам:',
      ],
      subSections: [
        {
          title: 'По отправителю',
          items: [
            'Собственные: Отправляются на устройство Пользователя с оборудования или домена, принадлежащего Сайту',
            'Сторонние: Отправляются на устройство Пользователя с оборудования или домена, управляемого другой организацией, обрабатывающей данные, полученные через файлы cookie',
          ],
        },
        {
          title: 'По сроку хранения',
          items: [
            'Сессионные: Активны с момента входа Пользователя на сайт до завершения сессии работы в браузере и автоматически удаляются при его закрытии',
            'Постоянные: Сохраняются на устройстве между сессиями и не удаляются при закрытии браузера. Данные файлы могут использоваться для определения частоты посещений сайтов и их эффективности. Срок хранения варьируется в зависимости от их назначения от нескольких минут до нескольких лет',
          ],
        },
        {
          title: 'По цели использования',
          items: [
            'Обязательные: Обеспечивают основные функции сайта, такие как навигация по страницам и доступ к защищённым областям. Без этих файлов сайт не сможет работать корректно',
            'Функциональные: Позволяют идентифицировать пользователей, возвращающихся на сайт, и запоминать их предпочтения (например, язык или регион)',
            'Аналитические: Позволяют собирать информацию о том, как посетители используют сайт, улучшать его работу и устранять неполадки в случае их появления. Такие данные собираются в агрегированной и анонимной форме',
            'Рекламные: Используются для того, чтобы предоставлять пользователям рекламу, которая соответствует их интересам, а также измерять эффективность рекламных кампаний',
          ],
        },
      ],
    },
    {
      title: 'Использование',
      paragraphs: [
        'Сайт использует программное обеспечение для определения количества пользователей, посещающих его страницы, и частоты этих посещений. Сайт не собирает персональные данные Пользователей или IP-адреса отдельных лиц. Данные используются исключительно в анонимной форме для статистики и улучшения работы сайта.',
      ],
      subSections: [
        {
          title: 'Исключение',
          items: [
            'cookie-файлы Google Analytics могут использовать IP-адреса для распознавания пользователей, но не для их персональной идентификации. Эти файлы помогают сайту получать данные о его посещаемости и улучшать пользовательский опыт. Пользователь может отказаться от использования Google Analytics, перейдя по следующей ссылке: ',
          ],
        },
      ],
    },
    {
      title: 'Основание',
      paragraphs: [
        'Основание, которое легитимирует обработку в связи с использованием обязательных файлов cookie, — это законный интерес компании «ICC Consul» LLP (KZ), предоставляющей свои услуги посредством сайта, в целях облегчения навигации Пользователя по сайту.',
        'Основание, которое легитимирует обработку в связи с использованием файлов cookie, не необходимых для навигации (функциональные, аналитические и рекламные), — это согласие Пользователя. Пользователь может отозвать это согласие в любое время, что не влияет на законность обработки, выполненной до его отзыва. Для этого Пользователь может ознакомиться со следующим разделом данной Политики о настройке и отключении файлов cookie, а также получить доступ к информации о процедуре реализации прав и другой информации, связанной с обработкой его персональных данных, через Политику конфиденциальности.',
      ],
      subSections: [
        {
          title: 'Управление',
          items: [
            'Большинство браузеров позволяют просматривать, удалять и блокировать cookie-файлы, а также настраивать уведомления об их записи. При этом, в случае удаления всех cookie-файлов произойдёт сброс всех настроек сайтов для Пользователя.',
            'С инструкциями по управлению cookie-файлами для распространённых браузеров можно ознакомиться по ссылкам ниже:',
          ],
        },
        {
          title: 'Информация о других браузерах и типах устройств',
          items: [
            'https://www.aboutcookies.org',
            'http://www.cookiecentral.com/faq',
            'Если Пользователь использует несколько разных устройств для доступа к сайту (компьютер, смартфон, планшет), то необходимо убедиться, что настройки браузера на каждом из них соответствуют его предпочтениям.',
          ],
        },
      ],
    },
  ],
  [
    {
      title: 'General provisions',
      paragraphs: [
        'By visiting the https://consul.online/ website (hereinafter referred to as the "Website"), the User consents to the use of cookie files in accordance with this policy.',
      ],
    },
    {
      title: 'What cookies files are',
      paragraphs: [
        "A cookie file is a small text file that the Website saves on the User's computer or mobile device when the User is visiting the Website. The use of these files allows the Website to function correctly, enhance its efficiency, and obtain information about users’visits and preferences on the Internet. A cookie file does not identify the individual but represents a combination of information about the device, browser, and the User. In our case, this information is anonymous and encrypted.",
      ],
    },
    {
      title: 'Classification',
      paragraphs: [
        'Cookie files can be classified according to the following parameters:',
      ],
      subSections: [
        {
          title: 'By the sender',
          items: [
            "First-party: Sent to the User's device from the equipment or a domain owned by the Website.",
            "Third-party: Sent to the User's device from the equipment or a domain managed by another organization process the data obtained through the cookie files.",
          ],
        },
        {
          title: 'By storage period',
          items: [
            'Session cookies: Active from the moment the User enters the Website until the end of the browser session and automatically deleted when the browser is closed.',
            'Permanent cookies: Stored on the device between sessions and not deleted when the browser is closed. These files may be used to determine the frequency of Website visits and their effectiveness. The storage period varies depending on their purpose from a few minutes to several years.',
          ],
        },
        {
          title: 'By purpose of use',
          items: [
            'Essential: Ensure basic Website functions such as navigating pages and accessing secure areas. Without these files, the Website may not function properly.',
            'Functional: Allow identifying returning Users and remembering their preferences (e.g., language or region).',
            'Analytical: Gather information on how visitors use the Website, improve its performance, and address issues if they arise. Such data is collected in aggregated and anonymous form.',
            'Advertising: Used to provide Users with ads that match their interests and measure the effectiveness of advertising campaigns.',
          ],
        },
      ],
    },
    {
      title: 'Usage',
      paragraphs: [
        'The Website uses software to determine the number of Users visiting its pages and the frequency of these visits. The Website does not collect personal data or IP addresses of individuals. Data is used exclusively in anonymous form for statistics and Website performance improvement.',
      ],
      subSections: [
        {
          title: 'Exception',
          items: [
            'Google Analytics cookies may use IP addresses to recognize Users but not for their personal identification. These files help the Website obtain data on its traffic and improve user experience. Users can opt-out of Google Analytics by visiting the following link:',
          ],
        },
      ],
    },
    {
      title: 'Legal Basis',
      paragraphs: [
        'The legal basis legitimizing the processing related to the use of essential cookie files is the legitimate interest of ICC Consul LLP (KZ), that provides its services through the Website in facilitating the navigation of Users on the Website.',
        'The legal basis legitimizing the processing related to the use of non-essential cookie files (functional, analytical, and advertising) is User’s consent. The User may withdraw this consent at any time, which does not affect the legality of the processing performed before its withdrawal. For this, the User can refer to the following section of this Cookie Policy on setting and disabling cookie files, as well as access information on the procedure for exercising rights and other information related to the processing of their personal data through the Privacy Policy.',
      ],
      subSections: [
        {
          title: 'Management',
          items: [
            'Most browsers allow to view, delete, and block cookie files, as well as customize notifications about their storage. Deleting all cookie files will reset all Website settings for the User.',
            'The instructions for managing cookie files for popular browsers can be found at the links below:',
          ],
        },
        {
          title: 'Information about other browsers and device types',
          items: [
            'https://www.aboutcookies.org',
            'http://www.cookiecentral.com/faq',
            'If the User uses multiple devices to access the Website (computer, smartphone, tablet), the User must ensure that browser settings on each of them match their preferences.',
          ],
        },
      ],
    },
  ],
]
export default vocabularyCookiePolicy
