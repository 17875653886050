import React from 'react'
import Box from '@mui/material/Box'
import MUICheckbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

export const Checkbox = ({ onChange, value, label, name, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        my: 3,
      }}
      {...props}
    >
      <MUICheckbox
        sx={{
          m: 0,
          p: 0,
          mr: 1,
        }}
        checked={value}
        onChange={onChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography
        variant='bodySmall'
        component='p'
        sx={{
          fontColor: 'text.primary',
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}
