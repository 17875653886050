import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyPassport'

export const childHasPassport = langSelected => {
  const { title, options } = vocabulary.workflow.childHasPassport

  return createStepSchema(
    title[langSelected],
    [
      {
        name: 'serviceDetails.childHasPassport',
        type: 'radio',
        componentProps: {
          options: createOptions(options, langSelected),
        },
      },
    ],
    ({ serviceDetails }) => ({
      serviceDetails: {
        childHasPassport: serviceDetails?.childHasPassport,
      },
    }),
  )
}
