import { useContext, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import logoImage from '../../images/logo-big.png'
import circleArrowImage from '../../images/circle-arrow-down.png'
import visaImage from '../../images/visa.png'
import masterCardImage from '../../images/masterCard.png'
import applePayImage from '../../images/apple.png'
import googlePayImage from '../../images/googlePay.png'
import payPalImage from '../../images/payPal.png'
import bitcoinImage from '../../images/bitcoin.png'
import vocabulary from '../../vocabulary/vocabulary'
import Layout599 from '../Layouts/Layout599'
import GlobalContext from '../../GlobalContext'
import styles from './styles599'
import { v4 as uuidv4 } from 'uuid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CardServiceProcess599 from '../components/ServiceProcess/CardServiceProcess599'
import CardServiceList599 from '../components/ServiceList/CardServiceList599'
import { useNavigate } from 'react-router-dom'

export default function Main599({ isBrowser }) {
  const { langSelected, GlobalActions } = useContext(GlobalContext)
  const [stateMenu, setMenu] = useState(false)
  const [serviceData, setServiceData] = useState([])
  const [serviceProcessData, setServiceProcessData] = useState([])
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const navigate = useNavigate()
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleClick = () => {
    window.scrollTo({
      top: windowHeight,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    setServiceData(vocabulary.serviceData[langSelected])
    setServiceProcessData(vocabulary.serviceProcess[langSelected])
  }, [langSelected])

  function setStateMenu(val) {
    setMenu(val)
  }

  const theme = useTheme()

  return (
    <Layout599
      stateMenu={stateMenu}
      setStateMenu={setStateMenu}
      langSelected={langSelected}
      theme={theme}
      main={true}
    >
      <Box
        style={{
          backgroundColor: theme.palette.primary.header,
          height: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box
          style={{
            backgroundColor: theme.palette.primary.header,
            height: '800px',

            display: 'flex',
            flexDirection: 'column',

            padding: '16px',
          }}
        >
          <Box
            style={{
              height: '328px',
              display: 'flex',
              marginTop: '10vh',
              flexDirection: 'column',
            }}
          >
            <Typography variant='titleSmall' sx={{ mb: '8px' }}>
              CONSUL ONLINE
            </Typography>

            <Typography variant='h1Small' sx={{ mb: '28px' }}>
              {vocabulary.siteName[langSelected]}
            </Typography>

            <Typography variant='bodySmall' component='p' sx={{ mb: '28px' }}>
              {vocabulary.slogan1[langSelected]}
              <br />
              {vocabulary.slogan2[langSelected]}
            </Typography>

            <Box onClick={handleClick}>
              <img
                src={circleArrowImage}
                alt='logo'
                style={{
                  width: '28px',
                  position: 'absolute',
                  bottom: '320px',
                  left: '30px',
                  zIndex: 200,
                }}
              />
            </Box>
          </Box>

          {!isBrowser ? (
            <img
              src={logoImage}
              alt='logo'
              style={{
                position: 'absolute',
                bottom: '-2vh',
                left: '0px',
                zIndex: 2,
                height: '408px',
              }}
            />
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          mx: '16px',
        }}
      >
        <Box sx={{ mb: '32px', mt: '80px' }}>
          <Typography variant='h2Small'>
            {vocabulary.ourServices[langSelected]}
          </Typography>
        </Box>
        {serviceData?.map((service, index) => (
          <CardServiceList599
            langSelected={langSelected}
            key={uuidv4()}
            headerText={service.headerText}
            cardText={service.cardText}
            cardImage={service.cardImage}
            index={index}
          />
        ))}

        <Typography
          variant='bodySmall'
          component='p'
          sx={{ mb: 2, mt: '48px' }}
        >
          {vocabulary.aboutUs1[langSelected]}
        </Typography>

        <Typography variant='bodySmall' component='p' sx={{ mb: '72px' }}>
          {vocabulary.aboutUs2[langSelected]}
        </Typography>
        <Box sx={{ mb: '32px' }}>
          <Typography variant='h2Small' component='p'>
            {vocabulary.serviceObtaining[langSelected]}
          </Typography>
        </Box>
        {serviceProcessData.map((service, index) => {
          let last = false

          if (serviceProcessData.length === index + 1) {
            last = true
          }

          return (
            <CardServiceProcess599
              langSelected={langSelected}
              key={uuidv4()}
              headerText={service.headerText}
              cardText={service.cardText}
              cardImage={service.cardImage}
              last={last}
            />
          )
        })}

        <Typography variant='titleSmall' sx={{ mt: '32px' }}>
          {vocabulary.processingTiming[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p' sx={{ mt: '8px' }}>
          {vocabulary.twoDays[langSelected]}
        </Typography>

        <Typography variant='titleSmall' sx={{ mt: '32px' }}>
          {vocabulary.paymentsMethods[langSelected]}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: '12px',
            mb: '12px',
          }}
        >
          <img src={visaImage} alt='visa' style={styles.smallImageStyle} />
          <img src={masterCardImage} alt='mc' style={styles.smallImageStyle} />
          <img
            src={applePayImage}
            alt='apple-pay'
            style={styles.smallImageStyle}
          />
          <img
            src={googlePayImage}
            alt='google-pay'
            style={styles.smallImageStyle}
          />
          <img src={payPalImage} alt='pay-pal' style={styles.smallImageStyle} />
          <img
            src={bitcoinImage}
            alt='bitcoin'
            style={styles.smallImageStyle}
          />
        </Box>

        <Typography variant='bodySmall' component='p' sx={{ mt: '8px' }}>
          {vocabulary.possiblePayRussian[langSelected]}
        </Typography>
        <Box sx={{ display: 'flex', my: 6.5, justifyContent: 'center' }}>
          <Button
            variant='contained'
            size='medium'
            sx={{ width: '328px', height: '40px' }}
            color='blueButton'
            onClick={() => {
              GlobalActions.userFlowMainOpen(true)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabulary.applicationSubmit[langSelected]}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Layout599>
  )
}
