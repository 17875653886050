import { useContext } from 'react'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import vocabulary from '../../vocabulary/vocabulary'
import Layout599 from '../Layouts/Layout599'
import GlobalContext from '../../GlobalContext'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import vocabularyAppointment from 'vocabulary/vocabularyAppointment'
import { CalendarCheck, CalendarX } from '@phosphor-icons/react'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

export default function Appointment599() {
  const { langSelected, GlobalActions } = useContext(GlobalContext)
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <Layout599 theme={theme} menu='true'>
      <Box sx={{ mt: '56px', mx: '16px' }}>
        <Box sx={{ mt: '32px', mb: '48px' }}>
          <Typography variant='h1Small' component='p'>
            {vocabularyAppointment.getAppointment[langSelected]}
          </Typography>
        </Box>
        <Typography variant='bodySmall' component='p' sx={{ mb: 3 }}>
          {vocabularyAppointment.text1[langSelected]}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            height: 164,
            border: '1px solid ' + theme.palette.primary.outlineVariant,
            borderRadius: '12px',
            p: 2,
          }}
        >
          <CalendarX
            size={54}
            color={theme.palette.primary.onTertiaryContainer}
            style={{ marginRight: '16px' }}
          />
          <Box sx={{ width: 1, mt: 1 }}>
            <Typography variant='titleSmall' component='p' sx={{ mb: 2 }}>
              {vocabularyAppointment.withoutAppointment[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='p'>
              {vocabularyAppointment.withoutAppointmentText[langSelected]}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: 164,
            border: '1px solid ' + theme.palette.primary.outlineVariant,
            borderRadius: '12px',
            p: 2,
            mt: 2,
          }}
        >
          <CalendarCheck
            size={54}
            color={theme.palette.primary.onTertiaryContainer}
            style={{ marginRight: '16px' }}
          />
          <Box sx={{ width: 1, mt: 1 }}>
            <Typography variant='titleSmall' component='p' sx={{ mb: 2 }}>
              {vocabularyAppointment.withAppointment[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='p'>
              {vocabularyAppointment.withAppointmentText[langSelected]}
            </Typography>
          </Box>
        </Box>

        <Typography variant='bodySmall' component='p' sx={{ my: 6.5 }}>
          {vocabularyAppointment.text2[langSelected]}
        </Typography>

        <Typography variant='titleLarge' component='p'>
          {vocabularyAppointment.howToGetAnAppointment[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p' sx={{ my: 2 }}>
          {vocabularyAppointment.text3[langSelected]}
        </Typography>
        {vocabularyAppointment.appointmentList.map((val, index) => (
          <Box
            key={uuidv4()}
            sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}
          >
            <Typography
              variant='h2Small'
              color={theme.palette.primary.onTertiaryContainer}
              component='p'
              sx={{ mr: 2 }}
            >
              {index + 1}
            </Typography>
            <Typography variant='bodySmall' component='p'>
              {val[langSelected]}
            </Typography>
          </Box>
        ))}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            w: 1,
            alignItems: 'center',
            mb: 6.5,
          }}
        >
          <Button
            variant='contained'
            size='medium'
            sx={{ width: '328px', height: '40px', mt: 6.5, mb: 2 }}
            color='blueButton'
            onClick={() => {
              GlobalActions.userFlowMainOpen(true)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabulary.applicationSubmit[langSelected]}
            </Typography>
          </Button>

          <Button
            variant='outlined'
            size='medium'
            sx={{
              width: '328px',
              borderColor: 'black',
              height: '40px',
              mx: 'auto',
            }}
            color='blueButton'
            onClick={() => {
              navigate(`${window.location.pathname}?open=chat`)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabulary.ask[langSelected]}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Layout599>
  )
}
