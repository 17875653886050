import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyCitizenship'

export const requestChildsData = langSelected => {
  const { format } = vocabulary.workflow.requestBirthDate
  const { title: birthDateTitle, label } =
    vocabulary.workflow.requestChildsBirthDate
  const { title: placeOfBirthTitle, options } =
    vocabulary.workflow.requestChildsPlaceOfBirth

  return createStepSchema(
    null,
    [
      {
        type: 'title',
        name: 'birthDateTitle',
      },
      {
        type: 'date-picker',
        name: 'serviceDetails.childBirthDate',
        componentProps: {
          label: label[langSelected],
          format: format[langSelected],
        },
      },
      {
        type: 'title',
        name: 'placeOfBirthTitle',
      },
      {
        type: 'radio',
        name: 'serviceDetails.childPlaceOfBirth',
        componentProps: {
          options: createOptions(options, langSelected),
        },
      },
    ],
    ({ serviceDetails }) => {
      return {
        birthDateTitle: birthDateTitle[langSelected],
        placeOfBirthTitle: placeOfBirthTitle[langSelected],
        serviceDetails: {
          childBirthDate: serviceDetails?.childBirthDate || null,
          childPlaceOfBirth: serviceDetails?.childPlaceOfBirth || null,
        },
      }
    },
  )
}
