import * as React from 'react'
import {
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from '@mui/material/'
import { v4 as uuidv4 } from 'uuid'
import { useTheme } from '@mui/material/styles'
import GlobalContext from 'GlobalContext'
import vocabularyWorkflow from 'vocabulary/vocabularyWorkflow'
import { Globe, MapPinSimpleArea } from '@phosphor-icons/react'
import { WorkflowContainer } from 'components/Workflow/WorkflowContainer'

export default function CountrySelection({ nextStep, ...props }) {
  const theme = useTheme()
  const [selected, setSelected] = React.useState(false)

  const { langSelected, GlobalActions, state } = React.useContext(GlobalContext)
  const [countrySelected, setCountrySelected] = React.useState(
    Object.keys(vocabularyWorkflow.countries[langSelected])[0],
  )
  const [cities, setCities] = React.useState(
    vocabularyWorkflow.countries[langSelected][countrySelected],
  )
  React.useEffect(() => {
    setCitySelected(
      vocabularyWorkflow.countries[langSelected][countrySelected][0],
    )
    setCities(vocabularyWorkflow.countries[langSelected][countrySelected])
  }, [countrySelected])

  const [citySelected, setCitySelected] = React.useState(
    vocabularyWorkflow.countries[langSelected][countrySelected][0],
  )

  const handleClose = () => {
    GlobalActions.clearDataToSend()
  }

  const [selectedAppointment, setSelectedAppointment] = React.useState('')

  const handleConfirm = () => {
    const values = {
      city: citySelected,
      country: countrySelected,
      appointment: selectedAppointment,
    }

    const step = nextStep
      ? nextStep(values, state.dataToSend)
      : state.dialogService.step + 1

    GlobalActions.setDialogServiceOpen({
      step,
      index: state.dialogService.index,
    })
    GlobalActions.setDataToSend(values)
  }

  return (
    <WorkflowContainer
      onConfirm={handleConfirm}
      onSkip={handleConfirm}
      onClose={handleClose}
      canMoveForward={selected}
      progress={15}
    >
      <Typography component='p' variant='titleLarge' sx={{ mb: '32px' }}>
        {vocabularyWorkflow.chooseConsulate[langSelected]}
      </Typography>

      <TextField
        value={countrySelected}
        placeholder={vocabularyWorkflow.country[langSelected]}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Globe size={24} weight='light' />
            </InputAdornment>
          ),
        }}
        onChange={event => {
          setSelected(true)
          setCountrySelected(event.target.value)
        }}
        sx={{
          minWidth: 1,
          height: 56,
          mr: '16px',
          borderRadius: 0,
          '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            color: theme.palette.primary.onSurface,
          },

          '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
            color: theme.palette.primary.onSurfaceVariant,
            opacity: 1,
          },
        }}
        variant='outlined'
        margin='dense'
        select
        label={vocabularyWorkflow.country[langSelected]}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
        }}
      >
        {Object.keys(vocabularyWorkflow.countries[langSelected]).map(
          (val, index) => (
            <MenuItem
              key={uuidv4()}
              sx={{ display: 'flex', alignItems: 'center' }}
              value={val}
            >
              {val}
            </MenuItem>
          ),
        )}
      </TextField>

      <TextField
        value={citySelected}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <MapPinSimpleArea size={24} weight='light' />
            </InputAdornment>
          ),
        }}
        onChange={event => {
          setSelected(true)
          setCitySelected(event.target.value)
        }}
        sx={{
          minWidth: 1,
          height: 56,
          mr: '16px',
          borderRadius: 0,
          '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            color: theme.palette.primary.onSurface,
          },

          '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
            color: theme.palette.primary.onSurfaceVariant,
            opacity: 1,
          },
        }}
        variant='outlined'
        margin='dense'
        select
        label={vocabularyWorkflow.city[langSelected]}
      >
        {cities.map((val, index) => (
          <MenuItem
            key={uuidv4()}
            sx={{ display: 'flex', alignItems: 'center' }}
            value={val}
          >
            {val}
          </MenuItem>
        ))}
      </TextField>

      <FormControl>
        <FormLabel id='demo-radio-buttons-group-label'>
          <Typography
            variant='titleSmall'
            component='p'
            sx={{ mt: '32px', mb: 2 }}
          >
            {vocabularyWorkflow.wantAnAppointment[langSelected]}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          value={selectedAppointment}
          onChange={event => {
            setSelected(true)
            setSelectedAppointment(event.target.value)
          }}
          name='radio-buttons-group'
          sx={{ height: 96 }}
        >
          <FormControlLabel
            value='yes'
            control={<Radio />}
            label={vocabularyWorkflow.yes[langSelected]}
          />

          <FormControlLabel
            value='no'
            control={<Radio />}
            label={vocabularyWorkflow.iDoItMyself[langSelected]}
          />
        </RadioGroup>
      </FormControl>
    </WorkflowContainer>
  )
}
