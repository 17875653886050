import mergeWith from 'lodash.mergewith'

export function merge(...objects) {
  // Customizer function to replace arrays entirely
  function customizer(objValue, srcValue) {
    if (Array.isArray(objValue)) {
      return srcValue
    }
  }

  return mergeWith({}, ...objects, customizer)
}
