import { createStepSchema } from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyConsulting'

const FINAL_STEP = 2

export const getSteps = langSelected => {
  const { title, additionalInfo } = vocabulary.workflow.requestDetails
  const requestDetails = createStepSchema(
    title[langSelected],
    [
      {
        type: 'text-field',
        name: 'serviceDetails?.comment',
        componentProps: {
          placeholder: additionalInfo[langSelected],
        },
      },
    ],
    ({ serviceDetails }) => ({ comment: serviceDetails?.comment }),
  )

  return [
    {
      step: 1,
      progress: 50,
      nextStep: FINAL_STEP,
      ...requestDetails,
    },
  ]
}
