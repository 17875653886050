import { vocabulary } from 'vocabulary/vocabularyRequest'

const getPOANamings = ({ section, service }, langSelected) => {
  const title = vocabulary.section[section][langSelected]

  return {
    summary: { title },
    details: {
      title,
      subtitle: vocabulary.powerOfAttorney.subtitle[service][langSelected],
    },
  }
}

const getPassportNamings = ({ section, serviceDetails = {} }, langSelected) => {
  const title = vocabulary.section[section][langSelected]
  const { passportVariant } = serviceDetails

  return {
    summary: { title },
    details: {
      title,
      subtitle: vocabulary.passport.subtitle[passportVariant]?.[langSelected],
    },
  }
}

const getConsentNamings = (
  { service, additionalServiceType },
  langSelected,
) => {
  const title = vocabulary.consent.summaryTitle[service][langSelected]

  const details =
    service !== 'Agreement'
      ? {
          title,
          subtitle:
            vocabulary.consent.subtitle[additionalServiceType][langSelected],
        }
      : {
          title:
            vocabulary.consent.subtitle[additionalServiceType][langSelected],
        }

  return {
    summary: { title },
    details,
  }
}

const getCertificatesNamings = ({ service, section }, langSelected) => {
  const sectionTitle = vocabulary.section[section][langSelected]
  const serviceTitle = vocabulary.certificates.subtitle[service]?.[langSelected]

  const title = service !== 'Not sure' ? serviceTitle : sectionTitle

  return {
    summary: { title },
    details: { title },
  }
}

const getCommonNamings = (
  { section, service, additionalServiceType },
  langSelected,
) => {
  const title = vocabulary.section[section][langSelected]

  return {
    summary: { title },
    details: { title },
  }
}

const getNamingsBySection = {
  poa: getPOANamings,
  passport: getPassportNamings,
  consent: getConsentNamings,
  certificates: getCertificatesNamings,
  citizenship: getCommonNamings,
  zags: getCommonNamings,
  consulting: getCommonNamings,
  legalEntities: getCommonNamings,
  other: getCommonNamings,
}

export const getTitleAndSubtitle = (requestData, langSelected) => {
  const { section } = requestData

  return getNamingsBySection[section](requestData, langSelected)
}
