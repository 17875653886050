import { Box, Typography } from '@mui/material'
import squareImg from '../../images/brown-square.png'

export default function CustomList({ val }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <img
        src={squareImg}
        alt='logo'
        width={20}
        style={{ marginRight: '16px' }}
      />
      <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
        {val}
      </Typography>
    </Box>
  )
}
