import React from 'react'
import Typography from '@mui/material/Typography'
import GlobalContext from 'GlobalContext'

export const Paragraph = ({ text, index }) => {
  const { langSelected } = React.useContext(GlobalContext)

  return (
    <Typography
      variant='bodySmall'
      component='p'
      sx={{
        my: index === 0 ? '8px' : '20px',
        fontColor: 'text.primary',
      }}
    >
      {text[langSelected]}
    </Typography>
  )
}
