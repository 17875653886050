import { useContext, useState } from 'react'
import { Button, ToggleButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import vocabulary from '../../vocabulary/vocabulary'
import Layout947 from '../Layouts/Layout947'
import GlobalContext from '../../GlobalContext'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import vocabularyPowerOfAttorney from 'vocabulary/vocabularyPowerOfAttorney'
import arrowRightImg from '../../images/arrow-black-right.png'
import vocabularyConsent from 'vocabulary/vocabularyConsent'
import vocabularyCertificates from 'vocabulary/vocabularyCertificates'
import vocabularyLegalEntities from 'vocabulary/vocabularyLegalEntities'
import vocabularyCitizenship from 'vocabulary/vocabularyCitizenship'
import vocabularyZags from 'vocabulary/vocabularyZags'
import vocabularyOther from 'vocabulary/vocabularyOther'
import vocabularyPassport from 'vocabulary/vocabularyPassport'
import vocabularyPrices from 'vocabulary/vocabularyPrices'
import PriceCard from 'pages/components/PriceCard/PriceCard'

import squareImg from '../../images/brown-square.png'

export default function Prices947() {
  const { langSelected, GlobalActions } = useContext(GlobalContext)
  const [pageActive, setPageActive] = useState(0)
  const [initial, setInitial] = useState(true)
  const [menuActive, setMenuActive] = useState(null)
  const [headerMenu, setHeaderMenu] = useState('')
  const theme = useTheme()

  const handleClick = (index, header) => {
    switch (index) {
      case 0:
        setMenuActive({
          priceWithoutAppointment:
            vocabularyPowerOfAttorney.priceWithoutAppointment,
          priceWithAppointment: vocabularyPowerOfAttorney.priceWithAppointment,
        })
        break
      case 1:
        setMenuActive({
          priceWithoutAppointment: vocabularyPassport.priceWithoutAppointment,
          priceWithAppointment: vocabularyPassport.priceWithAppointment,
        })
        break
      case 2:
        setMenuActive({
          priceWithoutAppointment: vocabularyConsent.priceWithoutAppointment,
          priceWithAppointment: vocabularyConsent.priceWithAppointment,
        })
        break
      case 3:
        setMenuActive({
          priceWithoutAppointment:
            vocabularyCertificates.priceWithoutAppointment,
          priceWithAppointment: vocabularyCertificates.priceWithAppointment,
        })
        break
      case 4:
        setMenuActive({
          priceWithoutAppointment:
            vocabularyLegalEntities.priceWithoutAppointment,
          priceWithAppointment: vocabularyLegalEntities.priceWithAppointment,
        })
        break
      case 5:
        setMenuActive({
          priceWithoutAppointment:
            vocabularyCitizenship.priceWithoutAppointment,
          priceWithAppointment: vocabularyCitizenship.priceWithAppointment,
        })
        break
      case 6:
        setMenuActive({
          priceWithoutAppointment: vocabularyZags.priceWithoutAppointment,
          priceWithAppointment: vocabularyZags.priceWithAppointment,
        })
        break
      case 7:
        setMenuActive({
          priceWithoutAppointment: vocabularyOther.priceWithoutAppointment,
          priceWithAppointment: vocabularyOther.priceWithAppointment,
        })
        break

      default:
    }

    setHeaderMenu(header)
    setPageActive(index)
  }

  const consentPageExtraPrice = pageActive === 2

  return (
    <Layout947 theme={theme} menu='true'>
      <Box sx={{ display: 'flex', flexDirection: 'row', m: 5 }}>
        <Box sx={{ width: 468 }}>
          <Typography variant='h1Small' component='p' sx={{ mx: 2 }}>
            {vocabularyPrices.pricesAndTariffs[langSelected]}
          </Typography>

          {initial ? (
            <Box sx={{ mx: 2, mb: 6.5, width: 468 }}>
              <Box sx={{ mb: '48px' }}></Box>
              <Typography variant='titleLarge' component='p' sx={{ mb: 4 }}>
                {vocabularyPrices.selectService[langSelected]}
              </Typography>

              {vocabularyPrices.typesOfPrice.map((val, index) => {
                return (
                  <Box
                    key={uuidv4()}
                    sx={{
                      borderTop:
                        !index > 0
                          ? '1px solid ' + theme.palette.primary.outlineVariant
                          : 'none',
                      borderBottom:
                        '1px solid ' + theme.palette.primary.outlineVariant,
                      backgroundColor: theme.palette.primary.surface,
                      width: '100%',
                      height: '48px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => {
                      setInitial(false)
                      handleClick(index, val[langSelected])
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {vocabularyPrices.icons[index]}
                      <Typography
                        variant='labelLarge'
                        component='p'
                        sx={{ ml: 1 }}
                      >
                        {val[langSelected]}
                      </Typography>
                    </Box>

                    <Box>
                      <img
                        src={arrowRightImg}
                        alt='logo'
                        width={20}
                        style={{ marginTop: '4px', marginRight: '16px' }}
                      />
                    </Box>
                  </Box>
                )
              })}
            </Box>
          ) : (
            <Box sx={{ mt: '56px', mx: '16px', mb: 6.5 }}>
              <Typography variant='h2Small' component='p' sx={{ mb: 4 }}>
                {headerMenu}
              </Typography>
              {pageActive !== 8 && pageActive !== 7 ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <PriceCard
                    data={menuActive.priceWithoutAppointment[langSelected]}
                    squareImg={squareImg}
                    colorBorder={true}
                  />
                  <Box sx={{ mt: 2 }} />

                  <PriceCard
                    data={menuActive.priceWithAppointment[langSelected]}
                    squareImg={squareImg}
                    colorBorder={false}
                  />

                  {consentPageExtraPrice ? (
                    <>
                      <Typography
                        variant='h2Small'
                        component='p'
                        sx={{ my: 4 }}
                      >
                        {vocabularyConsent.contractsAgreements[langSelected]}
                      </Typography>

                      <PriceCard
                        data={
                          vocabularyConsent.priceWithAppointment1[langSelected]
                        }
                        squareImg={squareImg}
                        colorBorder={false}
                      />

                      <Box sx={{ mt: 2 }} />

                      <PriceCard
                        data={
                          vocabularyConsent.priceWithoutAppointment1[
                            langSelected
                          ]
                        }
                        squareImg={squareImg}
                        colorBorder={false}
                      />
                    </>
                  ) : null}
                </Box>
              ) : (
                <Box>
                  <Typography
                    variant='bodySmall'
                    component='div'
                    sx={{ mb: 2 }}
                  >
                    {vocabularyOther.priceText[langSelected]}
                  </Typography>

                  <Typography
                    variant='bodySmall'
                    component='div'
                    sx={{ mb: '32px' }}
                  >
                    {vocabularyPassport.priceText2[langSelected]}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  my: 6.5,
                  display: 'flex',

                  width: '100%',
                }}
              >
                <Button
                  variant='contained'
                  size='medium'
                  sx={{ width: 228, height: '40px' }}
                  color='blueButton'
                  onClick={() => {
                    GlobalActions.userFlowMainOpen(true)
                  }}
                >
                  <Typography
                    variant='labelLarge'
                    sx={{ textTransform: 'none' }}
                  >
                    {vocabulary.applicationSubmit[langSelected]}
                  </Typography>
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        <Box>
          {!initial ? (
            <Box
              sx={{ mx: 2, mb: 6.5, display: 'flex', flexDirection: 'column' }}
            >
              <Typography variant='titleLarge' component='p' sx={{ mb: 4 }}>
                {vocabularyPrices.selectService[langSelected]}
              </Typography>

              {vocabularyPrices.typesOfPrice.map((val, index) => {
                return (
                  <ToggleButton
                    key={uuidv4()}
                    value={index}
                    variant='text'
                    selected={index === pageActive}
                    size='medium'
                    sx={{
                      border: 'none',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                    onClick={() => {
                      handleClick(index, val[langSelected])
                    }}
                  >
                    <Typography
                      component='p'
                      color='primary'
                      variant='labelLarge'
                      sx={{ textTransform: 'none' }}
                    >
                      {val[langSelected]}
                    </Typography>
                  </ToggleButton>
                )
              })}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Layout947>
  )
}
