const vocabularyPersonalArea = {
  personalArea: ['Личный кабинет', 'Personal area'],
  profile: ['Профиль', 'Profile'],
  applications: ['Заявки', 'Applications'],
  changePassword: ['Изменить пароль', 'Change password'],
  exit: ['Выйти', 'Exit'],
  openOrder: ['Открыть заявку', 'Open order'],
  changeOrder: ['Открыть заявку', 'Change order'],
  copyOrder: ['Копировать заявку', 'Copy order'],
  cancelOrder: ['Отменить заявку', 'Cancel order'],
  service: ['Услуга', 'Service'],
  status: ['Стaтус', 'Status'],
  confirmPassword: ['Подтвердите пароль', 'Confirm password'],
}

export default vocabularyPersonalArea
