import * as React from 'react'
import vocabulary from 'vocabulary/vocabularyPassport'
import GlobalContext from 'GlobalContext'
import { ServiceInfoModal } from 'components/ServiceInfo/__Modal/ServiceInfoModal'
import { itemRenderer } from 'components/ServiceInfo/itemRenderer'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'

export function PhotoRequirements() {
  const { state } = React.useContext(GlobalContext)
  const isOpened = state.section === 'photoRequirements'

  const content = vocabulary.photoRequirements

  return (
    <DialogModalWindow open={isOpened}>
      <ServiceInfoModal>
        {content.map((item, index) => itemRenderer(item, index))}
      </ServiceInfoModal>
    </DialogModalWindow>
  )
}
