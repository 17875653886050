import React from 'react'
import { useSearchParams } from 'react-router-dom'

export const useClearWorkflowSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return React.useCallback(() => {
    const { section, service, additiaonalServiceType, id, ...leftParams } =
      searchParams

    setSearchParams(leftParams)
  }, [])
}
