import { RadioGroup } from './RadioGroup'
import { TextField } from './TextField'
import { Select } from './Select'
import { RadioGroupWithText } from './RadioGroupWithText'
import { Switch } from './Switch'
import { DatePicker } from './DatePicker'
import { Title } from './Title'
import { Checkbox } from './Checkbox'
import { Paragraph } from './Paragraph'
import { Link } from './Link'

export const formFields = {
  radio: RadioGroup,
  'text-field': TextField,
  select: Select,
  'radio-with-additional-text': RadioGroupWithText,
  switch: Switch,
  'date-picker': DatePicker,
  title: Title,
  checkbox: Checkbox,
  paragraph: Paragraph,
  link: Link,
}
