import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import {
  Box,
  Button,
  Typography,
} from '../../../../node_modules/@mui/material/index'
import GlobalContext from '../../../GlobalContext'
import vocabularyWorkflow from '../../../vocabulary/vocabularyWorkflow'

const StripeForm = () => {
  const stripe = useStripe()
  const elements = useElements()
  const { langSelected, GlobalActions, state } = React.useContext(GlobalContext)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isActive, setIsActive] = useState(true)

  const handleSubmit = async event => {
    event.preventDefault()
setIsActive(false)
    if (!stripe || !elements) {
      return
    }

    const { error } = await stripe.confirmPayment({
      elements,

      redirect: 'always',
      confirmParams: {
        return_url: 'http://consul.online/payments_recieved',
      },
    })

    if (error) {
      setErrorMessage(error.message)
    } else {
      GlobalActions.setDialogServiceOpen({
        serviceIndex: state.dialogService.serviceIndex,
        step: state.dialogService.step + 1,
      })

      GlobalActions.clearDataToSend()
    }
  }

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
      onSubmit={handleSubmit}
    >
      <PaymentElement />

      <Box
        sx={{
          pb: 4,
          pt: '24px',
          mt: '8px',
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'column',
          flexGrow: 1,
          mx: 2,
        }}
      >
        <Button
          variant='contained'
          disabled={!stripe||!isActive}
          size='medium'
          sx={{ border: 'none', height: '40px', mb: 2 }}
          color='blueButton'
          onClick={handleSubmit}
        >
          <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
            {vocabularyWorkflow.payNow[langSelected]}
          </Typography>
        </Button>
      </Box>

      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
}

export default StripeForm
