import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Copy, Eye, PencilSimpleLine, XCircle } from '@phosphor-icons/react'

import { Box, Typography, Popover } from '@mui/material/'

import vocabularyPersonalArea from 'vocabulary/vocabularyPersonalArea'
import GlobalContext from 'GlobalContext'

export const RequestPopover = ({ onOpen, onCancel, ...props }) => {
  const { langSelected } = useContext(GlobalContext)

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Typography
          variant='labelLarge'
          component='div'
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={onOpen}
        >
          <Eye weight='light' size={18} style={{ marginRight: '8px' }} />

          {vocabularyPersonalArea.openOrder[langSelected]}
        </Typography>
        <Typography
          variant='labelLarge'
          component='div'
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'red',
            mt: 2,
            cursor: 'pointer',
          }}
          onClick={onCancel}
        >
          <XCircle size={18} weight='light' style={{ marginRight: '8px' }} />

          {vocabularyPersonalArea.cancelOrder[langSelected]}
        </Typography>
        {/* <Typography
        variant='labelLarge'
        component='div'
        sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
      >
        <PencilSimpleLine
          weight='light'
          size={18}
          style={{ marginRight: '8px' }}
        />

        {vocabularyPersonalArea.changeOrder[langSelected]}
      </Typography>
      <Typography
        variant='labelLarge'
        component='div'
        sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
      >
        <Copy
          size={18}
          weight='light'
          style={{ marginRight: '8px' }}
        />

        {vocabularyPersonalArea.copyOrder[langSelected]}
      </Typography>
      <Typography
        variant='labelLarge'
        component='div'
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'red',
        }}
      >
        <XCircle
          size={18}
          weight='light'
          style={{ marginRight: '8px' }}
        />

        {vocabularyPersonalArea.cancelOrder[langSelected]}
      </Typography> */}
      </Box>
    </Popover>
  )
}
