import { Buildings, FileText, Bank, Gavel } from '@phosphor-icons/react'
import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'

export const serviceTypes = [
  ['Доверенность', 'Power of attorney'],
  ['Согласие от супруга', 'Spousal consent'],
  ['Заявление в налоговую', 'Tax application'],
]

const powerOfAttorneyTypes = [
  ['Генеральная', 'General'],
  [
    'На покупку или продажу доли ООО',
    'For buying or selling a share of an LLC',
  ],
  ['На представительство в суде', 'For representation in court'],
  [
    'На представительство в госорганах',
    'For representation in government agencies',
  ],
]

const consentTypes = [
  ['От супруга на покупку доли в ООО', 'From spouse to buy shares'],
  ['От супруга на продажу доли в ООО', 'From spouse to sell shares'],
  ['От супруга на изменение доли в ООО', 'From spouse to change shares'],
  ['От супруга на дарение доли в ООО', 'From spouse to give shares'],
  ['От супруга на залог доли в ООО', 'From spouse to deduct property'],
]

const applicationTypes = [
  ['На регистрацию юридического лица', 'For registration of a legal entity'],
  ['На внесение изменений', 'For making changes'],
  [
    'На реорганизацию юридического лица',
    'For reorganization of a legal entity',
  ],
]

const vocabularyLegalEntities = {
  headerText: [
    'Документы для юридических лиц',
    'Documents for legal entities:',
  ],
  infoText1: [
    'Находясь за рубежом и имея при этом бизнес в России, для взаимодействия с госорганами внутри страны вам может потребоваться оформить, например, доверенность на вашего представителя в РФ или заявление в налоговую.',
    'Being abroad and having a business in Russia, in order to interact with government agencies within the country, you may need to issue, for example, a Power of Attorney for your representative in the Russian Federation or an application to the tax office.',
  ],
  infoText2: [
    'Такие документы можно получить в ближайшем к вам Российском консульстве. Однако сотрудники консульства не занимаются составлением текстов, только заверяют заранее подготовленные в специальном формате проекты документов.',
    'Such documents can be obtained from the Russian consulate closest to you. However, consulate staff are not involved in drafting texts; they only certify prepared draft documents in a special format.',
  ],
  infoText3: [
    'Мы поможем вам подготовить такой проект документа и разместим его в нужном формате на консульском портале, а также проконсультируем по поводу записи на приём в консульство или запишем вас сами.',
    'We will help you prepare such a draft document and upload it in the required format to the consulate portal, and also advise you on making an appointment at the consulate or will make an appointment for you.',
  ],
  typesOfDocuments: [
    {
      section: ['Доверенности:', 'Consular certificates'],
      types: [
        {
          id: 0,
          service: serviceTypes[0][1],
          additionalServiceType: powerOfAttorneyTypes[0][1],
          text: ['Генеральная', 'General'],
          icon: <FileText size={24} weight='light' />,
        },
        {
          id: 1,
          service: serviceTypes[0][1],
          additionalServiceType: powerOfAttorneyTypes[1][1],
          text: [
            'На покупку или продажу доли ООО',
            'For the purchase or sale of a share in an LLC',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
        {
          id: 2,
          service: serviceTypes[0][1],
          additionalServiceType: powerOfAttorneyTypes[2][1],
          text: ['На представительство в суде', 'For representation in court'],
          icon: <Gavel size={24} weight='light' />,
        },

        {
          id: 3,
          service: serviceTypes[0][1],
          additionalServiceType: powerOfAttorneyTypes[3][1],
          text: [
            'На представительство в госорганах',
            'For representation in government agencies',
          ],
          icon: <Bank size={24} weight='light' />,
        },
      ],
    },
    {
      section: ['Согласия: ', 'Consents:'],
      types: [
        {
          id: 4,
          service: serviceTypes[1][1],
          text: [
            'Супруга на сделку с долей ООО',
            'Spouse for a transaction with a share of an LLC',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
      ],
    },
    {
      section: ['Заявления в налоговую: ', 'Applications to the tax office:'],
      types: [
        {
          id: 5,
          service: serviceTypes[2][1],
          additionalServiceType: applicationTypes[0][1],
          text: [
            'На регистрацию юридического лица',
            'For registration of a legal entity',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
        {
          id: 6,
          service: serviceTypes[2][1],
          additionalServiceType: applicationTypes[1][1],
          text: ['На внесение изменений', 'To make changes'],
          icon: <Buildings size={24} weight='light' />,
        },
        {
          id: 7,
          service: serviceTypes[2][1],
          additionalServiceType: applicationTypes[2][1],
          text: [
            'На реорганизацию юридического лица',
            'For reorganization of a legal entity',
          ],
          icon: <Buildings size={24} weight='light' />,
        },
      ],
    },
  ],

  orderDescription: [
    [
      {
        headerText: 'Оформление заявки',
        cardText:
          'Опишите, что должно содержаться в документе. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Подготовка текста документа',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали, подготовит проект документа и разместит его на портале КД МИД России. Вы получите номер размещения, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Получение готового документа',
        cardText:
          'В день записи приходите в консульство с паспортом и номером размещения для заверения подготовленного документа',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submitting an application',
        cardText:
          'Describe for what purposes the certificate is required. You can send us the required documents either simultaneously with the application or directly to the lawyer after consulting with him.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparation of Document Text',
        cardText:
          'Our lawyer will contact you, discuss the details, prepare a draft document and post it on the CD portal of the Russian Foreign Ministry. You will receive an accommodation number, which you will need to take with you to the consulate. The service completion time is 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Appointment Scheduling at the Consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Receipt of the Ready Document',
        cardText:
          'On the day of registration, come to the consulate with your passport and accommodation number to certify the prepared document',
        cardImage: stampImage,
      },
    ],
  ],
  infoText4: [
    'При удостоверении доверенности (заявления) от имени юридического лица или от имени его представителя консульский работник обязан проверить их правоспособность.',
    'When certifying a power of attorney (application) on behalf of a legal entity or on behalf of its representative, the consular officer is obliged to check their legal capacity.',
  ],
  infoText5: [
    'Базовый пакет документов, который для этих целей потребуется предоставить в консульство:',
    'A basic package of documents that will need to be submitted to the consulate for these purposes:',
  ],
  userTypes: [
    {
      userName: ['Юридические лица', 'Legal entities'],
      desc: [
        [
          'Выписка из ЕГРЮЛ',
          'Extract from the Unified State Register of Legal Entities',
        ],
        ['Свидетельство ОГРН, ИНН, КПП', 'Certificate of OGRN, INN, KPP'],
        [
          'Решение и приказ о назначении директора',
          'Decision and order on the appointment of a director',
        ],
        ['Паспортные данные директора', "Director's passport details"],
      ],
    },
    {
      userName: [
        'Учредитель (участник, акционер)',
        'Founder (participant, shareholder)',
      ],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ представителя (учредителя, участника, акционера)',
          'Internal passport of the Russian Federation and/or international passport of the Russian Federation of the representative (founder, participant, shareholder)',
        ],
        ['СНИЛС (если имеется)', 'SNILS (if available)'],
        [
          'Устав Организации и его изменения, если имели место быть',
          'The Charter of the Organization and its changes, if any',
        ],
        [
          'Выписка из ЕГРЮЛ',

          'Extract from the Unified State Register of Legal Entities',
        ],
      ],
    },
  ],
  infoText6: [
    'Для оформления нотариальных документов приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
    'For the preparation of notarial documents, priority is given to the internal passport. If your internal passport has not been issued or is expired, you will need a valid international passport. If you have both passports, then you need to take two passports to your appointment at the consulate.',
  ],
  infoText7: [
    'Все документы предоставляются в подлинниках + копии, заверенные юридическим лицом.',
    'All documents are provided in originals + copies certified by a legal entity.',
  ],
  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '60',
    },
    {
      header: 'Without appointment at the consulate',
      list: [
        'Prepare document draft',
        'Submit document on the consulate portal',
        'Consult on the list of documents required for notarization',
        'Provide consultation on scheduling an appointment at the consulate independently',
      ],
      value: '60',
    },
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'Подготовим проект документа',
        'Разместим документ на консульском портале',
        'Проконсультируем по перечню документов, необходимых для заверения',
        'Запишем вас на приём в консульство',
      ],
      value: '80',
    },
    {
      header: 'With appointment at the consulate',
      list: [
        'Prepare document draft',
        'Submit document on the consulate portal',
        'Consult on the list of documents required for notarization',
        'Schedule an appointment for you at the consulate',
      ],
      value: '80',
    },
  ],

  faqMobile: [
    {
      userName: [
        'Можно ли в доверенности указать несколько доверенных лиц?',
        'Is it possible to specify several authorized persons in a power of attorney?',
      ],
      desc: [
        [
          'Да, доверенности могут быть оформлены от имени одного или нескольких лиц, на имя одного или нескольких лиц.',
          'Yes, a power of attorney can be issued on behalf of one or several persons, to the name of one or several persons.',
        ],
      ],
    },
    {
      userName: [
        'Возможно ли в Российском консульстве оформить доверенность для представления интересов в Казахстане или Белоруссии?',
        'Is it possible to issue a power of attorney at the Russian Consulate for representation of interests in Kazakhstan or Belarus?',
      ],
      desc: [
        [
          'Да. В соответствии с Конвенцией о правовой помощи, участниками которой являются Россия и Казахстан, Беларусь, Таджикистан, Грузия и др., доверенность, оформленная в Консульстве России, принимается госорганами и учреждениями этих стран, без дополнительного удостоверения (апостиля)',
          'Yes. In accordance with the Convention on Legal Assistance, of which Russia, Kazakhstan, Belarus, Tajikistan, Georgia, and others are participants, a power of attorney issued at the Russian Consulate is accepted by the government bodies and institutions of these countries without additional certification (apostille).',
        ],
      ],
    },
    {
      userName: [
        'На какой максимальный срок можно оформить доверенность?',
        'What is the maximum duration for which a power of attorney can be issued?',
      ],
      desc: [
        [
          'Вы вправе оформить доверенность с любым сроком действия. Вместе c тем в доверенности должен быть указан конкретный срок её действия, удостоверение «бессрочных» доверенностей не допускается.',
          'You are entitled to issue a Power of Attorney for any duration. However, the Power of Attorney must specify a specific duration of its validity; certification of "indefinite" Powers of Attorney is not allowed.',
        ],
      ],
    },
    {
      userName: [
        'Когда требуется согласие супруга на сделку?',
        'When is the consent of the spouse required for a transaction?',
      ],
      desc: [
        [
          'Согласие требуется в каждом случае, когда речь идёт о совместно нажитом в браке имуществе.',
          'Consent is required in every case when it comes to property acquired jointly during marriage.',
        ],
        [
          'К нему не относятся объекты, приобретённые одним из супругов до брака, полученные в дар или по наследству.',
          'This does not apply to objects acquired by one of the spouses before marriage, received as a gift, or through inheritance.',
        ],
      ],
    },
  ],
  faqDesc: [
    [
      {
        question: 'Можно ли в доверенности указать несколько доверенных лиц?',

        answer:
          'Да, доверенности могут быть оформлены от имени одного или нескольких лиц, на имя одного или нескольких лиц.',
      },
      {
        question:
          'Возможно ли в Российском консульстве оформить доверенность для представления интересов в Казахстане или Белоруссии?',

        answer:
          'Да. В соответствии с Конвенцией о правовой помощи, участниками которой являются Россия и Казахстан, Беларусь, Таджикистан, Грузия и др., доверенность, оформленная в Консульстве России, принимается госорганами и учреждениями этих стран, без дополнительного удостоверения (апостиля)',
      },
      {
        question: 'На какой максимальный срок можно оформить доверенность?',

        answer:
          'Вы вправе оформить доверенность с любым сроком действия. Вместе c тем в доверенности должен быть указан конкретный срок её действия, удостоверение «бессрочных» доверенностей не допускается.',
      },
      {
        question: 'Когда требуется согласие супруга на сделку?',

        answer:
          'К нему не относятся объекты, приобретённые одним из супругов до брака, полученные в дар или по наследству.',
        answer2:
          'К нему не относятся объекты, приобретённые одним из супругов до брака, полученные в дар или по наследству.',
      },
    ],
    [
      {
        question:
          'Is it possible to specify several authorized persons in a Power of Attorney?',
        answer:
          'Yes, a Power of Attorney can be issued on behalf of one or several persons, to the name of one or several persons.',
      },
      {
        question:
          'Is it possible to issue a Power of Attorney at the Russian Consulate for representation of interests in Kazakhstan or Belarus?',
        answer:
          'Yes. In accordance with the Convention on Legal Assistance, of which Russia, Kazakhstan, Belarus, Tajikistan, Georgia, and others are participants, a Power of Attorney issued at the Russian Consulate is accepted by the government bodies and institutions of these countries without additional certification (apostille).',
      },
      {
        question:
          'What is the maximum duration for which a Power of Attorney can be issued?',
        answer:
          'You are entitled to issue a Power of Attorney for any duration. However, the Power of Attorney must specify a specific duration of its validity; certification of "indefinite" Powers of Attorney is not allowed.',
      },
      {
        question:
          'When is the consent of the spouse required for a transaction?',
        answer:
          'This does not apply to objects acquired by one of the spouses before marriage, received as a gift, or through inheritance.',
        answer2:
          'This does not apply to objects acquired by one of the spouses before marriage, received as a gift, or through inheritance.',
      },
    ],
  ],
  interactuation: [
    [
      {
        type: 'title',
        text: ['Генеральная доверенность', 'General Power of Attorney.'],
      },
      {
        type: 'paragraph',
        text: [
          'Общая (генеральная) доверенность позволяет совершать любые по характеру действия перед любыми третьими лицами.',
          'A general Power of Attorney allows performing any actions of any nature before any third parties.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Общая доверенность даёт представителю возможность совершать разнообразные сделки по управлению имуществом, например:',
          'A general Power of Attorney gives the representative the ability to perform various transactions for managing property, such as:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'осуществлять всю административно-хозяйственную и оперативно-торговую деятельность компании',
            'to carry out all administrative, economic, and operational-trading activities of the company',
          ],
          [
            'управлять делами, имуществом и средствами компании, в частности, заключать договора, сделки, подписывать акты, расчётно-денежные документы',
            'to manage the affairs, property, and funds of the company, in particular, to conclude contracts, transactions, sign acts, and financial documents',
          ],
          [
            'представлять интересы во всех государственных учреждениях, предприятиях и в суде',
            'to represent interests in all government institutions, enterprises, and in court',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Доверенность на покупку или продажу доли общества',
          'Power of attorney for the purchase or sale of a share in a company.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Какие полномочия может включать:',
          'What powers it may include:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'представлять интересы доверителя в различных госучреждениях, подавать заявления и запрашивать от его имени документы',
            'to represent the interests of the principal in various government institutions, submit applications, and request documents on their behalf',
          ],
          [
            'проводить от его имени переговоры и решать все возникающие спорные вопросы',
            'to conduct negotiations on their behalf and resolve all arising disputes',
          ],
          [
            'заключить и подписать от его имени договор на продажу доли в уставном капитале общества',
            "to conclude and sign on their behalf a contract for the sale of a share in the company's authorized capital",
          ],
          [
            'получить по данному договору деньги',
            'to receive money under this contract',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: ['Дополнительно потребуются:', 'Additionally required:'],
      },
      {
        type: 'list',
        items: [
          ['СНИЛС (если имеется)', 'SNILS (if available)'],
          [
            'Согласия супруга на продажу доли общества (если юридическое лицо было создано или приобретено в браке). Можно оформить одновременно с доверенностью.',
            "Spouse's consent to the sale of the share in the company (if the legal entity was created or acquired during the marriage). It can be issued simultaneously with the Power of Attorney.",
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Доверенность на представительство в суде',
          'Power of Attorney for Representation in Court.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Данный вид доверенности позволяет представлять интересы в суде:',
          'This type of Power of Attorney allows representing interests in court:',
        ],
      },
      {
        type: 'list',
        items: [
          ['по гражданским делам', 'in civil cases'],
          ['по уголовным делам', 'in criminal cases'],
          ['по административным делам', 'in administrative cases'],
          [
            'по делам, рассматриваемым арбитражным судом (полномочия: отзыв и подписание искового заявления, заключение мирового соглашения и другие)',
            'in cases considered by the arbitration court (powers: withdrawal and signing of the claim statement, conclusion of a settlement agreement, and others)',
          ],
          [
            'в службе судебных приставов (полномочия: обжалование действий или бездействия судебных приставов, обжалование постановлений и другие)',
            'in the bailiff service (powers: appealing the actions or inactions of bailiffs, appealing rulings, and others)',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: ['Дополнительно потребуются:', 'Additionally required:'],
      },
      {
        type: 'list',
        items: [['СНИЛС (если имеются)', 'SNILS (if available)']],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Доверенность на представительство в госорганах России',
          'Power of Attorney for Representation in Government Agencies of Russia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Какие полномочия может включать:',
          'What powers it may include:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'действовать от имени доверителя в государственных ведомствах, организациях и службах: ГИБДД, ФССП, ЗАГС, страховых компаниях, Социальном фонде, суде, правоохранительных органах, банках и так далее',
            'to act on behalf of the principal in government departments, organizations, and services: traffic police, bailiff service, civil registry office, insurance companies, Social Fund, court, law enforcement agencies, banks, and so on',
          ],
          [
            'подавать заявления о государственной регистрации прав или сделок',
            'to submit applications for state registration of rights or transactions',
          ],
          [
            'распоряжаться зарегистрированными в государственных реестрах правами',
            'to manage rights registered in state registries',
          ],
          [
            'представлять интересы ИП в налоговой инспекции',
            'to represent the interests of individual entrepreneurs in the tax inspection',
          ],
          [
            'представлять интересы в исполнительном производстве',
            'to represent interests in enforcement proceedings',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: ['Дополнительно потребуются:', 'Additionally required:'],
      },
      {
        type: 'list',
        items: [['СНИЛС (если имеются)', 'SNILS (if available)']],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Согласие супруга на покупку / продажу / дарение / залог / изменение доли в ООО',
          "Spouse's consent for the purchase / sale / gift / pledge / change of share in LLC.",
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Документ, предоставляемый во всех случаях, когда доля в ООО приобретается в браке на общие деньги.',
          'A document provided in all cases when a share in an LLC is acquired during marriage with joint funds.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Согласие не требуется, если доля:',
          'Consent is not required if the share:',
        ],
      },
      {
        type: 'list',
        items: [
          ['куплена до вступления в брак', 'was purchased before marriage'],
          [
            'подарена или досталась в наследство одному из супругов',
            'was given as a gift or inherited by one of the spouses',
          ],
          [
            'принадлежит только одному супругу на основании брачного договора или договора о разделе имущества',
            'belongs to only one spouse based on a marriage contract or property division agreement',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Для оформления потребуются:',
          'The following documents are required for application:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'внутренние и/или заграничные паспорта РФ супругов (приоритет отдаётся внутреннему паспорту)',
            'internal and/or foreign passports of the spouses (priority is given to the internal passport)',
          ],
          [
            'адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
            'address of residence abroad in Russian (if there is no registration in Russia)',
          ],
          [
            'свидетельство о браке или его расторжении (если уже в разводе)',
            'marriage certificate or its dissolution (if already divorced)',
          ],
          [
            'наименование, адрес, ОГРН и ИНН компании, информация о размере доли',
            'name, address, OGRN, and TIN of the company, information about the share size',
          ],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление в налоговую на регистрацию юридического лица',
          'Application to the tax office for registration of a legal entity.',
        ],
      },
      {
        type: 'paragraph',
        text: ['Применяется форма Р11001.', 'Form P11001 is used.'],
      },
      {
        type: 'paragraph',
        text: [
          'Используется для регистрации нового юридического лица.',
          'It is used for the registration of a new legal entity.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление в налоговую на внесение изменений',
          'Application to the tax office for making changes.',
        ],
      },
      {
        type: 'paragraph',
        text: ['Применяется форма Р13014.', 'Form P13014 is used.'],
      },
      {
        type: 'paragraph',
        text: [
          'Используется для регистрации следующих изменений:',
          'It is used for registering the following changes:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'в ЕГРЮЛ (наименование, адрес, размер уставного капитала, филиалы, виды деятельности)',
            'in the Unified State Register of Legal Entities (name, address, size of authorized capital, branches, types of activity)',
          ],
          ['в уставе компании', "in the company's charter"],
          ['смена руководителя', 'change of director'],
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Заявление в налоговую на реорганизацию юридического лица',
          'Application to the tax office for reorganization of a legal entity.',
        ],
      },
      {
        type: 'paragraph',
        text: ['Применяется форма Р12016.', 'Form P12016 is used.'],
      },
      {
        type: 'paragraph',
        text: [
          'Используется для регистрации реорганизации юридического лица при:',
          'It is used for registering the reorganization of a legal entity in the case of:',
        ],
      },
      {
        type: 'list',
        items: [
          ['слиянии', 'merger'],
          ['присоединении', 'accession'],
          ['разделении', 'division'],
          ['выделении', 'separation'],
          ['преобразовании', 'transformation'],
        ],
      },
    ],
  ],
  workflow: {
    requestDocumentType: {
      title: ['Какой документ вам необходим?', 'What document do you need?'],
      options: [...serviceTypes, ['Не знаю', 'Not sure']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
      ],
    },
    requestPoaType: {
      title: [
        'Какая доверенность вам необходима?',
        'What Power of Attorney do you need?',
      ],
      options: [...powerOfAttorneyTypes, ['Другая', 'Other']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
      ],
    },
    requestApplicationType: {
      title: [
        'Какое заявление вам необходимо?',
        'What application do you need?',
      ],
      options: [...applicationTypes, ['Другое', 'Other']],
      additionalInfo: [
        'Опишите своими словами, какой документ требуется',
        'Describe in your own words, what is required',
      ],
    },
    requestConsentType: {
      title: [
        'Какое согласие вам необходимо?',
        'What type of consent do you need?',
      ],
      selectPlaceholder: ['Тип согласия', 'Type of consent'],
      options: [...consentTypes, ['Другой документ', 'Other']],
      additionalInfo: [
        'Или опишите своими словами, на что должно быть выдано согласие',
        'Or describe in your own words, what should be given as consent',
      ],
    },
  },
}

export default vocabularyLegalEntities
