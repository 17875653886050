import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import GlobalContext from 'GlobalContext'
import { Box, Typography, IconButton, useMediaQuery } from '@mui/material'
import leftArrow from '../../../images/icon/arrow-left.png'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { vocabulary } from 'vocabulary/vocabularyRequest'

export const RequestControls = () => {
  const navigate = useNavigate()
  const { langSelected } = useContext(GlobalContext)
  const isTableOrMobile = useMediaQuery(theme => theme.breakpoints.down('md'))

  const handleClose = useCallback(() => {
    navigate('/home')
  }, [navigate])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{ cursor: 'pointer', px: { xs: 0, md: 3 } }}
        onClick={handleClose}
      >
        <img
          src={leftArrow}
          alt='left arrow'
          width={'24px'}
          style={{ verticalAlign: 'middle' }}
        />
        {isTableOrMobile ? null : (
          <Typography
            variant='labelLarge'
            sx={{ verticalAlign: 'middle', ml: 1 }}
          >
            {vocabulary.allApplications[langSelected]}
          </Typography>
        )}
      </Box>
      <div>
        {/* {isTableOrMobile ? (
          <IconButton>
            <EditIcon />
          </IconButton>
        ) : null} */}
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </Box>
  )
}
