import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'

export default function CardServiceProcess1439({
  cardText,
  headerText,
  cardImage,
  about,
  details,
  orientation,
  last,
  navigate,
}) {
  const theme = useTheme()

  return (
    <Card
      sx={{
        width: orientation ? '264px' : null,
        backgroundColor: theme.palette.primary.surface,
        display: 'flex',
        height: 'auto',
        width: '100%',
      }}
      variant='inlined'
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: orientation ? 'column' : 'row',
          p: 0,
          mr: 2,
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          style={{
            minWidth: '70px',
            minHeight: '70px',
            maxWidth: '70px',
            maxHeight: '70px',
            borderRadius: '50%',
            backgroundColor: !about
              ? theme.palette.primary.tertiaryContainer
              : theme.palette.primary.surface,
            display: 'flex',
            borderStyle: about ? 'solid' : null,
            borderColor: '#C2C7CF',
            borderWidth: '1px',
            zIndex: 3,
            position: 'relative',
          }}
        >
          <img
            src={cardImage}
            alt='logo'
            style={{ width: '54px', height: '54px', margin: 'auto' }}
          />
        </Box>

        {!last ? (
          <Box
            style={{
              position: 'absolute',
              top: '35px',
              left: '35px',
              height: !orientation ? '200%' : '4px',
              width: !orientation ? '4px' : '100%',
              backgroundColor: theme.palette.primary.tertiaryContainer,
              zIndex: 1,
            }}
          />
        ) : null}

        <Box
          sx={{
            mt: 2,
            ml: 2,
            flexDirection: 'column',
            width: '100%',
            display: 'flex',
          }}
        >
          <Typography component='div' variant='titleSmall' sx={{ mb: 1 }}>
            {headerText}
          </Typography>

          <Typography variant='bodySmall'>{cardText}</Typography>
          <Typography
            sx={{
              mt: '8px',
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              color: theme.palette.primary.primary,
              cursor: 'pointer',
            }}
            variant='labelLarge'
            onClick={() => {
              navigate('/privacy-policy')
            }}
          >
            {details ? <>{details + ' → '} </> : null}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
