import { useContext, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import logoImage from '../../images/logo-desktop.png'
import visaImage from '../../images/visa.png'
import masterCardImage from '../../images/masterCard.png'
import applePayImage from '../../images/apple.png'
import googlePayImage from '../../images/googlePay.png'
import payPalImage from '../../images/payPal.png'
import bitcoinImage from '../../images/bitcoin.png'
import vocabulary from '../../vocabulary/vocabulary'
import Layout1239 from '../Layouts/Layout1239'
import GlobalContext from '../../GlobalContext'
import styles from './styles1239'
import { v4 as uuidv4 } from 'uuid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CardServiceList1239 from '../components/ServiceList/CardServiceList1239'
import CardServiceProcess1239 from '../components/ServiceProcess/CardServiceProcess1239'

export default function Main1239() {
  const { langSelected, GlobalActions } = useContext(GlobalContext)
  const [stateMenu, setMenu] = useState(false)
  const [serviceData, setServiceData] = useState([])
  const [serviceProcessData, setServiceProcessData] = useState([])

  useEffect(() => {
    setServiceData(vocabulary.serviceData[langSelected])
    setServiceProcessData(vocabulary.serviceProcess[langSelected])
  }, [langSelected])

  function setStateMenu(val) {
    setMenu(val)
  }

  const theme = useTheme()

  return (
    <Layout1239
      stateMenu={stateMenu}
      setStateMenu={setStateMenu}
      langSelected={langSelected}
      theme={theme}
      main={true}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.header,
          maxWidth: '1072px',
          minWidth: 1072,
          maxHeight: '310px',
          minHeight: '310px',
          display: 'flex',
          position: 'relative',
          zIndex: 1,
          flexDirection: 'column',
          borderRadius: '20px',

          justifyContent: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: '88px' }}>
          <Typography variant='titleSmall' sx={{ mb: '8px' }}>
            CONSUL ONLINE
          </Typography>

          <Typography variant='h1Small' sx={{ mb: '28px' }}>
            {vocabulary.siteName[langSelected]}
          </Typography>

          <Typography variant='bodySmall'>
            {vocabulary.slogan1[langSelected]}
            <br />
            {vocabulary.slogan2[langSelected]}
          </Typography>
        </Box>

        <img
          src={logoImage}
          alt='logo'
          style={{
            position: 'absolute',
            top: '66px',
            right: '88px',
            zIndex: 2,
            height: '168px',
          }}
        />
      </Box>
      <Box sx={{ maxWidth: 878, ml: '88px' }}>
        <Box sx={{ mb: '32px', mt: '80px' }}>
          <Typography variant='h2Small'>
            {vocabulary.ourServices[langSelected]}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {serviceData?.map((service, index) => (
            <CardServiceList1239
              langSelected={langSelected}
              key={uuidv4()}
              headerText={service.headerText}
              cardText={service.cardText}
              cardImage={service.cardImage}
              index={index}
            />
          ))}
        </Box>
        <Typography
          variant='bodySmall'
          sx={{ mb: 2, mt: '48px', maxWidth: 560 }}
        >
          {vocabulary.aboutUs1[langSelected]}
        </Typography>

        <Typography
          variant='bodySmall'
          component='p'
          sx={{ mb: '60px', maxWidth: 560 }}
        >
          {vocabulary.aboutUs2[langSelected]}
        </Typography>

        <Box sx={{ mb: '32px' }}>
          <Typography variant='h2Small'>
            {vocabulary.serviceObtaining[langSelected]}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {serviceProcessData.map((service, index) => {
            let last = false

            if (serviceProcessData.length === index + 1) {
              last = true
            }

            return (
              <CardServiceProcess1239
                langSelected={langSelected}
                key={uuidv4()}
                headerText={service.headerText}
                cardText={service.cardText}
                cardImage={service.cardImage}
                last={last}
                orientation={'landscape'}
              />
            )
          })}
        </Box>
        <Typography variant='titleSmall' sx={{ mt: '32px' }}>
          {vocabulary.processingTiming[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p' sx={{ mt: '8px' }}>
          {vocabulary.twoDays[langSelected]}
        </Typography>

        <Typography variant='titleSmall' sx={{ mt: '32px' }}>
          {vocabulary.paymentsMethods[langSelected]}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: '12px',
            mb: '12px',
          }}
        >
          <img src={visaImage} alt='visa' style={styles.smallImageStyle} />
          <img src={masterCardImage} alt='mc' style={styles.smallImageStyle} />
          <img
            src={applePayImage}
            alt='apple-pay'
            style={styles.smallImageStyle}
          />
          <img
            src={googlePayImage}
            alt='google-pay'
            style={styles.smallImageStyle}
          />
          <img src={payPalImage} alt='pay-pal' style={styles.smallImageStyle} />
          <img
            src={bitcoinImage}
            alt='bitcoin'
            style={styles.smallImageStyle}
          />
        </Box>

        <Typography variant='bodySmall' component='p' sx={{ mt: '8px' }}>
          {vocabulary.possiblePayRussian[langSelected]}
        </Typography>

        <Button
          variant='contained'
          size='medium'
          sx={{ width: '256px', height: '40px', my: 6.5 }}
          color='blueButton'
          onClick={() => {
            GlobalActions.userFlowMainOpen(true)
          }}
        >
          <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
            {vocabulary.applicationSubmit[langSelected]}
          </Typography>
        </Button>
      </Box>
    </Layout1239>
  )
}
