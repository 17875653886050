import React from 'react'
import { v4 as uuid } from 'uuid'
import { Controller } from 'react-hook-form'
import { Box, Typography } from '@mui/material'
import { formFields } from 'components/FormField/index'

export const Form = React.memo(({ title, schema, control }) => {
  return (
    <Box>
      {title ? (
        <Typography component='p' variant='titleLarge' sx={{ mb: 4 }}>
          {title}
        </Typography>
      ) : null}

      {schema?.map((field, index) =>
        renderFormField({ field, control, index }),
      )}
    </Box>
  )
})

export const renderFormField = ({ field, control, index }) => {
  const { type, name, componentProps } = field
  const Component = formFields[type]

  if (!Component) {
    console.error(`Form field type "${type}" not found`)

    return null
  }

  return (
    <Controller
      key={uuid()}
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value = '' } }) => (
        <Component
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          index={index}
          {...componentProps}
        />
      )}
    />
  )
}
