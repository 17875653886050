import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { TabsItem } from './__Item/TabsItem'
import { Box } from '@mui/material'

export const Tabs = ({ items, sx, onChange, active = 0 }) => {
  const [activeTab, setActiveTab] = useState(active)

  const handleChange = index => {
    setActiveTab(index)
    onChange?.(index)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        ...sx,
      }}
    >
      {items.map((item, index) => (
        <TabsItem
          key={uuid()}
          {...item}
          active={activeTab === index}
          onClick={() => handleChange(index)}
          index={index}
          first={index === 0}
          last={index === items.length - 1}
        />
      ))}
    </Box>
  )
}
