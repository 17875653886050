import vocabulary from 'vocabulary/vocabularyConsent'
import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'

export const requestPersonWhoTransferringRights = langSelected => {
  const { whoTitle, toWhomTitle, options } =
    vocabulary.workflow.requestPersonWhoTransferringRights

  return createStepSchema(null, [
    {
      name: 'title',
      type: 'title',
      componentProps: {
        value: whoTitle[langSelected],
      },
    },
    {
      name: 'serviceDetails.guarantor',
      type: 'radio',
      componentProps: {
        options: createOptions(options, langSelected),
      },
    },
    {
      name: 'title',
      type: 'title',
      componentProps: {
        value: toWhomTitle[langSelected],
      },
    },
    {
      name: 'serviceDetails.borrower',
      type: 'radio',
      componentProps: {
        options: createOptions(options, langSelected),
      },
    },
  ])
}
