import vocabulary, { serviceTypes } from 'vocabulary/vocabularyConsent'
import { requestDocumentType } from './requestDocumentType'
import { getTranslation } from 'pages/helpers/workflowStepSchema'
import { requestConsentType } from './requestConsentType'
import { requestApplicationType } from './requestApplicationType'
import { requestAgreementType } from './requestAgreementType'
import { requestAdditionalInformation } from './requestAdditionalInfo'
import { requestGuarantorAndBorrower } from './requestGuarantorAndBorrower'
import { requestCreditor } from './requestCreditor'
import { requestPersonWhoTransferringRights } from './requestPersonWhoTransferringRights'
import { requestRightsAreBeingTransferred } from './requestRightsAreBeingTransferred'

const COMMENT_STEP = 9
const FINAL_STEP = 10

export const getSteps = langSelected => {
  const [consent, application, agreement] = serviceTypes.map(
    getTranslation('eng'),
  )

  return [
    {
      step: 1,
      progress: 20,
      ...requestDocumentType(langSelected),
      nextStep: ({ service, serviceDetails }) => {
        const nextStep = {
          [consent]: 2,
          [application]: 3,
          [agreement]: 4,
        }[service]

        if (!nextStep) {
          return serviceDetails?.comment ? FINAL_STEP : COMMENT_STEP
        }

        return nextStep
      },
    },
    {
      step: 2,
      progress: 30,
      ...requestConsentType(langSelected),
      nextStep: ({ additionalServiceType, serviceDetails }) => {
        const other = vocabulary.workflow.requestConsentType.options.at(-1)[1]

        if (other === additionalServiceType && serviceDetails?.comment) {
          return FINAL_STEP
        }

        return COMMENT_STEP
      },
    },
    {
      step: 3,
      progress: 30,
      ...requestApplicationType(langSelected),
      nextStep: ({ additionalServiceType, serviceDetails }) => {
        const other =
          vocabulary.workflow.requestApplicationType.options.at(-1)[1]

        if (other === additionalServiceType && serviceDetails?.comment) {
          return FINAL_STEP
        }

        return COMMENT_STEP
      },
    },
    {
      step: 4,
      progress: 30,
      ...requestAgreementType(langSelected),
      nextStep: ({ additionalServiceType, serviceDetails }) => {
        const { options } = vocabulary.workflow.requestAgreementType
        const [
          _marriagecontract,
          _propertyDivisionagreement,
          _alimonyAgreement,
          suretyAgreement,
          assignmentAgreement,
          other,
        ] = options.map(getTranslation('eng'))

        if (additionalServiceType === suretyAgreement) {
          return 5
        }

        if (additionalServiceType === assignmentAgreement) {
          return 7
        }

        if (other === additionalServiceType && serviceDetails?.comment) {
          return FINAL_STEP
        }

        return COMMENT_STEP
      },
    },
    {
      step: 5,
      progress: 60,
      ...requestGuarantorAndBorrower(langSelected),
      nextStep: 7,
    },
    {
      step: 6,
      progress: 70,
      ...requestCreditor(langSelected),
      nextStep: FINAL_STEP,
    },
    {
      step: 7,
      progress: 60,
      ...requestPersonWhoTransferringRights(langSelected),
      nextStep: 9,
    },
    {
      step: 8,
      progress: 70,
      ...requestRightsAreBeingTransferred(langSelected),
      nextStep: FINAL_STEP,
    },
    {
      step: COMMENT_STEP,
      progress: 65,
      ...requestAdditionalInformation(langSelected),
      nextStep: FINAL_STEP,
    },
  ]
}
