import LeftDrawerDesktopScreen from '../components/LeftDrawer/LeftDrawerDesktopScreen'
import Footer1439 from '../components/Footer/Footer1439'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import WorkFlowMain from '../components/Workflow/WorkFlowMain'
import { useContext } from 'react'
import GlobalContext from '../../GlobalContext'
import SpeedDialButton from 'pages/components/SpeedDialButton'
import { Page } from 'components/Page/Page'

export default function Layout1439({ children }) {
  const theme = useTheme()

  const { state } = useContext(GlobalContext)

  return (
    <Page>
      <LeftDrawerDesktopScreen resolution={'1439'}>
        <SpeedDialButton />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.primary.surface,
            ml: state.menuSelected === 2 ? '426px' : `104px`,
            mt: `104px`,
            minHeight: '80vh',
          }}
        >
          {children}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <WorkFlowMain resolution='big' />
          <Footer1439 />
        </Box>
      </LeftDrawerDesktopScreen>
    </Page>
  )
}
