import {
  addUserAdminList,
  deleteUserAdminList,
  getAdmListCall,
} from 'api/admin'
import { useEffect, useState } from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material'

export default function AdmList() {
  const [usersList, setUsersList] = useState([])
  const [newUser, setNewUser] = useState('')
  const [error, setError] = useState(false)

  const getList = async () => {
    try {
      let result = await getAdmListCall()
      console.log(result.usersList)
      if (result) setUsersList(result?.usersList)
    } catch (error) {
      console.error('Error fetching admin list:', error)
    }
  }

  const handleDeleteUser = async id => {
    try {
      await deleteUserAdminList({ id })
      getList() // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting user:', error)
    }
  }

  const handleAddUser = async () => {
    try {
      let newAdmin = await addUserAdminList({ username: newUser })

      console.log(newAdmin, 'new admin')

      if (newAdmin === 'User not exist') {
        setError(true)
      } else {
        setNewUser('')
        getList()
        setError(false)
      }
    } catch (error) {
      console.error('Error adding new user:', error)
    }
  }

  useEffect(() => {
    getList()
  }, [])

  return (
    <Paper elevation={4} sx={{ m: 4, p: 4 }}>
      {usersList?.map(val => (
        <Box key={val._id} sx={{ m: 2, my: 4 }}>
          <Typography variant='h4' component='div'>
            {val.username}
            <Button
              variant='outlined'
              color='error'
              sx={{ ml: 2 }}
              onClick={() => handleDeleteUser(val._id)}
            >
              <Typography variant='h5'>Delete User</Typography>
            </Button>
          </Typography>
        </Box>
      ))}

      <Typography variant='h4' component='div' sx={{ m: 2 }}>
        Add new administrator to list (user should be registered before)
      </Typography>
      <Box sx={{ display: 'flex', m: 2 }}>
        <TextField
          value={newUser}
          onChange={e => setNewUser(e.target.value)}
          label='Username'
          variant='outlined'
          helperText={error ? 'Email does not exists.' : null}
          error={error}
        />
        <Button variant='contained' sx={{ ml: 2 }} onClick={handleAddUser}>
          <Typography variant='h5'>Add New User</Typography>
        </Button>
      </Box>
    </Paper>
  )
}
