import axios from 'axios'
import serviceAxios from './serviceAxios'
const baseUrl = process.env.REACT_APP_SERVER_API_URL

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
}

export async function signIn(data) {
  const apiUrl = baseUrl + `/auth/login`

  let response
  console.log(data, 'a')

  try {
    response = await axios.post(apiUrl, data, config)
    console.log(response, 'resp')
  } catch (error) {
    console.error('Error:', error.response.data)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response.data
}

export async function signInGoogle(data) {
  const apiUrl = baseUrl + `/auth/login-google`

  let response

  try {
    response = await axios.post(apiUrl, { data: data }, config)
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response.data
}

export async function passwordRecovery(data) {
  const apiUrl = baseUrl + `/auth/password-recovery`

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }
  let response

  try {
    response = await axios.put(apiUrl, data, config)
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response.data
}

export async function signUp(data) {
  const apiUrl = baseUrl + `/auth/register`

  let response

  console.log(apiUrl, data, config)

  try {
    response = await axios.post(apiUrl, data, config)
  } catch (error) {
    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      console.log(' Error:', error)
    }
  }

  return response.data
}

export async function sendCode(data) {
  try {
    const response = await axios.get(baseUrl + '/public/send-code?code=' + data)
    const result = response.data

    return result
  } catch (error) {
    console.error('An error occurred:', error)
    throw error
  }
}

export async function getUserInformation(data) {
  try {
    const response = await serviceAxios.get('/private/get-user-info')

    return response.data
  } catch (error) {
    console.error('An error occurred:', error)
    throw error
  }
}

export async function putUserInformation(data) {
  try {
    const response = await serviceAxios.put('/private/put-user-info', data)

    return response.data
  } catch (error) {
    console.error('An error occurred:', error)
    throw error
  }
}
