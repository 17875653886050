import React from 'react'
import { Typography } from '@mui/material/'
import GlobalContext from 'GlobalContext'

export const Title = ({ text, index }) => {
  const { langSelected } = React.useContext(GlobalContext)

  return (
    <Typography
      variant='titleLarge'
      component='div'
      sx={{ mb: 3, mt: index ? 4 : 0 }}
    >
      {text[langSelected]}
    </Typography>
  )
}
