import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  TextField,
  LinearProgress,
  Stack,
} from '@mui/material/'

import { useTheme } from '@mui/material/styles'
import GlobalContext from 'GlobalContext'
import leftArrow from 'images/icon/arrow-left.png'
import crossClose from 'images/icon/cross-close.png'
import vocabularyWorkflow from 'vocabulary/vocabularyWorkflow'
import vocabularyPowerOfAttorney from 'vocabulary/vocabularyPowerOfAttorney'
import { useForm } from 'react-hook-form'
import { renderFormField } from 'components/Form/Form'

export default function DialogPoaWorkflow0({ setNextWindow }) {
  const theme = useTheme()
  const [serviceNeeded, setServiceNeed] = React.useState('')
  const { langSelected, GlobalActions } = React.useContext(GlobalContext)
  const listOfServices = vocabularyPowerOfAttorney.typesOfPoa.map(val => ({
    value: val[langSelected],
    label: val[langSelected],
  }))
  const [selected, setSelected] = React.useState(false)

  const { watch, control, formState } = useForm()
  const formValues = watch()

  React.useEffect(() => {
    formState.isDirty && setSelected(true)
  }, [formState.isDirty])

  const onConfirm = event => {
    event.preventDefault()

    const selectedIndex = listOfServices.findIndex(
      option => option.value === formValues.serviceName,
    )

    GlobalActions.setDialogServiceOpen({
      step: 2,
      index: selectedIndex,
    })

    GlobalActions.setDataToSend({
      service: vocabularyPowerOfAttorney.typesOfPoa[selectedIndex]?.[1],
      serviceDetails: { ...formValues },
      additionalServiceType: serviceNeeded,
    })
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box sx={{ mx: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '76px',
          }}
        >
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              GlobalActions.setDialogServiceOpen(false)
              GlobalActions.clearDataToSend()
            }}
          >
            <img src={leftArrow} alt='left arrow' width={'24px'} />
          </Box>

          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              GlobalActions.setDialogServiceOpen(false)
              GlobalActions.clearDataToSend()
            }}
          >
            <img src={crossClose} alt='cross' width={'24px'} />
          </Box>
        </Box>

        <Typography component='p' variant='titleLarge' sx={{ mb: '32px' }}>
          {vocabularyPowerOfAttorney.whichTypes[langSelected]}
        </Typography>

        {renderFormField({
          field: {
            type: 'select',
            name: 'serviceName',
            componentProps: {
              options: listOfServices,
              label: vocabularyPowerOfAttorney.typeOfPoa[langSelected],
            },
          },
          control,
          index: 0,
        })}

        <TextField
          value={serviceNeeded}
          placeholder={vocabularyPowerOfAttorney.describeService[langSelected]}
          minRows='3'
          maxRows='3'
          onChange={event => {
            setSelected(true)
            setServiceNeed(event.target.value)
          }}
          sx={{
            minWidth: 1,
            mt: 2,
            mr: '16px',

            borderRadius: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
              color: theme.palette.primary.onSurfaceVariant,
              opacity: 1,
            },
          }}
          margin='dense'
          multiline
        ></TextField>
      </Box>
      <Box>
        <Stack>
          <LinearProgress
            color='progressBar'
            variant='determinate'
            value={10}
            sx={{ backgroundColor: theme.palette.primary.primaryContainer }}
          />
        </Stack>
        <Box
          sx={{
            pb: '16px',
            pt: '24px',
            mt: '8px',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            variant='text'
            disabled={selected}
            size='medium'
            sx={{ border: 'none', height: '40px' }}
            color='blueButton'
            onClick={onConfirm}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyWorkflow.skip[langSelected]}
            </Typography>
          </Button>

          <Button
            variant='text'
            size='medium'
            disabled={!selected}
            sx={{
              border: 'none',
              height: '40px',
              mr: '16px',
            }}
            color='blueButton'
            onClick={onConfirm}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyWorkflow.forward[langSelected]}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
