import serviceAxios from './serviceAxios'

export async function getAdminData() {
  let response = null

  try {
    response = await serviceAxios.get(`private/admin`)
  } catch (error) {
    console.error('Request error:', error.response)

    return error.response
  }

  return response.data
}

export async function getAdmListCall() {
  const apiUrl = '/private/get-adm-list'

  let response

  try {
    response = await serviceAxios.get(apiUrl)
    response = response.data
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response
}

export async function getUsersData() {
  let response = null

  try {
    response = await serviceAxios.get(`private/get-users`)
  } catch (error) {
    console.error('Request error:', error.response)

    return error.response
  }

  return response.data
}

export async function deleteUser(data) {
  const apiUrl = '/private/delete-user'

  let response

  try {
    response = await serviceAxios.put(apiUrl, data)
    console.log(response, 'response')
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response
}

export async function deleteUserAdminList(data) {
  const apiUrl = '/private/delete-user-adm-list'

  let response

  try {
    response = await serviceAxios.put(apiUrl, data)
    console.log(response, 'response')
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response
}

export async function addUserAdminList(data) {
  const apiUrl = '/private/add-user-adm-list'

  let response

  try {
    response = await serviceAxios.put(apiUrl, data)
    console.log(response, 'response')
  } catch (error) {
    console.error('[!] Error:', error)

    if (error.response.data.error === 'User not exist') {
      return 'User not exist'
    } else if (error.response.status === 401) {
      return 'bad credentials'
    } else {
      throw new Error('An error occurred during API call')
    }
  }

  return response
}
