import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyCitizenship'

const requestParentsCitizenship = (variant = 'default') => {
  return langSelected => {
    const { title, options } = vocabulary.workflow.requestParentsCitizenship

    return createStepSchema(title[variant][langSelected], [
      {
        name: 'service',
        type: 'radio',
        componentProps: {
          options: createOptions(options, langSelected),
        },
      },
    ])
  }
}

export const requestParentsCitizenshipDefault = requestParentsCitizenship()
export const requestParentsCitizenshipOnChildBirth =
  requestParentsCitizenship('onChildBirth')
