import React from 'react'
import Typography from '@mui/material/Typography'

export const Paragraph = ({ value, index }) => {
  return (
    <Typography
      variant='bodySmall'
      component='p'
      sx={{
        my: index === 0 ? '8px' : '20px',
        fontColor: 'text.primary',
      }}
    >
      {value}
    </Typography>
  )
}
