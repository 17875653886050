import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyCitizenship'

export const requestServiceType = langSelected => {
  const { title, options } = vocabulary.workflow.requestServiceType

  return createStepSchema(
    title[langSelected],
    [
      {
        name: 'service',
        type: 'radio',
        componentProps: {
          options: createOptions(options, langSelected),
        },
      },
    ],
    ({ service }) => ({ service }),
  )
}
