import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import GlobalContext from '../../../GlobalContext.js'
import CountrySelection from '../Workflow/CountrySelection.js'
import Payment from '../Workflow/Payment.js'
import GetUserDetails from '../Workflow/GetUsersDetails.js'
import RequestSuccessSent from '../Workflow/RequestSuccessSent.js'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'
import LegalEntitiesInfo from './LegalEntities/LegalEntitiesInfo.js'
import WorkflowStep from 'components/Workflow/WorkflowStep.jsx'
import { getSteps } from './LegalEntities/steps/index.js'
import CheckoutForm from '../Workflow/CheckoutForm.js'
import vocabularyLegalEntities from 'vocabulary/vocabularyLegalEntities.js'

export default function DialogLegalEntities() {
  const { state, langSelected } = React.useContext(GlobalContext)
  const price = {
    withAppointment: vocabularyLegalEntities.priceWithAppointment[1].value,
    withoutAppointment:
      vocabularyLegalEntities.priceWithoutAppointment[1].value,
  }
  const open = state.dialogService.section === 'legalEntities'

  const { step: currentStep } = state.dialogService
  const workflowSteps = getSteps(langSelected)

  return (
    <DialogModalWindow open={open}>
      {currentStep === -1 ? <LegalEntitiesInfo /> : null}
      {currentStep === 0 ? <CountrySelection height='auto' /> : null}
      {workflowSteps.map(({ step, ...workflowSchema }) =>
        step === currentStep ? (
          <WorkflowStep key={uuidv4()} {...workflowSchema} />
        ) : null,
      )}
      {currentStep === 5 ? <GetUserDetails height='auto' /> : null}
      {currentStep === 6 ? <Payment price={price} /> : null}
      {currentStep === 7 ? <CheckoutForm price={price} /> : null}
      {currentStep === 8 ? <RequestSuccessSent height='auto' /> : null}
    </DialogModalWindow>
  )
}
