import React from 'react'
import { Box, Typography } from '@mui/material'

export const RequestDocuments = ({ documents }) => (
  <Box p={2}>
    <Typography variant='h6'>Документы</Typography>
    {documents.map((document, index) => (
      <Box key={index} mt={2}>
        <Typography>
          <strong>{document.label}:</strong>
        </Typography>
        <Typography>{document.value}</Typography>
      </Box>
    ))}
  </Box>
)
