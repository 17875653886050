import * as React from 'react'
import { Card, CardActionArea } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import GlobalContext from '../../../GlobalContext'
import { useNavigate } from 'react-router-dom'

export default function CardServiceList600({
  cardText,
  headerText,
  cardImage,
  index,
}) {
  const { langSelected, GlobalActions } = React.useContext(GlobalContext)

  const navigate = useNavigate()

  return (
    <Card
      sx={{
        p: 0,
        backgroundColor: '#E6E8EE',
        justifyContent: 'space-between',
        border: 'none',
        mb: 2,
      }}
      variant='outlined'
    >
      <CardActionArea>
        <CardContent
          sx={{
            width: index === 8 ? '536px' : '260px',
            backgroundColor: '#E6E8EE',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '12px',
            p: 0,
            height: '224px',
          }}
          onClick={() => {
            switch (index) {
              case 0:
                GlobalActions.setSubmenuSelected(0)

                navigate('/poa')

                break
              case 1:
                GlobalActions.setSubmenuSelected(1)
                navigate('/passport')

                break
              case 2:
                navigate('/consent')
                GlobalActions.setSubmenuSelected(2)

                break
              case 3:
                GlobalActions.setSubmenuSelected(3)
                navigate('/certificates')

                break
              case 4:
                GlobalActions.setSubmenuSelected(4)
                navigate('/legal-entities')

                break
              case 5:
                GlobalActions.setSubmenuSelected(5)
                navigate('/citizenship')

                break

              case 6:
                GlobalActions.setSubmenuSelected(6)
                navigate('/zags')

                break
              case 7:
                GlobalActions.setSubmenuSelected(7)
                navigate('/other')

                break
              case 8:
                GlobalActions.setSubmenuSelected(8)
                navigate('/consulting')

                break

              default:
            }
          }}
        >
          <img
            src={cardImage}
            alt='logo'
            style={{
              width: '54px',
              height: '54px',
              margin: '16px',
            }}
          />

          <Typography
            component='div'
            variant='titleSmall'
            sx={{ marginLeft: '16px', marginBottom: '16px', marginTop: '16px' }}
          >
            {headerText}
          </Typography>

          <Typography
            variant='bodySmall'
            component='p'
            sx={{ ml: '16px', mr: '16px' }}
          >
            {cardText}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
