import React from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Typography } from '@mui/material'
import { RequestItem } from '../__Item/RequestItem'

export const RequestDetails = ({ details, title, subtitle }) => (
  <>
    <Typography variant='h6'>{title}</Typography>
    <Typography variant='subtitle1'>{subtitle}</Typography>
    {details.map((detail, index) => (
      <RequestItem
        key={uuid()}
        sx={{
          mt: { xs: 3, lg: 4 },
        }}
        direction='column'
        {...detail}
      />
    ))}
  </>
)
