import { deleteUser, getUsersData } from 'api/admin'
import { useEffect, useState } from 'react'
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@mui/material'
import { Button } from 'components/Button/Button'

export default function Users() {
  const getUsersLists = async () => {
    let data = await getUsersData()

    setData(data.usersData)
    setFilteredData(data.usersData)
    console.log(data)
  }

  useEffect(() => {
    getUsersLists()
  }, [])

  const [searchTerm, setSearchTerm] = useState('')
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    const lowercasedFilter = searchTerm.toLowerCase()
    const filtered = data.filter(
      item =>
        (item.username &&
          item.username.toLowerCase().includes(lowercasedFilter)) ||
        (item.familyName &&
          item.familyName.toLowerCase().includes(lowercasedFilter)) ||
        (item.givenName &&
          item.givenName.toLowerCase().includes(lowercasedFilter)),
    )
    setFilteredData(filtered)
  }, [searchTerm])

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant='h4' sx={{ marginBottom: 2 }}>
        User List
      </Typography>
      <TextField
        label='Search'
        variant='outlined'
        fullWidth
        sx={{ marginBottom: 2 }}
        onChange={e => setSearchTerm(e.target.value)}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Family Name</TableCell>
              <TableCell>Given Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map(row => (
              <TableRow key={row._id} component='div'>
                <TableCell>
                  <Typography
                    sx={{
                      color:
                        row?.role === 'admin' || row?.role === 'su'
                          ? 'red'
                          : null,
                    }}
                  >
                    {row.username}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color:
                        row?.role === 'admin' || row?.role === 'su'
                          ? 'red'
                          : null,
                    }}
                  >
                    {row.familyName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color:
                        row?.role === 'admin' || row?.role === 'su'
                          ? 'red'
                          : null,
                    }}
                  >
                    {row.givenName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      color:
                        row?.role === 'admin' || row?.role === 'su'
                          ? 'red'
                          : null,
                    }}
                  >
                    {row?.role === 'admin' && 'Administrator'}
                    {row?.role === 'su' && 'Super user'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Button
                    color='error'
                    onClick={() => {
                      deleteUser({ userId: row._id })
                      getUsersLists()
                    }}
                  >
                    DELETE
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
