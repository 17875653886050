import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  useTheme,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  ListItem,
} from '@mui/material/'

import Layout1439 from '../../Layouts/Layout1439'
import GlobalContext from '../../../GlobalContext'
import vocabularyPowerOfAttorney from '../../../vocabulary/vocabularyPowerOfAttorney'
import { useNavigate } from 'react-router-dom'
import { vocabulary as vocabularyRequest } from 'vocabulary/vocabularyRequest'

import vocabularyPersonalArea from '../../../vocabulary/vocabularyPersonalArea'
import { DotsThreeVertical, Envelope, Phone } from '@phosphor-icons/react'
import vocabulary from 'vocabulary/vocabulary'
import { putUserInformation } from 'api/auth'
import { RequestPopover } from 'pages/components/RequestPopover/RequestPopover'
import { cancelUserRequests } from 'api/workflow'
import ChangeUserPassword from 'pages/components/ChangeUserPassword'

const FORM_UPDATE_DELAY = 500

export default function Home1439() {
  const { langSelected, GlobalActions, state } = useContext(GlobalContext)
  const { userInfo } = state

  const navigate = useNavigate()
  const [userName, setUserName] = React.useState(userInfo?.givenName)
  const [userPhone, setUserPhone] = React.useState(userInfo?.phone)
  const [userMail, setUserMail] = React.useState(userInfo?.username)

  useEffect(() => {
    const handler = setTimeout(() => {
      putUserInformation({ givenName: userName, phone: userPhone })
    }, FORM_UPDATE_DELAY)

    return () => {
      clearTimeout(handler)
    }
  }, [userName, userPhone])

  useEffect(() => {
    GlobalActions.getUserRequests()
  }, [])

  ////popover
  const [anchorEls, setAnchorEls] = useState({}) // Store anchor elements by item index
  const [openPopovers, setOpenPopovers] = useState({}) // Store open state of popovers by item index
  const [changePasswordOpen, setChangePasswordOpen] = useState(false)

  const handleClick = (event, index) => {
    setAnchorEls(prev => ({ ...prev, [index]: event.currentTarget }))
    setOpenPopovers(prev => ({ ...prev, [index]: true }))
  }

  const handleClose = index => {
    setAnchorEls(prev => ({ ...prev, [index]: null }))
    setOpenPopovers(prev => ({ ...prev, [index]: false }))
    GlobalActions.getUserRequests()
  }

  const theme = useTheme()

  const [activeTab, setActiveTab] = useState(0)

  return (
    <Layout1439 footer='no' chat='no'>
      <Box
        sx={{
          mt: '56px',
          mx: 'auto',
          pt: '32px',
          width: '95%',
          overflowX: 'hidden',
          overflowY: 'auto',
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
        }}
      >
        <Typography variant='h1Small'>
          {vocabularyPersonalArea.personalArea[langSelected]}
        </Typography>
        <Box sx={{ width: 1 / 2 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              mt: 2,
              mx: 'auto',
              maxWidth: 'max(756px, 65%)',
            }}
          >
            <Box
              onClick={() => {
                setActiveTab(0)
              }}
              sx={{
                textTransform: 'none',
                border: '1px solid',
                borderTopLeftRadius: '20px',
                borderBottomLeftRadius: '20px',
                width: 1 / 2,
                height: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  activeTab === 0
                    ? theme.palette.primary.secondaryContainer
                    : null,
              }}
            >
              <Typography
                variant='labelSmallSemibold'
                component='div'
                sx={{ color: theme.palette.primary.onSurface }}
              >
                {vocabularyPersonalArea.profile[langSelected]}
              </Typography>
            </Box>
            <Box
              onClick={() => {
                setActiveTab(1)
              }}
              sx={{
                textTransform: 'none',
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderRight: '1px solid',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                width: 1 / 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  activeTab === 1
                    ? theme.palette.primary.secondaryContainer
                    : null,
                maxWidth: 'max(398px, 50%)',
              }}
            >
              <Typography
                component='div'
                variant='labelSmallSemibold'
                sx={{ color: theme.palette.primary.onSurface }}
              >
                {vocabularyPersonalArea.applications[langSelected]}
              </Typography>
            </Box>
          </Box>
          {/*first tab*/}
          {activeTab === 0 ? (
            <Box sx={{ marginX: 'auto' }}>
              <form style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                  value={userName}
                  placeholder={
                    vocabularyPowerOfAttorney.enterYourName[langSelected]
                  }
                  label={vocabularyPowerOfAttorney.userName[langSelected]}
                  InputLabelProps={{ shrink: true }}
                  onChange={event => {
                    setUserName(event.target.value)
                  }}
                  sx={{
                    minWidth: 1,
                    mt: 4,
                    mr: 2,

                    borderRadius: 0,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '5px',
                      color: theme.palette.primary.onSurface,
                    },
                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                      {
                        color: theme.palette.primary.onSurfaceVariant,
                        opacity: 1,
                      },
                  }}
                  variant='outlined'
                  margin='dense'
                />

                <TextField
                  value={userPhone}
                  placeholder={
                    vocabularyPowerOfAttorney.enterYourPhone[langSelected]
                  }
                  label={vocabularyPowerOfAttorney.userPhone[langSelected]}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Phone size={24} weight='light' />
                      </InputAdornment>
                    ),
                  }}
                  onChange={event => {
                    setUserPhone(event.target.value)
                  }}
                  sx={{
                    minWidth: 1,
                    mt: 2,
                    mr: '16px',

                    borderRadius: 0,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '5px',
                      color: theme.palette.primary.onSurface,
                    },
                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                      {
                        color: theme.palette.primary.onSurfaceVariant,
                        opacity: 1,
                      },
                  }}
                  variant='outlined'
                  margin='dense'
                />
                <TextField
                  value={userMail}
                  placeholder='mail@example.com'
                  disabled
                  label={vocabularyPowerOfAttorney.email[langSelected]}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Envelope size={24} weight='light' />
                      </InputAdornment>
                    ),
                  }}
                  onChange={event => {
                    setUserMail(event.target.value)
                  }}
                  sx={{
                    minWidth: 1,
                    mt: 2,
                    mr: '16px',

                    borderRadius: 0,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '5px',
                      color: theme.palette.primary.onSurface,
                    },
                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                      {
                        color: theme.palette.primary.onSurfaceVariant,
                        opacity: 1,
                      },
                  }}
                  variant='outlined'
                  margin='dense'
                />

                <Button
                  variant='outlined'
                  size='medium'
                  sx={{
                    width: '328px',
                    borderColor: 'black',
                    height: '40px',
                    mx: 'auto',
                    mt: 2,
                  }}
                  color='blueButton'
                  onClick={() => {
                    setChangePasswordOpen(true)
                  }}
                >
                  <Typography
                    variant='labelLarge'
                    sx={{ textTransform: 'none', ml: 1 }}
                  >
                    {vocabularyPersonalArea.changePassword[langSelected]}
                  </Typography>
                </Button>

                <Button
                  variant='text'
                  size='medium'
                  sx={{ width: 328, height: 40, my: 2, mx: 'auto' }}
                  color='blueButton'
                  onClick={() => {
                    GlobalActions.logOut()
                    navigate('/')
                  }}
                >
                  <Typography
                    variant='labelLarge'
                    sx={{ textTransform: 'none', ml: 1 }}
                  >
                    {vocabularyPersonalArea.exit[langSelected]}
                  </Typography>
                </Button>
              </form>
              {state.userInfo.role === 'admin' ||
              state.userInfo.role === 'su' ? (
                <>
                  <Divider />
                  <Box sx={{ display: 'flex' }}>
                    <Button
                      variant='contained'
                      size='medium'
                      sx={{ width: 250, height: 40, my: 6 }}
                      color='blueButton'
                      onClick={() => {
                        navigate('/private/info-ip')
                      }}
                    >
                      <Typography
                        variant='labelLarge'
                        sx={{ textTransform: 'none', ml: 1 }}
                      >
                        Visits statistic
                      </Typography>
                    </Button>

                    <Button
                      variant='contained'
                      size='medium'
                      sx={{ width: 250, height: 40, my: 6, ml: 4 }}
                      color='secondary'
                      onClick={() => {
                        navigate('/private/orders')
                      }}
                    >
                      <Typography
                        variant='labelLarge'
                        sx={{ textTransform: 'none', ml: 1 }}
                      >
                        View orders
                      </Typography>
                    </Button>
                    <Button
                      variant='contained'
                      size='medium'
                      sx={{ width: 250, height: 40, my: 6, ml: 4 }}
                      color='info'
                      onClick={() => {
                        navigate('/private/users')
                      }}
                    >
                      <Typography
                        variant='labelLarge'
                        sx={{ textTransform: 'none', ml: 1 }}
                      >
                        View users
                      </Typography>
                    </Button>
                    {state.userInfo.role === 'su' ? (
                      <Button
                        variant='contained'
                        size='medium'
                        sx={{ width: 250, height: 40, my: 6, ml: 4 }}
                        color='error'
                        onClick={() => {
                          navigate('/private/get-adm-list')
                        }}
                      >
                        <Typography
                          variant='labelLarge'
                          sx={{ textTransform: 'none', ml: 1 }}
                        >
                          Change Admin list
                        </Typography>
                      </Button>
                    ) : null}
                  </Box>
                </>
              ) : null}
            </Box>
          ) : null}

          {/*second tab*/}
          {activeTab === 1 ? (
            <Box sx={{}}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                  mt: 4,
                  mb: 2,
                }}
              >
                <Typography variant='labelLarge' sx={{ width: 1 / 8 }}>
                  №
                </Typography>

                <Typography variant='labelLarge' sx={{ width: 1 / 2 }}>
                  {vocabularyPersonalArea.service[langSelected]}
                </Typography>
                <Typography variant='labelLarge' sx={{ width: 1 / 4 }}>
                  {vocabularyPersonalArea.status[langSelected]}
                </Typography>
              </Box>
              <Divider />
              {state.userRequests.map(
                ({ id, requestData, requestStatus }, index) => {
                  requestData = JSON.parse(requestData)
                  let status

                  switch (requestStatus) {
                    case 'sent':
                      status = vocabulary.sent[langSelected]
                      break
                    case 'ready':
                      status = vocabulary.ready[langSelected]
                      break
                    default:
                      status = vocabulary.inProcess[langSelected]
                  }

                  return (
                    <ListItem key={index}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          flex: 1,
                          my: 2,
                        }}
                      >
                        <Typography
                          variant='labelSmall'
                          sx={{ width: 1 / 8, my: 'auto' }}
                        >
                          {index + 1}
                        </Typography>

                        <Typography
                          variant='labelSmall'
                          sx={{ width: 1 / 2, my: 'auto' }}
                        >
                          {
                            vocabularyRequest.section[requestData.section][
                              langSelected
                            ]
                          }
                        </Typography>

                        <Typography
                          variant='labelSmall'
                          sx={{ width: 1 / 4, my: 'auto' }}
                        >
                          {status}
                        </Typography>

                        <Button onClick={event => handleClick(event, index)}>
                          <DotsThreeVertical size={24} />
                        </Button>
                      </Box>
                      <RequestPopover
                        id={`popover-${index}`}
                        open={Boolean(openPopovers[index])}
                        anchorEl={anchorEls[index]}
                        onClose={() => handleClose(index)}
                        onOpen={() => navigate(`/requests/${id}`)}
                        onCancel={() => {
                          try {
                            cancelUserRequests({ requestId: id })
                            handleClose(index)
                          } catch {
                            console.log('something wrong')
                          }
                        }}
                      />
                      <Divider />
                    </ListItem>
                  )
                },
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box
        id='popoverAnchor'
        sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}
      ></Box>
      <ChangeUserPassword
        open={changePasswordOpen}
        setOpen={setChangePasswordOpen}
      />
    </Layout1439>
  )
}
