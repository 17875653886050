import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Typography, Box } from '@mui/material/'
import Divider from '@mui/material/Divider'
import { Button } from 'components/Button/Button'
import vocabulary from 'vocabulary/vocabularyWorkflow'
import GlobalContext from 'GlobalContext'
import leftArrow from 'images/icon/arrow-left.png'
import crossClose from 'images/icon/cross-close.png'

export function ServiceInfoModal({
  submit,
  onClose,
  children,
  clearDataBeforeClose = true,
}) {
  const { GlobalActions } = React.useContext(GlobalContext)

  const handleClose = () => {
    if (clearDataBeforeClose) {
      GlobalActions.setDialogServiceOpen(false)
      GlobalActions.clearDataToSend()
    }

    onClose?.()
  }

  return (
    <React.Fragment key={uuidv4()}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          pb: '24px',
        }}
      >
        <Header handleClose={handleClose} />
        <Content children={children} />
        <Footer submit={submit} handleClose={handleClose} />
      </Box>
    </React.Fragment>
  )
}

const Header = ({ handleClose }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '76px',
      minHeight: '76px',
      mx: '16px',
    }}
  >
    <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
      <img src={leftArrow} alt='left arrow' width={'24px'} />
    </Box>

    <Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
      <img src={crossClose} alt='cross' width={'24px'} />
    </Box>
  </Box>
)

const Content = ({ children }) => (
  <Box
    sx={{
      mx: '16px',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: '1 0 auto',
    }}
  >
    {children}
  </Box>
)

const Footer = ({ submit, handleClose }) => {
  const { langSelected } = React.useContext(GlobalContext)
  const isHidden = !submit

  if (isHidden) {
    return null
  }

  return (
    <Box
      sx={{
        mt: '30px',
      }}
    >
      <Divider variant='fullWidth' />

      <Box
        sx={{
          pt: '16px',
          display: 'flex',
          justifyContent: 'flex-start',
          mx: '16px',
        }}
      >
        <Button type='primary' onClick={submit} sx={{ mr: '16px' }}>
          <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
            {vocabulary.submit[langSelected]}
          </Typography>
        </Button>

        <Button
          type='secondary'
          sx={{
            '&.Mui-disabled': {
              border: 'none',
            },
          }}
          onClick={handleClose}
        >
          <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
            {vocabulary.ask[langSelected]}
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}
