import React from 'react'
import { itemRenderer } from 'components/ServiceInfo/itemRenderer'
import { vocabulary } from 'vocabulary/vocabularyPrivacyPolicy'
import { Typography, Container, Box } from '@mui/material'
import GlobalContext from 'GlobalContext'

export const PrivacyPolicy = () => {
  const { langSelected } = React.useContext(GlobalContext)
  const content = vocabulary.pageContent
  const title = vocabulary.title[langSelected]

  return (
    <Container
      sx={{
        mt: '56px',
        py: 3,
      }}
    >
      <Box
        sx={{
          maxWidth: '600px',
        }}
      >
        <Typography
          variant='h1Small'
          component='p'
          sx={{ mb: 2, fontSize: { xs: '24px', md: '32px', lg: '36px' } }}
        >
          {title}
        </Typography>
        {content.map((item, index) => itemRenderer(item, index))}
      </Box>
    </Container>
  )
}
