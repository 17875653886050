import React, { useContext, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Link,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import GlobalContext from 'GlobalContext'
import vocabularyPowerOfAttorney from 'vocabulary/vocabularyPowerOfAttorney'
import { WorkflowContainer } from 'components/Workflow/WorkflowContainer'
import { Envelope, Phone } from '@phosphor-icons/react'
import { useLoginFlowOpen } from 'pages/helpers/useLoginFlow'
import { Button } from 'components/Button/Button'
import { Input } from 'components/Input/Input'
import vocabularyWorkflow from 'vocabulary/vocabularyWorkflow'
import vocabulary from 'vocabulary/vocabulary'

const PHONE_PATTERN =
  /^\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export default function GetUserDetails(props) {
  const theme = useTheme()
  const { langSelected, GlobalActions, state, isLoggedIn } =
    React.useContext(GlobalContext)

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      userName: '',
      userPhone: '',
      userMail: '',
      checked: false,
    },
  })

  const openLoginForm = useLoginFlowOpen('login')
  const openSignUpForm = useLoginFlowOpen('signup')

  useEffect(() => {
    const { username, givenName, phone } = state.userInfo || {}

    username && setValue('userMail', username)
    givenName && setValue('userName', givenName)
    phone && setValue('userPhone', phone)
  }, [isLoggedIn, setValue, state.userInfo])

  const onConfirm = data => {
    if (data.checked) {
      GlobalActions.setDialogServiceOpen({
        index: state.dialogService.serviceIndex,
        step: state.dialogService.step + 1,
      })
      GlobalActions.setDataToSend({
        userData: {
          userName: data.userName,
          userMail: data.userMail,
          userPhone: data.userPhone,
        },
      })
    }
  }

  const onClose = () => {
    GlobalActions.clearDataToSend()
  }

  const handlers = React.useMemo(
    () => ({
      onConfirm: isLoggedIn ? handleSubmit(onConfirm) : null,
    }),
    [isLoggedIn, handleSubmit, onConfirm],
  )

  return (
    <WorkflowContainer
      onConfirm={handlers.onConfirm}
      onClose={onClose}
      canMoveForward={true}
    >
      {!isLoggedIn && (
        <>
          <Typography component='p' variant='titleLarge' sx={{ mb: '32px' }}>
            {vocabularyWorkflow.autorizeToFollow[langSelected]}
          </Typography>
          <Button onClick={openLoginForm} fullWidth={true} sx={{ mb: '20px' }}>
            {vocabulary.enter[langSelected]}
          </Button>
          <Button
            onClick={openSignUpForm}
            type='secondary'
            variant='text'
            fullWidth={true}
          >
            {vocabulary.signUp[langSelected]}
          </Button>
        </>
      )}

      {isLoggedIn && (
        <>
          <Typography component='p' variant='titleLarge' sx={{ mb: '32px' }}>
            {vocabularyPowerOfAttorney.provideContact[langSelected]}
          </Typography>

          <Input
            name='userName'
            label={vocabularyPowerOfAttorney.userName[langSelected]}
            placeholder={vocabularyPowerOfAttorney.enterYourName[langSelected]}
            control={control}
            rules={{
              required: vocabularyWorkflow.nameISRequired[langSelected],
            }}
          />

          <Input
            name='userPhone'
            label={vocabularyPowerOfAttorney.userPhone[langSelected]}
            placeholder={vocabularyPowerOfAttorney.enterYourPhone[langSelected]}
            adornment={<Phone size={24} weight='light' />}
            control={control}
            rules={{
              required: vocabularyWorkflow.phoneNumberIsRequired[langSelected],
              pattern: {
                value: PHONE_PATTERN,
                message:
                  vocabularyWorkflow.invalidPhoneNumberFormat[langSelected],
              },
            }}
          />

          <Input
            name='userMail'
            label={vocabularyPowerOfAttorney.email[langSelected]}
            placeholder='mail@example.com'
            adornment={<Envelope size={24} weight='light' />}
            control={control}
            rules={{
              required: vocabularyWorkflow.emailIsRequired[langSelected],
              pattern: {
                value: EMAIL_PATTERN,
                message: vocabularyWorkflow.invalidEmailAddress[langSelected],
              },
            }}
          />

          <Box
            sx={{
              my: 2,
            }}
          >
            <Controller
              name='checked'
              control={control}
              rules={{ required: vocabularyWorkflow.required[langSelected] }}
              render={({ field, fieldState }) => (
                <>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ mx: 0 }}
                      control={
                        <Checkbox
                          sx={{
                            m: 0,
                            p: 0,
                            mr: 1,
                          }}
                          {...field}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<UserAgreementText />}
                    />
                  </FormGroup>
                  {fieldState.error ? (
                    <FormHelperText
                      sx={{ ml: 4, color: theme.palette.error.main }}
                    >
                      {fieldState.error.message}
                    </FormHelperText>
                  ) : null}
                </>
              )}
            />
          </Box>
        </>
      )}
    </WorkflowContainer>
  )
}

const UserAgreementText = () => {
  const { langSelected } = useContext(GlobalContext)
  const { imAgree, imAgreePrivicyPolicy } = vocabularyPowerOfAttorney

  return (
    <div>
      <span>{imAgree[langSelected]}&nbsp;</span>
      <Link href='/privacy-policy' target='_blank'>
        {imAgreePrivicyPolicy[langSelected]}
      </Link>
    </div>
  )
}
