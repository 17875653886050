import React from 'react'
import { TextField, InputAdornment } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'

export const Input = React.forwardRef(
  (
    {
      name,
      label,
      placeholder,
      adornment,
      control,
      rules,
      autocomplete,
      ...rest
    },
    ref,
  ) => {
    const theme = useTheme()

    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <TextField
            {...rest}
            {...field}
            placeholder={placeholder}
            label={label}
            inputRef={ref}
            InputLabelProps={{ shrink: true }}
            autoComplete={autocomplete}
            InputProps={{
              ...rest.InputProps,
              startAdornment: adornment && (
                <InputAdornment position='start'>{adornment}</InputAdornment>
              ),
            }}
            sx={{
              minWidth: 1,
              mt: 2,
              mr: '16px',
              borderRadius: 0,
              '& .MuiOutlinedInput-root': {
                borderRadius: '5px',
                color: theme.palette.primary.onSurface,
              },
              '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                color: theme.palette.primary.onSurfaceVariant,
                opacity: 1,
              },
            }}
            variant='outlined'
            margin='dense'
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
          />
        )}
      />
    )
  },
)
