import { MagnifyingGlass } from '@phosphor-icons/react'
import { IconButton } from '@mui/material/'
import { useSearchParams } from 'react-router-dom'
import { SearchModal } from './SearchModal.phone'

export const SearchInput = () => {
  const [, setSearchParams] = useSearchParams()

  return (
    <>
      <IconButton
        onClick={() => setSearchParams({ open: 'search' })}
        aria-label='show 4 new mails'
        color='inherit'
      >
        <MagnifyingGlass size={24} weight='light' />
      </IconButton>
      <SearchModal />
    </>
  )
}
