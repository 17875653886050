import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  TextField,
  Divider,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@mui/material/'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import GlobalContext from 'GlobalContext'
//import facebook from 'images/facebook.png'
import google from 'images/google.png'
import crossClose from 'images/icon/cross-close.png'

import vocabulary from 'vocabulary/vocabulary'
import { Envelope, Eye, Lock } from '@phosphor-icons/react'
import { useGoogleLogin } from '@react-oauth/google'
import { useLoginFlowOpen, useLoginFlowClose } from 'pages/helpers/useLoginFlow'

export default function SignUp() {
  const theme = useTheme()

  const { langSelected, GlobalActions, isLoggedIn } =
    React.useContext(GlobalContext)

  const [countdownValue, setCountdownValue] = React.useState(55)
  const openLoginForm = useLoginFlowOpen('login')
  const closeSignUpForm = useLoginFlowClose()
  const [errorRecieved, setErrorRecieved] = React.useState(false)
  const [userPassword, setUserPassword] = React.useState('')
  const [userMail, setUserMail] = React.useState('')
  const [checked, setChecked] = React.useState(false)
  const [checkedError, setCheckedError] = React.useState(false)
  const [emailError, setEmailError] = React.useState(false)
  const [hided, setHided] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      await GlobalActions.logIn({
        token: tokenResponse.access_token,
        variant: 'google',
        lang: langSelected,
      })
      closeSignUpForm()
    },
  })

  React.useEffect(() => {
    if (countdownValue > 0) {
      const intervalId = setInterval(() => {
        setCountdownValue(prevValue => prevValue - 1)
      }, 1000)

      return () => clearInterval(intervalId)
    } else {
      setCountdownValue(55)
    }
  }, [countdownValue])

  const navigate = useNavigate()

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    return emailRegex.test(email)
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <form
        style={{
          marginLeft: '16px',
          marginRight: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '76px',
          }}
        >
          <Box sx={{ cursor: 'pointer' }} onClick={closeSignUpForm}>
            <img src={crossClose} alt='cross' width={'24px'} />
          </Box>
        </Box>

        <Typography component='p' variant='h2Large' sx={{ mx: 'auto' }}>
          {vocabulary.signUp[langSelected]}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            justifyContent: 'center',
            my: 4,
          }}
        >
          {/*     <Button
            variant='outlined'
            size='medium'
            sx={{ height: '40px', minWidth: 156, mr: 2 }}
            color='blueButton'
            disabled={false}
            onClick={() => {}}
          >
            <img src={facebook} alt='facebook-logo' width={'24px'} />
            <Typography
              variant='labelLarge'
              sx={{ ml: 1, textTransform: 'none' }}
            >
              Facebook
            </Typography>
          </Button>
        */}
          <Button
            variant='outlined'
            size='medium'
            sx={{ height: '40px', minWidth: 328 }}
            color='blueButton'
            disabled={false}
            onClick={() => {
              if (checked) {
                login()
              } else {
                setCheckedError(true)
              }
            }}
          >
            <img src={google} alt='google-logo' width={'24px'} />
            <Typography
              variant='labelLarge'
              sx={{ textTransform: 'none', ml: 1 }}
            >
              Google
            </Typography>
          </Button>
        </Box>
        <Divider>
          <Typography component='p' variant='labelLarge' sx={{ mx: 'auto' }}>
            {vocabulary.orWith[langSelected]}
          </Typography>
        </Divider>

        <TextField
          error={errorRecieved || emailError}
          value={userMail}
          disabled={isLoading}
          helperText={
            errorRecieved
              ? vocabulary.userExists[langSelected]
              : emailError
                ? vocabulary.userExists[langSelected]
                : null
          }
          placeholder={vocabulary.email[langSelected]}
          InputLabelProps={{ shrink: true }}
          autoComplete='email'
          type='email'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Envelope size={24} weight='light' />
              </InputAdornment>
            ),
          }}
          onChange={event => {
            setUserMail(event.target.value)

            if (isValidEmail(event.target.value)) {
              setEmailError(false)
            }
          }}
          sx={{
            minWidth: 1,
            mt: 4,
            mb: 3,

            borderRadius: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
              color: theme.palette.primary.onSurfaceVariant,
              opacity: 1,
            },
          }}
          variant='outlined'
          margin='dense'
        />

        <TextField
          value={userPassword}
          disabled={isLoading}
          type={hided ? 'password' : 'text'}
          autoComplete='current-password'
          placeholder={vocabulary.enterPassword[langSelected]}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Lock size={24} weight='light' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='start'>
                <Eye
                  size={24}
                  weight='light'
                  onClick={() => {
                    setHided(!hided)
                  }}
                  sx={{ cusor: 'pointer' }}
                />
              </InputAdornment>
            ),
          }}
          onChange={event => {
            setUserPassword(event.target.value)
          }}
          sx={{
            minWidth: 1,

            borderRadius: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
              color: theme.palette.primary.onSurfaceVariant,
              opacity: 1,
            },
          }}
          variant='outlined'
          margin='dense'
        />

        <Typography
          variant='labelSmallLight'
          sx={{ textTransform: 'none', ml: 2 }}
        >
          {vocabulary.passwordRequirements[langSelected]}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            my: 2,
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            control={
              <Checkbox
                disabled={isLoading}
                sx={{
                  color: theme.palette.primary.main,
                  '&.Mui-checked': {
                    color: 'white',
                    backgroundColor: theme.palette.primary.main,
                  },

                  '&.MuiCheckbox-root': {
                    borderRadius: '3px',
                    padding: 0,
                    mx: 2,
                    display: 'flex',
                  },
                  '& svg': {
                    scale: '1.1',
                  },
                  m: 0,
                  p: 0,
                }}
                checked={checked}
                onChange={event => {
                  setChecked(event.target.checked)
                  setCheckedError(false)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <>
                <Typography component='p' variant='labelSmallLight' sx={{}}>
                  {vocabulary.consent[langSelected]}
                </Typography>
                {checkedError ? (
                  <Typography
                    component='p'
                    color='error'
                    variant='labelSmallLight'
                    sx={{ mt: 2 }}
                  >
                    {vocabulary.errorConsent[langSelected]}
                  </Typography>
                ) : null}
              </>
            }
          />
        </Box>
        {isLoading ? (
          <>
            {' '}
            <CircularProgress
              sx={{
                //position: 'absolute',
                mx: 'auto',

                //left: 'calc(50% - 20px)',
                // transform: 'translateX(-50%)',
              }}
            />
            <Typography
              component='p'
              color='error'
              variant='body'
              sx={{ mx: 'auto', my: 1 }}
            >
              {countdownValue}
            </Typography>
          </>
        ) : (
          <Button
            variant='contained'
            size='medium'
            sx={{ width: '328px', height: '40px', m: 'auto', my: 1 }}
            color='blueButton'
            disabled={isLoading}
            onClick={async () => {
              if (isValidEmail(userMail)) {
                if (checked) {
                  setIsLoading(true)
                  let result = await GlobalActions.signUp({
                    username: userMail,
                    password: userPassword,
                    lang: langSelected,
                  })

                  if (result === 'bad credentials') {
                    setIsLoading(false)
                    setErrorRecieved(true)
                  } else {
                    closeSignUpForm()
                    navigate('/home')
                  }
                } else {
                  setIsLoading(false)
                  setCheckedError(true)
                }
              } else {
                setIsLoading(false)
                setEmailError(true)
              }
            }}
          >
            <Typography
              variant='labelLarge'
              sx={{ textTransform: 'none', ml: 1 }}
            >
              {vocabulary.signUp[langSelected]}
            </Typography>
          </Button>
        )}
        <Box sx={{ m: 'auto', mt: 1, display: 'flex', flexDirection: 'row' }}>
          <Typography variant='bodySmall'>
            {vocabulary.registred[langSelected]}
          </Typography>

          <Typography
            variant='labelLarge'
            color='primary'
            sx={{ ml: 2, textTransform: 'none' }}
            onClick={openLoginForm}
          >
            {vocabulary.enter[langSelected]}
          </Typography>
        </Box>
      </form>
    </Box>
  )
}
