import vocabulary from 'vocabulary/vocabularyOther'
import { getTranslation } from 'pages/helpers/workflowStepSchema'
import { requestServiceType } from './requestServiceType'
import {
  requestDocumentToLegalize,
  requestDocumentsDisponible,
} from './requestDocuments'

const FINAL_STEP = 4

export const getStepByChosenService = ({ service }) => {
  const [certificateToReturn, documentsLegalization] =
    vocabulary.workflow.requestServiceType.options.map(getTranslation('eng'))

  const nextStep =
    {
      [certificateToReturn]: 2,
      [documentsLegalization]: 3,
    }[service] || FINAL_STEP

  return nextStep
}

export const getSteps = langSelected => [
  {
    step: 1,
    progress: 20,
    ...requestServiceType(langSelected),
    nextStep: getStepByChosenService,
  },
  {
    step: 2,
    progress: 50,
    ...requestDocumentsDisponible(langSelected),
    nextStep: FINAL_STEP,
  },
  {
    step: 3,
    progress: 50,
    ...requestDocumentToLegalize(langSelected),
    nextStep: FINAL_STEP,
  },
]
