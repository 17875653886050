import {
  createOptions,
  createStepSchema,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyZags'

export const requestServiceType = langSelected => {
  const { title, options, label } = vocabulary.workflow.requestServiceType

  return createStepSchema(
    title[langSelected],
    [
      {
        type: 'select',
        name: 'service',
        componentProps: {
          options: createOptions(options, langSelected),
          label: label[langSelected],
        },
      },
    ],
    ({ service }) => ({ service }),
  )
}
