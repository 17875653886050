import React, { createContext, useContext, useReducer } from 'react'

const MIN_ZINDEX = 1295
const SCALE = 0.95

const DialogModalContext = createContext()

const modalReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      const newZIndex = state.length
        ? state[state.length - 1].zIndex + 1
        : MIN_ZINDEX
      const scaledModals = state.map(modal => ({ ...modal, scale: SCALE }))

      return [
        ...scaledModals,
        { id: action.payload.id, zIndex: newZIndex, scale: 1 },
      ]

    case 'CLOSE_MODAL':
      const filteredModals = state.filter(
        modal => modal.id !== action.payload.id,
      )

      return filteredModals.map((modal, index) => ({
        ...modal,
        scale: index === filteredModals.length - 1 ? 1 : SCALE,
      }))

    default:
      throw new Error(`Unknown action: ${action.type}`)
  }
}

export const DialogModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, [])

  const openModal = id => dispatch({ type: 'OPEN_MODAL', payload: { id } })
  const closeModal = id => dispatch({ type: 'CLOSE_MODAL', payload: { id } })

  return (
    <DialogModalContext.Provider
      value={{ modals: state, openModal, closeModal }}
    >
      {children}
    </DialogModalContext.Provider>
  )
}

export const useDialogModalContext = () => useContext(DialogModalContext)
