import dayjs from 'dayjs'
import { getTranslation } from 'pages/helpers/workflowStepSchema'
import { serviceTypes } from 'vocabulary/vocabularyCitizenship'
import { requestServiceType } from './requestServiceType'
import { requestPersonWhoObtainCitizenship } from './requestPersonWhoObtainCitizenship'
import { requestCase } from './requestCase'
import vocabulary from 'vocabulary/vocabularyCitizenship'
import { requestPersonWhoRenounceCitizenship } from './requestPersonWhoRenounceCitizenship'
import { requestBirthDate } from './requestBirthDate'
import { requestChildsBirthDate } from './requestChildsBirthDate'
import { requestChildsData } from './requestChildsData'
import {
  requestCitizenshipInfoForCurrentPeriod,
  requestCitizenshipInfoForPreviousPeriod,
} from './requestCitizenshipInfo'
import {
  requestParentsCitizenshipDefault,
  requestParentsCitizenshipOnChildBirth,
} from './requestParentsCitizenship'

const FINAL_STEP = 12

export const getStepByChosenService = ({ service }) => {
  const [checkCitizenship, obtainCitizenship, renounceCitizenship] =
    serviceTypes.map(getTranslation('eng'))

  const nextStep = {
    [checkCitizenship]: 2,
    [obtainCitizenship]: 5,
    [renounceCitizenship]: 9,
  }[service]

  return nextStep ? nextStep : FINAL_STEP
}

export const getSteps = langSelected => [
  {
    step: 1,
    progress: 20,
    nextStep: getStepByChosenService,
    ...requestServiceType(langSelected),
  },
  {
    step: 2,
    progress: 40,
    ...requestBirthDate(langSelected),
    nextStep: ({ serviceDetails = {} }) => {
      const { birthDate } = serviceDetails

      if (!birthDate) {
        return FINAL_STEP
      }

      const dateOfTheCitizenshipLaw = dayjs('1992-02-06')
      const diff = birthDate.diff(dateOfTheCitizenshipLaw, 'day')

      return diff < 0 ? 3 : 4
    },
  },
  {
    step: 3,
    progress: 60,
    ...requestCitizenshipInfoForPreviousPeriod(langSelected),
    nextStep: FINAL_STEP,
  },
  {
    step: 4,
    progress: 60,
    ...requestCitizenshipInfoForCurrentPeriod(langSelected),
    nextStep: FINAL_STEP,
  },
  {
    step: 5,
    progress: 40,
    ...requestPersonWhoObtainCitizenship(langSelected),
    nextStep: ({ serviceDetails }) => {
      const { options } = vocabulary.workflow.requestPersonWhoObtainCitizenship
      const [me, myChild] = options.map(getTranslation('eng'))

      if (!serviceDetails?.who) {
        return FINAL_STEP
      }

      if (serviceDetails.who === me) {
        return 6
      }

      if (serviceDetails.who === myChild) {
        return 7
      }
    },
  },
  {
    step: 6,
    progress: 50,
    ...requestCase(langSelected),
    nextStep: FINAL_STEP,
  },
  {
    step: 7,
    progress: 60,
    ...requestChildsData(langSelected),
    nextStep: 8,
  },
  {
    step: 8,
    progress: 70,
    ...requestParentsCitizenshipDefault(langSelected),
    nextStep: FINAL_STEP,
  },
  {
    step: 9,
    progress: 40,
    ...requestPersonWhoRenounceCitizenship(langSelected),
    nextStep: ({ serviceDetails }) => {
      const { options } =
        vocabulary.workflow.requestPersonWhoRenounceCitizenship
      const [me, meAndMyChild, onlyChild] = options.map(getTranslation('eng'))

      if (serviceDetails?.who === me) {
        // final steps
        return FINAL_STEP
      }

      if (
        serviceDetails?.who === onlyChild ||
        serviceDetails?.who === meAndMyChild
      ) {
        return 10
      }

      return FINAL_STEP
    },
  },
  {
    step: 10,
    progress: 60,
    ...requestChildsBirthDate(langSelected),
    nextStep: 11,
  },
  {
    step: 11,
    progress: 70,
    ...requestParentsCitizenshipOnChildBirth(langSelected),
    nextStep: FINAL_STEP,
  },
]
