import React from 'react'
import GlobalContext from 'GlobalContext'
import { useSearchParams } from 'react-router-dom'

export const useOpenServiceTypeDialog = ({ section, step = 0 }) => {
  const { GlobalActions } = React.useContext(GlobalContext)
  const [, setSearchParams] = useSearchParams()

  return React.useCallback(
    ({ id, service, additionalServiceType }) => {
      GlobalActions.setDialogServiceOpen({
        section,
        step,
        index: id,
      })

      GlobalActions.setDataToSend({
        section: section,
        service: service,
        additionalServiceType: additionalServiceType,
      })

      const searchParams = { section, service, id }

      if (additionalServiceType) {
        searchParams.additionalServiceType = encodeURIComponent(
          additionalServiceType,
        )
      }

      setSearchParams(searchParams)
    },
    [GlobalActions, section, step],
  )
}
