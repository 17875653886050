import { FileText, Buildings, Stamp, Bank, Scales } from '@phosphor-icons/react'
import passportImage from '../images/passport-black.png'
import marriageRingsImage from '../images/marriage-black.png'

const vocabularyPrices = {
  pricesAndTariffs: ['Цены и тарифы', 'Prices and tariffs'],
  selectService: ['Выберите услугу:', 'Select service:'],
  typesOfPrice: [
    ['Доверенности', 'Powers of Attorney'],
    ['Загранпаспорт', 'Passport (foreign)'],
    ['Согласия, заявления', 'Consents, Statements'],
    ['Справки', 'Certificates'],
    ['Документы для юр. лиц', 'Documents for Legal Entities'],
    ['Гражданство', 'Citizenship'],
    ['Услуги ЗАГС', 'Registry Office Services'],
    ['Другие консульские услуги', 'Other Consular Services'],
    ['Консультации', 'Consultation'],
  ],
  allServices: ['Все услуги', 'All services'],
  icons: [
    <FileText size={24} weight='light' />,
    <img
      src={passportImage}
      alt='passport'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <FileText size={24} weight='light' />,
    <Stamp size={24} weight='light' />,

    <Buildings size={24} weight='light' />,
    <img
      src={passportImage}
      alt='passport'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <img
      src={marriageRingsImage}
      alt='passport'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <Bank size={24} weight='light' />,
    <Scales size={24} weight='light' />,
  ],
}

export default vocabularyPrices
