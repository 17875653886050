export const createOptions = (options, langSelected) =>
  options.map(option => ({
    value: option[1],
    label: option[langSelected],
  }))

export const getTranslation = lang => {
  return value => (lang === 'ru' ? value[0] : value[1])
}

/***
 * @param {string} title
 * @param {Array<object>} schema
 * @param {function} getDefaultValues
 * @param {function} processFormValues
 * @returns {object}
 * */

export const createStepSchema = (
  title,
  schema,
  getDefaultValues,
  processFormValues,
) => ({
  title,
  schema,
  getDefaultValues,
  processFormValues,
})
