import React from 'react'
import GlobalContext from 'GlobalContext'
import { useForm } from 'react-hook-form'
import { WorkflowContainer } from 'components/Workflow/WorkflowContainer'
import { Form } from 'components/Form/Form'

/**
 *
 * @param {string} title title of the step
 * @param {Array} schema provided form fields
 * @param {(number|function)} nextStep determine next step based on form values
 */

export default function WorkflowStep({
  title,
  schema,
  nextStep,
  progress,
  processFormValues,
  getDefaultValues,
}) {
  const { state, GlobalActions } = React.useContext(GlobalContext)
  const defaultValues = getDefaultValues?.(state.dataToSend) || {}
  const { watch, control, formState } = useForm({ defaultValues })
  const formValues = watch()

  const handleClose = () => {
    GlobalActions.clearDataToSend()
  }

  const onConfirm = () => {
    const values = processFormValues?.(formValues) || formValues

    GlobalActions.setDataToSend(values)

    const step =
      typeof nextStep === 'function'
        ? nextStep(values, state.dataToSend)
        : nextStep

    GlobalActions.setDialogServiceOpen({
      index: state.dialogService.index,
      step: step ?? state.dialogService.step + 1,
    })
  }

  return (
    <WorkflowContainer
      onClose={handleClose}
      onConfirm={onConfirm}
      onSkip={onConfirm}
      canMoveForward={formState.isDirty}
      progress={progress}
    >
      <Form title={title} schema={schema} control={control} />
    </WorkflowContainer>
  )
}
