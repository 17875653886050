// search keys
export const optionsRu = [
  'Доверенности',
  'Генеральная доверенность',
  'Доверенность на дарение недвижимости',
  'Доверенность на покупку недвижимости',
  'Доверенность на продажу недвижимости',
  'Доверенность на продажу доли общества',
  'Доверенность на машину',
  'Доверенность на ведение наследственного дела',
  'Доверенность на оформление пенсии',
  'Доверенность на оформление материнского капитала',
  'Доверенность на управление банковскими счетами',
  'Доверенность на истребование и апостилирование документов',
  'Доверенность на представительство в суде',
  'Доверенность на представительство в госорганах',
  'Распоряжение об отмене доверенности',
  'Согласия, Заявляения, Договоры',
  'Загранпаспорт',
  'Согласие на выезд ребёнка за пределы РФ',
  'Согласие супруга',
  'Изменение доли в ООО',
  'Заявление на вступление / отказ от наследства',
  'Заявление на снятие с регистрационного учёта',
  'Заявление на оформление пенсии или восстановление её выплаты',
  'Завещание',
  'Брачный договор',
  'Соглашение о разделе имущества',
  'Соглашение об алиментах',
  'Договор поручительства',
  'Договор уступки права требования (цессии)',
  'Справки',
  'Справка о несудимости (об отсутствии или наличии судимости)',
  'Заявление о брачной правоспособности (о составе семьи, о семейном положении или статусе)',
  'Справка о подтверждении ПМЖ за пределами России',
  'Акт о личной явке',
  'Справка о выполнении (невыполнении) оплачиваемой работы за пределами РФ',
  'Документы ЗАГС (свидетельство о рождении, смерти, браке, разводе, смене имени)',
  'Справка о семейном положении',
  'Справка о стаже и трудовой деятельности',
  'Справка о прохождении воинской службы',
  'Документы для юридических лиц',
  'Генеральная доверенность (для юр. лиц)',
  'Доверенность на покупку или продажу доли общества',
  'Доверенность на представительство в суде (для юр. лиц)',
  'Доверенность на представительство в госорганах России (для юр. лиц)',
  'Заявление в налоговую на регистрацию юридического лица',
  'Заявление в налоговую на внесение изменений',
  'Заявление в налоговую на реорганизацию юридического лица',
  'Услуги ЗАГС',
  'Регистрация рождения ребёнка',
  'Регистрация усыновления (удочерения)',
  'Установление отцовства',
  'Регистрация заключения брака',
  'Регистрация расторжения брака',
  'Регистрация перемены имени',
  'Регистрация смерти',
  'Гражданство',
  'Проверка наличия гражданства РФ',
  'Получение гражданства РФ',
  'Выход из гражданства РФ',
  'Легализация документов',
  'Запись в консульство',

  'Отличия загранпаспорта сроком на 5 и 10 лет',
  'Как указывать сведения о трудовой деятельности',
  'Свидетельство на возвращение',
].sort((a, b) => a.localeCompare(b))

const optionsEn = [
  'Power of Attorney',
  'General Power of Attorney',
  'Power of Attorney for Gift of Real Estate',
  'Power of Attorney for Purchase of Real Estate',
  'Power of Attorney for Sale of Real Estate',
  'Power of Attorney for Sale of Company Shares',
  'Power of Attorney for a Car',
  'Power of Attorney for Inheritance Matters',
  'Power of Attorney for Pension Matters',
  'Power of Attorney for Maternity Capital',
  'Power of Attorney for Managing Bank Accounts',
  'Power of Attorney for Retrieval and Apostille of Documents',
  'Power of Attorney for Legal Representation in Court',
  'Power of Attorney for Representation in Government Agencies',
  'Revocation of Power of Attorney',
  'Consents, Statements, Contracts',
  'International Passport',
  'Consent for a Child to Travel Outside the Russian Federation',
  'Spousal Consent',
  'Change of Share in an LLC',
  'Application for Inheritance Entry/Refusal',
  'Application for Deregistration',
  'Application for Pension Assignment or Restoration',
  'Will',
  'Prenuptial Agreement',
  'Property Division Agreement',
  'Alimony Agreement',
  'Surety Agreement',
  'Assignment Agreement',
  'Certificates',
  'Criminal Record Certificate (Presence or Absence of Conviction)',
  'Statement of Marital Capacity (on family composition, marital status, or status)',
  'Certificate of Permanent Residence Outside Russia',
  'Personal Attendance Act',
  'Certificate of Employment Outside Russia',
  'ZAGS Documents (birth, death, marriage, divorce, name change certificates)',
  'Certificate of Marital Status',
  'Certificate of Work Experience and Employment',
  'Certificate of Military Service',
  'Documents for Legal Entities',
  'General Power of Attorney (for legal entities)',
  'Power of Attorney for Buying or Selling a Share of an LLC',
  'Power of Attorney for Legal Representation in Court (for legal entities)',
  'Power of Attorney for Representation in Government Agencies (for legal entities)',
  'Application to the Tax Office for Registration of a Legal Entity',
  'Application to the Tax Office for Making Changes',
  'Application to the Tax Office for Reorganization of a Legal Entity',
  'ZAGS Services',
  'Child Birth Registration',
  'Adoption Registration',
  'Establishment of Paternity',
  'Marriage Registration',
  'Divorce Registration',
  'Name Change Registration',
  'Death Registration',
  'Citizenship',
  'Russian Citizenship Verification',
  'Obtaining Russian Citizenship',
  'Renunciation of Russian Citizenship',
  'Document Legalization',
  'Appointment at the Consulate',

  'Differences between 5-year and 10-year international passports',
  'How to provide information about work activities',
  'Certificate for return',
].sort((a, b) => a.localeCompare(b))

export const options = [optionsRu, optionsEn]

export const vocabulary = {
  searchResults: ['Результаты поиска', 'Search results'],
  searchModalInputPlaceHolder: [
    'Найдите нужную информацию во ключевым словам',
    'Find necessary information by key words',
  ],
  page: ['Страница', 'Page'],
  notFound: ['Ничего не найдено', 'search results are not found'],
}

export const titleByPath = {
  '/poa': ['Доверенности', 'Power of Attorney'],
  '/about': ['О нас', 'About Us'],

  '/poa?section=poa&service=general&id=0': [
    'Генеральная доверенность',
    'General Power of Attorney',
  ],
  '/poa?section=poa&service=inheritance+of+real+estate&id=1': [
    'Доверенность на дарение недвижимости',
    'Power of Attorney for Gift of Real Estate',
  ],
  '/poa?section=poa&service=for+the+purchase+of+real+estate&id=2': [
    'Доверенность на покупку недвижимости',
    'Power of Attorney for Purchase of Real Estate',
  ],
  '/poa?section=poa&service=for+the+sale+of+real+estate&id=3': [
    'Доверенность на продажу недвижимости',
    'Power of Attorney for Sale of Real Estate',
  ],
  '/poa?section=poa&service=for+the+sale+of+a+share+in+a+company&id=4': [
    'Доверенность на продажу доли общества',
    'Power of Attorney for Sale of Company Shares',
  ],
  '/poa?section=poa&service=for+a+car&id=5': [
    'Доверенность на машину',
    'Power of Attorney for a Car',
  ],
  '/poa?section=poa&service=for+handling+inheritance+matters&id=6': [
    'Доверенность на ведение наследственного дела',
    'Power of Attorney for Inheritance Matters',
  ],
  '/poa?section=poa&service=for+processing+pension+documents&id=7': [
    'Доверенность на оформление пенсии',
    'Power of Attorney for Pension Matters',
  ],
  '/poa?section=poa&service=for+processing+maternity+capital+documents&id=8': [
    'Доверенность на оформление материнского капитала',
    'Power of Attorney for Maternity Capital',
  ],
  '/poa?section=poa&service=for+managing+bank+accounts&id=9': [
    'Доверенность на управление банковскими счетами',
    'Power of Attorney for Managing Bank Accounts',
  ],
  '/poa?section=poa&service=for+retrieval+and+apostille+of+documents&id=10': [
    'Доверенность на истребование и апостилирование документов',
    'Power of Attorney for Retrieval and Apostille of Documents',
  ],
  '/poa?section=poa&service=for+legal+representation+in+court&id=11': [
    'Доверенность на представительство в суде',
    'Power of Attorney for Legal Representation in Court',
  ],
  '/poa?section=poa&service=for+representation+in+government+agencies&id=12': [
    'Доверенность на представительство в госорганах',
    'Power of Attorney for Representation in Government Agencies',
  ],
  '/poa?section=poa&service=revocation+of+power+of+attorney&id=13': [
    'Распоряжение об отмене доверенности',
    'Revocation of Power of Attorney',
  ],

  '/passport': ['Загранпаспорт', 'International Passport'],

  '/consent': [
    'Согласия, Заявляения, Договоры',
    'Consents, Statements, Contracts',
  ],
  '/consent?section=consent&service=Consent&id=0&additionalServiceType=For%2520the%2520child%2520to%2520travel%2520outside%2520the%2520Russian%2520Federation':
    [
      'Согласие на выезд ребёнка за пределы РФ',
      'Consent for a Child to Travel Outside the Russian Federation',
    ],
  '/consent?section=consent&service=Consent&id=1': [
    'Согласие супруга',
    'Spousal Consent',
  ],
  '/consent?section=consent&service=Consent&id=2': [
    'Изменение доли в ООО',
    'Change of Share in an LLC',
  ],
  '/consent?section=consent&service=Application&id=3&additionalServiceType=For%2520inheritance%2520entry':
    [
      'Заявление на вступление / отказ от наследства',
      'Application for Inheritance Entry/Refusal',
    ],
  '/consent?section=consent&service=Application&id=4&additionalServiceType=For%2520deregistration':
    [
      'Заявление на снятие с регистрационного учёта',
      'Application for Deregistration',
    ],
  '/consent?section=consent&service=Application&id=5&additionalServiceType=For%2520pension%2520payment':
    [
      'Заявление на оформление пенсии или восстановление её выплаты',
      'Application for Pension Assignment or Restoration',
    ],
  '/consent?section=consent&service=Application&id=6&additionalServiceType=Will':
    ['Завещание', 'Will'],
  '/consent?section=consent&service=Agreement&id=7&additionalServiceType=Marriage%2520contract':
    ['Брачный договор', 'Prenuptial Agreement'],
  '/consent?section=consent&service=Agreement&id=8&additionalServiceType=Property%2520division%2520agreement':
    ['Соглашение о разделе имущества', 'Property Division Agreement'],
  '/consent?section=consent&service=Agreement&id=9&additionalServiceType=Alimony%2520agreement':
    ['Соглашение об алиментах', 'Alimony Agreement'],
  '/consent?section=consent&service=Agreement&id=10&additionalServiceType=Surety%2520agreement':
    ['Договор поручительства', 'Surety Agreement'],
  '/consent?section=consent&service=Agreement&id=11&additionalServiceType=Assignment%2520agreement':
    ['Договор уступки права требования (цессии)', 'Assignment Agreement'],

  '/certificates': ['Справки', 'Certificates'],
  '/certificates?section=certificates&service=Consular+certificate&id=0&additionalServiceType=Criminal%2520record%2520certificate':
    [
      'Справка о несудимости (об отсутствии или наличии судимости)',
      'Criminal Record Certificate (Presence or Absence of Conviction)',
    ],
  '/certificates?section=certificates&service=Consular+certificate&id=1&additionalServiceType=Marriage%2520capacity%2520certificate':
    [
      'Заявление о брачной правоспособности (о составе семьи, о семейном положении или статусе)',
      'Statement of Marital Capacity (on family composition, marital status, or status)',
    ],
  '/certificates?section=certificates&service=Consular+certificate&id=2&additionalServiceType=Permanent%2520residence%2520certificate%2520outside%2520the%2520Russian%2520Federation':
    [
      'Справка о подтверждении ПМЖ за пределами России',
      'Certificate of Permanent Residence Outside Russia',
    ],
  '/certificates?section=certificates&service=Consular+certificate&id=3&additionalServiceType=Personal%2520attendance%2520act':
    ['Акт о личной явке', 'Personal Attendance Act'],
  '/certificates?section=certificates&service=Consular+certificate&id=4&additionalServiceType=Work%2520certificate%2520outside%2520the%2520Russian%2520Federation':
    [
      'Справка о выполнении (невыполнении) оплачиваемой работы за пределами РФ',
      'Certificate of Employment Outside Russia',
    ],
  '/certificates?section=certificates&service=Request+a+document+from+Russia&id=5&additionalServiceType=Civil%2520registry%2520document':
    [
      'Документы ЗАГС (свидетельство о рождении, смерти, браке, разводе, смене имени)',
      'ZAGS Documents (birth, death, marriage, divorce, name change certificates)',
    ],
  '/certificates?section=certificates&service=Request+a+document+from+Russia&id=6&additionalServiceType=Certificate%2520of%2520marital%2520status':
    ['Справка о семейном положении', 'Certificate of Marital Status'],
  '/certificates?section=certificates&service=Request+a+document+from+Russia&id=7&additionalServiceType=Certificate%2520of%2520work%2520experience%2520and%2520employment':
    [
      'Справка о стаже и трудовой деятельности',
      'Certificate of Work Experience and Employment',
    ],
  '/certificates?section=certificates&service=Request+a+document+from+Russia&id=8&additionalServiceType=Certificate%2520of%2520military%2520service':
    [
      'Справка о прохождении воинской службы',
      'Certificate of Military Service',
    ],

  '/legal-entities': [
    'Документы для юридических лиц',
    'Documents for Legal Entities',
  ],
  '/legal-entities?section=legalEntities&service=Power+of+attorney&id=0&additionalServiceType=General':
    [
      'Генеральная доверенность (для юр. лиц)',
      'General Power of Attorney (for legal entities)',
    ],
  '/legal-entities?section=legalEntities&service=Power+of+attorney&id=1&additionalServiceType=For%2520buying%2520or%2520selling%2520a%2520share%2520of%2520an%2520LLC':
    [
      'Доверенность на покупку или продажу доли общества',
      'Power of Attorney for Buying or Selling a Share of an LLC',
    ],
  '/legal-entities?section=legalEntities&service=Power+of+attorney&id=2&additionalServiceType=For%2520representation%2520in%2520court':
    [
      'Доверенность на представительство в суде (для юр. лиц)',
      'Power of Attorney for Legal Representation in Court (for legal entities)',
    ],
  '/legal-entities?section=legalEntities&service=Power+of+attorney&id=3&additionalServiceType=For%2520representation%2520in%2520government%2520agencies':
    [
      'Доверенность на представительство в госорганах России (для юр. лиц)',
      'Power of Attorney for Representation in Government Agencies (for legal entities)',
    ],
  '/legal-entities?section=legalEntities&service=Spousal+consent&id=4': [
    'Согласие супруга (для юр. лиц)',
    'Spousal Consent (for legal entities)',
  ],
  '/legal-entities?section=legalEntities&service=Tax+application&id=5&additionalServiceType=For%2520registration%2520of%2520a%2520legal%2520entity':
    [
      'Заявление в налоговую на регистрацию юридического лица',
      'Application to the Tax Office for Registration of a Legal Entity',
    ],
  '/legal-entities?section=legalEntities&service=Tax+application&id=6&additionalServiceType=For%2520making%2520changes':
    [
      'Заявление в налоговую на внесение изменений',
      'Application to the Tax Office for Making Changes',
    ],
  '/legal-entities?section=legalEntities&service=Tax+application&id=7&additionalServiceType=For%2520reorganization%2520of%2520a%2520legal%2520entity':
    [
      'Заявление в налоговую на реорганизацию юридического лица',
      'Application to the Tax Office for Reorganization of a Legal Entity',
    ],

  '/zags': ['Услуги ЗАГС', 'ZAGS Services'],
  '/zags?section=zags&service=Childbirth&id=0': [
    'Регистрация рождения ребёнка',
    'Child Birth Registration',
  ],
  '/zags?section=zags&service=Adoption&id=1': [
    'Регистрация усыновления (удочерения)',
    'Adoption Registration',
  ],
  '/zags?section=zags&service=Establishment+of+paternity&id=2': [
    'Установление отцовства',
    'Establishment of Paternity',
  ],
  '/zags?section=zags&service=Marriage&id=3': [
    'Регистрация заключения брака',
    'Marriage Registration',
  ],
  '/zags?section=zags&service=Divorce&id=4': [
    'Регистрация расторжения брака',
    'Divorce Registration',
  ],
  '/zags?section=zags&service=Name+change&id=5': [
    'Регистрация перемены имени',
    'Name Change Registration',
  ],
  '/zags?section=zags&service=Death+registration&id=6': [
    'Регистрация смерти',
    'Death Registration',
  ],

  '/citizenship': ['Гражданство', 'Citizenship'],
  '/other': ['Другое', 'Other'],
  '/consulting': ['Консультация', 'Consulting'],
  '/appointment': ['Запись в консульство', 'Appointment at the Consulate'],
  '/prices': ['Цены', 'Prices'],

  '/passport?open=passportDifferences': [
    'Отличия загранпаспорта сроком на 5 и 10 лет',
    'Differences between 5-year and 10-year international passports',
  ],
  '/passport?open=workActivityTips': [
    'Как указывать сведения о трудовой деятельности',
    'How to provide information about work activities',
  ],
  '/citizenship#check-citizenship': [
    'Проверка на гражданство',
    'Citizenship verification',
  ],
  '/other#return-certificate': [
    'Свидетельство на возвращение',
    'Certificate for return',
  ],
}
