import { createStepSchema } from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyCitizenship'

export const requestBirthDate = langSelected => {
  const { title, label, format } = vocabulary.workflow.requestBirthDate

  return createStepSchema(
    title[langSelected],
    [
      {
        type: 'date-picker',
        name: 'serviceDetails.birthDate',
        componentProps: {
          label: label[langSelected],
          format: format[langSelected],
        },
      },
    ],
    ({ serviceDetails }) => ({
      serviceDetails: { birthDate: serviceDetails?.birthDate || null },
    }),
  )
}
