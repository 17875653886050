import Layout1439 from '../Layouts/Layout1439'
import { PrivacyPolicy } from 'pages/components/PrivacyPolicy/PrivacyPolicy'

export default function PrivacyPolicy1439() {
  return (
    <Layout1439>
      <PrivacyPolicy />
    </Layout1439>
  )
}
