import { createStepSchema } from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyCitizenship'

export const requestChildsBirthDate = langSelected => {
  const { label, format } = vocabulary.workflow.requestBirthDate
  const { title } = vocabulary.workflow.requestChildsBirthDate

  return createStepSchema(title[langSelected], [
    {
      type: 'date-picker',
      name: 'birthDate',
      componentProps: {
        label: label[langSelected],
        format: format[langSelected],
      },
    },
  ])
}
