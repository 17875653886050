import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'

export const createRequestDetailsStep = (
  { title, options },
  name,
  langSelected,
) => {
  return createStepSchema(
    title[langSelected],
    [
      {
        type: 'radio',
        name: `serviceDetails.${name}`,
        componentProps: {
          options: createOptions(options, langSelected),
        },
      },
    ],
    null,
    ({ details, ...other }) => ({
      ...other,
      details,
    }),
  )
}
