import {
  Baby,
  IdentificationCard,
  AddressBookTabs,
} from '@phosphor-icons/react'

import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'
import marriageRingsImage from '../images/marriage-black.png'

export const serviceTypes = [
  ['Рождение ребенка', 'Childbirth'],
  ['Усыновление (удочерение) ребенка', 'Adoption'],
  ['Установление отцовства', 'Establishment of paternity'],
  ['Заключение брака', 'Marriage'],
  ['Расторжение брака', 'Divorce'],
  ['Перемена имени', 'Name change'],
  ['Регистрация смерти', 'Death registration'],
]

const vocabularyZags = {
  zagsServices: ['Услуги ЗАГС', 'ZAGS Services'],
  infoText1: [
    'В консульстве или посольстве России граждане могут воспользоваться услугами ЗАГС: зарегистрировать рождение ребёнка, брак, развод или смерть близкого.',
    'At the Russian consulate or embassy, ​​citizens can use the services of a registry office: to register the birth of a child, marriage, divorce or death of a loved one.',
  ],
  infoText2: [
    'Мы будем рады помочь вам на каждом этапе взаимодействия с консульством: подготовим за вас заявление в нужном формате на консульском портале, проконсультируем по вашей ситуации и перечню документов, которые необходимо будет взять с собой, при необходимости окажем помощь в записи на приём в консульство.',
    'We will be happy to help you at every stage of interaction with the consulate: we will prepare an application for you in the required format on the consular portal, we will advise you on your situation and the list of documents that you will need to take with you, and, if necessary, we will assist you in making an appointment at the consulate.',
  ],
  zagsServicesConsulate: [
    'Услуги ЗАГС, оказываемые консульством',
    'Civil registry services provided by the consulate',
  ],
  typesOfDocuments: [
    {
      section: [
        'Услуги ЗАГС, оказываемые консульством',
        'Civil registry services provided by the consulate',
      ],
      types: [
        {
          service: serviceTypes[0][1],
          text: ['Рождение ребёнка', 'Birth of a child'],
          icon: <Baby size={24} weight='light' />,
        },

        {
          service: serviceTypes[1][1],
          text: ['Усыновление / удочерение', 'Adoption'],
          icon: <Baby size={24} weight='light' />,
        },
        {
          service: serviceTypes[2][1],
          text: ['Установление отцовства', 'Establishing paternity'],
          icon: <Baby size={24} weight='light' />,
        },

        {
          service: serviceTypes[3][1],
          text: ['Заключение брака', 'Marriage'],
          icon: <img src={marriageRingsImage} alt='marriage-ring' width={24} />,
        },

        {
          service: serviceTypes[4][1],
          text: ['Расторжение брака', 'Divorce'],
          icon: <img src={marriageRingsImage} alt='marriage-ring' width={24} />,
        },
        {
          service: serviceTypes[5][1],
          text: ['Перемена имени', 'Name change'],
          icon: <IdentificationCard size={24} weight='light' />,
        },
        {
          service: serviceTypes[6][1],
          text: ['Регистрация смерти', 'Death registration'],
          icon: <AddressBookTabs size={24} weight='light' />,
        },
      ],
    },
  ],

  orderDescription: [
    [
      {
        headerText: 'Оформление заявки',
        cardText:
          'Выберите нужную вам консульскую услугу и опишите вашу ситуацию. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Заполнение заявления',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали и заполнит онлайн-заявление на консульском портале. Вы получите заполненный документ, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Получение готового документа',
        cardText:
          'В день записи приходите в консульство с пакетом документов для подачи на получение услуги ЗАГС',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submitting an application',
        cardText:
          'Select the consular service you need and describe your situation. You can send us the required documents either simultaneously with the application or directly to the lawyer after consultation with him.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparation of Document Text',
        cardText:
          'Our lawyer will contact you, discuss the details, prepare a draft document and post it on the CD portal of the Russian Foreign Ministry. You will receive an accommodation number, which you will need to take with you to the consulate. The service completion time is 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Appointment Scheduling at the Consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Receipt of the Ready Document',
        cardText:
          'On the day of registration, come to the consulate with a package of documents to submit for the registry office service',
        cardImage: stampImage,
      },
    ],
  ],
  infoText3: [
    'Если вам требуется получить дубликат документа из ЗАГС (в том числе с апостилем) или справку о семейном положении для заключения брака с иностранцем, перейдите в раздел:',
    'If you need to obtain a duplicate document from the registry office (including with an apostille) or a certificate of marital status for marriage with a foreigner, go to the section:',
  ],
  documentRequest: ['Истребование документов ', 'Request for documents'],
  infoText4: [
    'Базовые комплекты документов, представляемые в консульство / посольство России для получения услуг ЗАГС:',
    'Basic sets of documents submitted to the Russian consulate/embassy to receive civil registry services:',
  ],

  userTypes: [
    {
      userName: ['Рождение ребенка', 'Birth of a child'],
      desc: [
        [
          'совместное заявление от родителей, находящихся в браке на момент рождения ребёнка, либо от одного из родителей, если он является единственным или не состоит в браке со вторым родителем на момент рождения ребёнка',
          'a joint application from the parents who are married at the time of the child’s birth, or from one of the parents if he is the only one or is not married to the second parent at the time of the child’s birth',
        ],
        [
          'загранпаспорта и/или внутренние паспорта обоих родителей или единственного родителя. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'International passports and/or internal passports of both parents or a single parent. If you have both valid passports, you must provide both',
        ],
        [
          'медицинская справка о рождении ребёнка от организации, в которой проходили роды, должным образом заверенная и переведённая на русский язык (при необходимости)',
          'medical certificate about the birth of a child from the organization where the birth took place, duly certified and translated into Russian (if necessary)',
        ],
        [
          'свидетельство о браке или совместное заявление родителей об установлении отцовства (если в браке не состоят)',
          'marriage certificate or joint statement of parents establishing paternity (if they are not married)',
        ],
      ],
    },
    {
      userName: ['Усыновление / удочерение', 'Adoption'],
      desc: [
        [
          'заявление от лица усыновителей (усыновителя)',
          'statement on behalf of the adoptive parents (adoptive parent)',
        ],
        [
          'решение суда об усыновлении ребёнка',
          'court decision on adoption of a child',
        ],
        [
          'загранпаспорта и/или внутренние паспорта усыновителей. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'international passports and/or internal passports of adoptive parents. If you have both valid passports, you must provide both',
        ],
      ],
    },
    {
      userName: ['Установление отцовства', 'Establishing paternity'],
      desc: [
        [
          'совместное заявление отца и матери ребёнка, не состоящих в браке на момент рождения ребёнка, или два отдельных, если один из заявителей не может явиться в консульство лично. При этом подпись на его заявлении должна быть заверена нотариально',
          'a joint application from the father and mother of the child who were not married at the time of the child’s birth, or two separate ones if one of the applicants is unable to appear at the consulate in person. In this case, the signature on his application must be notarized',
        ],
        [
          'заявление отца ребёнка, не состоящего в браке с его матерью на момент рождения ребёнка, в случае смерти матери, признание её недееспособной, отсутствия сведений о месте её пребывания или лишения её родительских прав, а также при наличии согласия органа опеки и попечительства',
          'statement of the child’s father, who is not married to his mother at the time of the child’s birth, in the event of the mother’s death, recognition of her as legally incompetent, lack of information about her place of stay or deprivation of her parental rights, as well as with the consent of the guardianship and trusteeship authority',
        ],
        [
          'заявление на основании решения суда об установлении отцовства или об установлении факта признания отцовства, вступившее в законную силу',
          'an application based on a court decision to establish paternity or to establish the fact of recognition of paternity, which has entered into legal force',
        ],
      ],

      headerDesc: [
        ['Один из следующих документов:', 'One of the following documents:'],
        ['А также:', 'And:'],
      ],
      addDesc: [
        ['свидетельство о рождении ребёнка', "child's birth certificate"],

        [
          'загранпаспорта и/или внутренние паспорта обоих родителей. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'International passports and/or internal passports of both parents. If you have both valid passports, you must provide both',
        ],
        [
          'согласие ребёнка, если он достиг совершеннолетия',
          'consent of the child if he has reached the age of majority',
        ],
      ],
    },
    {
      userName: ['Заключение брака', 'Marriage'],
      desc: [
        [
          'совместное заявление или два отдельных, если один из заявителей не может явиться в консульство лично. При этом подпись на его заявлении должна быть заверена нотариально',
          'a joint application or two separate ones, if one of the applicants is unable to appear at the consulate in person. In this case, the signature on his application must be notarized',
        ],

        [
          'загранпаспорта и/или внутренние паспорта усыновителей. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'international passports and/or internal passports of adoptive parents. If you have both valid passports, you must provide both',
        ],
        [
          'свидетельство о расторжении брака, если кто‑либо из заявителей ранее состоял в браке',
          'certificate of divorce, if any of the applicants was previously married',
        ],
      ],
    },
    {
      userName: ['Расторжение брака', 'Divorce'],
      desc: [
        [
          'при обоюдном согласии: совместное заявление или два отдельных, если один из заявителей не может явиться в консульство лично. При этом подпись на его заявлении должна быть заверена нотариально',
          'with mutual consent: a joint application or two separate ones, if one of the applicants cannot appear at the consulate in person. In this case, the signature on his application must be notarized',
        ],
        [
          'при наличии решения суда о расторжении брака: заявление супругов (или одного супруга) и решение суда',
          'if there is a court decision on divorce: application of the spouses (or one spouse) and court decision',
        ],
        [
          'при подаче одним из супругов: заявление и решение (приговор) суда, в котором другой супруг признан безвестно отсутствующим, недееспособным или осуждён на срок свыше 3 лет',
          'when filed by one of the spouses: an application and a decision (sentence) of the court in which the other spouse is declared missing, incompetent or sentenced to a term of more than 3 years',
        ],
      ],

      headerDesc: [
        ['Один из следующих документов:', 'One of the following documents:'],
        ['А также:', 'And:'],
      ],
      addDesc: [
        [
          'загранпаспорта и/или внутренние паспорта заявителей. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'international passports and/or internal passports of applicants. If you have both valid passports, you must provide both',
        ],

        ['свидетельство о браке', 'Marriage certificate'],
      ],
    },
    {
      userName: ['Перемена имени', 'Name change'],
      desc: [
        ['заявление о перемене имени', 'application for name change'],
        [
          'загранпаспорт и/или внутренний паспорт. Если имеются в наличии оба действительных паспорта, то нужно предоставить оба',
          'international passport and/or internal passport. If you have both valid passports, you must provide both',
        ],
        ['свидетельство о рождении', 'birth certificate'],
        [
          'свидетельство о браке (если заявитель в нем состоит)',
          'marriage certificate (if the applicant is a member of it)',
        ],
        [
          'свидетельство о расторжении брака (если заявитель желает вернуть добрачную фамилию)',
          'certificate of divorce (if the applicant wishes to return the premarital surname)',
        ],
        [
          'свидетельство о рождении каждого несовершеннолетнего ребёнка заявителя',
          'birth certificate of each minor child of the applicant',
        ],
        [
          'согласие родителей или законных представителей или решение суда при их отсутствии (для детей от 14 до 18 лет)',
          'consent of parents or legal representatives or a court decision in their absence (for children from 14 to 18 years old)',
        ],
        [
          'решения российского органа опеки и попечительства (для детей младше 14 лет)',
          'decisions of the Russian guardianship and trusteeship authority (for children under 14 years old)',
        ],
      ],
    },
    {
      userName: ['Регистрация смерти', 'Death registration'],
      desc: [
        ['заявление', 'statement'],
        [
          'документ, подтверждающий факт смерти, выданный медицинской организацией или частнопрактикующим врачом, либо решения суда об установлении факта смерти или об объявлении лица умершим',
          'a document confirming the fact of death, issued by a medical organization or a private practitioner, or a court decision to establish the fact of death or to declare a person deceased',
        ],
        [
          'загранпаспорт и/или внутренний паспорт умершего (загранпаспорт). Если имеются в наличии оба паспорта, то нужно предоставить оба',
          'international passport and/or internal passport of the deceased (foreign passport). If you have both passports, then you need to provide both',
        ],
        [
          'загранпаспорт и/или внутренний паспорт лица, сделавшего заявление о смерти',
          'international passport and/or internal passport of the person who made the statement of death',
        ],
      ],
    },
  ],

  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'заполним заявление (и опросный лист) на консульском портале',
        'проконсультируем по списку документов, которые потребуется предоставить, согласно вашей ситуации',
        'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '60-80',
    },
    {
      header: 'Without an appointment at the consulate',
      list: [
        'we will fill out the application (and questionnaire) on the consulate portal',
        'we will advise you on the list of documents that have to be provided, according to your situation',
        'we will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '60-80',
    },
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'заполним заявление (и опросный лист) на консульском портале',
        'проконсультируем по списку документов, которые потребуется предоставить, согласно вашей ситуации',
        'запишем вас на приём в консульство',
      ],
      value: '80-100',
    },
    {
      header: 'With an appointment at the consulate',
      list: [
        'we will fill out the application (and questionnaire) on the consulate portal',
        'we will advise you on the list of documents that have to be provided, according to your situation',
        'we will advise you on scheduling an appointment at the consulate on your own',
      ],
      value: '80-100',
    },
  ],
  faq: [
    {
      userName: [
        'Можно ли в консульстве зарегистрировать брак с иностранцем?',
        'Is it possible to register a marriage with a foreigner at the consulate?',
      ],
      desc: [
        [
          'Нет, консульство не регистрирует браки российских граждан с гражданами других государств.',
          'No, the consulate does not register marriages of Russian citizens with citizens of other states.',
        ],

        [
          'Такие браки следует заключать в органах ЗАГС на территории России, либо в аналогичных ведомствах за рубежом.',
          'Such marriages should be concluded in civil registry offices on the territory of Russia, or in similar departments abroad.',
        ],
      ],
    },
    {
      userName: [
        'Может ли заявление в консульство подать кто‑то один из супругов?',
        'Can one of the spouses submit an application to the consulate?',
      ],
      desc: [
        [
          'Да, в таком случае возможно оформить 2 отдельных заявления, при этом подпись отсутствующего лица должна быть нотариально заверена.',
          'Yes, in this case it is possible to issue 2 separate applications, and the signature of the absent person must be notarized.',
        ],
        [
          'На саму процедуру регистрации брака должны явиться оба брачующихся.',
          'Both spouses must appear for the marriage registration procedure themselves.',
        ],
      ],
    },
    {
      userName: [
        'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',
        'Can I affix an apostille to a registry office document at the consulate?',
      ],
      desc: [
        [
          'Нет, консульство не может заверять апостилем выданные в РФ документы. Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем',
          'No, the consulate cannot certify the documents issued in the Russian Federation with an apostille. However, through the consulate you can request a duplicate of a civil registry office document from Russia with an apostille already affixed to itConsent is required in every case when it comes to property acquired jointly during marriage.',
        ],
      ],
    },
    {
      userName: [
        'Можно ли в консульстве развестись?',
        'Is it possible to get a divorce at the consulate?',
      ],
      desc: [
        [
          'Да, если оба супруга согласны развестись, при этом у них нет общих несовершеннолетних детей или финансовых претензий друг к другу, либо есть решение российского суда о разводе.',
          'Yes, if both spouses agree to divorce, and they do not have joint minor children or financial claims against each other, or there is a Russian court decision on divorce.',
        ],
        [
          'Также один из супругов может подать на развод, если второй супруг судом признан безвестно отсутствующим, недееспособным или осуждён на срок свыше 3 лет.',

          'Also, one of the spouses can file for divorce if the second spouse is recognized by the court as missing, incompetent, or sentenced to a term of more than 3 years.',
        ],
      ],
    },
    {
      userName: [
        'Можно ли пожениться раньше, чем через месяц после подачи заявления?',
        'Is it possible to get married earlier than a month after submitting the application?',
      ],
      desc: [
        [
          'Да, если на то есть уважительные причины или особые обстоятельства: беременность, рождение ребёнка, непосредственная угроза жизни одной из сторон и другие.',
          'Yes, if there are good reasons or special circumstances: pregnancy, birth of a child, immediate threat to the life of one of the parties, and others.',
        ],
      ],
    },
  ],

  faqDesc: [
    [
      {
        question: 'Можно ли в консульстве зарегистрировать брак с иностранцем?',
        answer:
          'Нет, консульство не регистрирует браки российских граждан с гражданами других государств. Такие браки следует заключать в органах ЗАГС на территории России, либо в аналогичных ведомствах за рубежом.',
      },
      {
        question:
          'Может ли заявление в консульство подать кто‑то один из супругов?',
        answer:
          'Да, в таком случае возможно оформить 2 отдельных заявления, при этом подпись отсутствующего лица должна быть нотариально заверена. На саму процедуру регистрации брака должны явиться оба брачующихся.',
      },
      {
        question:
          'Могу ли я в консульстве проставить апостиль на документ ЗАГСа?',
        answer:
          'Нет, консульство не может заверять апостилем выданные в РФ документы. Тем не менее, через консульство вы можете истребовать повторный документ ЗАГС из России с уже проставленным на нем апостилем.',
      },
      {
        question: 'Можно ли в консульстве развестись?',
        answer:
          'Да, если оба супруга согласны развестись, при этом у них нет общих несовершеннолетних детей или финансовых претензий друг к другу, либо есть решение российского суда о разводе. Также один из супругов может подать на развод, если второй супруг судом признан безвестно отсутствующим, недееспособным или осуждён на срок свыше 3 лет.',
      },
      {
        question:
          'Можно ли пожениться раньше, чем через месяц после подачи заявления?',
        answer:
          'Да, если на то есть уважительные причины или особые обстоятельства: беременность, рождение ребёнка, непосредственная угроза жизни одной из сторон и другие.',
      },
    ],

    [
      {
        question:
          'Is it possible to register a marriage with a foreigner at the consulate?',
        answer:
          'No, the consulate does not register marriages of Russian citizens with citizens of other states. Such marriages should be concluded in civil registry offices on the territory of Russia, or in similar departments abroad.',
      },
      {
        question:
          'Can one of the spouses submit an application to the consulate?',
        answer:
          'Yes, in this case it is possible to issue 2 separate applications, and the signature of the absent person must be notarized. Both spouses must appear for the marriage registration procedure itself.',
      },
      {
        question:
          'Can I affix an apostille to a registry office document at the consulate?',
        answer:
          'No, the consulate cannot certify with an apostille documents issued in the Russian Federation. However, through the consulate you can request a repeat civil registry office document from Russia with an apostille already affixed to it.',
      },
      {
        question: 'Is it possible to get a divorce at the consulate?',
        answer:
          'Yes, if both spouses agree to divorce, and they do not have common minor children or financial claims against each other, or there is a Russian court decision on divorce. Also, one of the spouses can file for divorce if the second spouse is recognized by the court as missing, incompetent, or sentenced to a term of more than 3 years.',
      },
      {
        question:
          'Is it possible to get married earlier than a month after submitting the application?',
        answer:
          'Yes, if there are good reasons or special circumstances: pregnancy, birth of a child, immediate threat to the life of one of the parties, and others.',
      },
    ],
  ],
  interactuation: [
    [
      {
        type: 'title',
        text: [
          'Регистрация рождения ребёнка',
          "Registration of a child's birth.",
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Зарегистрировать рождение ребёнка в консульстве и оформить ему свидетельство о рождении возможно, только если оба родителя либо единственный родитель) являются гражданами России.',
          'It is possible to register the birth of a child at the consulate and issue a birth certificate only if both parents (or the sole parent) are citizens of Russia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Регистрация рождения возможна одновременно с подачей заявлений на оформление гражданства и паспорта ребёнку (такая возможность предусмотрена не во всех консульствах и зависит от страны обращения).',
          'Birth registration can be done simultaneously with the application for citizenship and passport for the child (this possibility is not available in all consulates and depends on the country of application).',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Для подачи заявления на оформление свидетельства о рождении необходимо личное присутствие одного из родителей, присутствие самого ребёнка и второго родителя не требуются.',
          'To submit an application for a birth certificate, the personal presence of one of the parents is required, the presence of the child and the second parent is not needed.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Заявление должно быть подано не позднее чем через месяц со дня рождения ребёнка.',
          "The application must be submitted no later than one month after the child's birth.",
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Свидетельство о рождении ребёнка российского образца выдаётся в день предоставления полного пакета документов в консульство.',
          'The Russian birth certificate is issued on the day the full set of documents is submitted to the consulate.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: [
          'Регистрация усыновления (удочерения)',
          'Registration of adoption.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Усыновители обязаны обратиться в консульство не позднее одного месяца со дня вступления в силу решения суда об усыновлении.',
          'Adopters are required to contact the consulate no later than one month from the date the court decision on adoption comes into force.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'При составлении акта об усыновлении в консульстве определяется, какими станут фамилия и отчество усыновляемого, а также изменится ли имя, дата либо место рождения усыновляемого ребёнка, необходимо ли записать усыновителей в качестве родителей.',
          'When drawing up the adoption act at the consulate, it is determined what the surname and patronymic of the adopted child will be, and whether the name, date, or place of birth of the adopted child will change, and whether it is necessary to record the adopters as parents.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Усыновителям выдаётся свидетельство об усыновлении.',
          'Adopters are issued an adoption certificate.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Тайна усыновления охраняется законом, поэтому без согласия усыновителей, а после их смерти без согласия органа опеки и попечительства запрещается предоставлять любые сведения об усыновлении.',
          'The secrecy of adoption is protected by law, so without the consent of the adopters, and after their death without the consent of the guardianship authority, it is prohibited to provide any information about the adoption.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Установление отцовства', 'Establishment of Paternity'],
      },
      {
        type: 'paragraph',
        text: [
          'Оформление установления отцовства в консульстве возможно для граждан России на следующих основаниях:',
          'The establishment of paternity at the consulate is possible for Russian citizens on the following grounds:',
        ],
      },
      {
        type: 'collapsed',
        first: true,
        title: [
          'Совместное заявление отца и матери',
          'The joint application of the father and mother',
        ],
        content: [
          {
            type: 'paragraph',
            text: [
              'Подаётся в случае, если родители ребёнка не состояли в браке на момент его рождения, при этом сведения об отце вносились со слов матери или в соответствующей графе был прочерк.',
              "Submitted in the case if the child's parents were not married at the time of birth, while the information about the father was entered based on the mother's words or the corresponding field was blank.",
            ],
          },
          {
            type: 'paragraph',
            text: [
              'Заявление может быть подано во время регистрации рождения ребёнка, после него или во время беременности матери, если все заранее понимают, что после рождения ребёнка это будет сделать по каким-либо причинам затруднительно.',
              "The application can be submitted during the registration of the child's birth, after it, or during the mother's pregnancy if everyone understands in advance that it will be difficult to do so for any reason after the child's birth.",
            ],
          },
          {
            type: 'paragraph',
            text: [
              'В случае, если свидетельство о рождении было оформлено более одного года назад, проводится дополнительная проверка с истребованием копии записи акта о рождении ребёнка. В данном случае срок предоставления услуги может составить до 1 года.',
              "If the birth certificate was issued more than a year ago, an additional check is carried out by requesting a copy of the child's birth record. In this case, the service delivery time may take up to 1 year.",
            ],
          },
          {
            type: 'paragraph',
            text: [
              'При наличии иностранного свидетельства о рождении — установление отцовства производится только в органах ЗАГС России или аналогичных ведомствах данного иностранного государства.',
              'If there is a foreign birth certificate, paternity is established only in the Civil Registry Office of Russia or similar agencies of the respective foreign state.',
            ],
          },
        ],
      },
      {
        type: 'collapsed',
        title: ['По заявлению отца', 'The application of the father'],
        content: [
          {
            type: 'paragraph',
            text: [
              'Заявление отца, не состоящего в браке с матерью ребёнка на момент рождения ребёнка, подаётся в случае:',
              'The application of the father, not married to the child at the time of birth of the child, is submitted in the following cases:',
            ],
          },
          {
            type: 'list',
            items: [
              ['смерти матери', 'death of the mother'],
              [
                'признания матери недееспособной',
                "mother's death is not indelible",
              ],
              [
                'отсутствия сведений о её месте пребывания',
                'absence of information about her place of residence',
              ],
              [
                'лишения матери родительских прав',
                "absence of mother's parents rights",
              ],
              [
                'согласия на установление отцовства органа опеки и попечительства',
                'consent to establish paternity of the guardianship and trusteeship authority',
              ],
            ],
          },
        ],
      },
      {
        type: 'collapsed',
        title: [
          'На основании решения суда',
          'On the basis of the court decision',
        ],
        content: [
          {
            type: 'paragraph',
            text: [
              'Может быть сделано устно или в письменной форме следующими лицами:',
              'May be done orally or in writing by the following persons:',
            ],
          },
          {
            type: 'list',
            items: [
              ['матерью или отцом ребёнка', 'mother or father of the child'],
              [
                'опекуном (попечителем) ребёнка',
                'guardian (trustee) of the child',
              ],
              [
                'лицом, на иждивении которого находится ребёнок',
                'person of who a child is dependent on',
              ],
              [
                'самим ребёнком, достигшим совершеннолетия',
                'by the child himself who has reached the age of majority',
              ],
            ],
          },
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Регистрация заключения брака', 'Registration of marriage.'],
      },
      {
        type: 'paragraph',
        text: [
          'Регистрация брака в консульстве / посольстве производится только при личном присутствии лиц, вступающих брак, и только между гражданами РФ.',
          'Marriage registration at the consulate/embassy is carried out only in the presence of the persons getting married and only between citizens of the Russian Federation.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Консульство не регистрирует браки российских граждан с гражданами других государств. Такие браки следует заключать в органах ЗАГС на территории России, либо в аналогичных ведомствах за рубежом.',
          'The consulate does not register marriages between Russian citizens and citizens of other countries. Such marriages should be registered at the registry offices in Russia or in similar institutions abroad.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'На подачу заявления приходят оба брачующихся, либо один из них с нотариально заверенным заявлением от второго.',
          'Both parties getting married must come to submit the application, or one of them with a notarized statement from the other.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Заключение брака происходит по истечении 1 месяца после подачи заявления, однако данный срок может быть уменьшен или увеличен решением консульства при наличии уважительных причин или особых обстоятельств: беременности, рождения ребёнка, непосредственной угрозы жизни одной из сторон и других.',
          'The marriage is concluded 1 month after the application is submitted, but this period can be shortened or extended by the consulate for valid reasons or special circumstances: pregnancy, birth of a child, immediate threat to the life of one of the parties, and others.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'На саму процедуру регистрации заключения брака должны явиться лично оба брачующихся.',
          'Both parties getting married must personally attend the marriage registration procedure.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Регистрация расторжения брака', 'Registration of divorce.'],
      },
      {
        type: 'paragraph',
        text: [
          'Расторжения брака в консульстве возможно только между гражданами РФ в следующих случаях:',
          'Divorce at the consulate is possible only between Russian citizens in the following cases:',
        ],
      },
      {
        type: 'list',
        items: [
          [
            'оба супруга согласны развестись, при этом у них нет общих несовершеннолетних детей или финансовых претензий друг к другу',
            'both spouses agree to divorce, and they have no common minor children or financial claims against each other',
          ],
          [
            'супруги уже имеют решение российского суда о расторжении брака',
            'the spouses already have a Russian court decision on divorce',
          ],
          [
            'у одного из супругов есть решение (приговор) суда, в котором другой супруг признан безвестно отсутствующим, недееспособным или осуждён на срок свыше 3 лет',
            'one of the spouses has a court decision (verdict) in which the other spouse is declared missing, incapacitated, or sentenced to a term of more than 3 years',
          ],
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Расторжение брака происходит по истечении 1 месяца со дня подачи заявления, при наличии решения суда о разводе — в течение 10 дней.',
          'The divorce takes place 1 month after the application is submitted, and in the presence of a court decision on divorce — within 10 days.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Регистрация перемены имени', 'Registration of name change.'],
      },
      {
        type: 'paragraph',
        text: [
          'Любой гражданин России старше 14 лет имеет право поменять свои имя, фамилию и отчество.',
          'Any Russian citizen over the age of 14 has the right to change their first name, last name, and patronymic.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Срок рассмотрения заявления — 1 месяц с момента подачи. По итогу выдаётся свидетельство о перемене имени, которое является основанием для подачи на замену всех основных российских документов: внутреннего и заграничного паспорта, свидетельств о рождении всех несовершеннолетних детей (при наличии) и других.',
          'The application processing time is 1 month from the date of submission. As a result, a name change certificate is issued, which serves as the basis for applying for the replacement of all main Russian documents: internal and foreign passports, birth certificates of all minor children (if any), and others.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Полный процесс замены документов занимает несколько месяцев, при этом оформление некоторых из них, например, внутреннего паспорта, возможно только на территории России.',
          'The complete process of replacing documents takes several months, while some documents, such as the internal passport, can only be processed within Russia.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'При этом не стоит забывать, что помимо российских документов, необходимо будет вносить изменения и в заграничные. Стоит заранее изучить, что для этого потребуется и сможет ли консульство предоставить документы в требуемом формате.',
          'It should be remembered that, in addition to Russian documents, changes will also need to be made to foreign ones. It is worth researching in advance what will be required for this and whether the consulate can provide the documents in the required format.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Процедура и конкретные сроки получения новых документов зависят от страны консульства и индивидуальных обстоятельств гражданина. Мы проконсультируем вас, как лучше поступить в вашем конкретном случае, чтобы пройти этот процесс в минимально возможные сроки.',
          'The procedure and specific time frames for obtaining new documents depend on the country of the consulate and the individual circumstances of the citizen. We will advise you on the best course of action in your specific case to go through this process as quickly as possible.',
        ],
      },
    ],
    [
      {
        type: 'title',
        text: ['Регистрация смерти', 'Registration of death.'],
      },
      {
        type: 'paragraph',
        text: [
          'Любые действия с телами российских граждан (если у умершего имелось только гражданство РФ), включая оформление документов, за рубежом производятся только на основании официального письма из посольства / консульства России. В этой связи в случае смерти гражданина России его родственникам настоятельно рекомендуется обратиться в консульство для получения консультации, координации своих действий, помощи в обращении в компанию ритуальных услуг и оформления документов.',
          'Any actions with the bodies of Russian citizens (if the deceased had only Russian citizenship), including the processing of documents, abroad are carried out only on the basis of an official letter from the Russian embassy/consulate. In this regard, in the event of the death of a Russian citizen, his relatives are strongly advised to contact the consulate for consultation, coordination of their actions, assistance in contacting the funeral services company, and paperwork.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Регистрация смерти производится по письменному или устному заявлению родственника умершего, любого лица, присутствовавшего в момент смерти, представителей медицинской организации, властей страны.',
          'Death registration is carried out based on a written or oral statement from a relative of the deceased, any person present at the time of death, representatives of a medical organization, or the authorities of the country.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Заявление о смерти должно быть сделано не позднее чем через 3 дня со дня наступления смерти либо обнаружения тела умершего.',
          'A death statement must be made no later than 3 days after the death or the discovery of the body of the deceased.',
        ],
      },
      {
        type: 'paragraph',
        text: [
          'Повторная регистрация смерти и выдача соответствующей справки в консульстве не предусмотрена.',
          'Repeated death registration and the issuance of the corresponding certificate at the consulate are not provided.',
        ],
      },
    ],
  ],
  workflow: {
    requestServiceType: {
      title: [
        'Что необходимо зарегистрировать?',
        'What do you need to register?',
      ],
      options: serviceTypes,
      label: ['Выберите из списка', 'Select from the list'],
    },
    requestMarriageStatusAtTheBirthTime: {
      title: [
        'Состояли ли родители ребёнка в браке на момент его рождения?',
        "Were the child's parents married at the time of birth?",
      ],
      options: [
        ['Да', 'Yes'],
        [
          'Нет, но хотим оформить официальное отцовство',
          'No, but we want to establish official paternity',
        ],
        [
          'Нет, у ребёнка единственный родитель',
          'No, the child has a single parent',
        ],
      ],
    },
    requestPaternityBase: {
      title: [
        'Что будет основанием для установления отцовства?',
        'What will be the basis for establishing paternity?',
      ],
      options: [
        [
          'Совместное заявление обоих родителей, не состоящих в браке',
          'Joint statement of both parents not married to each other',
        ],
        [
          'Заявление отца, не состоящего в браке с матерью ребенка',
          "Statement of the father not married to the child's mother",
        ],
        ['Решение суда', 'Court decision'],
      ],
    },
    requestIsTheMarriageFirst: {
      title: [
        'Является ли этот брак первым для обоих будущих супругов?',
        'Is this the first marriage for both future spouses?',
      ],
      options: [
        ['Да', 'Yes'],
        ['Нет', 'No'],
      ],
    },
    requestCase: {
      title: ['Выберите ваш случай', 'Select your case'],
      options: [
        [
          'Мы оба согласны на развод, общих несовершеннолетних детей нет и финансовых претензий друг к другу не имеем',
          'We both agree to divorce, have no minor children, and have no financial claims against each other',
        ],
        [
          'У нас есть решение суда о расторжении брака',
          'We have a court decision on the dissolution of the marriage',
        ],
        [
          'У меня есть решение (приговор) суда о том, что мой супруг признан судом безвестно отсутствующим, недееспособным либо осуждён на срок свыше 3 лет',
          'I have a court decision (sentence) that my spouse is declared missing, incapacitated, or sentenced to more than 3 years',
        ],
      ],
    },
  },
  priceText1: [
    'Стоимость услуги зависит от наличия в пакете документов заявителя документов на иностранном языке и будет определена после консультации с юристом.',
    'The cost of the service depends on the presence of documents in a foreign language in the applicant’s package of documents and will be determined after consultation with a lawyer.',
  ],
}

export default vocabularyZags
