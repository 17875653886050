import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  TextField,
  Divider,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from '@mui/material/'
import { useTheme } from '@mui/material/styles'
import GlobalContext from 'GlobalContext'
//import facebook from 'images/facebook.png'
import google from 'images/google.png'
import crossClose from 'images/icon/cross-close.png'
import vocabulary from 'vocabulary/vocabulary'
import { Envelope, Eye, Lock, SignIn } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import { useLoginFlowOpen, useLoginFlowClose } from 'pages/helpers/useLoginFlow'

export default function Login() {
  const theme = useTheme()

  const { langSelected, GlobalActions } = React.useContext(GlobalContext)
  const navigate = useNavigate()
  const [userPassword, setUserPassword] = React.useState('')
  const [userMail, setUserMail] = React.useState('')
  const [checked, setChecked] = React.useState(true)
  const [hided, setHided] = React.useState(true)
  const [errorRecieved, setErrorRecieved] = React.useState(false)
  const openSignUpForm = useLoginFlowOpen('signup')
  const openForgetPasswordForm = useLoginFlowOpen('forgetPassword')
  const closeLoginForm = useLoginFlowClose()

  React.useEffect(() => {
    const storedUsername = localStorage.getItem('username')
    const storedPassword = localStorage.getItem('password')

    if (storedUsername && storedPassword) {
      setUserMail(storedUsername)
      setUserPassword(storedPassword)
      setChecked(true)
    }
  }, [])

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      GlobalActions.logIn({
        token: tokenResponse.access_token,
        variant: 'google',
        lang: langSelected,
      })
      closeLoginForm()
    },
  })

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <form
        style={{
          marginLeft: '16px',
          marginRight: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '76px',
          }}
        >
          <Box sx={{ cursor: 'pointer' }} onClick={closeLoginForm}>
            <img src={crossClose} alt='cross' width={'24px'} />
          </Box>
        </Box>

        <Typography component='p' variant='h2Large' sx={{ mx: 'auto' }}>
          {vocabulary.enter[langSelected]}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            justifyContent: 'center',
            my: 4,
          }}
        >
          {/* <Button
            variant='outlined'
            size='medium'
            sx={{ height: '40px', minWidth: 156, mr: 2 }}
            color='blueButton'
            disabled={false}
            onClick={() => {}}
          >
            <img src={facebook} alt='facebook-logo' width={'24px'} />
            <Typography
              variant='labelLarge'
              sx={{ ml: 1, textTransform: 'none' }}
            >
              Facebook
            </Typography>
          </Button>

         */}{' '}
          <Button
            variant='outlined'
            size='medium'
            sx={{ height: '40px', minWidth: 328 }}
            color='blueButton'
            disabled={false}
            onClick={login}
          >
            <img src={google} alt='google-logo' width={'24px'} />
            <Typography
              variant='labelLarge'
              sx={{ textTransform: 'none', ml: 1 }}
            >
              Google
            </Typography>
          </Button>
        </Box>
        <Divider>
          <Typography component='p' variant='labelLarge' sx={{ mx: 'auto' }}>
            {vocabulary.orWith[langSelected]}
          </Typography>
        </Divider>

        <TextField
          error={errorRecieved}
          value={userMail}
          placeholder={vocabulary.email[langSelected]}
          InputLabelProps={{ shrink: true }}
          autoComplete='email'
          type='email'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Envelope size={24} weight='light' />
              </InputAdornment>
            ),
          }}
          onChange={event => {
            setUserMail(event.target.value)
          }}
          sx={{
            minWidth: 1,
            mt: 4,
            mb: 3,

            borderRadius: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
              color: theme.palette.primary.onSurfaceVariant,
              opacity: 1,
            },
          }}
          variant='outlined'
          margin='dense'
        />

        <TextField
          error={errorRecieved}
          value={userPassword}
          helperText={
            errorRecieved ? vocabulary.errorCredentiales[langSelected] : null
          }
          type={hided ? 'password' : 'text'}
          autoComplete='current-password'
          placeholder={vocabulary.enterPassword[langSelected]}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Lock size={24} weight='light' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='start'>
                <Eye
                  size={24}
                  weight='light'
                  onClick={() => {
                    setHided(!hided)
                  }}
                />
              </InputAdornment>
            ),
          }}
          onChange={event => {
            setUserPassword(event.target.value)
          }}
          sx={{
            minWidth: 1,

            borderRadius: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
              color: theme.palette.primary.onSurfaceVariant,
              opacity: 1,
            },
          }}
          variant='outlined'
          margin='dense'
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            my: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: theme.palette.primary.main,
                  '&.Mui-checked': {
                    color: 'white',
                    backgroundColor: theme.palette.primary.main,
                  },

                  '&.MuiCheckbox-root': {
                    borderRadius: '3px',
                    padding: 0,
                    margin: 2,
                  },
                  '& svg': {
                    scale: '1.1',
                  },
                }}
                checked={checked}
                onChange={event => {
                  setChecked(event.target.checked)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography component='p' variant='bodySmall'>
                {vocabulary.remember[langSelected]}
              </Typography>
            }
          />

          <Typography
            component='p'
            variant='labelLarge'
            color='primary'
            onClick={openForgetPasswordForm}
            sx={{}}
          >
            {vocabulary.forgetPassword[langSelected]}
          </Typography>
        </Box>

        <Button
          variant='contained'
          size='medium'
          sx={{ width: '328px', height: '40px', m: 'auto', my: 1 }}
          color='blueButton'
          onClick={async () => {
            let response = await GlobalActions.logIn({
              username: userMail,
              password: userPassword,
            })

            if (response === 'bad credentials') {
              setErrorRecieved(true)
            } else {
              if (checked) {
                localStorage.setItem('username', userMail)
                localStorage.setItem('password', userPassword)
              } else {
                localStorage.removeItem('username')
                localStorage.removeItem('password')
              }

              closeLoginForm()
            }
          }}
        >
          <SignIn size={24} />
          <Typography
            variant='labelLarge'
            sx={{ textTransform: 'none', ml: 1 }}
          >
            {vocabulary.enter[langSelected]}
          </Typography>
        </Button>

        <Typography
          variant='bodySmall'
          sx={{ textTransform: 'none', m: 'auto', my: 1 }}
        >
          {vocabulary.dontRegister[langSelected]}
        </Typography>

        <Typography
          variant='labelLarge'
          color='primary'
          sx={{ m: 'auto', textTransform: 'none' }}
          onClick={openSignUpForm}
        >
          {vocabulary.signUp[langSelected]}
        </Typography>
      </form>
    </Box>
  )
}
