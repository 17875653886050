import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, useTheme, Box, Typography, Card } from '@mui/material/'
import vocabulary from '../../vocabulary/vocabulary'
import Layout1239 from '../Layouts/Layout1239'
import GlobalContext from '../../GlobalContext'
import squareImg from '../../images/brown-square.png'
import { v4 as uuidv4 } from 'uuid'
import styles from './styles1239'
import AccordionCustom from '../components/AccordionCustom'
import vocabularyPassport from '../../vocabulary/vocabularyPassport'
import vocabularyCitizenship from '../../vocabulary/vocabularyCitizenship'
import vocabularyOther from '../../vocabulary/vocabularyOther'
import { Check, FileText, Info, TagSimple } from '@phosphor-icons/react'

import vocabularyPowerOfAttorney from 'vocabulary/vocabularyPowerOfAttorney'
import Faq12391439 from 'pages/components/Faq/Faq12391439'
import { Workflow } from 'pages/components/DialogServices/Other/Workflow'
import { useOpenDialogService } from 'pages/helpers/useOpenDialogService'
import { PhotoRequirements } from 'pages/components/DialogServices/PhotoRequirements'
import { useScrollToTop } from 'hooks/useScrollToTop'

export default function Other1239() {
  const { langSelected } = useContext(GlobalContext)
  const [stateMenu, setMenu] = useState(false)
  const [columnHeight, setColumnHeight] = useState(0)
  const elementRef = useRef(null)

  function setStateMenu(val) {
    setMenu(val)
  }

  useScrollToTop()
  const theme = useTheme()

  const CustomTab = ({ children, value, index }) => {
    return <Box hidden={value !== index}>{children}</Box>
  }

  const [activeTab, setActiveTab] = useState(0)
  useEffect(() => {
    const height = elementRef?.current?.offsetHeight

    setColumnHeight(height - 52)
    console.log('Height:', height)
  }, [activeTab])

  const openDialogService = useOpenDialogService({
    section: 'other',
  })

  return (
    <Layout1239
      stateMenu={stateMenu}
      setStateMenu={setStateMenu}
      langSelected={langSelected}
      theme={theme}
      menu='true'
    >
      <Box
        sx={{
          mt: 2,
          mx: '16px',
          pt: '32px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            width: 708,
            display: 'flex',
            alignSelf: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Typography variant='h1Small'>
            {vocabularyOther.otherServices[langSelected]}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            <Box
              onClick={() => {
                setActiveTab(0)
              }}
              sx={{
                textTransform: 'none',
                border: '1px solid',
                borderTopLeftRadius: '20px',
                borderBottomLeftRadius: '20px',
                width: 236,
                height: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  activeTab === 0
                    ? theme.palette.primary.secondaryContainer
                    : null,
              }}
            >
              <Typography
                variant='labelLarge'
                component='div'
                sx={{
                  color: theme.palette.primary.onSurface,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {activeTab === 0 ? (
                  <Check
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                ) : (
                  <Info
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                )}

                {vocabularyPassport.info[langSelected]}
              </Typography>
            </Box>
            <Box
              onClick={() => {
                setActiveTab(1)
              }}
              sx={{
                textTransform: 'none',
                borderRight: '1px solid',
                borderTop: '1px solid',
                borderBottom: '1px solid',
                width: 236,
                height: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  activeTab === 1
                    ? theme.palette.primary.secondaryContainer
                    : null,
              }}
            >
              <Typography
                component='div'
                variant='labelLarge'
                sx={{
                  color: theme.palette.primary.onSurface,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {activeTab === 1 ? (
                  <Check
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                ) : (
                  <FileText
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                )}
                {vocabularyPassport.documents[langSelected]}
              </Typography>
            </Box>
            <Box
              onClick={() => {
                setActiveTab(2)
              }}
              sx={{
                textTransform: 'none',
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderRight: '1px solid',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                width: 236,
                height: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  activeTab === 2
                    ? theme.palette.primary.secondaryContainer
                    : null,
              }}
            >
              <Typography
                component='div'
                variant='labelLarge'
                sx={{
                  color: theme.palette.primary.onSurface,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {activeTab === 2 ? (
                  <Check
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                ) : (
                  <TagSimple
                    size={18}
                    weight='light'
                    style={{ marginRight: '8px' }}
                  />
                )}

                {vocabularyPassport.prices[langSelected]}
              </Typography>
            </Box>
          </Box>

          {/*first tab*/}

          <CustomTab value={activeTab} index={0}>
            <Typography variant='h2Small' component='div' sx={{ mt: 6.5 }}>
              {vocabularyPassport.commonInformation[langSelected]}
            </Typography>
            <Typography
              variant='titleLarge'
              component='div'
              sx={{ mt: 6.5, mb: 3 }}
            >
              {vocabularyOther.legalization[langSelected]}
            </Typography>

            <Typography
              variant='bodySmall'
              component='div'
              sx={{ mt: '32px', mb: 2 }}
            >
              {vocabularyOther.infoText1[langSelected]}
            </Typography>
            <Typography variant='bodySmall' sx={{ mb: 2 }} component='div'>
              {vocabularyOther.infoText2[langSelected]}
            </Typography>
            <Typography variant='bodySmall' sx={{ mb: 2 }} component='div'>
              {vocabularyOther.infoText3[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div'>
              {vocabularyOther.infoText4[langSelected]}
            </Typography>

            <Typography
              variant='titleLarge'
              component='div'
              sx={{ mt: 6.5, mb: 3 }}
              id='return-certificate'
            >
              {vocabularyOther.certificateReturn[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
              {vocabularyOther.infoText5[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div'>
              {vocabularyOther.whoCanApply[langSelected]}
            </Typography>

            {vocabularyOther.certificateReturnList.map(val => {
              return (
                <Box
                  key={uuidv4()}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    m: '16px',
                  }}
                >
                  <img
                    src={squareImg}
                    alt='logo'
                    width={20}
                    style={{ marginRight: '16px' }}
                  />
                  <Typography variant='bodySmall' component='div'>
                    {val[langSelected]}
                  </Typography>
                </Box>
              )
            })}

            <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
              {vocabularyOther.infoText6[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
              {vocabularyOther.infoText7[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
              {vocabularyOther.infoText8[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
              {vocabularyOther.infoText9[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
              {vocabularyOther.infoText10[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
              {vocabularyOther.infoText11[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
              {vocabularyOther.infoText12[langSelected]}
            </Typography>

            <Box
              sx={{
                mt: 4,
                mb: 6.5,
                display: 'flex',
                width: 1,
              }}
            >
              <Button
                variant='contained'
                size='medium'
                sx={{ width: 280, height: 40 }}
                color='blueButton'
                onClick={openDialogService}
              >
                <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                  {vocabulary.applicationSubmit[langSelected]}
                </Typography>
              </Button>
            </Box>

            <Box
              sx={{ display: 'flex', flexDirection: 'row-reverse', width: 1 }}
            >
              <Card
                onClick={() => {
                  setActiveTab(1)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                sx={{
                  mt: '32px',
                  mb: '50px',
                  px: 2,
                  pt: '8px',
                  height: 70,
                  borderRadius: '12px',
                  width: 1 / 2,
                  backgroundColor: theme.palette.primary.surfaceContainer,
                }}
                variant='outlined'
              >
                <Typography
                  variant='labelSmall'
                  component='div'
                  sx={{ color: theme.palette.primary.onSurfaceVariant }}
                >
                  {vocabularyPassport.next[langSelected]} &rarr;
                </Typography>
                <Typography variant='titleSmall'>
                  {vocabularyPassport.necessaryDoc[langSelected]}
                </Typography>
              </Card>
            </Box>
          </CustomTab>

          {/*second tab*/}

          <CustomTab value={activeTab} index={1}>
            <Typography
              variant='h2Small'
              component='div'
              sx={{ mt: 6.5, mb: 4 }}
            >
              {vocabularyPassport.requiredDocuments[langSelected]}
            </Typography>

            <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
              {vocabularyCitizenship.infoText8[langSelected]}
            </Typography>

            {vocabularyOther.otherTypes.map((val, index) => {
              return (
                <AccordionCustom
                  key={uuidv4()}
                  index={1}
                  styles={styles}
                  val={val}
                  langSelected={langSelected}
                  borderUp={true}
                />
              )
            })}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                mt: 6.5,
              }}
            >
              <Card
                onClick={() => {
                  setActiveTab(0)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                sx={{
                  mb: 2,

                  pt: '8px',

                  height: 70,
                  borderRadius: '12px',
                  backgroundColor: theme.palette.primary.surfaceContainer,
                  width: 1 / 2,
                }}
                variant='outlined'
              >
                <Typography
                  variant='labelSmall'
                  component='div'
                  sx={{ ml: 2, color: theme.palette.primary.onSurfaceVariant }}
                >
                  &larr; {vocabularyPassport.back[langSelected]}
                </Typography>
                <Typography component='div' sx={{ ml: 2 }} variant='titleSmall'>
                  {vocabularyPassport.commonInformation[langSelected]}
                </Typography>
              </Card>
              <Box sx={{ ml: 2 }} />
              <Card
                onClick={() => {
                  setActiveTab(2)
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                sx={{
                  mb: '50px',
                  pt: '8px',
                  height: 70,
                  minWidth: 1 / 2,
                  borderRadius: '12px',
                  backgroundColor: theme.palette.primary.surfaceContainer,
                }}
                variant='outlined'
              >
                <Typography
                  variant='labelSmall'
                  component='div'
                  sx={{ ml: 2, color: theme.palette.primary.onSurfaceVariant }}
                >
                  {vocabularyPassport.next[langSelected]} &rarr;
                </Typography>
                <Typography component='div' sx={{ ml: 2 }} variant='titleSmall'>
                  {vocabularyPassport.prices[langSelected]}
                </Typography>
              </Card>
            </Box>
          </CustomTab>

          {/* third tab */}
          <CustomTab value={activeTab} index={2}>
            <Typography
              variant='h2Small'
              component='div'
              sx={{ mt: 6.5, mb: 4 }}
            >
              {vocabularyPassport.priceAndTariffs[langSelected]}
            </Typography>

            <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
              {vocabularyOther.priceText[langSelected]}
            </Typography>

            <Typography variant='bodySmall' component='div' sx={{ mb: '32px' }}>
              {vocabularyPassport.priceText2[langSelected]}
            </Typography>

            <Box
              sx={{
                mt: 4,
                mb: 6.5,
                display: 'flex',
                width: 1,
              }}
            >
              <Button
                variant='contained'
                size='medium'
                sx={{ width: 280, height: 40 }}
                color='blueButton'
                onClick={openDialogService}
              >
                <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                  {vocabulary.applicationSubmit[langSelected]}
                </Typography>
              </Button>
            </Box>

            <Card
              onClick={() => {
                setActiveTab(1)
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
              sx={{
                mb: 2,
                px: '16px',
                pt: '8px',
                height: 70,
                borderRadius: '12px',
                width: 1 / 2,
                backgroundColor: theme.palette.primary.surfaceContainer,
              }}
              variant='outlined'
            >
              <Typography
                variant='labelSmall'
                component='div'
                sx={{ color: theme.palette.primary.onSurfaceVariant }}
              >
                &larr; {vocabularyPassport.back[langSelected]}
              </Typography>
              <Typography variant='titleSmall'>
                {vocabularyPassport.necessaryDoc[langSelected]}
              </Typography>
            </Card>
          </CustomTab>
        </Box>

        <Box
          sx={{
            width: 304,
            ml: '16px',
            backgroundColor: theme.palette.primary.surfaceContainerLow,
            px: '24px',
            overflowY: 'auto',
            maxHeight: columnHeight,
          }}
        >
          {console.log('rerender', columnHeight)}
          <Typography
            variant='titleLarge'
            component='div'
            sx={{ py: 2, textTransform: 'none' }}
          >
            {vocabularyPowerOfAttorney.faq[langSelected]}
          </Typography>

          <Faq12391439 value={vocabularyOther.faqDesc[langSelected]} />
        </Box>
      </Box>
      <Workflow />
      <PhotoRequirements />
    </Layout1239>
  )
}
