import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material/'
import { useTheme } from '@mui/material/styles'
import GlobalContext from 'GlobalContext'
//import facebook from 'images/facebook.png'

import crossClose from 'images/icon/cross-close.png'

import vocabulary from 'vocabulary/vocabulary'
import { Eye, Lock } from '@phosphor-icons/react'

import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'
import vocabularyPersonalArea from 'vocabulary/vocabularyPersonalArea'
import { changeUserPasswordCall } from 'api/workflow'

export default function ChangeUserPassword({ open, setOpen }) {
  const theme = useTheme()

  const { langSelected } = React.useContext(GlobalContext)
  const [hided, setHided] = React.useState(true)
  const [errorRecieved, setErrorRecieved] = React.useState(false)
  const [userPassword, setUserPassword] = React.useState('')
  const [userPasswordConfirmed, setUserPasswordConfirmed] = React.useState('')

  return (
    <DialogModalWindow
      open={open}
      disableEnforceFocus
      height='650px'
      width='400px'
      minHeight='auto'
      sx={{ zIndex: 2500 }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <form
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '76px',
            }}
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setOpen(false)
              }}
            >
              <img src={crossClose} alt='cross' width={'24px'} />
            </Box>
          </Box>

          <Typography
            component='p'
            variant='h2Large'
            sx={{ mx: 'auto', mb: 8 }}
          >
            {vocabularyPersonalArea.changePassword[langSelected]}
          </Typography>

          <TextField
            value={userPassword}
            type={hided ? 'password' : 'text'}
            autoComplete='current-password'
            placeholder={vocabulary.enterPassword[langSelected]}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Lock size={24} weight='light' />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='start'>
                  <Eye
                    size={24}
                    weight='light'
                    onClick={() => {
                      setHided(!hided)
                    }}
                  />
                </InputAdornment>
              ),
            }}
            onChange={event => {
              setUserPassword(event.target.value)
            }}
            sx={{
              minWidth: 1,

              borderRadius: 0,
              '& .MuiOutlinedInput-root': {
                borderRadius: '5px',
                color: theme.palette.primary.onSurface,
              },
              '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                color: theme.palette.primary.onSurfaceVariant,
                opacity: 1,
              },
            }}
            variant='outlined'
            margin='dense'
          />

          <TextField
            value={userPasswordConfirmed}
            type={hided ? 'password' : 'text'}
            error={errorRecieved}
            id='outlined-error-helper-text'
            defaultValue='Hello World'
            helperText={
              errorRecieved ? vocabulary.passwordMismatch[langSelected] : null
            }
            autoComplete='current-password'
            placeholder={vocabularyPersonalArea.confirmPassword[langSelected]}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Lock size={24} weight='light' />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='start'>
                  <Eye
                    size={24}
                    weight='light'
                    onClick={() => {
                      setHided(!hided)
                    }}
                  />
                </InputAdornment>
              ),
            }}
            onChange={event => {
              setUserPasswordConfirmed(event.target.value)
              setErrorRecieved(false)
            }}
            sx={{
              minWidth: 1,
              my: 4,
              borderRadius: 0,
              '& .MuiOutlinedInput-root': {
                borderRadius: '5px',
                color: theme.palette.primary.onSurface,
              },
              '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                color: theme.palette.primary.onSurfaceVariant,
                opacity: 1,
              },
            }}
            variant='outlined'
            margin='dense'
          />

          <Typography
            variant='labelSmallLight'
            sx={{ textTransform: 'none', ml: 2 }}
          >
            {vocabulary.passwordRequirements[langSelected]}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              my: 2,
              alignItems: 'center',
              flexGrow: 1,
            }}
          ></Box>

          <Button
            variant='outlined'
            size='medium'
            sx={{
              width: '328px',
              borderColor: 'black',
              height: '40px',
              mx: 'auto',
              mt: 2,
            }}
            color='blueButton'
            onClick={() => {
              if (userPassword === userPasswordConfirmed) {
                changeUserPasswordCall({ password: userPassword })
                setOpen(false)
              } else {
                setErrorRecieved(true)
              }
            }}
          >
            <Typography
              variant='labelLarge'
              sx={{ textTransform: 'none', ml: 1 }}
            >
              {vocabularyPersonalArea.changePassword[langSelected]}
            </Typography>
          </Button>

          <Button
            variant='text'
            size='medium'
            sx={{ width: 328, height: 40, my: 4, mx: 'auto' }}
            color='blueButton'
            onClick={() => {
              setOpen(false)
            }}
          >
            <Typography
              variant='labelLarge'
              sx={{ textTransform: 'none', ml: 1 }}
            >
              {vocabularyPersonalArea.exit[langSelected]}
            </Typography>
          </Button>
        </form>
      </Box>
    </DialogModalWindow>
  )
}
