import vocabulary, { serviceTypes } from 'vocabulary/vocabularyZags'
import { getTranslation } from 'pages/helpers/workflowStepSchema'
import { requestServiceType } from './requestServiceType'
import { createRequestDetailsStep } from './createRequestDetailsStep'

const FINAL_STEP = 6
const {
  requestMarriageStatusAtTheBirthTime,
  requestPaternityBase,
  requestIsTheMarriageFirst,
  requestCase,
} = vocabulary.workflow

export const getStepByChosenService = ({ service }) => {
  const [Childbirth, Adoption, EstablishmentOfPaternity, Marriage, Divorce] =
    serviceTypes.map(getTranslation('eng'))

  const nextStep =
    {
      [Childbirth]: 2,
      [EstablishmentOfPaternity]: 3,
      [Marriage]: 4,
      [Divorce]: 5,
    }[service] || FINAL_STEP

  return nextStep
}

export const getSteps = langSelected => [
  {
    step: 1,
    progress: 20,
    ...requestServiceType(langSelected),
    nextStep: getStepByChosenService,
  },
  {
    step: 2,
    progress: 50,
    ...createRequestDetailsStep(
      requestMarriageStatusAtTheBirthTime,
      'marriageStatus',
      langSelected,
    ),
    nextStep: FINAL_STEP,
  },
  {
    step: 3,
    progress: 50,
    ...createRequestDetailsStep(
      requestPaternityBase,
      'paternityBase',
      langSelected,
    ),
    nextStep: FINAL_STEP,
  },
  {
    step: 4,
    progress: 50,
    ...createRequestDetailsStep(
      requestIsTheMarriageFirst,
      'isThisMarriageFirst',
      langSelected,
    ),
    nextStep: FINAL_STEP,
  },
  {
    step: 5,
    progress: 50,
    ...createRequestDetailsStep(requestCase, 'divorceBase', langSelected),
    nextStep: FINAL_STEP,
  },
]
