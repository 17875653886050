import React, { useCallback, useContext } from 'react'
import { putUserRequest } from 'api/putUserRequest'
import GlobalContext from 'GlobalContext'

export const usePutUserRequest = ({ onSuccess, onFailure }) => {
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const { state, langSelected } = useContext(GlobalContext)

  const sendUserRequest = useCallback(async () => {
    setIsLoading(true)
    setError(null)

    const response = await putUserRequest(state.dataToSend, langSelected)

    if (response?.status === 200) {
      onSuccess?.(response?.data)
    } else {
      onFailure?.(response?.data)
      setError(response?.data?.message)
    }

    setIsLoading(false)
  }, [state.dataToSend, onSuccess, onFailure])

  return { isLoading, error, sendUserRequest }
}
