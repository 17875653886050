import { Typography } from '@mui/material'

export const Title = ({ value, index }) => (
  <Typography
    component='p'
    variant='titleLarge'
    sx={{
      mb: 2,
      mt: index === 0 ? 0 : 4,
    }}
  >
    {value}
  </Typography>
)
