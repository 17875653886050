import { useContext } from 'react'
import GlobalContext from '../../GlobalContext'
import vocabularyUserAgreement from 'vocabulary/vocabularyUserAgreement'
import { Container, Paper, Typography, Box } from '@mui/material'

const UserAgreement = () => {
  const { langSelected } = useContext(GlobalContext)

  return (
    <Container maxWidth='xl'>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        {vocabularyUserAgreement[langSelected].map(
          (vocabularyUserAgreement, index) => (
            <Box key={index} mb={4}>
              <Typography variant='h5' gutterBottom>
                {vocabularyUserAgreement.title}
              </Typography>
              {vocabularyUserAgreement.content.map((paragraph, idx) => (
                <Box key={idx} mb={2}>
                  {Array.isArray(paragraph) ? (
                    <Box sx={{ pl: 2 }}>
                      {paragraph.map((subItem, subIdx) => (
                        <Typography key={subIdx} variant='body1' gutterBottom>
                          {subItem}
                        </Typography>
                      ))}
                    </Box>
                  ) : (
                    <Typography variant='h6' gutterBottom>
                      {paragraph}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          ),
        )}
      </Paper>
    </Container>
  )
}

export default UserAgreement
