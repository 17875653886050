import { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import vocabulary from '../../vocabulary/vocabulary'
import Layout1439 from '../Layouts/Layout1439'
import GlobalContext from '../../GlobalContext'
import vocabularyAboutUs from '../../vocabulary/vocabularyAboutUs'
import { v4 as uuidv4 } from 'uuid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Faq12391439 from '../components/Faq/Faq12391439'
import CardServiceProcess1439 from '../components/ServiceProcess/CardServiceProcess1439'
import { useNavigate } from 'react-router-dom'

export default function AboutUs1439() {
  const { langSelected, GlobalActions } = useContext(GlobalContext)
  const [serviceProcessData, setServiceProcessData] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    setServiceProcessData(vocabularyAboutUs.serviceProcess[langSelected])
  }, [langSelected])

  const theme = useTheme()

  return (
    <Layout1439 menu='true'>
      <Box
        sx={{
          mx: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          maxWidth: 1128,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: 744 }}>
          <Box sx={{ width: 744 }}>
            <Typography
              component='p'
              variant='h1Small'
              sx={{ mt: '32px', mb: '48px' }}
            >
              {vocabularyAboutUs.aboutUsSection[langSelected]}
            </Typography>

            <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
              {vocabularyAboutUs.aboutUsSection1[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
              {vocabularyAboutUs.aboutUsSection2[langSelected]}
            </Typography>
            <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
              {vocabularyAboutUs.aboutUsSection3[langSelected]}
            </Typography>
            <Typography variant='bodySmall'>
              {vocabularyAboutUs.aboutUsSection4[langSelected]}
            </Typography>
          </Box>
          <Typography
            variant='h2Small'
            component='p'
            sx={{ mt: 6.5, mb: '48px' }}
          >
            {vocabularyAboutUs.whyChooseUs[langSelected]}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {serviceProcessData.map((service, index) => {
              let last = false

              if (serviceProcessData.length === index + 1) {
                last = true
              }

              return (
                <CardServiceProcess1439
                  langSelected={langSelected}
                  key={uuidv4()}
                  headerText={service.headerText}
                  cardText={service.cardText}
                  cardImage={service.cardImage}
                  about={service.about}
                  details={service.details}
                  last={last}
                  navigate={navigate}
                />
              )
            })}
          </Box>
          <Typography variant='bodySmall' component='p' sx={{ mt: '64px' }}>
            {vocabularyAboutUs.ourMission[langSelected]}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              variant='contained'
              size='medium'
              sx={{ width: '260px', height: '40px', mt: 6.5 }}
              color='blueButton'
              onClick={() => {
                GlobalActions.userFlowMainOpen(true)
              }}
            >
              <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                {vocabulary.applicationSubmit[langSelected]}
              </Typography>
            </Button>
            <Button
              variant='outlined'
              size='medium'
              sx={{
                width: '260px',
                borderColor: 'black',
                height: '40px',
                mt: 2,
                mb: 6.5,
              }}
              color='blueButton'
              onClick={() => {
                navigate(`${window.location.pathname}?open=chat`)
              }}
            >
              <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                {vocabularyAboutUs.askQuestion[langSelected]}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: 360,
            ml: '16px',
            backgroundColor: theme.palette.primary.surfaceContainerLow,
            px: '24px',
            height: 'fit-content',
          }}
        >
          <Typography variant='titleLarge' sx={{ my: '16px' }} component='p'>
            {vocabularyAboutUs.faq[langSelected]}
          </Typography>

          <Faq12391439 value={vocabularyAboutUs.faqDesc[langSelected]} />
        </Box>
      </Box>
    </Layout1439>
  )
}
