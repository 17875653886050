import { useCallback, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useOnInputValueChange = (defaultValue = '') => {
  const [value, setValue] = useState(defaultValue)
  const [, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (defaultValue) {
      setValue('query', defaultValue)
    }
  }, [defaultValue])

  const onChange = useCallback((event, newValue) => {
    setValue(newValue)

    newValue && setSearchParams({ open: 'search', query: newValue })
  }, [])

  return [value, onChange]
}
