import React, { useContext, useState, useEffect } from 'react'
import { Button, useTheme, Box, Typography, Card } from '@mui/material/'
import vocabulary from '../../vocabulary/vocabulary'
import Layout819 from '../Layouts/Layout819'
import GlobalContext from '../../GlobalContext'
import squareImg from '../../images/brown-square.png'
import { v4 as uuidv4 } from 'uuid'
import styles from './styles819'
import AccordionCustom from '../components/AccordionCustom'
import CardServiceProcess819 from '../components/ServiceProcess/CardServiceProcess819'
import PriceCard from '../components/PriceCard/PriceCard'
import vocabularyPassport from '../../vocabulary/vocabularyPassport'
import vocabularyConsent from '../../vocabulary/vocabularyConsent'
import arrowRightImg from '../../images/arrow-black-right.png'
import vocabularyCertificates from '../../vocabulary/vocabularyCertificates'
import { useNavigate } from 'react-router-dom'
import vocabularyZags from '../../vocabulary/vocabularyZags'
import { useOpenServiceTypeDialog, useOpenDialogService } from 'pages/helpers'
import {
  Check,
  FileText,
  Info,
  Question,
  TagSimple,
} from '@phosphor-icons/react'
import { RegistryOfficeDialog } from 'pages/components/DialogServices/RegistryOffice/Workflow'
import { Link } from 'components/Link/Link'

export default function Zags819() {
  const { langSelected } = useContext(GlobalContext)
  const openDialogService = useOpenDialogService({
    section: 'zags',
  })
  const openServiceTypeDialog = useOpenServiceTypeDialog({
    section: 'zags',
    step: -1,
  })
  const [stateMenu, setMenu] = useState(false)
  const navigate = useNavigate()

  function setStateMenu(val) {
    setMenu(val)
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])
  const theme = useTheme()

  const CustomTab = ({ children, value, index }) => {
    return <Box hidden={value !== index}>{children}</Box>
  }

  const [activeTab, setActiveTab] = useState(0)

  return (
    <Layout819
      stateMenu={stateMenu}
      setStateMenu={setStateMenu}
      langSelected={langSelected}
      theme={theme}
      menu='true'
    >
      <Box sx={{ mt: 6.5, mx: 2, pt: 4, overflow: 'hidden' }}>
        <Typography variant='h1Small'>
          {vocabularyZags.zagsServices[langSelected]}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',

            mt: 2,
          }}
        >
          <Box
            onClick={() => {
              setActiveTab(0)
            }}
            sx={{
              textTransform: 'none',
              border: '1px solid',
              borderTopLeftRadius: '20px',
              borderBottomLeftRadius: '20px',
              width: 189,
              height: 48,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                activeTab === 0
                  ? theme.palette.primary.secondaryContainer
                  : null,
            }}
          >
            <Typography
              variant='labelLarge'
              component='div'
              sx={{
                color: theme.palette.primary.onSurface,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {activeTab === 0 ? (
                <Check
                  size={18}
                  weight='light'
                  style={{ marginRight: '8px' }}
                />
              ) : (
                <Info size={18} weight='light' style={{ marginRight: '8px' }} />
              )}

              {vocabularyPassport.info[langSelected]}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setActiveTab(1)
            }}
            sx={{
              textTransform: 'none',
              borderRight: '1px solid',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              width: 189,
              height: 48,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                activeTab === 1
                  ? theme.palette.primary.secondaryContainer
                  : null,
            }}
          >
            <Typography
              component='div'
              variant='labelLarge'
              sx={{
                color: theme.palette.primary.onSurface,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {activeTab === 1 ? (
                <Check
                  size={18}
                  weight='light'
                  style={{ marginRight: '8px' }}
                />
              ) : (
                <FileText
                  size={18}
                  weight='light'
                  style={{ marginRight: '8px' }}
                />
              )}
              {vocabularyPassport.documents[langSelected]}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setActiveTab(2)
            }}
            sx={{
              textTransform: 'none',
              borderRight: '1px solid',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              width: 189,
              height: 48,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                activeTab === 2
                  ? theme.palette.primary.secondaryContainer
                  : null,
            }}
          >
            <Typography
              component='div'
              variant='labelLarge'
              sx={{
                color: theme.palette.primary.onSurface,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {activeTab === 2 ? (
                <Check
                  size={18}
                  weight='light'
                  style={{ marginRight: '8px' }}
                />
              ) : (
                <TagSimple
                  size={18}
                  weight='light'
                  style={{ marginRight: '8px' }}
                />
              )}

              {vocabularyPassport.prices[langSelected]}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setActiveTab(3)
            }}
            style={{
              textTransform: 'none',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderRight: '1px solid',
              borderTopRightRadius: '20px',
              borderBottomRightRadius: '20px',
              width: 189,
              height: 48,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                activeTab === 3
                  ? theme.palette.primary.secondaryContainer
                  : null,
            }}
          >
            <Typography
              component='div'
              variant='labelLarge'
              sx={{
                color: theme.palette.primary.onSurface,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {activeTab === 3 ? (
                <Check
                  size={18}
                  weight='light'
                  style={{ marginRight: '8px' }}
                />
              ) : (
                <Question
                  size={18}
                  weight='light'
                  style={{ marginRight: '8px' }}
                />
              )}
              {vocabularyPassport.questions[langSelected]}
            </Typography>
          </Box>
        </Box>

        {/*first tab*/}
        <CustomTab value={activeTab} index={0}>
          <Typography variant='h2Small' component='div' sx={{ mt: 6.5 }}>
            {vocabularyPassport.commonInformation[langSelected]}
          </Typography>

          <Typography
            variant='bodySmall'
            component='div'
            sx={{ mt: '32px', mb: 2 }}
          >
            {vocabularyZags.infoText1[langSelected]}
          </Typography>
          <Typography variant='bodySmall' component='div'>
            {vocabularyZags.infoText2[langSelected]}
          </Typography>

          <Box
            sx={{
              mt: 6.5,
              display: 'flex',
              width: 1,
            }}
          >
            <Button
              variant='contained'
              size='medium'
              sx={{ width: 240, height: 40 }}
              color='blueButton'
              onClick={openDialogService}
            >
              <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                {vocabulary.applicationSubmit[langSelected]}
              </Typography>
            </Button>
          </Box>

          {vocabularyZags.typesOfDocuments.map(val => {
            return (
              <Box sx={{ mt: 6.5 }} key={uuidv4()}>
                <Typography sx={{ mb: 3 }} component='div' variant='titleLarge'>
                  {vocabularyZags.zagsServicesConsulate[langSelected]}
                </Typography>
                {val.types.map((val, index) => {
                  return (
                    <Box
                      key={uuidv4()}
                      sx={{
                        borderTop:
                          !index > 0
                            ? '1px solid ' +
                              theme.palette.primary.outlineVariant
                            : 'none',
                        borderBottom:
                          '1px solid ' + theme.palette.primary.outlineVariant,
                        backgroundColor: theme.palette.primary.surface,
                        width: '100%',
                        minHeight: '48px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      onClick={() =>
                        openServiceTypeDialog({ id: index, ...val })
                      }
                    >
                      <Typography
                        component='div'
                        sx={{ my: 1 }}
                        variant='labelLarge'
                      >
                        {val.text[langSelected]}
                      </Typography>

                      <Box>
                        <img
                          src={arrowRightImg}
                          alt='logo'
                          width={20}
                          style={{ marginTop: '4px', marginRight: '16px' }}
                        />
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            )
          })}

          <Typography variant='bodySmall' component='div' sx={{ my: 2 }}>
            {vocabularyZags.infoText3[langSelected]}
          </Typography>

          <Link to={'/certificates#documents-retrieval'}>
            <Typography
              variant='labelLarge'
              component='div'
              color='primary'
              sx={{ my: 2, ml: 2 }}
            >
              {vocabularyZags.documentRequest[langSelected]} &rarr;
            </Typography>
          </Link>

          <Typography
            variant='titleLarge'
            component='p'
            sx={{ mt: 6.5, mb: 4 }}
          >
            {vocabularyConsent.registrationProcess[langSelected]}
          </Typography>

          {vocabularyZags.orderDescription[langSelected].map(
            (service, index) => {
              let last = false

              if (
                vocabularyZags.orderDescription[langSelected].length ===
                index + 1
              ) {
                last = true
              }

              return (
                <CardServiceProcess819
                  langSelected={langSelected}
                  key={uuidv4()}
                  headerText={service.headerText}
                  cardText={service.cardText}
                  cardImage={service.cardImage}
                  last={last}
                />
              )
            },
          )}
          <Box
            sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}
          >
            <Card
              onClick={() => {
                setActiveTab(1)
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
              sx={{
                mt: '32px',
                mb: '50px',
                px: 2,
                pt: '8px',
                height: 70,
                borderRadius: '12px',
                width: 1 / 2,
                cursor: 'pointer',
                backgroundColor: theme.palette.primary.surfaceContainer,
              }}
              variant='outlined'
            >
              <Typography
                variant='labelSmall'
                component='div'
                sx={{ color: theme.palette.primary.onSurfaceVariant }}
              >
                {vocabularyPassport.next[langSelected]} &rarr;
              </Typography>
              <Typography variant='titleSmall'>
                {vocabularyPassport.necessaryDoc[langSelected]}
              </Typography>
            </Card>
          </Box>
        </CustomTab>

        {/*second tab*/}

        <CustomTab value={activeTab} index={1}>
          <Typography variant='h2Small' component='div' sx={{ mt: 6.5, mb: 4 }}>
            {vocabularyPassport.requiredDocuments[langSelected]}
          </Typography>

          <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
            {vocabularyZags.infoText4[langSelected]}
          </Typography>

          {vocabularyZags.userTypes.map((val, index) => {
            return (
              <AccordionCustom
                key={uuidv4()}
                index={index}
                styles={styles}
                val={val}
                langSelected={langSelected}
              />
            )
          })}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mt: 6.5,
            }}
          >
            <Card
              onClick={() => {
                setActiveTab(0)
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
              sx={{
                mb: 2,
                pt: '8px',
                height: 70,
                borderRadius: '12px',
                backgroundColor: theme.palette.primary.surfaceContainer,
                width: 1 / 2,
              }}
              variant='outlined'
            >
              <Typography
                variant='labelSmall'
                component='div'
                sx={{ ml: 2, color: theme.palette.primary.onSurfaceVariant }}
              >
                &larr; {vocabularyPassport.back[langSelected]}
              </Typography>
              <Typography component='div' sx={{ ml: 2 }} variant='titleSmall'>
                {vocabularyPassport.commonInformation[langSelected]}
              </Typography>
            </Card>
            <Box sx={{ ml: 2 }} />
            <Card
              onClick={() => {
                setActiveTab(2)
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
              sx={{
                mb: '50px',

                pt: '8px',
                height: 70,
                minWidth: 1 / 2,
                borderRadius: '12px',
                backgroundColor: theme.palette.primary.surfaceContainer,
              }}
              variant='outlined'
            >
              <Typography
                variant='labelSmall'
                component='div'
                sx={{ ml: 2, color: theme.palette.primary.onSurfaceVariant }}
              >
                {vocabularyPassport.next[langSelected]} &rarr;
              </Typography>
              <Typography component='div' sx={{ ml: 2 }} variant='titleSmall'>
                {vocabularyPassport.prices[langSelected]}
              </Typography>
            </Card>
          </Box>
        </CustomTab>

        {/* third tab */}

        <CustomTab value={activeTab} index={2}>
          <Typography variant='h2Small' component='div' sx={{ mt: 6.5, mb: 4 }}>
            {vocabularyPassport.priceAndTariffs[langSelected]}
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: 1 / 2 }}>
              <PriceCard
                data={vocabularyZags.priceWithoutAppointment[langSelected]}
                squareImg={squareImg}
                colorBorder={true}
              />
            </Box>
            <Box sx={{ width: '16px' }} />
            <Box sx={{ width: 1 / 2 }}>
              <PriceCard
                data={vocabularyZags.priceWithAppointment[langSelected]}
                squareImg={squareImg}
                colorBorder={false}
              />
            </Box>
          </Box>

          <Typography
            variant='bodySmall'
            component='div'
            sx={{ mt: 6.5, mb: 2 }}
          >
            {vocabularyZags.priceText1[langSelected]}
          </Typography>
          <Typography variant='bodySmall' component='div' sx={{ mb: '32px' }}>
            {vocabularyPassport.priceText2[langSelected]}
          </Typography>

          <Box
            sx={{
              mt: 4,
              mb: 6.5,
              display: 'flex',

              width: 1,
            }}
          >
            <Button
              variant='contained'
              size='medium'
              sx={{ width: 240, height: 40 }}
              color='blueButton'
              onClick={openDialogService}
            >
              <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                {vocabulary.applicationSubmit[langSelected]}
              </Typography>
            </Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Card
              onClick={() => {
                setActiveTab(1)
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
              sx={{
                mb: 2,
                pt: '8px',
                height: 70,
                borderRadius: '12px',
                backgroundColor: theme.palette.primary.surfaceContainer,
                width: 1 / 2,
              }}
              variant='outlined'
            >
              <Typography
                variant='labelSmall'
                component='div'
                sx={{ ml: 2, color: theme.palette.primary.onSurfaceVariant }}
              >
                &larr; {vocabularyPassport.back[langSelected]}
              </Typography>
              <Typography variant='titleSmall' component='div' sx={{ ml: 2 }}>
                {vocabularyPassport.necessaryDoc[langSelected]}
              </Typography>
            </Card>

            <Box sx={{ ml: 2 }} />

            <Card
              onClick={() => {
                setActiveTab(3)
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
              sx={{
                mb: 2,
                pt: '8px',
                height: 70,
                borderRadius: '12px',
                backgroundColor: theme.palette.primary.surfaceContainer,
                width: 1 / 2,
              }}
              variant='outlined'
            >
              <Typography
                variant='labelSmall'
                component='div'
                sx={{ ml: 2, color: theme.palette.primary.onSurfaceVariant }}
              >
                {vocabularyPassport.next[langSelected]} &rarr;
              </Typography>
              <Typography variant='titleSmall' component='div' sx={{ ml: 2 }}>
                {vocabularyPassport.questions[langSelected]}
              </Typography>
            </Card>
          </Box>
        </CustomTab>
        {/* forth tab*/}
        <CustomTab value={activeTab} index={3}>
          <Typography variant='h2Small' component='div' sx={{ mt: 6.5, mb: 4 }}>
            {vocabularyPassport.faq[langSelected]}
          </Typography>

          {vocabularyZags.faq.map((val, index) => {
            return (
              <AccordionCustom
                key={uuidv4()}
                index={index}
                styles={styles}
                val={val}
                faq={true}
                langSelected={langSelected}
              />
            )
          })}

          <Box
            sx={{
              my: 6.5,
              display: 'flex',

              width: 1,
            }}
          >
            <Button
              variant='outlined'
              size='medium'
              sx={{ width: 240, height: 40, borderColor: 'black' }}
              color='blueButton'
              onClick={() => {
                navigate(`${window.location.pathname}?open=chat`)
              }}
            >
              <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                {vocabulary.ask[langSelected]}
              </Typography>
            </Button>
          </Box>

          <Card
            onClick={() => {
              setActiveTab(2)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            sx={{
              mb: 6.5,
              px: 2,
              pt: 1,
              height: 70,
              width: 1 / 2,
              borderRadius: '12px',
              backgroundColor: theme.palette.primary.surfaceContainer,
            }}
            variant='outlined'
          >
            <Typography
              variant='labelSmall'
              component='div'
              sx={{ color: theme.palette.primary.onSurfaceVariant }}
            >
              &larr; {vocabularyPassport.back[langSelected]}
            </Typography>
            <Typography variant='titleSmall'>
              {vocabularyPassport.prices[langSelected]}
            </Typography>
          </Card>
        </CustomTab>
      </Box>
      <RegistryOfficeDialog />
    </Layout819>
  )
}
