export const formatDateToDDMMYYYY = dateString => {
  // Создание объекта Date из строки даты
  const date = new Date(dateString)

  // Получение дня, месяца и года из объекта Date
  const day = String(date.getDate()).padStart(2, '0') // Добавление ведущего нуля, если необходимо
  const month = String(date.getMonth() + 1).padStart(2, '0') // Месяцы в JavaScript начинаются с 0, поэтому нужно добавить 1
  const year = date.getFullYear()

  // Форматирование даты в нужный формат
  return `${day}.${month}.${year}`
}
