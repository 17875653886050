import * as React from 'react'
import { Card, CardActionArea } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import GlobalContext from '../../../GlobalContext'
import { useNavigate } from 'react-router-dom'

export default function CardServiceList599({
  cardText,
  headerText,
  cardImage,
  index,
}) {
  const theme = useTheme()

  const { langSelected, GlobalActions } = React.useContext(GlobalContext)

  const navigate = useNavigate()

  return (
    <Card
      sx={{
        height: '144px',
        backgroundColor: theme.palette.primary.light,
        display: 'flex',
        border: 'none',
        mb: 2,
      }}
      variant='outlined'
    >
      <CardActionArea>
        <CardContent
          sx={{
            backgroundColor: '#E6E8EE',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '12px',
          }}
          onClick={() => {
            switch (index) {
              case 0:
                GlobalActions.setSubmenuSelected(0)

                navigate('/poa')

                break
              case 1:
                GlobalActions.setSubmenuSelected(1)
                navigate('/passport')

                break
              case 2:
                navigate('/consent')
                GlobalActions.setSubmenuSelected(2)

                break
              case 3:
                GlobalActions.setSubmenuSelected(3)
                navigate('/certificates')

                break
              case 4:
                GlobalActions.setSubmenuSelected(4)
                navigate('/legal-entities')

                break
              case 5:
                GlobalActions.setSubmenuSelected(5)
                navigate('/citizenship')

                break

              case 6:
                GlobalActions.setSubmenuSelected(6)
                navigate('/zags')

                break
              case 7:
                GlobalActions.setSubmenuSelected(7)
                navigate('/other')

                break
              case 8:
                GlobalActions.setSubmenuSelected(8)
                navigate('/consulting')

                break

              default:
            }
          }}
        >
          <img
            src={cardImage}
            alt='logo'
            style={{
              width: '54px',
              height: '54px',
              marginBottom: '58px',
            }}
          />
          <Box sx={{ ml: 2, mr: 1 }}>
            <Typography component='div' variant='titleSmall'>
              {headerText}
            </Typography>

            <Typography
              component='div'
              variant='bodySmall'
              sx={{ mt: 1, wordWrap: 'break-word' }}
            >
              {cardText}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
