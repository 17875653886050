import { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import GlobalContext from 'GlobalContext'

export const usePathChangeHandler = () => {
  const [searchParams] = useSearchParams()

  const { GlobalActions } = useContext(GlobalContext)

  useEffect(() => {
    const section = searchParams?.get('section')
    const service = searchParams?.get('service')
    const id = searchParams?.get('id')
    const additionalServiceType = searchParams?.get('additionalServiceType')

    if (section && service && id) {
      GlobalActions.setDialogServiceOpen({
        // open dialog service information window
        step: section === 'poa' ? 1 : -1,
        section,
        index: id,
      })

      GlobalActions.setDataToSend({
        section: 'consent',
        service,
        additionalServiceType: decodeURIComponent(additionalServiceType),
        serviceDetails: {
          serviceName: service,
        },
      })
    }
  }, [searchParams])
}
