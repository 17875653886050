import React, { useContext, useState } from 'react'
import { Button, useTheme, Box, Typography, Card } from '@mui/material/'
import vocabulary from '../../vocabulary/vocabulary'
import Layout600 from '../Layouts/Layout600'
import GlobalContext from '../../GlobalContext'
import { Workflow } from 'pages/components/DialogServices/Other/Workflow'
import { PhotoRequirements } from 'pages/components/DialogServices/PhotoRequirements'
import { useNavigate } from 'react-router-dom'
import UserAgreement from 'pages/components/UserAgreement'

export default function UserAgreement600() {
  const { langSelected } = useContext(GlobalContext)
  const [stateMenu, setMenu] = useState(false)
  const navigate = useNavigate()

  function setStateMenu(val) {
    setMenu(val)
  }

  const theme = useTheme()

  return (
    <Layout600
      stateMenu={stateMenu}
      setStateMenu={setStateMenu}
      langSelected={langSelected}
      theme={theme}
      menu='true'
    >
      <Box sx={{ mt: 8 }}>
        <Typography variant='h1Small' component='p' sx={{ mx: 2 }}>
          {vocabulary.termsOfUse[langSelected]}
        </Typography>
        <UserAgreement />
      </Box>
      <Workflow />
      <PhotoRequirements />
    </Layout600>
  )
}
