import React, { useContext, useState } from 'react'
import { Button, useTheme, Box, Typography, Card } from '@mui/material/'
import vocabulary from '../../vocabulary/vocabulary'
import Layout599 from '../Layouts/Layout599'
import GlobalContext from '../../GlobalContext'
import squareImg from '../../images/brown-square.png'
import { v4 as uuidv4 } from 'uuid'
import styles from './styles599'
import AccordionCustom from '../components/AccordionCustom'
import CardServiceProcess599 from '../components/ServiceProcess/CardServiceProcess599'
import PriceCard from '../components/PriceCard/PriceCard'
import vocabularyPassport from '../../vocabulary/vocabularyPassport'
import vocabularyConsent from '../../vocabulary/vocabularyConsent'
import { Workflow } from 'pages/components/DialogServices/Citizenship/Workflow'
import { useOpenDialogService } from 'pages/helpers'
import { PhotoRequirements } from 'pages/components/DialogServices/PhotoRequirements'
import vocabularyCitizenship from '../../vocabulary/vocabularyCitizenship'
import { useNavigate } from 'react-router-dom'
import { useScrollToTop } from 'hooks/useScrollToTop'

export default function Citizenship599() {
  const { langSelected } = useContext(GlobalContext)
  const [stateMenu, setMenu] = useState(false)
  const navigate = useNavigate()

  function setStateMenu(val) {
    setMenu(val)
  }

  useScrollToTop()

  const theme = useTheme()

  const CustomTab = ({ children, value, index }) => {
    return <Box hidden={value !== index}>{children}</Box>
  }

  const [activeTab, setActiveTab] = useState(0)

  const openDialogService = useOpenDialogService({ section: 'citizenship' })

  return (
    <Layout599
      stateMenu={stateMenu}
      setStateMenu={setStateMenu}
      langSelected={langSelected}
      theme={theme}
      menu='true'
    >
      <Box
        sx={{
          mt: '56px',
          mx: '16px',
          pt: '32px',
          maxWidth: '90%',
          overflow: 'hidden',
        }}
      >
        <Typography variant='h1Small'>
          {vocabularyCitizenship.citizenship[langSelected]}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            mt: 2,
          }}
        >
          <Box
            onClick={() => {
              setActiveTab(0)
            }}
            sx={{
              textTransform: 'none',
              border: '1px solid',
              borderTopLeftRadius: '20px',
              borderBottomLeftRadius: '20px',
              width: 82,
              height: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                activeTab === 0
                  ? theme.palette.primary.secondaryContainer
                  : null,
            }}
          >
            <Typography
              variant='labelSmallSemibold'
              component='div'
              sx={{ color: theme.palette.primary.onSurface }}
            >
              {vocabularyPassport.info[langSelected]}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setActiveTab(1)
            }}
            sx={{
              textTransform: 'none',
              borderRight: '1px solid',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              width: 82,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                activeTab === 1
                  ? theme.palette.primary.secondaryContainer
                  : null,
            }}
          >
            <Typography
              component='div'
              variant='labelSmallSemibold'
              sx={{ color: theme.palette.primary.onSurface }}
            >
              {vocabularyPassport.documents[langSelected]}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setActiveTab(2)
            }}
            sx={{
              textTransform: 'none',
              borderRight: '1px solid',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              width: 82,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                activeTab === 2
                  ? theme.palette.primary.secondaryContainer
                  : null,
            }}
          >
            <Typography
              component='div'
              variant='labelSmallSemibold'
              sx={{ color: theme.palette.primary.onSurface }}
            >
              {vocabularyPassport.prices[langSelected]}
            </Typography>
          </Box>
          <Box
            onClick={() => {
              setActiveTab(3)
            }}
            style={{
              textTransform: 'none',
              borderTop: '1px solid',
              borderBottom: '1px solid',
              borderRight: '1px solid',
              borderTopRightRadius: '20px',
              borderBottomRightRadius: '20px',
              width: 82,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:
                activeTab === 3
                  ? theme.palette.primary.secondaryContainer
                  : null,
            }}
          >
            <Typography
              component='div'
              variant='labelSmallSemibold'
              sx={{ color: theme.palette.primary.onSurface }}
            >
              {vocabularyPassport.questions[langSelected]}
            </Typography>
          </Box>
        </Box>

        {/*first tab*/}

        <CustomTab value={activeTab} index={0}>
          <Typography variant='h2Small' component='div' sx={{ mt: 6.5 }}>
            {vocabularyPassport.commonInformation[langSelected]}
          </Typography>

          <Typography
            variant='bodySmall'
            component='div'
            sx={{ mt: '32px', mb: 2 }}
          >
            {vocabularyCitizenship.infoText1[langSelected]}
          </Typography>
          <Typography variant='bodySmall' component='div'>
            {vocabularyCitizenship.infoText2[langSelected]}
          </Typography>

          <Box
            sx={{
              mt: 6.5,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              variant='contained'
              size='medium'
              sx={{ width: '328px', height: '40px' }}
              color='blueButton'
              onClick={openDialogService}
            >
              <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                {vocabulary.applicationSubmit[langSelected]}
              </Typography>
            </Button>
          </Box>

          <Typography
            sx={{ mb: 3, mt: 6.5 }}
            component='div'
            variant='titleLarge'
            id='check-citizenship'
          >
            {vocabularyCitizenship.checkCitizenship[langSelected]}
          </Typography>
          <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
            {vocabularyCitizenship.infoText3[langSelected]}
          </Typography>
          <Typography variant='bodySmall' component='div' sx={{ mb: 2 }}>
            {vocabularyCitizenship.infoText4[langSelected]}
          </Typography>
          <Typography variant='bodySmall' component='div'>
            {vocabularyCitizenship.infoText5[langSelected]}
          </Typography>

          <Typography
            sx={{ mb: 3, mt: 6.5 }}
            component='div'
            variant='titleLarge'
          >
            {vocabularyCitizenship.receivingCitizenship[langSelected]}
          </Typography>
          <Typography sx={{ mb: 3 }} variant='bodySmall' component='div'>
            {vocabularyCitizenship.infoText6[langSelected]}
          </Typography>

          {vocabularyCitizenship.userTypes.map((val, index) => {
            return (
              <AccordionCustom
                key={uuidv4()}
                index={index}
                styles={styles}
                val={val}
                langSelected={langSelected}
                faq={true}
              />
            )
          })}

          {vocabularyCitizenship.userTypes1.map((val, index) => {
            return (
              <AccordionCustom
                key={uuidv4()}
                index={1}
                styles={styles}
                val={val}
                langSelected={langSelected}
                borderUp={true}
              />
            )
          })}

          <Typography
            sx={{ mb: 3, mt: 6.5 }}
            component='div'
            variant='titleLarge'
          >
            {vocabularyCitizenship.citizenshipExit[langSelected]}
          </Typography>
          <Typography sx={{ mb: 3 }} variant='bodySmall' component='div'>
            {vocabularyCitizenship.infoText7[langSelected]}
          </Typography>
          {vocabularyCitizenship.citizenshipExitList.map(val => {
            return (
              <Box
                key={uuidv4()}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  m: '16px',
                }}
              >
                <img
                  src={squareImg}
                  alt='logo'
                  width={20}
                  style={{ marginRight: '16px' }}
                />
                <Typography variant='bodySmall' component='div'>
                  {val[langSelected]}
                </Typography>
              </Box>
            )
          })}

          <Typography
            variant='titleLarge'
            component='p'
            sx={{ mt: 6.5, mb: 4 }}
          >
            {vocabularyConsent.registrationProcess[langSelected]}
          </Typography>

          {vocabularyCitizenship.orderDescription[langSelected].map(
            (service, index) => {
              let last = false

              if (
                vocabularyCitizenship.orderDescription[langSelected].length ===
                index + 1
              ) {
                last = true
              }

              return (
                <CardServiceProcess599
                  langSelected={langSelected}
                  key={uuidv4()}
                  headerText={service.headerText}
                  cardText={service.cardText}
                  cardImage={service.cardImage}
                  last={last}
                />
              )
            },
          )}

          <Card
            onClick={() => {
              setActiveTab(1)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            sx={{
              mt: '32px',
              mb: '50px',
              px: 2,
              pt: '8px',
              height: 70,
              borderRadius: '12px',
              flexGrow: 1,
              backgroundColor: theme.palette.primary.surfaceContainer,
            }}
            variant='outlined'
          >
            <Typography
              variant='labelSmall'
              component='div'
              sx={{ color: theme.palette.primary.onSurfaceVariant }}
            >
              {vocabularyPassport.next[langSelected]} &rarr;
            </Typography>
            <Typography variant='titleSmall'>
              {vocabularyPassport.necessaryDoc[langSelected]}
            </Typography>
          </Card>
        </CustomTab>

        {/*second tab*/}

        <CustomTab value={activeTab} index={1}>
          <Typography variant='h2Small' component='div' sx={{ mt: 6.5, mb: 4 }}>
            {vocabularyPassport.requiredDocuments[langSelected]}
          </Typography>

          <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
            {vocabularyCitizenship.infoText8[langSelected]}
          </Typography>

          <Typography
            sx={{ mb: 3, mt: 6.5 }}
            component='div'
            variant='titleLarge'
          >
            {vocabularyCitizenship.checkCitizenship[langSelected]}
          </Typography>

          {vocabularyCitizenship.userTypes2.map((val, index) => {
            return (
              <AccordionCustom
                key={uuidv4()}
                index={1}
                styles={styles}
                val={val}
                langSelected={langSelected}
                borderUp={true}
              />
            )
          })}

          <Typography
            sx={{ mb: 3, mt: 6.5 }}
            component='div'
            variant='titleLarge'
          >
            {vocabularyCitizenship.receivingCitizenship[langSelected]}
          </Typography>

          {vocabularyCitizenship.userTypes3.map((val, index) => {
            return (
              <AccordionCustom
                key={uuidv4()}
                index={1}
                styles={styles}
                val={val}
                langSelected={langSelected}
                borderUp={true}
              />
            )
          })}

          <Typography
            sx={{ mb: 3, mt: 6.5 }}
            component='div'
            variant='titleLarge'
          >
            {vocabularyCitizenship.citizenshipExit[langSelected]}
          </Typography>

          {vocabularyCitizenship.userTypes4.map((val, index) => {
            return (
              <AccordionCustom
                key={uuidv4()}
                index={1}
                styles={styles}
                val={val}
                langSelected={langSelected}
                borderUp={true}
              />
            )
          })}

          <Card
            onClick={() => {
              setActiveTab(0)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            sx={{
              mb: 2,
              mt: 6.5,
              px: 2,
              pt: 1,
              height: 70,
              borderRadius: '12px',
              backgroundColor: theme.palette.primary.surfaceContainer,
            }}
            variant='outlined'
          >
            <Typography
              variant='labelSmall'
              component='div'
              sx={{ color: theme.palette.primary.onSurfaceVariant }}
            >
              &larr; {vocabularyPassport.back[langSelected]}
            </Typography>
            <Typography variant='titleSmall'>
              {vocabularyPassport.commonInformation[langSelected]}
            </Typography>
          </Card>

          <Card
            onClick={() => {
              setActiveTab(2)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            sx={{
              mb: '50px',
              px: '16px',
              pt: '8px',
              height: 70,
              borderRadius: '12px',
              backgroundColor: theme.palette.primary.surfaceContainer,
            }}
            variant='outlined'
          >
            <Typography
              variant='labelSmall'
              component='div'
              sx={{ color: theme.palette.primary.onSurfaceVariant }}
            >
              {vocabularyPassport.next[langSelected]} &rarr;
            </Typography>
            <Typography variant='titleSmall'>
              {vocabularyPassport.prices[langSelected]}
            </Typography>
          </Card>
        </CustomTab>

        {/* third tab */}
        <CustomTab value={activeTab} index={2}>
          <Typography variant='h2Small' component='div' sx={{ mt: 6.5, mb: 4 }}>
            {vocabularyPassport.priceAndTariffs[langSelected]}
          </Typography>

          <PriceCard
            data={vocabularyCitizenship.priceWithoutAppointment[langSelected]}
            squareImg={squareImg}
            colorBorder={true}
          />
          <Box sx={{ mt: 2 }} />
          <PriceCard
            data={vocabularyCitizenship.priceWithAppointment[langSelected]}
            squareImg={squareImg}
            colorBorder={false}
          />

          <Typography
            variant='bodySmall'
            component='div'
            sx={{ mt: 6.5, mb: 2 }}
          >
            {vocabularyCitizenship.priceText1[langSelected]}
          </Typography>
          <Typography variant='bodySmall' component='div' sx={{ mb: '32px' }}>
            {vocabularyPassport.priceText2[langSelected]}
          </Typography>

          <Box
            sx={{
              mt: 4,
              mb: 6.5,
              display: 'flex',
              justifyContent: 'center',
              width: 1,
            }}
          >
            <Button
              variant='contained'
              size='medium'
              sx={{ width: '328px', height: '40px' }}
              color='blueButton'
              onClick={openDialogService}
            >
              <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                {vocabulary.applicationSubmit[langSelected]}
              </Typography>
            </Button>
          </Box>

          <Card
            onClick={() => {
              setActiveTab(1)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            sx={{
              mb: 2,
              px: '16px',
              pt: '8px',
              height: 70,
              borderRadius: '12px',
              backgroundColor: theme.palette.primary.surfaceContainer,
            }}
            variant='outlined'
          >
            <Typography
              variant='labelSmall'
              component='div'
              sx={{ color: theme.palette.primary.onSurfaceVariant }}
            >
              &larr; {vocabularyPassport.back[langSelected]}
            </Typography>
            <Typography variant='titleSmall'>
              {vocabularyPassport.necessaryDoc[langSelected]}
            </Typography>
          </Card>

          <Card
            onClick={() => {
              setActiveTab(3)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            sx={{
              mb: '50px',
              px: '16px',
              pt: '8px',
              height: 70,
              borderRadius: '12px',
              backgroundColor: theme.palette.primary.surfaceContainer,
            }}
            variant='outlined'
          >
            <Typography
              variant='labelSmall'
              component='div'
              sx={{ color: theme.palette.primary.onSurfaceVariant }}
            >
              {vocabularyPassport.next[langSelected]} &rarr;
            </Typography>
            <Typography variant='titleSmall'>
              {vocabularyPassport.questions[langSelected]}
            </Typography>
          </Card>
        </CustomTab>
        {/* forth tab*/}
        <CustomTab value={activeTab} index={3}>
          <Typography variant='h2Small' component='div' sx={{ mt: 6.5, mb: 4 }}>
            {vocabularyPassport.faq[langSelected]}
          </Typography>

          {vocabularyCitizenship.faq.map((val, index) => {
            return (
              <AccordionCustom
                key={uuidv4()}
                index={index}
                styles={styles}
                val={val}
                faq={true}
                langSelected={langSelected}
              />
            )
          })}

          <Box
            sx={{
              my: 6.5,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              variant='outlined'
              size='medium'
              sx={{ width: '328px', height: '40px', borderColor: 'black' }}
              color='blueButton'
              onClick={() => {
                navigate(`${window.location.pathname}?open=chat`)
              }}
            >
              <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
                {vocabulary.ask[langSelected]}
              </Typography>
            </Button>
          </Box>

          <Card
            onClick={() => {
              setActiveTab(2)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
            sx={{
              mt: '32px',
              mb: 6.5,
              px: 2,
              pt: '8px',
              height: 70,
              borderRadius: '12px',
              flexGrow: 1,
              backgroundColor: theme.palette.primary.surfaceContainer,
            }}
            variant='outlined'
          >
            <Typography
              variant='labelSmall'
              component='div'
              sx={{ color: theme.palette.primary.onSurfaceVariant }}
            >
              &larr; {vocabularyPassport.back[langSelected]}
            </Typography>
            <Typography variant='titleSmall'>
              {vocabularyPassport.prices[langSelected]}
            </Typography>
          </Card>
        </CustomTab>
      </Box>
      <Workflow />
      <PhotoRequirements />
    </Layout599>
  )
}
