import { Divider, Box } from '@mui/material'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'

export default function Faq819({ value, about }) {
  const theme = useTheme()

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        width: '100%',
        height: 'fit-content',
      }}
    >
      {value?.map((val, index) => (
        <Box
          sx={{
            width: '360px',

            backgroundColor: theme.palette.primary.surfaceContainerLow,
          }}
          key={uuidv4()}
        >
          <Divider variant='large' sx={{ mb: '24px', mr: '4px' }} />
          <Typography variant='labelLarge' component='p' sx={{ mx: '6px' }}>
            {val.question}
          </Typography>
          <Typography
            variant='bodySmall'
            component='p'
            sx={{ mx: '6px', mb: '24px', mt: '12px' }}
          >
            {val.answer}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
