const vocabularyWorkflow = {
  applicationSubmit: ['Оформить заявку', 'Submit an Application'],
  submit: ['Оформить', 'Submit'],
  ask: ['Спросить', 'Ask'],
  typeOfService: ['Вид услуги', 'Type of service'],
  describeServiceNeeded: [
    'Или опишите своими словами, чем мы вам можем помочь',
    'Or describe in your own words how we can help you.',
  ],
  chooseFromList: ['Выберите из списка', 'Choose from list'],
  skip: ['Пропустить', 'Skip'],
  chooseConsulate: [
    'В каком консульстве вы хотите оформлять документы?',
    'In which consulate do you want to process documents',
  ],
  country: ['Страна', 'Country'],
  city: ['Город', 'City'],
  sections: [
    [
      'Доверенности',
      'Загранпаспорт',
      'Согласия, заявления, договоры',
      'Справки',
      'Документы для юр. лиц',
      'Гражданство',
      'Услуги ЗАГС',
      'Другие консульские услуги',
      'Консультационные услуги',
    ],
    [
      'Powers of Attorney',
      'Passport (foreign)',
      'Consents, Statements, Contracts',
      'References',
      'Documents for Legal Entities',
      'Citizenship',
      'Registry Office Services',
      'Other Consular Services',
      'Consulting Services',
    ],
    [
      'poa',
      'passport',
      'consent',
      'certificates',
      'legal-entities',
      'citizenship',
      'zags',
      'other',
      'consulting',
    ],
  ],
  countries: [
    {
      Абхазия: ['Сухум'],
      Австралия: ['Канберра', 'Сидней'],
      Австрия: ['Вена', 'Зальцбург'],
      Азербайджан: ['Баку'],
      Албания: ['Тирана'],
      Алжир: ['Алжир', 'Аннаба'],
      Ангола: ['Луанда'],
      Аргентина: ['Буэнос-Айрес'],
      Армения: ['Гюмри', 'Ереван'],
      Афганистан: ['Кабул', 'Мазари-Шариф'],
      Бангладеш: ['Дакка', 'Читтагонг'],
      Бахрейн: ['Манама'],
      Беларусь: ['Брест', 'Минск'],
      Бельгия: ['Брюссель'],
      Бенин: ['Котону'],
      Болгария: ['София'],
      Боливия: ['Ла-Пас'],
      'Босния и Герцеговина': ['Сараево'],
      Ботсвана: ['Габороне'],
      Бразилия: ['Бразилиа', 'Рио-де-Жанейро', 'Сан-Пауло'],
      Бруней: ['Бандар-Сери-Бегаван'],
      Бурунди: ['Бужумбур'],
      Ватикан: ['Ватикан'],
      Великобритания: ['Лондон', 'Эдинбург'],
      Венгрия: ['Будапешт', 'Дебрецен'],
      Венесуэла: ['Каракас'],
      Вьетнам: ['Дананг', 'Ханой', 'Хошимин'],
      Габон: ['Либревиль'],
      Гайана: ['Джорджтаун'],
      Гана: ['Аккра'],
      Гватемала: ['Гватемала'],
      Гвинея: ['Конакри'],
      'Гвинея-Бисау': ['Бисау'],
      Германия: ['Берлин', 'Бонн'],
      Греция: ['Афины', 'Салоники'],
      Грузия: ['Тбилиси'],
      Дания: ['Копенгаген'],
      Джибути: ['Джибути'],
      Египет: ['Александрия', 'Каир'],
      Замбия: ['Лусака'],
      Зимбабве: ['Хараре'],
      Израиль: ['Тель-Авив', 'Хайфа'],
      Индия: ['Калькутта', 'Мумбаи', 'Нью-Дели', 'Ченнаи'],
      Индонезия: ['Джакарта'],
      Иордания: ['Амман'],
      Ирак: ['Багдад', 'Басра', 'Эрбиль'],
      Иран: ['Исфахан', 'Решт', 'Тегеран'],
      Ирландия: ['Дублин'],
      Исландия: ['Рейкьявик'],
      Испания: ['Барселона', 'Мадрид'],
      Италия: ['Генуя', 'Милан', 'Палермо', 'Рим'],
      'Йеменская Республика': ['Аден', 'Сана'],
      'Кабо-Верде': ['Прайя'],
      Казахстан: ['Алма-Ата', 'Астана', 'Уральск', 'Усть-Каменогорск'],
      Камбоджа: ['Пномпень'],
      Камерун: ['Яунде'],
      Канада: ['Монреаль', 'Оттава', 'Торонто'],
      Катар: ['Доха'],
      Кения: ['Найроби'],
      Кипр: ['Лимасол', 'Никосия'],
      Киргизия: ['Бишкек', 'Ош'],
      Китай: ['Гонконг', 'Гуанчжоу', 'Пекин', 'Шанхай', 'Шэньян'],
      КНДР: ['Пхеньян', 'Чондин'],
      Колумбия: ['Богота'],
      'Конго, Демократическая Республика': ['Киншаса'],
      'Конго, Республика': ['Браззавиль'],
      'Коста-Рика': ['Сан-Хосе'],
      'Кот-д’Ивуар': ['Абиджан'],
      Куба: ['Гавана'],
      Кувейт: ['Эль-Кувейт'],
      Лаос: ['Вьентьян'],
      Латвия: ['Даугавпилс', 'Лиепая', 'Рига'],
      Ливан: ['Бейрут'],
      Ливия: ['Триполи'],
      Литва: ['Вильнюс'],
      Люксембург: ['Люксембург'],
      Маврикий: ['Порт-Луи'],
      Мавритания: ['Нуакшот'],
      Мадагаскар: ['Антананариву'],
      Малайзия: ['Куала-Лумпур'],
      Мали: ['Бамако'],
      Мальта: ['Валлета'],
      Марокко: ['Касабланка', 'Рабат'],
      Мексика: ['Мехико'],
      Мозамбик: ['Мапуту'],
      Молдавия: ['Кишинёв'],
      Монголия: ['Дархан', 'Улан-Батор', 'Эрдэнэт'],
      Мьянма: ['Янгон'],
      Намибия: ['Виндхук'],
      Непал: ['Катманду'],
      Нигерия: ['Абуджа', 'Лагос'],
      Нидерланды: ['Гаага'],
      Никарагуа: ['Манагуа'],
      'Новая Зеландия': ['Веллингтон'],
      Норвегия: ['Баренцбург', 'Киркенес', 'Осло'],
      ОАЭ: ['Абу-Даби', 'Дубай'],
      Оман: ['Маскат'],
      Пакистан: ['Исламабад', 'Карачи'],
      Палестина: ['Газа'],
      Панама: ['Панама'],
      Перу: ['Лима'],
      Польша: ['Варшава', 'Гданьск', 'Краков', 'Познань'],
      Португалия: ['Лиссабон'],
      Руанда: ['Кигали'],
      Румыния: ['Бухарест', 'Констанца'],
      'Саудовская Аравия': ['Джидда', 'Эр-Рияд'],
      'Северная Македония': ['Скопье'],
      'Сейшельские острова': ['Виктория'],
      Сенегал: ['Дакар'],
      Сербия: ['Белград'],
      Сингапур: ['Сингапур'],
      Сирия: ['Алеппо', 'Дамаск'],
      Словакия: ['Братислава'],
      Словения: ['Любляна'],
      Судан: ['Хартум'],
      США: ['Вашингтон', 'Нью-Йорк', 'Хьюстон'],
      Таджикистан: ['Душанбе', 'Худжанд'],
      Таиланд: ['Бангкок'],
      Тайвань: ['Тайбей'],
      Танзания: ['Дар-Эс-Салам'],
      Тунис: ['Тунис'],
      Туркменистан: ['Ашхабад', 'Туркменбаши'],
      Турция: ['Анкара', 'Анталья', 'Стамбул', 'Трабзон'],
      Уганда: ['Кампала'],
      Узбекистан: ['Ташкент'],
      Украина: ['Киев', 'Львов', 'Одесса', 'Харьков'],
      Уругвай: ['Монтевидео'],
      Филиппины: ['Манила'],
      Финляндия: ['Мариенхамн', 'Хельсинки'],
      Франция: ['Вильфранш-сюр-Мер', 'Марсель', 'Париж', 'Страсбург'],
      Хорватия: ['Загреб'],
      ЦАР: ['Банги'],
      Чад: ['Нджамена'],
      Черногория: ['Подгорица'],
      Чехия: ['Прага'],
      Чили: ['Сантьяго'],
      Швейцария: ['Берн', 'Женева'],
      Швеция: ['Стокгольм'],
      'Шри-Ланка': ['Коломбо'],
      Эквадор: ['Кито'],
      Эритрея: ['Асмара'],
      Эстония: ['Таллинн'],
      Эфиопия: ['Аддис-Абеба'],
      ЮАР: ['Кейптаун', 'Претория'],
      'Южная Корея': ['Пусан', 'Сеул'],
      Ямайка: ['Кингстон'],
      Япония: ['Ниигата', 'Осака', 'Саппоро', 'Токио', 'Хакодатэ'],
    },
    {
      Abkhazia: ['Sukhumi'],
      Australia: ['Canberra', 'Sydney'],
      Austria: ['Vienna', 'Salzburg'],
      Azerbaijan: ['Baku'],
      Albania: ['Tirana'],
      Algeria: ['Algiers', 'Annaba'],
      Angola: ['Luanda'],
      Argentina: ['Buenos Aires'],
      Armenia: ['Gyumri', 'Yerevan'],
      Afghanistan: ['Kabul', 'Mazar-i-Sharif'],
      Bangladesh: ['Dhaka', 'Chittagong'],
      Bahrain: ['Manama'],
      Belarus: ['Brest', 'Minsk'],
      Belgium: ['Brussels'],
      Benin: ['Cotonou'],
      Bulgaria: ['Sofia'],
      Bolivia: ['La Paz'],
      'Bosnia and Herzegovina': ['Sarajevo'],
      Botswana: ['Gaborone'],
      Brazil: ['Brasilia', 'Rio de Janeiro', 'Sao Paulo'],
      Brunei: ['Bandar Seri Begawan'],
      Burundi: ['Bujumbura'],
      Vatican: ['Vatican'],
      'United Kingdom': ['London', 'Edinburgh'],
      Hungary: ['Budapest', 'Debrecen'],
      Venezuela: ['Caracas'],
      Vietnam: ['Da Nang', 'Hanoi', 'Ho Chi Minh City'],
      Gabon: ['Libreville'],
      Guyana: ['Georgetown'],
      Ghana: ['Accra'],
      Guatemala: ['Guatemala City'],
      Guinea: ['Conakry'],
      'Guinea-Bissau': ['Bissau'],
      Germany: ['Berlin', 'Bonn'],
      Greece: ['Athens', 'Thessaloniki'],
      Georgia: ['Tbilisi'],
      Denmark: ['Copenhagen'],
      Djibouti: ['Djibouti'],
      Egypt: ['Alexandria', 'Cairo'],
      Zambia: ['Lusaka'],
      Zimbabwe: ['Harare'],
      Israel: ['Tel Aviv', 'Haifa'],
      India: ['Kolkata', 'Mumbai', 'New Delhi', 'Chennai'],
      Indonesia: ['Jakarta'],
      Jordan: ['Amman'],
      Iraq: ['Baghdad', 'Basra', 'Erbil'],
      Iran: ['Isfahan', 'Rasht', 'Tehran'],
      Ireland: ['Dublin'],
      Iceland: ['Reykjavik'],
      Spain: ['Barcelona', 'Madrid'],
      Italy: ['Genoa', 'Milan', 'Palermo', 'Rome'],
      Yemen: ['Aden', "Sana'a"],
      'Cape Verde': ['Praia'],
      Kazakhstan: ['Almaty', 'Astana', 'Oral', 'Oskemen'],
      Cambodia: ['Phnom Penh'],
      Cameroon: ['Yaounde'],
      Canada: ['Montreal', 'Ottawa', 'Toronto'],
      Qatar: ['Doha'],
      Kenya: ['Nairobi'],
      Cyprus: ['Limassol', 'Nicosia'],
      Kyrgyzstan: ['Bishkek', 'Osh'],
      China: ['Hong Kong', 'Guangzhou', 'Beijing', 'Shanghai', 'Shenyang'],
      'North Korea': ['Pyongyang', 'Chongjin'],
      Colombia: ['Bogota'],
      'Democratic Republic of the Congo': ['Kinshasa'],
      'Republic of the Congo': ['Brazzaville'],
      'Costa Rica': ['San Jose'],
      'Ivory Coast': ['Abidjan'],
      Cuba: ['Havana'],
      Kuwait: ['Kuwait City'],
      Laos: ['Vientiane'],
      Latvia: ['Daugavpils', 'Liepaja', 'Riga'],
      Lebanon: ['Beirut'],
      Libya: ['Tripoli'],
      Lithuania: ['Vilnius'],
      Luxembourg: ['Luxembourg'],
      Mauritius: ['Port Louis'],
      Mauritania: ['Nouakchott'],
      Madagascar: ['Antananarivo'],
      Malaysia: ['Kuala Lumpur'],
      Mali: ['Bamako'],
      Malta: ['Valletta'],
      Morocco: ['Casablanca', 'Rabat'],
      Mexico: ['Mexico City'],
      Mozambique: ['Maputo'],
      Moldova: ['Chisinau'],
      Mongolia: ['Darkhan', 'Ulaanbaatar', 'Erdenet'],
      Myanmar: ['Yangon'],
      Namibia: ['Windhoek'],
      Nepal: ['Kathmandu'],
      Nigeria: ['Abuja', 'Lagos'],
      Netherlands: ['The Hague'],
      Nicaragua: ['Managua'],
      'New Zealand': ['Wellington'],
      Norway: ['Barentsburg', 'Kirkenes', 'Oslo'],
      UAE: ['Abu Dhabi', 'Dubai'],
      Oman: ['Muscat'],
      Pakistan: ['Islamabad', 'Karachi'],
      Palestine: ['Gaza'],
      Panama: ['Panama City'],
      Peru: ['Lima'],
      Poland: ['Warsaw', 'Gdansk', 'Krakow', 'Poznan'],
      Portugal: ['Lisbon'],
      Rwanda: ['Kigali'],
      Romania: ['Bucharest', 'Constanta'],
      'Saudi Arabia': ['Jeddah', 'Riyadh'],
      'North Macedonia': ['Skopje'],
      Seychelles: ['Victoria'],
      Senegal: ['Dakar'],
      Serbia: ['Belgrade'],
      Singapore: ['Singapore'],
      Syria: ['Aleppo', 'Damascus'],
      Slovakia: ['Bratislava'],
      Slovenia: ['Ljubljana'],
      Sudan: ['Khartoum'],
      USA: ['Washington', 'New York', 'Houston'],
      Tajikistan: ['Dushanbe', 'Khujand'],
      Thailand: ['Bangkok'],
      Taiwan: ['Taipei'],
      Tanzania: ['Dar es Salaam'],
      Tunisia: ['Tunis'],
      Turkmenistan: ['Ashgabat', 'Turkmenbashi'],
      Turkey: ['Ankara', 'Antalya', 'Istanbul', 'Trabzon'],
      Uganda: ['Kampala'],
      Uzbekistan: ['Tashkent'],
      Ukraine: ['Kyiv', 'Lviv', 'Odessa', 'Kharkiv'],
      Uruguay: ['Montevideo'],
      Philippines: ['Manila'],
      Finland: ['Mariehamn', 'Helsinki'],
      France: ['Villefranche-sur-Mer', 'Marseille', 'Paris', 'Strasbourg'],
      Croatia: ['Zagreb'],
      CAR: ['Bangui'],
      Chad: ["N'Djamena"],
      Montenegro: ['Podgorica'],
      'Czech Republic': ['Prague'],
      Chile: ['Santiago'],
      Switzerland: ['Bern', 'Geneva'],
      Sweden: ['Stockholm'],
      'Sri Lanka': ['Colombo'],
      Ecuador: ['Quito'],
      Eritrea: ['Asmara'],
      Estonia: ['Tallinn'],
      Ethiopia: ['Addis Ababa'],
      'South Africa': ['Cape Town', 'Pretoria'],
      'South Korea': ['Busan', 'Seoul'],
      Jamaica: ['Kingston'],
      Japan: ['Niigata', 'Osaka', 'Sapporo', 'Tokyo', 'Hakodate'],
    },
  ],
  wantAnAppointment: [
    'Хотите, чтобы мы вас сами записали на приём в консульство?',
    'Would you like us to schedule an appointment for you at the consulate?',
  ],
  yes: ['Да', 'Yes'],
  iDoItMyself: ['Нет, я запишусь сам(a)', 'No, I will schedule it myself.'],
  forward: ['Далее', 'Forward'],
  payment: ['Оплата', 'Payment'],
  paymentText: [
    'Вы можете оплатить услугу сейчас или после консультации с юристом',
    'You can pay for the service now or after consulting with the lawyer',
  ],
  payLater: [
    'Оформить заявку, оплатить позже',
    'Submit an application, pay later',
  ],
  payNow: ['Оплатить сейчас', 'Pay now'],
  sendRequest: ['Отправить заявку', 'Send request'],
  saveRequest: ['Сохранить в черновики', 'Save as draft'],
  selectedPrice: ['Выбранный тариф:', 'The selected tariff:'],
  withAppointment: ['С записью в консульство', 'With apointment in consulate'],
  withoutAppointment: [
    'Без записи в консульство',
    'Without apointment in consulate',
  ],
  amount: ['Сумма к оплате:', 'Ammount to pay'],
  paymentVariant: ['Выберите способ оплаты:', 'Choose the payment method:'],
  card: ['Международная банковская карта', 'Pay with card'],
  paymentDetail: [
    'Оплата российскими банковскими картами, с использованием системы PayPal или в криптовалюте на сайте не производится, но возможна по запросу.',
    'Payment by Russian bank cards, using PayPal, or in cryptocurrency is not available on the website, but can be arranged upon request.',
  ],
  goToPayment: ['Перейти к оплате', 'Proceed to payment.'],
  applicationSubmitted: [
    'Заявка успешно отправлена!',
    'The application has been successfully submitted!',
  ],
  applicationSubmittedText1: [
    'В ближайшее время наши юристы свяжутся с вами по указанному вами номеру телефона.',
    'Our lawyers will contact you soon at the phone number you provided.',
  ],
  applicationSubmittedText2: [
    'Информация с логином и паролем к вашему личному кабинету отправлена на указанную вами почту.',
    'The information with the login and password to your personal account has been sent to the email you provided.',
  ],
  applicationSubmittedText3: ['Хорошего дня!', 'Have a good day!'],
  perfect: ['Отлично!', 'Perfect!'],
  autorizeToFollow: [
    'Авторизируйтесь для того, чтобы продолжить',
    'Log in to continue',
  ],
  nameISRequired: ['Укажите, пожалуйста, имя', 'Name is required'],
  phoneNumberIsRequired: ['Укажите телефон', 'Email is required'],
  invalidPhoneNumberFormat: [
    'Неверный формат телефона',
    'Invalid phone number format',
  ],
  emailIsRequired: ['Укажите электронную почту', 'Email is required'],
  invalidEmailAddress: [
    'Неверный адрес электронной почты',
    'Invalid email address',
  ],
  required: ['Обязательное поле', 'Required'],
  paymentMade1: ['Платеж был ', 'Payment was'],
  paymentMade2: ['успешно выполнен.', ' successfully made. '],
  paymentMade3: [
    ' Спасибо за оплату наших услуг!',
    'Thank you for paying for our services!',
  ],
}
export default vocabularyWorkflow
