import vocabulary from 'vocabulary/vocabularyConsent'
import { createStepSchema } from 'pages/helpers/workflowStepSchema'

export const requestAdditionalInformation = langSelected => {
  const { title, additionalInfo } =
    vocabulary.workflow.requestAdditionalInformation

  return createStepSchema(
    title[langSelected],
    [
      {
        name: 'serviceDetails?.comment',
        type: 'text-field',
        componentProps: {
          placeholder: additionalInfo[langSelected],
        },
      },
    ],
    ({ serviceDetails }) => ({
      serviceDetails: { comment: serviceDetails?.comment },
    }),
  )
}
