import * as React from 'react'
import { Dialog } from '@mui/material/'

import { v4 as uuidv4 } from 'uuid'

import GlobalContext from '../../../GlobalContext'
import FirstScreenUserFlowMain from './FirstScreenUserFlowMain'
import CountrySelection from './CountrySelection'
import ThirdScreenUserFlowMain from './ThirdScreenUserFlowMain'
import { useTheme } from '@mui/material/styles'

export default function WorkFlowMain({ smallScreen }) {
  const { GlobalActions, state } = React.useContext(GlobalContext)
  const [dialogOpen, setDialogOpen] = React.useState('first')

  React.useEffect(() => {
    setDialogOpen('first')
  }, [state.userFlowMainOpenScreen])

  const setNextWindow = data => {
    setDialogOpen(data)
  }

  const theme = useTheme()

  const primaryColorRGB = theme.palette.primary.main.substring(1)
  const backgroundColorWithOpacity = `rgba(${parseInt(primaryColorRGB.substring(0, 2), 16)}, ${parseInt(primaryColorRGB.substring(2, 4), 16)}, ${parseInt(primaryColorRGB.substring(4, 6), 16)}, 0.5)`

  const handleClose = () => {
    GlobalActions.userFlowMainOpen(false)
  }

  const NoTransition = React.forwardRef(({ children }, ref) => {
    return React.cloneElement(children, { ref, tabIndex: -1 })
  })

  return (
    <React.Fragment key={uuidv4()}>
      <Dialog
        fullScreen={smallScreen ? true : false}
        open={state.userFlowMainOpenScreen}
        onClose={handleClose}
        TransitionComponent={NoTransition}
        keepMounted
        hideBackdrop
        sx={{
          '& .MuiPaper-root': {
            height: smallScreen ? null : 850,
            width: smallScreen ? null : 550,
          },
          '& .MuiDialog-container': {
            backgroundColor: backgroundColorWithOpacity,
          },
        }}
      >
        {dialogOpen === 'first' ? (
          <FirstScreenUserFlowMain smallScreen={smallScreen} />
        ) : null}
        {dialogOpen === 'second' ? (
          <CountrySelection
            setNextWindow={setNextWindow}
            nexwindow={dialogOpen}
          />
        ) : null}
        {dialogOpen === 'third' ? (
          <ThirdScreenUserFlowMain setNextWindow={setNextWindow} />
        ) : null}
      </Dialog>
    </React.Fragment>
  )
}
