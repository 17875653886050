import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  MenuItem,
  InputAdornment,
  TextField,
  Menu,
} from '@mui/material/'
import { User, Globe } from '@phosphor-icons/react'
import { TranslateOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import GlobalContext from 'GlobalContext'
import vocabulary from 'vocabulary/vocabulary'
import LoginFlow from '../LoginFlow/LoginFlow'
import { useNavigate } from 'react-router-dom'
import { useLoginFlowOpen } from 'pages/helpers/useLoginFlow'
import { changeUserIp, postUserIp } from 'api/getIp'
import { SearchInput } from '../Search/SearchInput.desktop'

export default function HeaderDesktop({ resolution }) {
  const { langSelected, GlobalActions, state, isLoggedIn } =
    useContext(GlobalContext)
  const [countrySelected, setCountrySelected] = useState(""
    //vocabulary.countries[1][langSelected],
  )
  const [anchorEl, setAnchorEl] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const navigate = useNavigate()

  const menuId = 'primary-search-account-menu'
  const mobileMenuId = 'primary-search-account-menu-mobile'

  const handleChangeCountry = async event => {
    const selectedCountry = event.target.value
    setCountrySelected(selectedCountry)

    const index = vocabulary.countries.findIndex(
      item => item[langSelected] === selectedCountry,
    )
    if (index !== -1) {
      await GlobalActions.setCountry(selectedCountry)
      changeUserIp({ country: vocabulary.countries[index][1] })
    }
  }

  const countryRequest = async () => {
    let returnedCountry = await postUserIp()
    let index = vocabulary.countries.findIndex(country =>
      country.includes(returnedCountry),
    )

    if (index !== -1) {
      setCountrySelected(vocabulary.countries[index][langSelected])
GlobalActions.setCountry(returnedCountry)

    }
  }

  useEffect(() => {
    countryRequest()

    const index = vocabulary.countries.findIndex(
      country => country[langSelected] === countrySelected,
    )

    if (index === -1) {
      setCountrySelected(vocabulary.countries[0][langSelected])
    }
  }, [langSelected, countrySelected])

  const filteredCountries = vocabulary.countries.filter(country =>
    country[langSelected].toLowerCase().includes(searchQuery.toLowerCase()),
  )

  if (langSelected !== 0) {
    filteredCountries.sort((a, b) => {
      if (a[langSelected].toLowerCase() < b[langSelected].toLowerCase())
        return -1
      if (a[langSelected].toLowerCase() > b[langSelected].toLowerCase())
        return 1
      return 0
    })
  }

  const theme = useTheme()

  const handleLanguageMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const openLoginWindow = useLoginFlowOpen('login')

  return (
    <Box
      sx={{
        position: 'fixed',
        top: '0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width:
          state.menuSelected === 2 && resolution === '1439'
            ? `calc(100% - 300px)`
            : 1,
        height: '104px',
        zIndex: 100,
        backgroundColor: theme.palette.primary.surface,
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{width:"35%"}}>
      <SearchInput />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          mr: '90px',
        }}
      >
        <TextField
          value={countrySelected}
          onChange={handleChangeCountry}
          sx={{
            minWidth: 244,
            height: 56,
            mr: '16px',
            borderRadius: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
            },
          }}
          variant='outlined'
          margin='dense'
          label={vocabulary.nameCountry[langSelected]}
          select
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 200,
                },
              },
            },
            renderValue: selected => selected || searchQuery,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Globe size={24} weight='light' />
              </InputAdornment>
            ),
          }}
        >
          {filteredCountries.map((country, index) => (
            <MenuItem
              key={index}
              sx={{ display: 'flex', alignItems: 'center' }}
              value={country[langSelected]}
            >
              {country[langSelected]}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          sx={{ minWidth: 164, height: 56, mr: '16px', borderRadius: 'none' }}
          value={langSelected}
          onChange={event => {
            GlobalActions.setLang(event.target.value)
          }}
          variant='outlined'
          margin='dense'
          select
          InputProps={{
            sx: {
              borderRadius: '5px',
            },
            startAdornment: (
              <InputAdornment position='start'>
                <TranslateOutlined />
              </InputAdornment>
            ),
          }}
        >
          <MenuItem value={0}>Russian</MenuItem>
          <MenuItem value={1}>English</MenuItem>
        </TextField>

        {resolution !== '947' && (
          <IconButton
            edge='end'
            aria-label='account of current user'
            aria-controls={menuId}
            aria-haspopup='true'
            color='inherit'
            sx={{ opacity: 0.7, minWidth: 48 }}
            onClick={handleLanguageMenuOpen}
          >
            <TranslateOutlined />
          </IconButton>
        )}

        <IconButton
          aria-label='show more'
          aria-controls={mobileMenuId}
          aria-haspopup='true'
          color='inherit'
          sx={{ opacity: 0.7, minWidth: 40 }}
          onClick={() => {
            if (!isLoggedIn) {
              openLoginWindow()
            } else {
              navigate('/home')
            }
          }}
        >
          <User />
        </IconButton>
      </Box>
      <LoginFlow />
    </Box>
  )
}
