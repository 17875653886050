/* eslint-disable no-restricted-globals */
import * as React from 'react'
import Box from '@mui/material/Box'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import { useTheme } from '@mui/material/styles'
import { ChatText } from '@phosphor-icons/react'
import { TelegramLogo, WhatsappLogo, Robot } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { Chat } from 'pages/components/Chat/Chat'

export default function SpeedDialButton() {
  const navigate = useNavigate()

  const actions = [
    {
      icon: (
        <Robot
          size={32}
          onClick={() => {
            navigate(`${location.pathname}?open=chat`)
          }}
        />
      ),
      name: 'Chat',
    },
    {
      icon: (
        <TelegramLogo
          size={32}
          onClick={() => {
            window.location.href = process.env.REACT_APP_TELEGRAM_CONNECTION
          }}
        />
      ),
      name: 'Telegram',
    },
    {
      icon: (
        <WhatsappLogo
          size={32}
          onClick={() => {
            window.location.href = process.env.REACT_APP_WHATSAPP_CONNECTION
          }}
        />
      ),
      name: 'WhatspApp',
    },
  ]

  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: '30px',
          right: '30px',
          width: '56px',
          padding: '15px',
          borderRadius: 0,
          zIndex: '11',
        }}
      >
        <SpeedDial
          ariaLabel='SpeedDial basic example'
          FabProps={{
            style: {
              backgroundColor: theme.palette.primary.primaryContainer,
              borderRadius: '15px',
            },
          }}
          sx={{
            position: 'absolute',
            backgroundColor: 'transparent',
            bottom: 16,
            right: 16,
          }}
          icon={
            <ChatText
              color={theme.palette.primary.onPrimaryContainer}
              size={32}
              weight='duotone'
            />
          }
        >
          {actions.map(action => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </SpeedDial>
      </Box>
      <Chat />
    </>
  )
}
