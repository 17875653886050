import starImage from '../images/star.png'
import shieldImage from '../images/shield.png'
import handsImage from '../images/hands.png'
import clockImage from '../images/clock.png'

const vocabularyAboutUs = {
  //About us
  aboutUsSection: ['o нас', 'about us'],
  aboutUsSection1: [
    'Consul online - это объединение профессионалов единомышленников, международных юристов с опытом работы российскими консульскими учреждениями и с иностранными правовыми организациями.',
    'Consul.online is an association of like-minded professionals, international lawyers with experience working with Russian consular institutions and foreign legal organizations.',
  ],
  aboutUsSection2: [
    'Наши юристы подготовят документы в том формате, который требуется для подачи в консульство или посольство РФ,  а также учитывает требования тех российских организаций, в которых эти документы впоследствии могут быть предоставлены.',
    ' Our lawyers will prepare documents in the format required for submission to the Russian consulate or embassy, taking into account the requirements of those Russian organizations to which these documents may subsequently be submitted.',
  ],
  aboutUsSection3: [
    'Мы работаем за пределами России и понимаем с какими проблемами сталкиваются граждане России за рубежом.',
    'We operate outside of Russia and understand the challenges faced by Russian citizens abroad.',
  ],
  aboutUsSection4: [
    'Этот проект создан для помощи нашим соотечественникам, проживающим по всему миру, а также гражданам других стран желающих получить юридическую услугу в рамках российского законодательства на территории иностранных государств без необходимости посещения РФ.',
    'This project is created to assist our compatriots living around the world, as well as citizens of other countries who wish to obtain legal services within the framework of Russian legislation in foreign countries without the need to visit Russia.',
  ],
  whyChooseUs: ['Почему выбирают нас?', 'Why choose us?'],
  serviceProcess: [
    [
      {
        headerText: 'Профессионализм',
        cardText:
          '15 лет опыта работы в юриспруденции, включая взаимодействие с Российскими консульствами',
        about: true,
        cardImage: starImage,
      },
      {
        headerText: 'Доверие',
        cardText:
          'Мы строго соблюдаем требования законодательства по работе с персональными данными.',
        about: true,
        cardImage: shieldImage,
        details: 'Подробнее',
      },

      {
        headerText: 'Ответственность',
        cardText:
          'В случае отказа в приёме документов в консульстве мы вернём вам деньги или повторно окажем услугу бесплатно',
        about: true,
        cardImage: handsImage,
        // details: 'Подробнее',
      },
      {
        headerText: 'Оперативность',
        cardText: 'Срок подготовки документа — 2 рабочих дня с момента оплаты',
        about: true,
        cardImage: clockImage,
      },
    ],
    [
      {
        headerText: 'Professionalism',
        cardText:
          '15 years of experience in jurisprudence, including interaction with Russian consulates',
        about: true,
        cardImage: starImage,
      },
      {
        headerText: 'Trust',
        cardText:
          'We strictly comply with the requirements of legislation regarding the handling of personal data.',
        about: true,
        cardImage: shieldImage,
        details: 'Details',
      },
      {
        headerText: 'Responsible approach',
        cardText:
          'In case of refusal to accept documents at the consulate, we will refund your money or provide the service again free of charge',
        about: true,
        cardImage: handsImage,
        //  details: 'Details',
      },
      {
        headerText: 'Efficiency',
        cardText:
          'Document preparation time - is 2 business days from the moment of payment',
        about: true,
        cardImage: clockImage,
      },
    ],
  ],
  ourMission: [
    'Наша миссия — упростить процесс получения российских государственных услуг за пределами России.',
    'Our mission is to simplify the process of obtaining Russian government services outside of Russia.',
  ],
  faq: ['Часто задаваемые вопросы', 'Frequency asked question'],
  faqDesc: [
    [
      {
        question: 'В каких странах вы оказываете свои услуги?',
        answer:
          'Мы оказываем наши услуги во всех странах мира, где есть действующее консульство/посольство России.',
      },
      {
        question: 'Какие услуги вы предоставляете?',
        answer:
          'Мы оказываем услуги по подготовке документов для подачи в Российские консульства/ посольства, включая оформление нотариальных документов, загранпаспортов, гражданства России, проверку принадлежности к гражданству РФ, получение справок и услуг ЗАГС',
      },
      {
        question:
          'Я уже записался в консульство, но не успел подготовить проект документа, вы можете мне помочь?',
        answer:
          'Да, мы подготовим проект нотариального документа и разместим его на сайте нужного вам консульства.',
      },
      {
        question:
          'У меня все готово, мне нужна только запись в консульство, можете записать?',
        answer:
          'Нет, мы не оказываем отдельной услуги по записи в консульство / посольство России',
      },
      {
        question:
          'Я уже подготовил все документы, можете ли вы мне помочь с их проверкой и взаимодействием с консульством?',
        answer:
          'Да, конечно, мы проверим подготовленный вами проект документа, при необходимости внесём исправления, разместим его на сайте нужного вам консульства и постараемся помочь с записью на приём.',
      },
      {
        question: 'Как вы принимаете оплату за услуги?',
        answer:
          'Любую услугу можно оплатить онлайн на сайте. Оплата принимается международными банковскими картами, а также с помощью Apple Pay и Google Pay. Оплата российскими банковскими картами, с использованием системы PayPal или в криптовалюте на сайте не производится, но возможна по запросу.',
      },
    ],
    [
      {
        question: 'In which countries do you provide your services?',
        answer:
          'We provide our services in all countries where there is an active Russian consulate/embassy.',
      },
      {
        question: 'What services do you offer?',
        answer:
          'We provide services for preparing documents for submission to Russian consulates/embassies, including notarized documents, passports, Russian citizenship, verification of Russian citizenship, obtaining certificates and services from the Registrar.',
      },
      {
        question:
          "I have already scheduled an appointment at the consulate, but I haven't had time to prepare the document draft. Can you help me?",
        answer:
          'Yes, we will prepare a draft notarial document and publish it on the website of the consulate you need.',
      },
      {
        question:
          'I have everything ready, I just need an appointment at the consulate, can you schedule it?',
        answer:
          'No, we do not provide a separate service for scheduling appointments at the Russian consulate/embassy.',
      },
      {
        question:
          'I have prepared all the documents, can you help me with their verification and interaction with the consulate?',
        answer:
          'Yes, of course, we will check the document draft prepared by you, make corrections if necessary, publish it on the website of the consulate you need, and try to help with scheduling an appointment.',
      },
      {
        question: 'How do you accept payment for services?',
        answer:
          'Any service can be paid online on the website. Payment is accepted by international bank cards, as well as with Apple Pay and Google Pay. Payment with Russian bank cards, PayPal, or in cryptocurrency is not processed on the website, but is possible upon request.',
      },
    ],
  ],
  askQuestion: ['Задать вопрос', 'Ask a question'],
}
export default vocabularyAboutUs
