import documentBrown from '../images/document-brown.png'
import buildingImage from '../images/building.png'
import marriageRingsImage from '../images/marriage.png'
import courtImage from '../images/court.png'
import balanceImage from '../images/balance.png'
import passportImage from '../images/passport.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'

const vocabulary = {
  //home screen (1 element Russian, 2 element English)

  siteName: ['Консульский юрист', 'CONSUL ONLINE'],
  slogan1: [
    'Услуги по подготовке документов онлайн  ',
    'Online document preparation services for',
  ],
  slogan2: [
    'для подачи в консульства России',
    'submission to Russian consulates.',
  ],
  ourServices: ['наши услуги', 'our services'],
  aboutUs1: [
    'Консульский юрист online — это правовая помощь гражданам России и иностранным гражданам в оформлении документов, необходимых для подачи в Российские консульские учреждения за рубежом',
    'Online CONSUL ONLINE is legal assistance to citizens of Russia and foreign citizens in preparing documents required for submission to Russian consular offices abroad',
  ],
  aboutUs2: [
    'Наша миссия — упростить процесс получения российских государственных услуг за пределами России',
    'Our mission is to simplify the process of obtaining Russian government services outside of Russia.',
  ],
  serviceObtaining: ['процесс получения услуги', 'Service obtaining process'],
  //menu header
  nameCountry: ['Страна', 'Country'],
  search: ['Поиск', 'Search'],
  countries: [
    ['Абхазия', 'Abkhazia'],
    ['Австралия', 'Australia'],
    ['Австрия', 'Austria'],
    ['Азербайджан', 'Azerbaijan'],
    ['Албания', 'Albania'],
    ['Алжир', 'Algeria'],
    ['Ангола', 'Angola'],
    ['Аргентина', 'Argentina'],
    ['Армения', 'Armenia'],
    ['Афганистан', 'Afghanistan'],
    ['Бангладеш', 'Bangladesh'],
    ['Бахрейн', 'Bahrain'],
    ['Беларусь', 'Belarus'],
    ['Бельгия', 'Belgium'],
    ['Бенин', 'Benin'],
    ['Болгария', 'Bulgaria'],
    ['Боливия', 'Bolivia'],
    ['Босния и Герцеговина', 'Bosnia and Herzegovina'],
    ['Ботсвана', 'Botswana'],
    ['Бразилия', 'Brazil'],
    ['Бруней', 'Brunei'],
    ['Бурунди', 'Burundi'],
    ['Ватикан', 'Vatican City'],
    ['Великобритания', 'United Kingdom'],
    ['Венгрия', 'Hungary'],
    ['Венесуэла', 'Venezuela'],
    ['Вьетнам', 'Vietnam'],
    ['Габон', 'Gabon'],
    ['Гайана', 'Guyana'],
    ['Гана', 'Ghana'],
    ['Гватемала', 'Guatemala'],
    ['Гвинея', 'Guinea'],
    ['Гвинея-Бисау', 'Guinea-Bissau'],
    ['Греция', 'Greece'],
    ['Грузия', 'Georgia'],
    ['Дания', 'Denmark'],
    ['Джибути', 'Djibouti'],
    ['Египет', 'Egypt'],
    ['Замбия', 'Zambia'],
    ['Зимбабве', 'Zimbabwe'],
    ['Израиль', 'Israel'],
    ['Индия', 'India'],
    ['Индонезия', 'Indonesia'],
    ['Иордания', 'Jordan'],
    ['Ирак', 'Iraq'],
    ['Иран', 'Iran'],
    ['Ирландия', 'Ireland'],
    ['Исландия', 'Iceland'],
    ['Испания', 'Spain'],
    ['Италия', 'Italy'],
    ['Йеменская Республика', 'Yemen'],
    ['Кабо-Верде', 'Cape Verde'],
    ['Казахстан', 'Kazakhstan'],
    ['Камбоджа', 'Cambodia'],
    ['Камерун', 'Cameroon'],
    ['Канада', 'Canada'],
    ['Катар', 'Qatar'],
    ['Кения', 'Kenya'],
    ['Кипр', 'Cyprus'],
    ['Киргизия (Кыргызстан)', 'Kyrgyzstan'],
    ['Китай', 'China'],
    ['КНДР', 'North Korea'],
    ['Колумбия', 'Colombia'],
    ['Конго, Демократическая Республика', 'Democratic Republic of the Congo'],
    ['Конго, Республика', 'Republic of the Congo'],
    ['Коста-Рика', 'Costa Rica'],
    ['Кот-д’Ивуар', 'Ivory Coast'],
    ['Куба', 'Cuba'],
    ['Кувейт', 'Kuwait'],
    ['Лаос', 'Laos'],
    ['Латвия', 'Latvia'],
    ['Ливан', 'Lebanon'],
    ['Ливия', 'Libya'],
    ['Литва', 'Lithuania'],
    ['Люксембург', 'Luxembourg'],
    ['Маврикий', 'Mauritius'],
    ['Мавритания', 'Mauritania'],
    ['Мадагаскар', 'Madagascar'],
    ['Малайзия', 'Malaysia'],
    ['Мали', 'Mali'],
    ['Мальта', 'Malta'],
    ['Марокко', 'Morocco'],
    ['Мексика', 'Mexico'],
    ['Мозамбик', 'Mozambique'],
    ['Молдавия (Молдова)', 'Moldova'],
    ['Монголия', 'Mongolia'],
    ['Мьянма', 'Myanmar'],
    ['Намибия', 'Namibia'],
    ['Непал', 'Nepal'],
    ['Нигерия', 'Nigeria'],
    ['Нидерланды', 'Netherlands'],
    ['Никарагуа', 'Nicaragua'],
    ['Новая Зеландия', 'New Zealand'],
    ['Норвегия', 'Norway'],
    ['ОАЭ', 'United Arab Emirates'],
    ['Оман', 'Oman'],
    ['Пакистан', 'Pakistan'],
    ['Палестина', 'Palestine'],
    ['Панама', 'Panama'],
    ['Перу', 'Peru'],
    ['Польша', 'Poland'],
    ['Португалия', 'Portugal'],
    ['Руанда', 'Rwanda'],
    ['Россия', 'Russia'],
    ['Румыния', 'Romania'],
    ['Саудовская Аравия', 'Saudi Arabia'],
    ['Северная Македония', 'North Macedonia'],
    ['Сейшельские острова', 'Seychelles'],
    ['Сенегал', 'Senegal'],
    ['Сербия', 'Serbia'],
    ['Сингапур', 'Singapore'],
    ['Сирия', 'Syria'],
    ['Словакия', 'Slovakia'],
    ['Словения', 'Slovenia'],
    ['Судан', 'Sudan'],
    ['США', 'United States'],
    ['Таджикистан', 'Tajikistan'],
    ['Таиланд', 'Thailand'],
    ['Тайвань (Китай)', 'Taiwan'],
    ['Танзания', 'Tanzania'],
    ['Тунис', 'Tunisia'],
    ['Туркменистан', 'Turkmenistan'],
    ['Турция', 'Turkey'],
    ['Уганда', 'Uganda'],
    ['Узбекистан', 'Uzbekistan'],
    ['Украина', 'Ukraine'],
    ['Уругвай', 'Uruguay'],
    ['Филиппины', 'Philippines'],
    ['Финляндия', 'Finland'],
    ['Франция', 'France'],
    ['Хорватия', 'Croatia'],
    ['ЦАР', 'Central African Republic'],
    ['Чад', 'Chad'],
    ['Черногория', 'Montenegro'],
    ['Чехия', 'Czech Republic'],
    ['Чили', 'Chile'],
    ['Швейцария', 'Switzerland'],
    ['Швеция', 'Sweden'],
    ['Шри-Ланка', 'Sri Lanka'],
    ['Эквадор', 'Ecuador'],
    ['Эритрея', 'Eritrea'],
    ['Эстония', 'Estonia'],
    ['Эфиопия', 'Ethiopia'],
    ['ЮАР', 'South Africa'],
    ['Южная Корея', 'South Korea'],
    ['Ямайка', 'Jamaica'],
    ['Япония', 'Japan'],
  ],

  selectCountry: ['Выберите страну...', 'Select country...'],

  serviceData: [
    [
      //Russian
      {
        headerText: 'Доверенности',
        cardText:
          'Генеральная, на покупку-продажу недвижимости, на транспортное средство, на получение пенсии и др.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Загранпаспорт',
        cardText:
          'Подготовка заявления на оформление, консультация по перечню документов',
        cardImage: passportImage,
      },
      {
        headerText: 'Согласия, заявления, договоры',
        cardText: 'На выезд ребёнка, на продажу недвижимости для супруга и др.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Справки',
        cardText:
          'Об отсутствии судимости, для заключения брака с иностранцем, истребование документов и др.',
        cardImage: stampImage,
      },
      {
        headerText: 'Документы для юридических лиц',
        cardText: 'Доверенности, заявления для юридических лиц и др.',
        cardImage: buildingImage,
      },
      {
        headerText: 'Гражданство',
        cardText:
          'Оформление, в том числе для ребёнка, рождённого за рубежом, отказ от гражданства РФ и др.',
        cardImage: passportImage,
      },

      {
        headerText: 'Услуги ЗАГС',
        cardText:
          'Регистрация брака, расторжение брака, регистрация рождения ребенка, установление отцовства и др.',
        cardImage: marriageRingsImage,
      },
      {
        headerText: 'Другие консульские услуги',
        cardText:
          'Свидетельство о возвращении в Россию, легализация документов и др.',
        cardImage: courtImage,
      },
      {
        headerText: 'Консультационные услуги',
        cardText:
          'По вашему вопросу, в рамках законодательства России на территории иностранных государств',
        cardImage: balanceImage,
      },
    ],
    [
      {
        headerText: 'Power of attorney',
        cardText:
          'General, for real estate purchase and sale, for vehicles, for pension acquisition, and others',
        cardImage: documentBrown,
      },
      {
        headerText: 'Passport',
        cardText:
          'Preparation of application for registration, consultation on the list of documents',
        cardImage: passportImage,
      },
      {
        headerText: 'Consents, applications, contracts.',
        cardText:
          "For child's departure, for sale of real estate for spouse, etc",
        cardImage: documentBrown,
      },
      {
        headerText: 'Certificates',
        cardText:
          'About the absence of a criminal record, for marriage with a foreigner, requesting documents, etc.',
        cardImage: stampImage,
      },
      {
        headerText: 'Documents for legal entities',
        cardText:
          'Regarding absence of convictions, for marriage with a foreigner, about marital status, etc',
        cardImage: buildingImage,
      },
      {
        headerText: 'Citizenship',
        cardText:
          'Processing, including for a child born abroad, renunciation of Russian citizenship, etc',
        cardImage: passportImage,
      },

      {
        headerText: 'Registry Office Services',
        cardText:
          'Marriage registration, divorce, birth registration of a child, establishment of paternity, etc.',
        cardImage: marriageRingsImage,
      },
      {
        headerText: 'Additional consular services',
        cardText:
          'Certificate of return to Russia, legalization of documents, etc.',
        cardImage: courtImage,
      },
      {
        headerText: 'Consulting services',
        cardText:
          'Regarding your question, within the framework of Russian legislation on the territory of foreign countries',
        cardImage: balanceImage,
      },
    ],
  ],
  serviceProcess: [
    [
      {
        headerText: 'Оформление заявки',
        cardText:
          'Выберите нужную услугу на сайте, оформите заявку и предоставьте требуемый список документов',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Подготовка текста документа',
        cardText:
          'Наш юрист свяжется c вами, подготовит документ и разместит  на портале КД МИД России',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью',
        cardImage: calendarImage,
      },
      {
        headerText: 'Получение готового документа',
        cardText:
          'В назначенный день приходите в консульство для оформления документа',
        cardImage: stampImage,
      },
    ],

    [
      {
        headerText: 'Application processing',
        cardText:
          'Select the required service on the website, submit an application, and provide the necessary list of documents',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparation of document text',
        cardText:
          'Our lawyer will contact you, prepare the document, and publish it on the portal of the Consular Department of the Ministry of Foreign Affairs of Russia',
        cardImage: documentBrown,
      },
      {
        headerText: 'Appointment scheduling at the consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance',
        cardImage: calendarImage,
      },
      {
        headerText: 'Receiving the finalized document',
        cardText:
          'On the appointed day, come to the consulate to process the document',
        cardImage: stampImage,
      },
    ],
  ],
  processingTiming: [
    'Срок подготовки документа:',
    'Document preparation time:',
  ],
  twoDays: [
    '2 рабочих дня с момента оплаты',
    '2 business days from the moment of payment',
  ],
  paymentsMethods: ['Способы оплаты:', 'Payment methods:'],
  possiblePayRussian: [
    'По запросу также возможна оплата российскими картами.',
    'Payment with Russian cards is also possible upon request.',
  ],
  applicationSubmit: ['Оформить заявку', 'Submit an application'],
  submit: ['Оформить', 'Submit'],
  ask: ['Задать вопрос', 'Ask question'],
  ////Drawer Left
  listDrawerLeft: [
    [
      'Главная',
      'О нас',
      'Услуги',
      'Запись в консульство',
      'Связаться с нами',
      'Часто задаваемые вопросы',
      'Цены',
    ],
    [
      'Main',
      'About Us',
      'Services',
      'Consulate Appointment',
      'Contact Us',
      'Frequently Asked Questions',
      'Prices',
    ],
  ],
  listDrawerLeftSubMenu: [
    [
      'Доверенности',
      'Загранпаспорт',
      'Согласия, заявления, договоры',
      'Справки',
      'Документы для юр. лиц',
      'Гражданство',
      'Услуги ЗАГС',
      'Другие консульские услуги',
      'Консультационные услуги',
    ],
    [
      'Powers of Attorney',
      'Passport (foreign)',
      'Consents, Statements, Contracts',
      'Certificates',
      'Documents for Legal Entities',
      'Citizenship',
      'Registry Office Services',
      'Other Consular Services',
      'Consulting Services',
    ],
  ],
  footerList: [
    [
      'О нас',

      'Запись на приём в консульство',

      'Вопросы',
      'Цены',
      'Пользовательское соглашение',

      'Политика использования cookies',
      'Политика конфиденциальности',
    ],
    [
      'About us',

      'Appointment at the consulate',

      'Questions',
      'Price',

      'User Agreement',
      'Cookies Policy',
      'Privacy Policy',
    ],
  ],
  //About us
  aboutUsSection: ['o нас', 'about us'],
  aboutUsSection1: [
    'Consul online - это объединение профессионалов единомышленников, международных юристов с опытом работы российскими консульскими учреждениями и с иностранными правовыми организациями.',
    'Consul.online is an association of like-minded professionals, international lawyers with experience working with Russian consular institutions and foreign legal organizations.',
  ],
  aboutUsSection2: [
    'Наши юристы подготовят документы в том формате, который требуется для подачи в консульство или посольство РФ,  а также учитывает требования тех российских организаций, в которых эти документы впоследствии могут быть предоставлены.',
    ' Our lawyers will prepare documents in the format required for submission to the Russian consulate or embassy, taking into account the requirements of those Russian organizations to which these documents may subsequently be submitted.',
  ],
  aboutUsSection3: [
    'Мы работаем за пределами России и понимаем с какими проблемами сталкиваются граждане России за рубежом.',
    'We operate outside of Russia and understand the challenges faced by Russian citizens abroad.',
  ],
  aboutUsSection4: [
    'Этот проект создан для помощи нашим соотечественникам, проживающим по всему миру, а также гражданам других стран желающих получить юридическую услугу в рамках российского законодательства на территории иностранных государств без необходимости посещения РФ.',
    'This project is created to assist our compatriots living around the world, as well as citizens of other countries who wish to obtain legal services within the framework of Russian legislation in foreign countries without the need to visit Russia.',
  ],
  whyChooseUs: ['Почему выбирают нас?', 'Why choose us?'],

  enter: ['Войти', 'Enter'],
  orWith: ['Или с помощью', 'Or with'],
  email: ['Электронная почта', 'E-mail'],
  enterPassword: ['Введите пароль', 'Enter password'],
  remember: ['Запомнить', 'Remember me'],
  forgetPassword: ['Забыли пароль?', 'Forget password ?'],
  dontRegister: ['Еще не зарегистрированы?', 'Not registred?'],
  signUp: ['Зарегистрироваться', 'Sign up'],
  sent: ['запрос отправлен', 'request sent'],
  regester: ['Регистрация', 'Sign up'],
  passwordRequirements: [
    'Минимум 8 символов, включая цифры и специальные символы',
    'Minimum 8 characters, including numbers and special characters',
  ],
  consent: [
    'Я даю своё согласие на обработку персональных данных и соглашаюсь с условиями политики конфиденциальности',
    'I give my consent to the processing of personal data and agree to the terms of the privacy policy',
  ],
  registred: ['Уже зарегистрированы?', 'Already registred ?'],

  forgetPasswordText: [
    'Укажите адрес электронной почты, на который мы вышлем вам инструкцию для восстановления пароля',
    'Enter your email address to which we will send you instructions for password recovery',
  ],
  send: ['Отправить', 'Send'],
  rememberedPassword: ['Вспомнили пароль?', 'Is remeber password?'],
  checkYourMail: ['Проверьте свою почту', 'Check your email'],
  checkYourMailSent: [
    'Мы отправили вам на почту инструкцию по восстановлению пароля',
    'We have sent you instructions on how to reset your password by email.',
  ],
  understand: ['Понятно', 'I am undrestand'],
  errorConsent: [
    'Вы должны дать вам свое согласие',
    'You should give you your consent',
  ],
  errorCredentiales: ['Неверные учетные данные', 'Bad credentiales'],
  userExists: [
    'Пользователь существует, выберите другой адрес электронной почты',
    'User exists, choose another email',
  ],
  emailError: ['Электронная почта имеет плохой формат', 'Email is bad formed'],
  yes: ['Да', 'Yes'],
  no: ['Нет', 'No'],
  writeMessage: ['Введите сообщение...', 'Type a message...'],
  chatPlaceholder: [
    'Я ИИ, созданный для того, чтобы помочь вам с консульскими вопросами, связанными с документацией и подобными темами. Вы можете спросить у меня что угодно.',
    'I am an AI designed to help you with consular questions based on paperwork and similar topics. You can ask me whatever you want.',
  ],
  cookiePolicy: ['Политика использования файлов cookie', 'Cookie Policy'],
  termsOfUse: ['пользовательское соглашение', 'terms of use'],
  contactUs: [
    'Вы также можете связаться с нашими сотрудниками ',
    'You can also contact our employees',
  ],
  passwordMismatch: ['Несовпадение паролей', 'Password mismatch'],
  ready: ['Готовo', 'Ready'],
  inProcess: ['В процессе', 'In process'],
}

export default vocabulary
