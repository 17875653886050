import * as React from 'react'
import vocabulary, {
  certificateTypes,
  documentTypes,
} from 'vocabulary/vocabularyCertificates'
import GlobalContext from 'GlobalContext'

import ServiceInfo from 'components/ServiceInfo/ServiceInfo'

export default function CertificateInfo() {
  const { state, GlobalActions } = React.useContext(GlobalContext)
  let serviceIndex = state.dialogService.index

  const onSubmit = event => {
    const step = getRequestStep(state.dataToSend.service)
    GlobalActions.setDialogServiceOpen({
      step,
    })
  }

  const handleClose = () => {
    GlobalActions.clearDataToSend()
  }

  const content = vocabulary.interactuation[serviceIndex]

  return (
    <ServiceInfo onClose={handleClose} onSubmit={onSubmit} content={content} />
  )
}

const isServiceType = (service, types) => {
  return types.some(([, type]) => service === type)
}

const getRequestStep = service => {
  const isCertificate = isServiceType(service, certificateTypes)

  if (isCertificate) {
    return 2 // certificates form
  }

  const isDocument = isServiceType(service, documentTypes)

  if (isDocument) {
    return 3 // documents form
  }

  return 0 // default step
}
