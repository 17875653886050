import { Divider, Box } from '@mui/material'
import { Typography } from '@mui/material'

export default function Faq599({ value, resolution }) {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {value?.map((val, index) => (
        <Box
          style={{
            backgroundColor: resolution === '819' ? '#F2F3F9' : null,
          }}
          key={index}
        >
          <Divider variant='large' sx={{ mb: '24px' }} />
          <Typography variant='labelLarge'>{val.question}</Typography>
          <Typography
            variant='bodySmall'
            component='div'
            sx={{ mb: '24px', mt: '12px' }}
          >
            {val.answer}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
