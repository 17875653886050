import Layout599 from '../Layouts/Layout599'
import { PrivacyPolicy } from 'pages/components/PrivacyPolicy/PrivacyPolicy'

export default function PrivacyPolicy599() {
  return (
    <Layout599>
      <PrivacyPolicy />
    </Layout599>
  )
}
