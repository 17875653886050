import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { createPayment } from 'api/workflow'
import { Box } from '@mui/material/'
import { useEffect, useState } from 'react'
import StripeForm from './StripeForm'
import GlobalContext from '../../../GlobalContext'

import * as React from 'react'

import crossClose from '../../../images/icon/cross-close.png'
/*const stripe = loadStripe(
  'pk_test_51NVD4hLG9Ky20DAilBrrBP0V1bExOVaAwkRoXjof1v2ayYGscYP5HxUeYMd41rJlC7Y2zJv2SAt9FMbkuJjsNDrJ006Q9mStlr',
)
*/

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PK)

export default function CheckoutForm(props) {
  const { langSelected, GlobalActions, state } = React.useContext(GlobalContext)

  const [clientSecret, setClientSecret] = useState(null)

  let initPayment = async data => {
    let secret = await createPayment(data)
    setClientSecret(secret)
  }

  const priceToShow = state.dataToSend.appointment
    ? props.price?.withAppointment
    : props.price?.withoutAppointment

  useEffect(() => {
    initPayment(parseInt(priceToShow))
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: props.height || '100%',
        flex: 1,
        m: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '76px',
        }}
      >
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            GlobalActions.setDialogServiceOpen(false)
            GlobalActions.clearDataToSend()
          }}
        >
          <img src={crossClose} alt='cross' width={'24px'} />
        </Box>
      </Box>

      {clientSecret !== null ? (
        <Elements
          stripe={stripe}
          options={{ clientSecret: clientSecret.client_secret }}
        >
          <StripeForm />
        </Elements>
      ) : null}
    </Box>
  )
}
