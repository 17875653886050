import HeaderMobile from '../components/Header/HeaderMobile'
import LeftDrawerSmallScreen from '../components/LeftDrawer/LeftDrawerSmallScreen'

import { useTheme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Footer600 from '../components/Footer/Footer600'
import SpeedDialButton from '../components/SpeedDialButton'
import WorkFlowMain from '../components/Workflow/WorkFlowMain'
import { Page } from 'components/Page/Page'

export default function Layout600({
  children,
  stateMenu,
  setStateMenu,
  langSelected,
  main,
}) {
  const theme = useTheme()

  return (
    <Page>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.primary.surface,
          pt: '10px',
          flexGrow: 1,
        }}
      >
        <LeftDrawerSmallScreen
          stateMenu={stateMenu}
          setStateMenu={setStateMenu}
          langSelected={langSelected}
        />

        <HeaderMobile displaySize='small' />
        <WorkFlowMain smallScreen={true} />
        {!main ? (
          <Box sx={{ mx: 2, mt: 4, minHeight: '70vh' }}>{children}</Box>
        ) : (
          <>{children}</>
        )}
        <SpeedDialButton />
        <Footer600 langSelected={langSelected} />
      </Box>
    </Page>
  )
}
