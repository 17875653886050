import { ThemeProvider } from '@mui/material/styles'
import { GlobalContextWrapper } from './GlobalContext'
import Paths from './Paths'
import theme from './theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DialogModalProvider } from './contexts/DialogModalContext'

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GlobalContextWrapper>
        <DialogModalProvider>
          <ThemeProvider theme={theme}>
            <Paths />
          </ThemeProvider>
        </DialogModalProvider>
      </GlobalContextWrapper>
    </LocalizationProvider>
  )
}

export default App
