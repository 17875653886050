import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  TextField,
  LinearProgress,
  Stack,
  Switch,
} from '@mui/material/'

import { useTheme } from '@mui/material/styles'
import GlobalContext from '../../../../GlobalContext'
import leftArrow from '../../../../images/icon/arrow-left.png'
import crossClose from '../../../../images/icon/cross-close.png'
import vocabularyWorkflow from '../../../../vocabulary/vocabularyWorkflow'
import vocabularyPowerOfAttorney from '../../../../vocabulary/vocabularyPowerOfAttorney'

export default function DialogPoaWorkflow5() {
  const theme = useTheme()
  const [selected, setSelected] = React.useState(false)
  const { langSelected, GlobalActions, state } = React.useContext(GlobalContext)
  const [checked, setChecked] = React.useState(true)

  const [whatGives, setWhatGives] = React.useState('')

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box sx={{ mx: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '76px',
          }}
        >
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              GlobalActions.setDialogServiceOpen({
                step: 4,
                index: state.dialogService.index,
              })
            }}
          >
            <img src={leftArrow} alt='left arrow' width={'24px'} />
          </Box>

          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              GlobalActions.setDialogServiceOpen(false)
              GlobalActions.clearDataToSend()
            }}
          >
            <img src={crossClose} alt='cross' width={'24px'} />
          </Box>
        </Box>

        <Typography component='p' variant='titleLarge' sx={{ mb: '32px' }}>
          {vocabularyPowerOfAttorney.whatTrust[langSelected]}
        </Typography>

        <TextField
          value={whatGives}
          placeholder={vocabularyPowerOfAttorney.describeService[langSelected]}
          label={vocabularyPowerOfAttorney.powers[langSelected]}
          InputLabelProps={{ shrink: true }}
          minRows='3'
          maxRows='3'
          onChange={event => {
            setSelected(true)
            setWhatGives(event.target.value)
          }}
          sx={{
            minWidth: 1,
            mt: 2,
            mr: '16px',

            borderRadius: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
              color: theme.palette.primary.onSurfaceVariant,
              opacity: 1,
            },
          }}
          variant='outlined'
          margin='dense'
          multiline
        ></TextField>
        <Typography component='p' sx={{ ml: '10px' }} variant='labelSmallLight'>
          {vocabularyPowerOfAttorney.powerAbove[langSelected]}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            justifyContent: 'space-between',
            mt: 4,
            alignItems: 'center',
          }}
        >
          <Typography variant='bodyLarge'>
            {vocabularyPowerOfAttorney.withAtorneyRights[langSelected]}
          </Typography>

          {console.log(checked)}

          <Switch
            checked={checked}
            sx={{
              width: 58,
              height: 38,
              backgroundColor: checked ? theme.palette.primary.primary : null,
              border: `2px solid ${theme.palette.primary.outlined}`,
              borderRadius: '20px',

              '.MuiSwitch-thumb': {
                transform: 'translateX(-6px) translateY(-6px)',
                backgroundColor: theme.palette.primary.outlined,
                width: 28,
                height: 28,
              },
              '.MuiSwitch-track': {
                height: 34,
                backgroundColor: theme.palette.primary.surfaceContainerHigh,
              },
              '& .Mui-checked': {
                transform: 'translateX(22px) translateY(-0px)',

                '.MuiSwitch-thumb': {
                  backgroundColor: '#fff',
                  '&::before': {
                    content: "''",
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    left: 0,
                    top: 0,

                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                      '#fff',
                    )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
                  },
                },
              },
            }}
            onChange={event => {
              setSelected(true)
              setChecked(event.target.checked)
            }}
          />
        </Box>
      </Box>
      <Box>
        <Stack>
          <LinearProgress
            color='progressBar'
            variant='determinate'
            value={50}
            sx={{ backgroundColor: theme.palette.primary.primaryContainer }}
          />
        </Stack>
        <Box
          sx={{
            pb: '16px',
            pt: '24px',
            mt: '8px',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            variant='text'
            size='medium'
            disabled={selected}
            sx={{ border: 'none', height: '40px' }}
            color='blueButton'
            onClick={() => {
              GlobalActions.setDialogServiceOpen({
                index: state.dialogService.index,
                step: 6,
              })
              GlobalActions.setDataToSend({
                serviceDetails: {
                  whatGives: whatGives,
                  transferRights: checked,
                  timePoa: null,
                  documentsUploaded: null,
                },
              })
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyWorkflow.skip[langSelected]}
            </Typography>
          </Button>

          <Button
            variant='text'
            size='medium'
            disabled={!selected}
            sx={{
              border: 'none',
              height: '40px',
              mr: '16px',
              '&.Mui-disabled': {
                border: 'none', // Remove border when the button is disabled
              },
            }}
            color='blueButton'
            onClick={() => {
              GlobalActions.setDialogServiceOpen({
                index: state.dialogService.index,
                step: 6,
              })
              GlobalActions.setDataToSend({
                serviceDetails: {
                  whatGives: whatGives,
                  transferRights: checked,
                  timePoa: null,
                  documentsUploaded: null,
                },
              })
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyWorkflow.forward[langSelected]}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
