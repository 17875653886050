import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyCertificates'

export const certificateOrDocument = langSelected => {
  const { title, options, additionalInfo } =
    vocabulary.workflow.certificateOrDocument

  return {
    step: 1,
    progress: 20,
    ...createStepSchema(
      title[langSelected],
      [
        {
          name: 'documentType',
          type: 'radio-with-additional-text',
          componentProps: {
            options: createOptions(options, langSelected),
            placeholder: additionalInfo[langSelected],
          },
        },
      ],
      dataToSend => ({
        documentType: {
          chosen: dataToSend.service,
          text: dataToSend.serviceDetails?.comment,
        },
      }),
      ({ documentType }) => ({
        service: documentType.chosen,
        serviceDetails:
          options.at(-1)[1] === documentType.chosen
            ? { comment: documentType.text }
            : {},
      }),
    ),
    nextStep: ({ service, serviceDetails }) => {
      switch (service) {
        // Certificate is chosen
        case options[0][1]:
          // Next step is to get a certificate type
          return 2
        // Document is chosen
        case options[1][1]:
          // Next step is to get an document type
          return 3
        // Unkown is chosen
        case options[2][1]:
          // Go to the final common steps
          return serviceDetails?.comment ? 6 : 5
        default:
          console.warn('Unknown document type is chosen')

          return 5
      }
    },
  }
}

export const requestCertificateType = langSelected => {
  const { title, options, additionalInfo } = vocabulary.workflow.certificateType

  return {
    step: 2,
    progress: 30,
    title: title[langSelected],
    schema: [
      {
        name: 'additionalServiceType',
        type: 'radio-with-additional-text',
        componentProps: {
          options: createOptions(options, langSelected),
          placeholder: additionalInfo[langSelected],
        },
      },
    ],
    getDefaultValues: dataToSend => {
      return {
        additionalServiceType: {
          chosen: dataToSend.additionalServiceType,
          text: dataToSend.details,
        },
      }
    },
    processFormValues: ({ additionalServiceType }) => ({
      additionalServiceType: additionalServiceType.chosen,
      additionalServiceType:
        options.at(-1)[1] === additionalServiceType.chosen
          ? { comment: additionalServiceType.text }
          : {},
    }),
    nextStep: ({ additionalServiceType, serviceDetails }) => {
      // Other is chosen
      if (
        options.at(-1)[1] === additionalServiceType &&
        serviceDetails?.comment
      ) {
        // Go to the final common steps
        return 6
      }

      // Additional information step
      return 5
    },
  }
}

export const requestDocumentType = langSelected => {
  const { title, options, additionalInfo, apostile } =
    vocabulary.workflow.documentType

  return {
    step: 3,
    progress: 30,
    title: title[langSelected],
    schema: [
      {
        name: 'additionalServiceType',
        type: 'radio-with-additional-text',
        componentProps: {
          options: createOptions(options, langSelected),
          placeholder: additionalInfo[langSelected],
        },
      },
      {
        name: 'apostile',
        type: 'switch',
        componentProps: {
          title: apostile[langSelected],
        },
      },
    ],
    getDefaultValues: dataToSend => ({
      additionalServiceType: {
        chosen: dataToSend.additionalServiceType,
        text: dataToSend.details,
      },
    }),
    processFormValues: ({ additionalServiceType, ...values }) => ({
      ...values,
      additionalServiceType: additionalServiceType.chosen,
      serviceDetails:
        options.at(-1)[1] === additionalServiceType.chosen
          ? { comment: additionalServiceType.text }
          : {},
    }),
    nextStep: ({ additionalServiceType, serviceDetails }) => {
      // document from civil registry is chosen
      if (options[0][1] === additionalServiceType) {
        return 4
      }

      // Other is chosen
      if (
        options.at(-1)[1] === additionalServiceType &&
        serviceDetails?.comment
      ) {
        // Go to the final common steps
        return 6
      }

      // Comments step
      return 5
    },
  }
}

export const requestCivilRegistryType = langSelected => {
  const { title, options } = vocabulary.workflow.civilRegistryType

  return {
    step: 4,
    progress: 50,
    title: title[langSelected],
    schema: [
      {
        name: 'serviceDetails.civilRegistryType',
        type: 'radio',
        componentProps: {
          options: createOptions(options, langSelected),
        },
      },
    ],
    getDefaultValues: ({ additionalServiceType }) => ({
      additionalServiceType,
    }),
    nextStep: 5,
  }
}

export const requestAdditionalInformation = langSelected => {
  const { title, additionalInfo } =
    vocabulary.workflow.requestAdditionalInformation

  return {
    step: 5,
    progress: 65,
    title: title[langSelected],
    schema: [
      {
        name: 'serviceDetails?.comment',
        type: 'text-field',
        componentProps: {
          placeholder: additionalInfo[langSelected],
        },
      },
    ],
    getDefaultValues: ({ serviceDetails }) => ({
      comments: serviceDetails?.comment,
    }),
  }
}

export const getSteps = langSelected =>
  [
    certificateOrDocument,
    requestCertificateType,
    requestCivilRegistryType,
    requestDocumentType,
    requestAdditionalInformation,
  ].map(getStepSchema => getStepSchema(langSelected))
