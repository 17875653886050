import React from 'react'
import MUITextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

const styles = theme => ({
  mt: 2,

  borderRadius: 0,
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    color: theme.palette.primary.onSurface,
  },
  '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
    color: theme.palette.primary.onSurfaceVariant,
    opacity: 1,
  },
})

export const TextField = ({
  onChange,
  value = '',
  placeholder,
  sx = {},
  wrapperSX,
}) => {
  const theme = useTheme()
  const customSX = styles(theme)

  return (
    // Additional wrapper because of the problems
    // with the MUI relative TextFields of select type
    <Box sx={wrapperSX}>
      <MUITextField
        value={value}
        placeholder={placeholder}
        minRows='3'
        maxRows='3'
        onChange={onChange}
        fullWidth
        sx={{ ...customSX, ...sx }}
        margin='dense'
        multiline
      />
    </Box>
  )
}
