import Layout1239 from '../Layouts/Layout1239'
import { PrivacyPolicy } from 'pages/components/PrivacyPolicy/PrivacyPolicy'

export default function PrivacyPolicy1239() {
  return (
    <Layout1239>
      <PrivacyPolicy />
    </Layout1239>
  )
}
