import React, { useContext, useState, useEffect } from 'react'
import { Button, useTheme, Box } from '@mui/material/'
import vocabulary from '../../vocabulary/vocabulary'
import Layout600 from '../Layouts/Layout600'
import GlobalContext from '../../GlobalContext'
import vocabularyPowerOfAttorney from '../../vocabulary/vocabularyPowerOfAttorney'
import squareImg from '../../images/brown-square.png'
import arrowRightImg from '../../images/arrow-black-right.png'
import { v4 as uuidv4 } from 'uuid'
import styles from './styles600'
import DialogPoa from '../components/DialogServices/DialogPoa'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useOpenServiceTypeDialog } from 'pages/helpers/useOpenServiceTypeDialog'
import CardServiceProcess600 from '../components/ServiceProcess/CardServiceProcess600'
import PriceCard from '../components/PriceCard/PriceCard'
import Faq600 from '../components/Faq/Faq600'
import AccordionCustom from '../components/AccordionCustom'

export default function PowerOfAttorney600() {
  const { langSelected, GlobalActions } = useContext(GlobalContext)
  const [stateMenu, setMenu] = useState(false)

  const navigate = useNavigate()

  function setStateMenu(val) {
    setMenu(val)
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const theme = useTheme()
  const openTypeDialog = useOpenServiceTypeDialog({
    section: 'poa',
    step: 1,
  })

  return (
    <Layout600
      stateMenu={stateMenu}
      setStateMenu={setStateMenu}
      langSelected={langSelected}
      theme={theme}
      menu='true'
    >
      <Box
        sx={{
          mt: '56px',
          mx: '16px',
          pt: '32px',
          overflow: 'hidden',
        }}
      >
        <Typography variant='h1Small'>
          {vocabularyPowerOfAttorney.powersOfAttorney[langSelected]}
        </Typography>
        <Typography
          variant='bodySmall'
          component='p'
          sx={{ mb: 2, mt: '48px' }}
        >
          {vocabularyPowerOfAttorney.desc1[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
          {vocabularyPowerOfAttorney.desc2[langSelected]}
        </Typography>
        <Typography variant='bodySmall'>
          {vocabularyPowerOfAttorney.desc3[langSelected]}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            variant='contained'
            size='medium'
            sx={{ width: '260px', height: '40px', my: 6.5 }}
            color='blueButton'
            onClick={() => {
              GlobalActions.setDataToSend({
                section: 'poa',
              })
              GlobalActions.setDialogServiceOpen({
                step: 0,
                serviceIndex: null,
                section: 'poa',
              })
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabulary.applicationSubmit[langSelected]}
            </Typography>
          </Button>
        </Box>
        <Typography variant='h2Small'>
          {vocabularyPowerOfAttorney.necessaryDocList[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p' sx={{ my: '32px' }}>
          {vocabularyPowerOfAttorney.baseDocList[langSelected]}
        </Typography>
        <Box>
          {vocabularyPowerOfAttorney.userTypes
            .filter(val => Boolean(val.desc))
            .map((val, index) => {
              return (
                <AccordionCustom
                  key={uuidv4()}
                  index={index}
                  styles={styles}
                  val={val}
                  langSelected={langSelected}
                />
              )
            })}
        </Box>
        <Typography
          variant='bodySmall'
          component='p'
          sx={{ mb: 2, mt: '32px' }}
        >
          {vocabularyPowerOfAttorney.infoPowerOfAttorney1[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
          {vocabularyPowerOfAttorney.infoPowerOfAttorney2[langSelected]}
        </Typography>
        <Box sx={{ mt: 6.5, mb: '48px' }}>
          <Typography variant='h2Small'>
            {vocabularyPowerOfAttorney.typesOfPowersOfAttorney[langSelected]}
          </Typography>
        </Box>
        {vocabularyPowerOfAttorney.typesOfPoa.map((val, index) => {
          return (
            <Box
              key={uuidv4()}
              sx={{
                borderTop:
                  !index > 0
                    ? '1px solid ' + theme.palette.primary.outlineVariant
                    : 'none',
                borderBottom:
                  '1px solid ' + theme.palette.primary.outlineVariant,
                backgroundColor: theme.palette.primary.surface,
                width: '100%',
                height: '48px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={() =>
                openTypeDialog({
                  id: index,
                  service: val[1],
                })
              }
            >
              <Box sx={{ display: 'flex' }}>
                <img
                  src={vocabularyPowerOfAttorney.imagesTypesOfPoa[index]}
                  alt='logo'
                  width={20}
                />
                <Typography
                  variant='labelLarge'
                  component='p'
                  sx={{ ml: '16px' }}
                >
                  {val[langSelected]}
                </Typography>
              </Box>
              <Box>
                <img
                  src={arrowRightImg}
                  alt='logo'
                  width={20}
                  style={{ marginTop: '4px', marginRight: '16px' }}
                />
              </Box>
            </Box>
          )
        })}
        <Box sx={{ mt: 6.5, mb: '32px' }}>
          <Typography variant='h2Small'>
            {vocabularyPowerOfAttorney.orderOfRegistration[langSelected]}
          </Typography>
        </Box>

        {vocabularyPowerOfAttorney.orderDescription[langSelected].map(
          (service, index) => {
            let last = false

            if (
              vocabularyPowerOfAttorney.orderDescription[langSelected]
                .length ===
              index + 1
            ) {
              last = true
            }

            return (
              <CardServiceProcess600
                langSelected={langSelected}
                key={uuidv4()}
                headerText={service.headerText}
                cardText={service.cardText}
                cardImage={service.cardImage}
                last={last}
              />
            )
          },
        )}
        <Box sx={{ mt: 6.5, mb: '32px' }}>
          <Typography variant='h2Small'>
            {vocabularyPowerOfAttorney.prices[langSelected]}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <PriceCard
            data={
              vocabularyPowerOfAttorney.priceWithoutAppointment[langSelected]
            }
            squareImg={squareImg}
            colorBorder={true}
          />
        </Box>
        <PriceCard
          data={vocabularyPowerOfAttorney.priceWithAppointment[langSelected]}
          squareImg={squareImg}
          colorBorder={false}
        />

        <Typography variant='bodySmall' component='p' sx={{ mt: '32px' }}>
          {vocabularyPowerOfAttorney.paymentProcessing1[langSelected]}
        </Typography>

        <Typography variant='bodySmall' component='p' sx={{ mt: 2 }}>
          {vocabularyPowerOfAttorney.paymentProcessing2[langSelected]}
        </Typography>
        <Box
          sx={{
            my: 6.5,

            display: 'flex',

            width: '100%',
          }}
        >
          <Button
            variant='contained'
            size='medium'
            sx={{ width: 260, height: '40px' }}
            color='blueButton'
            onClick={() => {
              GlobalActions.setDataToSend({
                section: 'poa',
              })
              GlobalActions.setDialogServiceOpen({
                step: 0,
                serviceIndex: null,
                section: 'poa',
              })
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabulary.applicationSubmit[langSelected]}
            </Typography>
          </Button>
        </Box>

        <Box sx={{ mb: '32px' }}>
          <Typography variant='h2Small'>
            {vocabularyPowerOfAttorney.faq[langSelected]}
          </Typography>
        </Box>

        <Faq600 value={vocabularyPowerOfAttorney.faqDesc[langSelected]} />

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            mt: 6.5,
            mb: 6.5,
          }}
        >
          <Button
            variant='outlined'
            color='blueButton'
            size='medium'
            sx={{ width: 260, borderColor: 'black', height: '40px' }}
            onClick={() => {
              navigate(`${window.location.pathname}?open=chat`)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyPowerOfAttorney.askQuestions[langSelected]}
            </Typography>
          </Button>
        </Box>

        <DialogPoa smallScreen={true} />
      </Box>
    </Layout600>
  )
}
