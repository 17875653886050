import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import squareImg from '../../images/brown-square.png'
import { v4 as uuidv4 } from 'uuid'
import { useTheme } from '@mui/material/styles'
import { useOpenDialogService } from 'pages/helpers/useOpenDialogService'
import { Link } from 'components/Link/Link'

export default function AccordionCustom({ val, langSelected, index, faq }) {
  const theme = useTheme()
  const openPhotoRequirements = useOpenDialogService({
    section: 'photoRequirements',
  })

  return (
    <Accordion
      key={uuidv4()}
      disableGutters
      sx={{
        borderTop:
          index === 0
            ? '1px solid ' + theme.palette.primary.outlineVariant
            : 'none',
        borderBottom: '1px solid ' + theme.palette.primary.outlineVariant,
        backgroundColor: theme.palette.primary.surface,
        width: 1,
      }}
      elevation={0}
      square={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1-content'
        id='panel1-header'
      >
        <Typography variant='labelLarge'>
          {val.userName[langSelected]}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {val?.headerDesc ? (
            <Typography component='div' sx={{ mb: 2 }} variant='bodySmall'>
              {val.headerDesc[0][langSelected]}
            </Typography>
          ) : null}

          {val.desc?.map(val => {
            return (
              <ListItem
                key={uuidv4()}
                sx={{ alignItems: 'flex-start', p: '0' }}
              >
                {!faq ? (
                  <img
                    src={squareImg}
                    alt='logo'
                    width={20}
                    style={{ marginTop: '4px', marginRight: '16px' }}
                  />
                ) : null}
                <ListItemText sx={{ width: '150px' }}>
                  <Typography component='div' variant='bodySmall'>
                    {val[langSelected]}
                  </Typography>
                </ListItemText>
              </ListItem>
            )
          })}
          {val?.headerDesc?.[1] ? (
            <Typography component='div' sx={{ my: 2 }} variant='bodySmall'>
              {val.headerDesc[1][langSelected]}
            </Typography>
          ) : null}

          {val?.addDesc
            ? val.addDesc.map(val => {
                return (
                  <ListItem
                    key={uuidv4()}
                    sx={{ alignItems: 'flex-start', p: '0' }}
                  >
                    {!faq ? (
                      <img
                        src={squareImg}
                        alt='logo'
                        width={20}
                        style={{ marginTop: '4px', marginRight: '16px' }}
                      />
                    ) : null}
                    <ListItemText sx={{ width: '150px' }}>
                      <Typography component='div' variant='bodySmall'>
                        {val[langSelected]}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                )
              })
            : null}

          {val?.ps ? (
            <Typography
              component='div'
              variant='labelLarge'
              color='primary'
              sx={{
                ml: 4,
                mt: 2,
                cursor: 'pointer',
              }}
              onClick={event => {
                event.stopPropagation()
                openPhotoRequirements()
              }}
            >
              {val.ps[langSelected]}
            </Typography>
          ) : null}

          {val?.link ? (
            <Link to={val.link.to}>
              <Typography
                component='div'
                variant='labelLarge'
                color='primary'
                sx={{
                  mt: 2,
                  cursor: 'pointer',
                }}
              >
                {val.link.text[langSelected]}
              </Typography>
            </Link>
          ) : null}
        </List>

        {val.comments
          ? val.comments.map(text => (
              <Typography
                key={uuidv4()}
                sx={{ ml: 4, my: 2 }}
                component='div'
                variant='bodySmall'
              >
                {text[langSelected]}
              </Typography>
            ))
          : null}
      </AccordionDetails>
    </Accordion>
  )
}
