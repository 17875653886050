import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  TextField,
  InputAdornment,
  FormControl,
} from '@mui/material/'
import { useTheme } from '@mui/material/styles'
import GlobalContext from 'GlobalContext'

import crossClose from 'images/icon/cross-close.png'

import vocabulary from 'vocabulary/vocabulary'
import { Envelope } from '@phosphor-icons/react'
import { passwordRecovery } from 'api/auth'
import { useLoginFlowOpen, useLoginFlowClose } from 'pages/helpers/useLoginFlow'

export default function ForgetPassword() {
  const theme = useTheme()

  const { langSelected } = React.useContext(GlobalContext)
  const [userMail, setUserMail] = React.useState('')
  const openLoginForm = useLoginFlowOpen('login')
  const openCheckYourEmailForm = useLoginFlowOpen('checkYourMail')
  const closeForgerPasswordForm = useLoginFlowClose()

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <FormControl
        sx={{ mx: '16px', display: 'flex', flexDirection: 'column' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '76px',
          }}
        >
          <Box sx={{ cursor: 'pointer' }} onClick={closeForgerPasswordForm}>
            <img src={crossClose} alt='cross' width={'24px'} />
          </Box>
        </Box>

        <Typography component='p' variant='titleLarge' sx={{ mb: 4 }}>
          {vocabulary.forgetPassword[langSelected]}
        </Typography>
        <Typography component='p' variant='bodySmall'>
          {vocabulary.forgetPasswordText[langSelected]}
        </Typography>

        <TextField
          value={userMail}
          placeholder={vocabulary.email[langSelected]}
          InputLabelProps={{ shrink: true }}
          type='email'
          autoComplete='email'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Envelope size={24} weight='light' />
              </InputAdornment>
            ),
          }}
          onChange={event => {
            setUserMail(event.target.value)
          }}
          sx={{
            minWidth: 1,
            mt: 4,
            mb: 3,

            borderRadius: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '5px',
              color: theme.palette.primary.onSurface,
            },
            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
              color: theme.palette.primary.onSurfaceVariant,
              opacity: 1,
            },
          }}
          variant='outlined'
          margin='dense'
        />

        <Button
          variant='contained'
          size='medium'
          sx={{ width: '328px', height: '40px', m: 'auto', my: 1 }}
          color='blueButton'
          onClick={() => {
            passwordRecovery({ username: userMail, lang: langSelected })
            openCheckYourEmailForm()
          }}
        >
          <Typography
            variant='labelLarge'
            sx={{ textTransform: 'none', ml: 1 }}
          >
            {vocabulary.send[langSelected]}
          </Typography>
        </Button>

        <Box sx={{ m: 'auto', mt: 1, display: 'flex', flexDirection: 'row' }}>
          <Typography variant='bodySmall'>
            {vocabulary.rememberedPassword[langSelected]}
          </Typography>

          <Typography
            variant='labelLarge'
            color='primary'
            sx={{ ml: 2, textTransform: 'none' }}
            onClick={openLoginForm}
          >
            {vocabulary.enter[langSelected]}
          </Typography>
        </Box>
      </FormControl>
    </Box>
  )
}
