import { createTheme } from '@mui/material/styles'
const theme = createTheme({
  palette: {
    primary: {
      light: '#E6E8EE',
      main: '#34618E',
      header: '#BECBDC',
      dark: '#002884',
      footer: '#001D2F',
      primaryContainer: '#D0E4FF',
      secondaryContainer: '#D8E2F0',
      onPrimaryContainer: '#001D35',
      tertiaryContainer: '#EFECEB',
      onTertiaryContainer: '#A66D43',
      surface: '#F8F9FF',
      onSurface: '#191C20',
      outlineVariant: '#C2C7CF',
      black: '#000000',
      surfaceContainerLow: '#F2F3F9',
      outlined: '#73777F',
      surfaceContainer: '#ECEEF4',
      onSurfaceVariant: '#42474E',
      primary: '#34618E',
      surfaceContainerHigh: '#E6E8EE',
    },
    blueButton: { main: '#34618E', contrastText: 'white' },
    clearButton: { main: '#F8F9FF', contrastText: '#34618E' },
    progressBar: { main: '#34618E' },
    text: {
      primary: '#191C20',
      light: '#E9DB5D',
      dark: '#A29415',
      contrastText: '#242105',
    },
  },
  shape: {
    borderRadius: 20,
  },
  typography: {
    h1Large: {
      fontFamily: 'Source Serif 4, serif',
      fontSize: '45px',
      lineHeight: '52px',
      textTransform: 'uppercase',
    },
    h1Small: {
      fontFamily: 'Source Serif 4, serif',
      fontSize: '36px',
      lineHeight: '44px',
      textTransform: 'uppercase',
    },

    h2Large: {
      fontFamily: 'Source Serif 4, serif',
      fontSize: '32px',
      lineHeight: '40px',
      textTransform: 'uppercase',
    },

    h2Small: {
      fontFamily: 'Source Serif 4, serif',
      fontSize: '28px',
      lineHeight: '36px',
      textTransform: 'uppercase',
    },

    titleLarge: {
      ///title Large
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '28px',
    },
    titleSmall: {
      fontFamily: 'Raleway, sans-serif',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '24px',
    },
    bodySmall: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 200,
      fontSize: '14px',
      lineHeight: '20px',
    },
    labelLarge: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
    },
    labelLargeShrink: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    labelSmall: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
    },
    labelSmallSemibold: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
    },
    labelSmallLight: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 200,
      fontSize: '12px',
      lineHeight: '16px',
    },

    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1Small: 'h6',
            h1Large: 'h1',
            h2Large: 'h2',
            h2Small: 'h6',
            labelLarge: 'h6',
            labelLargeShrink: 'h6',
            labelSmall: 'h6',
            labelSmallSemibold: 'h6',
            titleLarge: 'h4',
            titleSmall: 'h5',
            bodyLarge: 'h6',
            bodySmall: 'p',
            labelSmallLight: 'p',
            default: 'div',
          },
        },
      },
    },
  },
})
export default theme
