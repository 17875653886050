import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import GlobalContext from 'GlobalContext.js'
import CountrySelection from '../../Workflow/CountrySelection.js'
import Payment from '../../Workflow/Payment.js'
import GetUserDetails from '../../Workflow/GetUsersDetails.js'
import RequestSuccessSent from '../../Workflow/RequestSuccessSent.js'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'
import { getSteps } from './steps/index.js'
import WorkflowStep from 'components/Workflow/WorkflowStep'
import vocabularyPassport from 'vocabulary/vocabularyPassport'
import CheckoutForm from 'pages/components/Workflow/CheckoutForm.js'

export function Workflow() {
  const { state, langSelected } = React.useContext(GlobalContext)

  const open = state.dialogService.section === 'passport'
  const price = {
    withAppointment: vocabularyPassport.priceWithAppointment[1].value,
    withoutAppointment: vocabularyPassport.priceWithoutAppointment[1].value,
  }

  const { step: currentStep } = state.dialogService
  const workflowSteps = getSteps(langSelected)

  return (
    <DialogModalWindow open={open}>
      {currentStep === 0 ? <CountrySelection height='auto' /> : null}
      {workflowSteps.map(({ step, ...workflowSchema }) =>
        currentStep === step ? (
          <WorkflowStep key={uuidv4()} {...workflowSchema} />
        ) : null,
      )}
      {currentStep === 9 ? <GetUserDetails height='auto' /> : null}
      {currentStep === 10 ? <Payment price={price} /> : null}
      {currentStep === 11 ? <CheckoutForm price={price} /> : null}
      {currentStep === 12 ? <RequestSuccessSent height='auto' /> : null}
    </DialogModalWindow>
  )
}
