import { Backdrop, CircularProgress, Box } from '@mui/material'
import React from 'react'

export default function Loading() {
  const [open] = React.useState(true)

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Backdrop
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  )
}
