import { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import vocabulary from '../../vocabulary/vocabulary'
import Layout600 from '../Layouts/Layout600'
import GlobalContext from '../../GlobalContext'
import vocabularyAboutUs from '../../vocabulary/vocabularyAboutUs'
import { v4 as uuidv4 } from 'uuid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CardServiceProcess600 from '../components/ServiceProcess/CardServiceProcess600'
import Faq600 from '../components/Faq/Faq600'
import { useNavigate } from 'react-router-dom'

export default function AboutUs600() {
  const { langSelected, GlobalActions } = useContext(GlobalContext)
  const [serviceProcessData, setServiceProcessData] = useState([])

  const navigate = useNavigate()
  useEffect(() => {
    setServiceProcessData(vocabularyAboutUs.serviceProcess[langSelected])
  }, [langSelected])

  const theme = useTheme()

  return (
    <Layout600 theme={theme} menu='true'>
      <Box sx={{ mt: '56px', mx: '16px' }}>
        <Box sx={{ mt: '32px', mb: '48px' }}>
          <Typography variant='h1Small'>
            {vocabularyAboutUs.aboutUsSection[langSelected]}
          </Typography>
        </Box>
        <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
          {vocabularyAboutUs.aboutUsSection1[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
          {vocabularyAboutUs.aboutUsSection2[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
          {vocabularyAboutUs.aboutUsSection3[langSelected]}
        </Typography>
        <Typography variant='bodySmall'>
          {vocabularyAboutUs.aboutUsSection4[langSelected]}
        </Typography>

        <Typography
          variant='h2Small'
          component='p'
          sx={{ mt: 6.5, mb: '48px' }}
        >
          {vocabularyAboutUs.whyChooseUs[langSelected]}
        </Typography>

        {serviceProcessData.map((service, index) => {
          let last = false

          if (serviceProcessData.length === index + 1) {
            last = true
          }

          return (
            <CardServiceProcess600
              langSelected={langSelected}
              key={uuidv4()}
              headerText={service.headerText}
              cardText={service.cardText}
              cardImage={service.cardImage}
              about={service.about}
              details={service.details}
              last={last}
              navigate={navigate}
            />
          )
        })}

        <Typography variant='bodySmall' component='p' sx={{}}>
          {vocabularyAboutUs.ourMission[langSelected]}
        </Typography>
        <Typography
          variant='h2Small'
          sx={{ mt: '50px', mb: '50px' }}
          component='p'
        >
          {vocabularyAboutUs.faq[langSelected]}
        </Typography>

        <Faq600 value={vocabularyAboutUs.faqDesc[langSelected]} />

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            variant='contained'
            size='medium'
            sx={{ width: '260px', height: '40px', mt: 6.5 }}
            color='blueButton'
            onClick={() => {
              GlobalActions.userFlowMainOpen(true)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabulary.applicationSubmit[langSelected]}
            </Typography>
          </Button>
          <Button
            variant='outlined'
            size='medium'
            sx={{
              width: '260px',
              borderColor: 'black',
              height: '40px',
              mt: 2,
              mb: 6.5,
            }}
            color='blueButton'
            onClick={() => {
              GlobalActions.userFlowMainOpen(true)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyAboutUs.askQuestion[langSelected]}{' '}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Layout600>
  )
}
