import { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import vocabulary from '../../vocabulary/vocabulary'
import Layout599 from '../Layouts/Layout599'
import GlobalContext from '../../GlobalContext'
import vocabularyAboutUs from '../../vocabulary/vocabularyAboutUs'
import { v4 as uuidv4 } from 'uuid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CardServiceProcess599 from '../components/ServiceProcess/CardServiceProcess599'
import Faq599 from '../components/Faq/Faq599'
import { useNavigate } from 'react-router-dom'

export default function AboutUs599() {
  const { langSelected, GlobalActions } = useContext(GlobalContext)
  const [serviceProcessData, setServiceProcessData] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    setServiceProcessData(vocabularyAboutUs.serviceProcess[langSelected])
  }, [langSelected])

  const theme = useTheme()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <Layout599 theme={theme} menu='true'>
      <Box sx={{ mt: '56px', mx: '16px' }}>
        <Box sx={{ mt: '32px', mb: '48px' }}>
          <Typography variant='h1Small' component='p'>
            {vocabularyAboutUs.aboutUsSection[langSelected]}
          </Typography>
        </Box>
        <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
          {vocabularyAboutUs.aboutUsSection1[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
          {vocabularyAboutUs.aboutUsSection2[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p' sx={{ mb: 2 }}>
          {vocabularyAboutUs.aboutUsSection3[langSelected]}
        </Typography>
        <Typography variant='bodySmall' component='p'>
          {vocabularyAboutUs.aboutUsSection4[langSelected]}
        </Typography>

        <Typography
          variant='titleLarge'
          component='p'
          sx={{ mt: 6.5, mb: '48px' }}
        >
          {vocabularyAboutUs.whyChooseUs[langSelected]}
        </Typography>

        {serviceProcessData.map((service, index) => {
          let last = false

          if (serviceProcessData.length === index + 1) {
            last = true
          }

          return (
            <CardServiceProcess599
              langSelected={langSelected}
              key={uuidv4()}
              headerText={service.headerText}
              cardText={service.cardText}
              cardImage={service.cardImage}
              about={service.about}
              details={service.details}
              last={last}
              navigate={navigate}
            />
          )
        })}

        <Typography variant='bodySmall' component='p' sx={{ mt: '64px' }}>
          {vocabularyAboutUs.ourMission[langSelected]}
        </Typography>
        <Typography
          variant='titleLarge'
          component='div'
          sx={{ mt: 6.5, mb: '48px' }}
        >
          {vocabularyAboutUs.faq[langSelected]}
        </Typography>

        <Faq599 value={vocabularyAboutUs.faqDesc[langSelected]} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            w: 1,
            alignItems: 'center',
          }}
        >
          <Button
            variant='contained'
            size='medium'
            sx={{ width: '328px', height: '40px', mt: 6.5, mb: 2 }}
            color='blueButton'
            onClick={() => {
              GlobalActions.userFlowMainOpen(true)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabulary.applicationSubmit[langSelected]}
            </Typography>
          </Button>

          <Button
            variant='outlined'
            size='medium'
            sx={{
              width: '328px',
              borderColor: 'black',
              height: '40px',
              mx: 'auto',
              mb: 2,
            }}
            color='blueButton'
            onClick={() => {
              navigate(`${window.location.pathname}?open=chat`)
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabulary.ask[langSelected]}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Layout599>
  )
}
