import vocabulary from 'vocabulary/vocabularyConsent'
import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'

export const requestCreditor = langSelected => {
  const { title, subjectOfGuaranteeTitle, options, additionalInfo } =
    vocabulary.workflow.requestCreditor

  return createStepSchema(title[langSelected], [
    {
      name: 'serviceDetails.creditor',
      type: 'radio',
      componentProps: {
        options: createOptions(options, langSelected),
      },
    },
    {
      name: 'title',
      type: 'title',
      componentProps: {
        value: subjectOfGuaranteeTitle[langSelected],
      },
    },
    {
      name: 'serviceDetails.subjectOfGuarantee',
      type: 'text-field',
      componentProps: {
        placeholder: additionalInfo[langSelected],
      },
    },
  ])
}
