import { useTheme } from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router-dom'

import logoFooter from '../../../images/logo-footer.png'
import footerLine from '../../../images/footer-line.png'
import GlobalContext from '../../../GlobalContext'
import vocabulary from '../../../vocabulary/vocabulary'
import React, { useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export default function Footer599() {
  const theme = useTheme()
  const { langSelected } = useContext(GlobalContext)
  const navigate = useNavigate()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          width: 1,
          height: '5px',
          backgroundImage: `url(${footerLine})`,
          backgroundRepeat: 'repeat-x',
          backgroundSize: 'contain',
        }}
      />

      <Box
        sx={{
          // backgroundColor: theme.palette.primary.secondaryContainer,
          width: '100%',
          pt: '24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: theme.palette.primary.footer,
        }}
      >
        {vocabulary.footerList[langSelected].map((text, index) => (
          <React.Fragment key={uuidv4()}>
            <Typography
              variant='labelLarge'
              sx={{ mb: '20px', cursor: 'pointer' }}
              onClick={() => {
                switch (index) {
                  case 0:
                    navigate('/about')
                    break

                  case 1:
                    navigate('/appointment')
                    break

                  case 2:
                    navigate('/faq')
                    break

                  case 3:
                    navigate('/prices')
                    break

                  case 4:
                    navigate('/user-agreement')
                    break

                  case 5:
                    navigate('/cookie-policy')
                    break

                  case 6:
                    navigate('/privacy-policy')
                    break
                }
              }}
            >
              {text}
            </Typography>

            {index === 5 || index === 8 ? (
              <Divider
                sx={{
                  bgcolor: 'secondary',
                  width: '240px',
                  m: 'auto',
                  mt: '-10px',
                  mb: '10px',
                }}
              />
            ) : null}
          </React.Fragment>
        ))}
        <Typography variant='bodySmall'>
          © 2024 Consulate Lawyer Online
        </Typography>
        <img
          src={logoFooter}
          alt='logo'
          style={{
            width: '120px',
            height: '135px',
            marginTop: '48px',
            marginBottom: '40px',
          }}
        />
      </Box>
    </Box>
  )
}
