import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'
import vocabulary from './vocabulary'

export const passportVariants = [
  [
    'Нового поколения сроком на 10 лет',
    'Next generation with a duration of 10 years.',
  ],
  [
    'Старого образца сроком на 5 лет',
    'Old variant with a duration of 5 years.',
  ],
]

export const whoNeedsPassportOptions = [
  ['Мне (физ лицо старше 18 лет)', 'Me (an individual over 18 years old)'],
  ['Ребёнку младше 14 лет', 'Child under 14 years old'],
  ['Ребёнку возрастом от 14 до 18 лет', 'Child aged 14 to 18 years old'],
]

const binaryOptions = [vocabulary.yes, vocabulary.no]

const vocabularyPassport = {
  passport: ['загранпаспорт', 'Passport(foriegn)'],
  info: ['Инфо', 'Info'],
  documents: ['Документы', 'Documents'],
  prices: ['Тарифы', 'Prices'],
  questions: ['Вопросы', 'Questions'],
  commonInformation: ['Общая информация', 'Common Information'],
  requiredDocuments: [
    'Какие документы потребуются?',
    'What documents will be required?',
  ],
  priceAndTariffs: ['ТАРИФЫ И ЦЕНЫ', 'TARIFFS AND PRICES'],
  faq: ['Часто задаваемые вопросы', '  Frequently Asked Questions'],
  mainInf1: [
    'В консульстве или посольстве России возможно оформить Российский загранпаспорт сроком на 5 или 10 лет.',
    'At the consulate or embassy of Russia, it is possible to obtain a Russian passport valid for 5 or 10 years.',
  ],
  readDetails: [
    'Читать подробнее, чем отличаются',
    'Read more about how they differ',
  ],
  mainInf2: [
    'На консульском портале в нужном формате мы подготовим за вас заявление на получение загранпаспорта, проконсультируем по перечню документов, которые необходимо взять с собой в консульство, при необходимости окажем помощь в записи на приём в консульство.',
    'On the consulate portal, we will prepare an application for obtaining a passport in the required format, provide consultation on the list of documents you have to bring to the consulate, and if necessary, assist you in scheduling an appointment at the consulate.',
  ],
  times: ['Сроки оформления', 'Processing Times'],
  oficialProcessingTimes: [
    'Официальные сроки оформления паспорта с момента подачи документов в консульство:',
    'The official processing times for a passport from the moment documents are submitted to the consulate:',
  ],
  oficialProcessingTimesList: [
    [
      '1 месяц — загранпаспорт старого образца (сроком на 5 лет) детям до 14 лет',
      '1 month - old-style passport (valid for 5 years) for children under 14',
    ],
    [
      '2 месяца — загранпаспорт старого образца (сроком на 5 лет) совершеннолетним',
      '2 months - old-style passport (valid for 5 years) for adults',
    ],
    [
      '3 месяца — загранпаспорт нового поколения (сроком на 10 лет)',
      '3 months - new-generation passport (valid for 10 years)',
    ],
  ],
  processingTimeText1: [
    'Однако на данный момент по независящим от консульств / посольств причинам сроки оформления загранпаспорта могут быть увеличены до полугода и более. Возможные задержки связаны с нерегулярным характером авиасообщения между странами.',
    'However, at the moment, due to reasons beyond the control of consulates/embassies, the processing time for a passport can be extended to six months or more. Possible delays are associated with the irregular nature of air travel between countries.',
  ],
  processingTimeText2: [
    'Данные сроки в свою очередь не учитывают время, которое может занять сам процесс записи на подачу документов. Поэтому рекомендуем начинать заниматься вопросом замены истекающего загранпаспорта заблаговременно.',
    'These deadlines do not take into account the time it may take to schedule an appointment to submit the documents. Therefore, we recommend starting the process of replacing an expiring passport well in advance.',
  ],
  passportProcess: [
    'Процесс подачи на оформление загранпаспорта',
    'The process of applying for an foreign passport',
  ],
  next: ['Далее', 'Next'],
  necessaryDoc: ['Требуемые документы', 'Nesessary documents'],
  necessaryDocText1: [
    'Комплекты документов, представляемые в консульство / посольство России на оформление паспортов также зависят от возраста заявителя и от имеющихся у него документов:',
    "The sets of documents submitted to the Russian consulate/embassy for a new passport also depend on the applicant's age and the documents they possess.",
  ],
  userTypes: [
    {
      userName: ['Лица старше 18 лет', 'Individuals over 18 years old'],
      desc: [
        [
          'Заявление на оформление загранпаспорта',
          'Application for passport application',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт РФ. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
          'Russian passport and/or Russian internal passport. If you have both passports, you need to bring two passports to the consulate appointment.',
        ],
        [
          'В случае смены ФИО, документ подтверждающий данный факт (свидетельство о заключении брака, перемены имени, о расторжении брака и т.д.). Если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык',
          'In case of a change in the full name, a document confirming this fact (marriage certificate, name change, divorce, etc.) is required. If the document is of foreign origin, it must be apostilled and notarized with a translation into Russian.',
        ],
        [
          'В случае если вы оформляете паспорт старого образца (сроком на 5 лет) вам необходимо заранее подготовить 4 фотографии 3,5 х 4,5 см',
          'If you are applying for an old-style passport (valid for 5 years), you need to prepare 4 photos sized 3.5 x 4.5 cm in advance.',
        ],
      ],
      ps: ['Смотреть требования к фотографии', 'View photo requirements'],
    },

    {
      userName: ['Ребёнок младше 14 лет', 'Child under 14 years old'],
      desc: [
        [
          'Заявление на оформление загранпаспорта ребёнка, заполненное от имени родителя (иного законного представителя)',
          'Application for child passport application filled out on behalf of the parent (other legal representative)',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт родителя (иного законного представителя)',
          'Passport and/or national passport of the parent (other legal representative)',
        ],
        [
          'Загранпаспорт ребёнка (если это повторное оформление)',
          'Child passport (if it is a renewal)',
        ],
        [
          'Свидетельство о рождении ребёнка, содержащее отметку (или вкладыш) о принадлежности ребёнка к гражданству РФ. Если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык',
          "Child's birth certificate containing a note (or insert) on the child's citizenship of the Russian Federation. If the document is of foreign origin, it must be apostilled and notarized with a translation into Russian",
        ],
        [
          'Иные документы, подтверждающие родство (если из свидетельства о рождении чётко не прослеживаются родственные отношения) заявителя и ребёнка или полномочия законного представителя',
          'Other documents confirming the relationship (if the birth certificate does not clearly show the relationship) between the applicant and the child or the authority of the legal representative',
        ],
        [
          'В случае если вы оформляете паспорт старого образца (сроком на 5 лет) вам необходимо заранее подготовить 4 фотографии 3,5 х 4,5 см',
          'If you are applying for an old-style passport (valid for 5 years), you need to prepare 4 photos sized 3.5 x 4.5 cm in advance',
        ],
        [
          'Загранпаспорт для ребёнка можно оформлять начиная с его рождения, однако в связи с тем, что внешность ребенка быстро меняется, до 6 лет рекомендуется оформлять паспорт только старого образца сроком на 5 лет.',
          "A child's passport can be issued starting from their birth, however, due to the fact that a child's appearance changes quickly, it is recommended to issue only an old-style passport valid for 5 years until the age of 6.",
        ],
      ],
      comments: [
        [
          'Загранпаспорт для ребёнка можно оформлять начиная с его рождения, однако в связи с тем, что внешность ребенка быстро меняется, до 6 лет рекомендуется оформлять паспорт только старого образца сроком на 5 лет.',
          '',
        ],
        [
          'Присутствие ребёнка при подаче документов обязательно, при получении готового загранпаспорта — уже не требуется.',
          'The presence of the child is mandatory when submitting documents, it is not required when receiving the ready passport.',
        ],
      ],
      ps: ['Смотреть требования к фотографии', 'View photo requirements'],
    },
    {
      userName: [
        'Ребёнок возрастом от 14 до 18 лет',
        'Teenager aged 14 to 18 years old',
      ],
      desc: [
        [
          'Заявление на оформление загранпаспорта ребенка, заполненное от имени родителя (иного законного представителя)',
          'Application for child passport application filled out on behalf of the parent (other legal representative)',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт родителя (иного законного представителя)',
          'Passport and/or national passport of the parent (other legal representative)',
        ],
        [
          'Загранпаспорт и/или внутренний паспорт ребенка',
          'Passport and/or national passport of the child',
        ],
        [
          'Документы, подтверждающие родство заявителя и ребенка (свидетельство о рождении, о браке, о перемене имени и другие) или полномочия законного представителя. Если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык',
          'Documents confirming the relationship between the applicant and the child (birth certificate, marriage certificate, name change certificate, and others) or the authority of the legal representative. If the document is of foreign origin, it must be apostilled and notarized with a translation into Russian',
        ],
        [
          'В случае если вы оформляете паспорт старого образца (сроком на 5 лет) вам необходимо заранее подготовить 4 фотографии 3,5 х 4,5 см.',
          'If you are applying for an old-style passport (valid for 5 years), you need to prepare 4 photos sized 3.5 x 4.5 cm in advance.',
        ],
      ],
      comments: [
        [
          'Присутствие ребенка и родителя обязательно как при подаче, так и при получении готового документа.',
          'The presence of the child and the parent is mandatory both when submitting the documents and when receiving the finished document.',
        ],
      ],
      ps: ['Смотреть требования к фотографии', 'View photo requirements'],
    },
  ],
  passportInfoText1: [
    'Оформить новый загранпаспорт в консульстве можно как на основании внутреннего паспорта РФ, так и действующего загранпаспорта.',
    'A new passport can be obtained at the consulate, based on both your Russian internal passport or a valid foreign passport',
  ],
  passportInfoText2: [
    'В случае, когда отсутствуют оба документа, проводится проверка наличия у заявителя российского гражданства. Только после его подтверждения производится приём документов.',
    "In case both documents are absent, a check is conducted to verify the applicant's Russian citizenship. Only after its confirmation the documents are accepted",
  ],
  passportInfoTextBlue1: [
    'Подробнее о проверке на гражданство',
    'More details about the citizenship verification process.',
  ],
  passportInfoText3: [
    'При необходимости в срочном возвращении в Россию заявителю может быть оформлено свидетельство на возвращение.',
    'If an urgent return to Russia is required, the applicant may be issued a return certificate.',
  ],
  passportInfoTextBlue2: [
    'Подробнее о Свидетельстве на возращение',
    'More details about the Return Certificate.',
  ],
  cvInfo: [
    'Сведения о трудовой деятельности',
    'Information about employment history.',
  ],
  cvInfoText: [
    'Для составления заявления на оформление загранпаспорта помимо вышеперечисленных документов, нам потребуются от вас сведения о работе и учёбе за последние 10 лет с указанием следующей информации:',
    'To prepare the application for a passport, in addition to the listed documents, we will also need information about your employment and education for the last 10 years, including the following details:',
  ],
  cvList: [
    ['период работы / учёбы', 'period of work / study'],
    ['полное наименование организации', 'full name of the organization'],
    ['должность', 'position'],
    ['адрес организации', 'organization address'],
  ],
  readText: [
    'Читать, как правильно указывать',
    'To read, how to correctly specify.',
  ],
  back: ['Назад', 'Back'],

  priceWithoutAppointment: [
    {
      header: 'Без записи в консульстве',
      list: [
        'заполним заявление на оформление загранпаспорта на консульском портале',
        'проконсультируем по перечню документов для оформления',
        'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '40',
    },

    {
      header: 'Without an appointment at the consulate',
      list: [
        'we will fill out the application for a foreign passport on the consulate portal.',
        'we will provide consultation on the list of required documents.',
        'we will advise you on scheduling an appointment at the consulate on your own.',
      ],
      value: '40',
    },
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'заполним заявление на оформление загранпаспорта на консульском портале',
        'проконсультируем по перечню документов для оформления',
        'запишем вас на приём в консульство',
      ],
      value: '80',
    },

    {
      header: 'With an appointment at the consulate',
      list: [
        'we will fill out the application for a passport on the consulate portal.',
        'we will provide consultation on the list of documents required for processing.',
        'we will advise you on scheduling an appointment at the consulate on your own.',
      ],
      value: '80',
    },
  ],
  priceWithoutAppointment1: [
    {
      header: 'С записью в консульство',
      list: [
        'заполним заявление на оформление загранпаспорта на консульском портале',
        'проконсультируем по перечню документов для оформления',
        'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '40',
    },

    {
      header: 'With an appointment at the consulate',
      list: [
        'we will fill out the application for a passport on the consulate portal.',
        'we will provide consultation on the list of documents required for processing.',
        'we will provide consultation on the process of scheduling an appointment at the consulate independently.',
      ],
      value: '40',
    },
  ],
  priceWithAppointment1: [
    {
      header: 'С записью в консульство',
      list: [
        'заполним заявление на оформление загранпаспорта на консульском портале',
        'проконсультируем по перечню документов для оформления',
        'запишем вас на приём в консульство',
      ],
      value: '80',
    },

    {
      header: 'With an appointment at the consulate',
      list: [
        'we will fill out the application for a passport on the consular portal.',
        'we will specify the list of documents required for submission according to your situation.',
        'we will provide consultation on the list of documents required for processing.',
        'we will schedule an appointment for you at the consulate.',
      ],
      value: '80',
    },
  ],

  priceText1: [
    'Оплатить услугу возможно как во время оформления заявки на сайте, так и после консультации с юристом.',
    'You can pay for the service either during the application process on the website or after consultation with the lawyer.',
  ],
  priceText2: [
    'Мы принимаем к оплате на сайте международные банковские карты, Apple Pay и Google Pay. Оплата российскими банковскими картами, с использованием системы PayPal или в криптовалюте на сайте не производится, но возможна по запросу.',
    'We accept international bank cards, Apple Pay, and Google Pay for payment on the website. Payment with Russian bank cards, using the PayPal, or in cryptocurrency is not processed on the website, but is possible upon request.',
  ],

  faqDesc: [
    [
      {
        question: 'Могу ли я в консульстве поменять внутренний паспорт РФ?',
        answer:
          'Нет, внутренний паспорт РФ можно оформить и получить только на территории России при личном присутствии',
      },
      {
        question:
          'Чем отличаются между собой загранпаспорта сроком на 5 и на 10 лет?',
        answer:
          'Количеством доступных страниц для виз и штампов, степенью защиты, которая влияет на скорость прохождения контроля. В паспорт старого образца можно вписывать детей. Паспорт нового образца можно оформить в качестве второго в дополнение к уже имеющемуся. Подробнее читать здесь',
      },
      {
        question:
          'Можно ли вместо оформления загранпаспорта ребёнку просто вписать его в свой?',
        answer:
          'Нет, согласно Административному регламенту ФМС, вступившему в силу 16 апреля 2010 года, внесение сведений о детях осуществляется только для подтверждения родства, но не позволяет детям выезжать за границу по паспорту родителей.',
      },
      {
        question: 'Как вписать ребёнка в свой загранпаспорт?',
        answer:
          'Детей можно вписать только в загранпаспорт старого образца сроком на 5 лет при оформлении, либо уже в готовый. Для этого дополнительно потребуются фотографии ребёнка и свидетельство о рождении.',
      },
      {
        question:
          'Можно ли оформить второй загранпаспорт в дополнение к действующему?',
        answer:
          'Да, но вторым загранпаспортом может быть только паспорт нового образца сроком на 10 лет.',
      },
      {
        question:
          'Что происходит с моим старым паспортом при подаче документов на новый загранпаспорт РФ?',
        answer:
          'Ранее выданный паспорт остаётся у вас на руках при подаче документов на новый загранпаспорт. В случае подачи на замену старого, он аннулируется после получения нового. В случае оформления дополнительного загранпаспорта, он остаётся действующим.',
      },
      {
        question:
          'Как оформить новый загранпаспорт РФ, если у меня истёк срок действия старого паспорта и нет внутреннего паспорта?',
        answer:
          'При отсутствии действующего загранпаспорта и внутреннего паспорта требуется проверка принадлежности к гражданству РФ в ближайшем консульстве/посольстве. Заявление на новый паспорт не принимается до завершения проверки принадлежности заявителя к российскому гражданству',
      },
      {
        question:
          'Требуется ли военный билет для получения заграничного паспорта РФ?',
        answer:
          'Нет, для оформления и получения загранпаспорта не требуется ни военного билета, ни справки из военкомата, ни приписного свидетельства.',
      },
      {
        question:
          'У меня нет внутреннего паспорта РФ и скоро истекает срок загранпаспорта. Могу ли я оформить в консульстве новый загранпаспорт?',
        answer:
          'Да, гражданин России, находящийся за рубежом, может оформить новый загранпаспорт на основании действующего загранпаспорта РФ',
      },
      {
        question:
          'У меня нет Российского загранпаспорта, но есть внутренний паспорт РФ. Могу ли я подать на оформление Российского загранпаспорта?',
        answer:
          'Да, по внутреннему паспорту РФ также возможно оформить загранпаспорт',
      },
      {
        question:
          'Я в настоящий момент проживаю на территории Украины и у меня скоро заканчивается срок действия загранпаспорта РФ. Где я могу его поменять?',
        answer:
          'На настоящий момент, российские граждане, проживающие в Украине, могут оформить российский загранпаспорт только на территории граничащих с Украиной государств (либо на территории других государств), в которых функционируют Российские консульства: Польша, Беларусь и т.д.',
      },
    ],
    [
      {
        question: 'Can I change my Russian internal passport at the consulate?',
        answer:
          'No, the Russian internal passport can only be processed and obtained on the territory of Russia in person.',
      },
      {
        question:
          'What is the difference between the 5-year and the 10-year passport?',
        answer:
          'The number of available pages for visas and stamps, the degree of protection, which affects the speed of passing control. In the old-style passport, children can be included. The new-style passport can be obtained as a second one in addition to the existing one. Read more here',
      },
      {
        question:
          'Can I simply include my child in my passport instead of applying for a separate one for them?',
        answer:
          "No, according to the Administrative Regulations of the FMS, which entered into force on April 16, 2010, the inclusion of children's information is only for the purpose of confirming kinship, but it does not allow children to travel abroad using their parents' passport.",
      },
      {
        question: 'How can I include my child in my passport?',
        answer:
          'Children can only be included in the old-style passport for 5 years during the application process or after it has been issued. For this, additional photos of the child and a birth certificate will be required.',
      },
      {
        question:
          'Can I apply for a second passport in addition to the one I already have?',
        answer:
          'Yes, but the second passport can only be a new-style passport valid for 10 years.',
      },
      {
        question:
          'What happens to my old passport when I apply for a new Russian passport?',
        answer:
          'The previously issued passport remains with you when applying for a new passport. In the case of applying for a replacement of the old one, it is invalidated after receiving the new one. In the case of obtaining an additional passport, it remains valid.',
      },
      {
        question:
          'How can I apply for a new Russian passport if my old one has expired and I do not have an internal passport?',
        answer:
          "In the absence of a valid foreign passport and internal passport, it is necessary to verify the Russian citizenship at the nearest consulate/embassy. The application for a new passport is not accepted until the verification of the applicant's Russian citizenship is completed.",
      },
      {
        question: 'Is a military ID required to obtain a Russian passport?',
        answer:
          'No, neither a military ID nor a certificate from the military enlistment office nor a military service registration certificate is required to apply for and receive a passport.',
      },
      {
        question:
          'I do not have an internal Russian passport, and my foreign passport will soon expire. Can I apply for a new foreign passport at the consulate?',
        answer:
          'Yes, a Russian citizen located abroad can obtain a new foreign passport based on the valid foreign passport of the Russian Federation.',
      },
      {
        question:
          'I do not have an internal Russian passport, but I have an internal passport. Can I apply for a Russian passport?',
        answer:
          'Yes, a Russian foreign passport can also be obtained based on the internal passport.',
      },
      {
        question:
          'I currently reside in Ukraine and my Russian passport is about to expire. Where can I replace it?',
        answer:
          'At present, the Russian citizens residing in Ukraine can only obtain a Russian passport on the territory of neighboring states bordering Ukraine (or in other states) where Russian consulates operate: Poland, Belarus, etc.',
      },
    ],
  ],
  faqList: [
    {
      userName: [
        'Могу ли я в консульстве поменять внутренний паспорт РФ?',
        'Can I change my Russian internal passport at the consulate?',
      ],
      desc: [
        [
          'Нет, внутренний паспорт РФ можно оформить и получить только на территории России при личном присутствии',
          'No, the Russian internal passport can only be processed and obtained on the territory of Russia in person.',
        ],
      ],
    },
    {
      userName: [
        'Чем отличаются между собой загранпаспорта сроком на 5 и на 10 лет?',
        'What is the difference between a 5-year and a 10-year passport?',
      ],
      desc: [
        [
          'Количеством доступных страниц для виз и штампов, степенью защиты, которая влияет на скорость прохождения контроля. В паспорт старого образца можно вписывать детей. Паспорт нового образца можно оформить в качестве второго в дополнение к уже имеющемуся.',
          'The number of available pages for visas and stamps, the degree of protection, which affects the speed of passing control. In the old-style passport, children can be included. A new-style passport can be obtained as a second one in addition to the existing one.',
        ],
      ],
      link: {
        to: '/passport?open=passportDifferences',
        text: ['Подробнее читать здесь', 'Read more here'],
      },
    },
    {
      userName: [
        'Можно ли вместо оформления загранпаспорта ребёнку просто вписать его в свой?',
        'Can I simply include my child in my passport instead of applying for a separate one for them?',
      ],
      desc: [
        [
          'Нет, согласно Административному регламенту ФМС, вступившему в силу 16 апреля 2010 года, внесение сведений о детях осуществляется только для подтверждения родства, но не позволяет детям выезжать за границу по паспорту родителей.',
          "No, according to the Administrative Regulations of the FMS, which entered into force on April 16, 2010, the inclusion of children's information is only for the purpose of confirming kinship, but it does not allow children to travel abroad using their parents' passport.",
        ],
      ],
    },
    {
      userName: [
        'Как вписать ребёнка в свой загранпаспорт?',
        'How can I include my child in my passport?',
      ],
      desc: [
        [
          'Детей можно вписать только в загранпаспорт старого образца сроком на 5 лет при оформлении, либо уже в готовый. Для этого дополнительно потребуются фотографии ребёнка и свидетельство о рождении.',
          'Children can only be included in the old-style passport for 5 years during the application process or after it has been issued. For this, additional photos of the child and a birth certificate will be required.',
        ],
      ],
    },
    {
      userName: [
        'Можно ли оформить второй загранпаспорт в дополнение к действующему?',
        'Can I apply for a second passport in addition to the one I already have?',
      ],
      desc: [
        [
          'Да, но вторым загранпаспортом может быть только паспорт нового образца сроком на 10 лет.',
          'Yes, but the second passport can only be a new-style passport valid for 10 years.',
        ],
      ],
    },
    {
      userName: [
        'Что происходит с моим старым паспортом при подаче документов на новый загранпаспорт РФ?',
        'What happens to my old passport when I apply for a new Russian passport?',
      ],
      desc: [
        [
          'Ранее выданный паспорт остаётся у вас на руках при подаче документов на новый загранпаспорт. В случае подачи на замену старого, он аннулируется после получения нового. В случае оформления дополнительного загранпаспорта, он остаётся действующим.',
          'The previously issued passport remains with you when applying for a new passport. In the case of applying for a replacement for the old one, it is invalidated after receiving the new one. In the case of obtaining an additional passport, it remains valid.',
        ],
      ],
    },
    {
      userName: [
        'Как оформить новый загранпаспорт РФ, если у меня истёк срок действия старого паспорта и нет внутреннего паспорта?',
        'How can I apply for a new Russian passport if my old one has expired and I do not have an internal passport?',
      ],
      desc: [
        [
          'При отсутствии действующего загранпаспорта и внутреннего паспорта требуется проверка принадлежности к гражданству РФ в ближайшем консульстве/посольстве. Заявление на новый паспорт не принимается до завершения проверки принадлежности заявителя к российскому гражданству',
          "In the absence of a valid foreign passport and internal passport, it is necessary to verify the Russian citizenship at the nearest consulate/embassy. The application for a new passport is not accepted until the verification of the applicant's affiliation with Russian citizenship is completed.",
        ],
      ],
    },
    {
      userName: [
        'Требуется ли военный билет для получения заграничного паспорта РФ?',
        'Is a military ID required to obtain a Russian passport?',
      ],
      desc: [
        [
          'Нет, для оформления и получения загранпаспорта не требуется ни военного билета, ни справки из военкомата, ни приписного свидетельства.',
          'No, neither a military ID nor a certificate from the military enlistment office nor a draft certificate is required to apply for and receive a passport.',
        ],
      ],
    },
    {
      userName: [
        'У меня нет внутреннего паспорта РФ и скоро истекает срок загранпаспорта. Могу ли я оформить в консульстве новый загранпаспорт?',
        'I do not have a Russian passport, but I have an internal passport. Can I apply for a Russian passport?',
      ],
      desc: [
        [
          'Да, гражданин России, находящийся за рубежом, может оформить новый загранпаспорт на основании действующего загранпаспорта РФ',
          'Yes, a Russian passport can also be obtained based on the internal passport.',
        ],
      ],
    },
    {
      userName: [
        'У меня нет Российского загранпаспорта, но есть внутренний паспорт РФ. Могу ли я подать на оформление Российского загранпаспорта?',
        'I currently reside in Ukraine and my Russian passport is about to expire. Where can I replace it?',
      ],
      desc: [
        [
          'Да, по внутреннему паспорту РФ также возможно оформить загранпаспорт',
          'At present, Russian citizens residing in Ukraine can only obtain a Russian passport on the territory of neighboring states bordering Ukraine (or in other states) where Russian consulates operate: Poland, Belarus, etc.',
        ],
      ],
    },
    {
      userName: [
        'Я в настоящий момент проживаю на территории Украины и у меня скоро заканчивается срок действия загранпаспорта РФ. Где я могу его поменять?',
        'I currently reside in Ukraine and my Russian passport is about to expire. Where can I replace it?',
      ],
      desc: [
        [
          'На настоящий момент, российские граждане, проживающие в Украине, могут оформить российский загранпаспорт только на территории граничащих с Украиной государств (либо на территории других государств), в которых функционируют Российские консульства: Польша, Беларусь и т.д.',
          'At present, Russian citizens residing in Ukraine can only obtain a Russian passport on the territory of neighboring states bordering Ukraine (or in other states) where Russian consulates operate: Poland, Belarus, etc.',
        ],
      ],
    },
  ],

  orderDescription: [
    [
      {
        headerText: 'Оформление заявки',
        cardText:
          'Опишите вашу ситуацию. Направьте нам требуемый пакет документов и сведения о трудовом стаже. Это возможно сделать как на этапе оформления заявки на сайте, так и после консультации с юристом.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Заполнение заявления на оформление загранпаспорта',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали, и заполнит онлайн-заявление на портале КД МИД России. Вы получите QR-код, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Подача документов на оформление',
        cardText:
          'В день записи приходите в консульство с пакетом документов, QR-кодом и распечатанным заявлением для подачи на оформление загранпаспорта',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submission of an Application',
        cardText:
          'Describe your situation. Send us the required package of documents and information about your work experience. This can be done both at the stage of submitting an application on the website and after consulting with a lawyer.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Filling Out a Passport Application',
        cardText:
          'Our lawyer will contact you, discuss the details, and fill out the online application on the portal of the Consular Department of the Ministry of Foreign Affairs of Russia. You will receive a QR code that you have to take with you to the consulate. The service completion time is 2 business days from the date of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Scheduling an appointment at the Consulate',
        cardText:
          'Schedule an appointment at the consulate on your own or with our assistance (according to the selected tariff).',
        cardImage: calendarImage,
      },
      {
        headerText: 'Submission of Documents',
        cardText:
          'On the day of the appointment, arrive to the consulate with the package of documents, QR code, and printed application for submitting your passport application.',
        cardImage: stampImage,
      },
    ],
  ],

  whoNeedsPassport: ['Кому нужен загранпаспорт?', 'Who needs a passport?'],
  toWhom: ['Кому оформляется', 'To whom is it issued?'],
  passportVariant: [
    'Какой вид загранпаспорта вы хотите оформить? ',
    'What type of passport would you like to apply for?',
  ],
  readDifference: ['Читать, чем отличаются', 'Read how they differ'],
  doYouHavePassport: [
    'Есть ли у вас действующий загранпаспорт РФ?',
    'Do you have a valid Russian passport?',
  ],
  doYouHavePassport1: [
    'Есть ли действующий внутренний паспорт РФ?',
    'Do you have a valid Russian internal passport?',
  ],
  yes: ['Да', 'Yes'],
  no: ['Нет', 'No'],
  reasonOfPassportIssue: [
    'По какой причине оформляется загранпаспорт?',
    'For what reason is a foreign passport issued?',
  ],
  additionalPassport: [
    'В дополнение к уже имеющемуся',
    'In addition to the existing one',
  ],
  isExpired: ['Истекает срок действия', 'The expiration date is approaching.'],
  familyNameChangeMarriage: [
    'Смена фамилии в связи с регистрацией или расторжением брака',
    'Change of surname due to marriage registration or divorce.',
  ],
  familyNameChange: [
    'Смена ФИО вследствие специальной процедуры ЗАГС',
    "Change of full name as a result of a special procedure by the Registrar's Office.",
  ],
  citizenshipConfirmation: [
    'Подтверждение наличия гражданства РФ',
    'Confirmation of Russian citizenship.',
  ],
  citizenshipConfirmationText: [
    'Для подачи на оформление загранпаспорта вам нужно предоставить документы, содержащие отметку (или вкладыш) с указанием гражданство РФ. ',
    'To apply for the issuance of a passport, you need to provide documents containing a stamp (or insert) indicating Russian citizenship.',
  ],
  iHaveIt: ['У меня есть такой документ', 'I have this document'],
  citizenshipConfirmationText1: [
    'Перед подачей документов на оформление загранпаспорта вам нужно пройти процедуру проверки на гражданство РФ.',
    'Before submitting documents for obtaining a foreign passport, you need to undergo a procedure to verify Russian citizenship.',
  ],
  readDetailsConfirmation: [
    'Читать подробнее о процедуре',
    'Read more about the procedure.',
  ],
  alreadyConfirmed: [
    'Я прошел проверку на гражданство',
    'I have undergone citizenship verification.',
  ],
  exitFromCitizenship: [
    'Выход из гражданства РФ',
    'Renunciation of Russian citizenship',
  ],
  photoRequirements: [
    {
      type: 'title',
      text: ['Требования к фотографии', 'Photo Requirements'],
    },
    {
      type: 'paragraph',
      text: [
        'Для загранпаспорта нового образца (на 10 лет) сфотографируют прямо в консульстве. Приносить фото с собой не нужно. Фото будет чёрно-белым.',
        'For a new type of passport (for 10 years), the photo will be taken directly at the consulate. Bringing a photo with you is not necessary. The photo will be black and white.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Для загранпаспорта старого образца (на 5 лет) нужно сделать фотографии самостоятельно и принести на приём в консульство.',
        'For an old type of passport (for 5 years), you need to take the photos yourself and bring them to the consulate.',
      ],
    },
    {
      type: 'list',
      items: [
        ['количество: 4 штуки', 'quantity: 4 pieces'],
        ['цветное или чёрно-белое', 'color or black and white'],
        ['размер: 35x45 мм', 'size: 35x45 mm'],
        [
          'овал лица занимает 70-80% от фото',
          'face oval occupies 70-80% of the photo',
        ],
        [
          'лицо смотрит на камеру, глаза должны быть открытыми, а волосы не должны заслонять их',
          'face looks at the camera, eyes should be open, and hair should not cover them',
        ],
        ['выражение лица нейтральное', 'neutral facial expression'],
        ['всё лицо чёткое, в фокусе', 'entire face clear, in focus'],
        [
          'фон светлый, ровный, без полос, пятен и изображения посторонних предметов и теней',
          'background light, even, without stripes, spots, or images of foreign objects and shadows',
        ],
        ['фото соответствует возрасту', 'photo matches the age'],
        [
          'если религия обязывает покрывать голову, овал лица должно быть чётко видно. В иных случаях головных уборов быть не должно',
          'if religion requires covering the head, the oval of the face should be clearly visible. In other cases, headwear should not be present',
        ],
        [
          'не допускается фотографирование в очках с затемнёнными стёклами и (или) в форменной одежде',
          'photography in glasses with tinted lenses and (or) in uniform clothing is not allowed',
        ],
      ],
    },
  ],
  workflow: {
    whoNeedsPassport: {
      title: ['Кому нужен загранпаспорт?', 'Who needs a foreign passport?'],
      options: whoNeedsPassportOptions,
    },
    passportVariant: {
      title: [
        'Какой вид загранпаспорта вы хотите оформить?',
        'What type of foreign passport do you want to apply for?',
      ],
      options: passportVariants,
      link: ['Читать, чем отличаются', 'Read how they differ'],
    },
    childHasPassport: {
      title: [
        'Есть ли у ребенка на данный момент другой действующий загранпаспорт РФ?',
        'Does the child currently have another valid Russian foreign passport?',
      ],
      options: binaryOptions,
    },
    adultHasPassport: {
      title: {
        hasForeignPassport: [
          'Есть ли другой действующий загранпаспорт РФ?',
          'Is there another valid Russian foreign passport?',
        ],
        hasInternalPassport: [
          'Есть ли действующий внутренний паспорт РФ?',
          'Is there a valid internal Russian passport?',
        ],
      },
      options: binaryOptions,
    },
    reasonOfPassportIssue: {
      title: [
        'По какой причине оформляется загранпаспорт?',
        'For what reason is the foreign passport issued?',
      ],
      optionsHasValidPassport: [
        ['В дополнение к уже имеющемуся', 'In addition to the existing one'],
        ['Истекает срок действия', 'The validity period is expiring'],
        [
          'Смена фамилии в связи с регистрацией или расторжением брака',
          'Change of surname due to registration or dissolution of marriage',
        ],
        [
          'Смена ФИО вследствие специальной процедуры ЗАГС',
          'Change of name due to a special registry office procedure',
        ],
      ],
      optionsHasNoValidPassport: [
        ['Первичное оформление', 'Initial issue'],
        ['Истек срок действия', 'The validity period has expired'],
        ['Паспорт был утерян / украден', 'Passport was lost / stolen'],
      ],
    },
    citizenshipConfirmation: {
      title: [
        'Подтверждение наличия гражданства РФ',
        'Confirmation of Russian citizenship',
      ],
      variants: {
        initialIssue: {
          description: [
            'Для подачи на оформление загранпаспорта вам нужно предоставить документы, содержащие отметку (или вкладыш) с указанием гражданства РФ.',
            'To apply for a foreign passport, you need to provide documents containing a mark (or insert) indicating Russian citizenship.',
          ],
          checkbox: ['У меня есть такой документ', 'I have such a document'],
        },
        other: {
          description: [
            'Перед подачей документов на оформление загранпаспорта вам нужно пройти процедуру проверки на гражданство РФ.',
            'Before submitting documents for a foreign passport, you need to undergo the procedure for checking Russian citizenship.',
          ],
          checkbox: [
            'Я прошел проверку на гражданство',
            'I have passed the citizenship check',
          ],
        },
      },
    },
  },
  differencesBetweenOldAndNew: [
    {
      type: 'title',
      text: [
        'Чем отличаются между собой загранпаспорта сроком на 5 и на 10 лет?',
        'What is the difference between 5-year and 10-year passports?',
      ],
    },
    {
      type: 'list',
      title: ['Срок действия:', 'Validity:'],
      items: [
        ['нового образца — 10 лет', 'new generation - 10 years'],
        ['старого образца — 5 лет', 'old generation - 5 years'],
      ],
    },
    {
      type: 'list',
      title: [
        'Количество страниц (доступное пространство под визы и отметки контроля):',
        'Number of pages (available space for visas and control marks):',
      ],
      items: [
        ['нового образца — 46 страниц', 'new generation - 46 pages'],
        ['старого образца — 36 страниц', 'old generation - 36 pages'],
      ],
    },
    {
      type: 'list',
      title: ['Степень защиты:', 'Protection level:'],
      items: [
        [
          'в паспорта нового образца монтируется микрочип с цветным цифровым изображением лица и папиллярных узоров 2 пальцев рук. Паспорт надёжно защищён от подделки и позволяет быстрее проходить погранконтроль',
          'The new passport contains a microchip with a color digital image of the face and papillary patterns of 2 fingers. The passport is reliably protected from counterfeiting and allows for faster border control.',
        ],
        [
          'в паспортах старого поколения биометрических данных нет',
          'Old generation passports do not contain biometric data',
        ],
      ],
    },
    {
      type: 'list',
      title: ['Фото на паспорт:', 'Passport photo:'],
      items: [
        [
          'на паспорт нового образца вас сфотографируют на специальном оборудовании в консульстве, дополнительно приносить с собой фотографии не нужно',
          'The new passport contains a microchip with a color digital image of the face and papillary patterns of 2 fingers. The passport is reliably protected from counterfeiting and allows for faster border control.',
        ],
        [
          'для паспорта старого образца нужно принести фото с собой',
          'Old generation passports do not contain biometric data',
        ],
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Вписывать ребёнка можно только в паспорта старого образца.',
        'A child can only be entered into old-style passports.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Дополнительно к уже имеющемуся можно оформить только загранпаспорт нового образца.',
        'In addition to the one you already have, you can only apply for a new-style passport.',
      ],
    },
  ],
  workActivityTips: [
    {
      type: 'title',
      text: [
        'Как указывать данные о работе и учёбе',
        'How to provide information about work and education',
      ],
    },
    {
      type: 'list',
      items: [
        [
          'Укажите все сведения о трудовой деятельности, учёбе и службе за последние 10 лет',
          'Provide all information about employment, education, and service for the last 10 years',
        ],
        [
          'Если вы работали, но не вносили сведения в трудовую книжку, их нужно указать по трудовому договору',
          'If you worked but did not enter the information in the work book, you need to provide it according to the employment contract',
        ],
        [
          'Если компания находится не в России, напишите страну и адрес русскими буквами',
          'If the company is not located in Russia, write the country and address in Russian letters',
        ],
        [
          'Для одной организации указывайте все должности и их период. Например: 11.2020–05.2021 — кассир, 05.2021–12.2021 — бухгалтер',
          'For one organization, list all positions and their period. For example: 11.2020–05.2021 — cashier, 05.2021–12.2021 — accountant',
        ],
        [
          'Название должности указывается русскими буквами и в переводе на русский язык',
          'The job title is indicated in Russian letters and translated into Russian',
        ],
        [
          'Если работали в нескольких местах по совместительству, укажите все места работы',
          'If you worked in several places part-time, list all workplaces',
        ],
        [
          'Если работали и учились одновременно, укажите и место работы, и место учёбы',
          'If you worked and studied at the same time, indicate both the place of work and the place of study',
        ],
        [
          'Если находились на длительном больничном, в декрете или отпуске без сохранения заработной платы, укажите место работы, службы или учёбы в это время',
          'If you were on a long sick leave, maternity leave, or unpaid leave, indicate the place of work, service, or study during this time',
        ],
      ],
    },
    {
      type: 'list',
      title: ['Где искать информацию:', 'Where to find information:'],
      items: [
        ['ваша трудовая книжка', 'your work book'],
        [
          'сайт налоговой (содержит сведения о компании и её адрес)',
          'tax website (contains information about the company and its address)',
        ],
        [
          'справка из ПФР (можно заказать через госуслуги)',
          'certificate from the Pension Fund (can be ordered through public services)',
        ],
      ],
    },
  ],
}

export default vocabularyPassport
