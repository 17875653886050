import { v4 as uuid } from 'uuid'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { search } from 'api/search'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'
import { Header } from 'components/Workflow/WorkflowContainer'
import { Box, Typography } from '@mui/material'
import { Input } from 'components/Input/Input'
import { useForm } from 'react-hook-form'
import { useEffect, useRef, useState } from 'react'
import { SearchResult } from './SearchResult'
import { useOnKeyDown } from './hooks/useOnKeyDown'
import { vocabulary, titleByPath } from 'vocabulary/vocabularySearch'
import GlobalContext from 'GlobalContext'

export const SearchModal = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const isOpened = searchParams?.get('open') === 'search'
  const searchParamsQuery = searchParams?.get('query')
  const searchModalRef = useRef(null)
  const [searchResults, setSearchResults] = useState([])
  const { langSelected } = useContext(GlobalContext)
  const navigate = useNavigate()

  const onEnterPressed = ({ link }) => {
    navigate(link.to)
  }

  const { active, setActive, onKeyDown } = useOnKeyDown({
    onEnterPressed,
    values: searchResults,
  })

  searchModalRef.current?.focus()

  useEffect(() => {
    if (searchModalRef.current) {
      searchModalRef.current.addEventListener('keydown', onKeyDown)
    }

    return () =>
      searchModalRef.current?.removeEventListener('keydown', onKeyDown)
  }, [searchModalRef.current, onKeyDown])

  const handleClose = () => {
    const { open, ...restParams } = searchParams

    setSearchParams(restParams)
  }

  const { control, watch, setValue } = useForm({
    defaultValues: { query: searchParamsQuery },
  })
  const formValues = watch()

  useEffect(() => {
    if (searchParamsQuery) {
      setValue('query', searchParamsQuery)
    }
  }, [searchParamsQuery])

  const updateSearchResults = async query => {
    const { searchResults } = await search(query, langSelected)

    setSearchResults(
      searchResults.map(searchResult => ({
        title:
          titleByPath[searchResult.link.to]?.[langSelected] ||
          vocabulary.page[langSelected],
        ...searchResult,
      })),
    )
  }

  useEffect(() => {
    const { query } = formValues

    if (query?.length > 1) {
      updateSearchResults(query)
    } else {
      setSearchResults([])
    }
  }, [formValues.query])

  return (
    <DialogModalWindow open={isOpened}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: '1 0 auto',
          maxWidth: '100%',
        }}
      >
        <Box sx={{ mx: '16px' }}>
          <Header onClose={handleClose} onBackward={handleClose} />

          <Typography variant='titleLarge'>
            {vocabulary.searchResults[langSelected]}
          </Typography>

          <Input
            autocomplete='off'
            ref={searchModalRef}
            control={control}
            name='query'
            placeholder={vocabulary.searchModalInputPlaceHolder[langSelected]}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 2,
              width: 1,
              py: 2,
            }}
          >
            {searchResults.map((item, index) => (
              <SearchResult
                key={uuid()}
                {...item}
                active={active === index}
                onHover={() => setActive(index)}
              />
            ))}
            {searchResults.length === 0
              ? vocabulary.notFound[langSelected]
              : null}
          </Box>
        </Box>
      </Box>
    </DialogModalWindow>
  )
}
