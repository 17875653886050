import { Switch as MuiSwitch, styled } from '@mui/material'

import { crossIcon } from './assets/cross'
import { checkedIcon } from './assets/checked'

// TODO: add border as in Figma components

export const Switch = styled(MuiSwitch)(({ theme }) => ({
  width: '60px',
  height: '36px',
  padding: '2px 4px',

  '& .MuiSwitch-switchBase': {
    transitionDuration: '250ms',
    padding: 0,
    transform: 'translate(8px, 6px)',

    '&.Mui-checked': {
      transform: 'translate(28px, 6px)',

      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.primary,
        opacity: 1,
        border: 0,
      },

      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff',
      },

      '& .MuiSwitch-thumb:before': getSwitchIcon(
        checkedIcon,
        theme.palette.primary.onPrimaryContainer,
      ),
    },
  },

  '& .MuiSwitch-thumb': {
    width: 24,
    height: 24,

    boxShadow: 'none',
    backgroundColor: theme.palette.primary.outlined,

    '&:before': getSwitchIcon(
      crossIcon,
      theme.palette.primary.surfaceContainerHigh,
    ),
  },

  '& .MuiSwitch-track': {
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.surfaceContainerHigh,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

const getSwitchIcon = (icon, color) => ({
  width: '100%',
  height: '100%',
  content: "''",

  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundImage: `url('data:image/svg+xml;utf8,${encodeURIComponent(icon)}')`,

  color,
})
