import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import GlobalContext from 'GlobalContext.js'
import CountrySelection from '../../Workflow/CountrySelection.js'
import Payment from '../../Workflow/Payment.js'
import GetUserDetails from '../../Workflow/GetUsersDetails.js'
import RequestSuccessSent from '../../Workflow/RequestSuccessSent.js'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'
import RegistryOfficeInfo from './RegistryOfficeInfo.js'
import { getSteps } from './steps/index.js'
import WorkflowStep from 'components/Workflow/WorkflowStep'
import vocabularyZags from 'vocabulary/vocabularyZags.js'
import CheckoutForm from 'pages/components/Workflow/CheckoutForm.js'

export function RegistryOfficeDialog() {
  const { state, langSelected } = React.useContext(GlobalContext)

  const open = state.dialogService.section === 'zags'

  const { step: currentStep } = state.dialogService
  const workflowSteps = getSteps(langSelected)
  const price = {
    withAppointment: vocabularyZags.priceWithAppointment[1].value.substring(
      0,
      2,
    ),
    withoutAppointment:
      vocabularyZags.priceWithoutAppointment[1].value.substring(0, 2),
  }

  return (
    <DialogModalWindow open={open}>
      {currentStep === -1 ? <RegistryOfficeInfo /> : null}
      {currentStep === 0 ? <CountrySelection height='auto' /> : null}
      {workflowSteps.map(({ step, ...workflowSchema }) =>
        currentStep === step ? (
          <WorkflowStep key={uuidv4()} {...workflowSchema} />
        ) : null,
      )}
      {currentStep === 6 ? <GetUserDetails height='auto' /> : null}
      {currentStep === 7 ? <Payment price={price} /> : null}
      {currentStep === 8 ? <CheckoutForm price={price} /> : null}
      {currentStep === 9 ? <RequestSuccessSent height='auto' /> : null}
    </DialogModalWindow>
  )
}
