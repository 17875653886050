import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyPassport'

const reasonOfIssue =
  ({ hasValidPassport }) =>
  langSelected => {
    const { title, optionsHasValidPassport, optionsHasNoValidPassport } =
      vocabulary.workflow.reasonOfPassportIssue
    const options = hasValidPassport
      ? optionsHasValidPassport
      : optionsHasNoValidPassport

    return createStepSchema(
      title[langSelected],
      [
        {
          name: 'serviceDetails.reasonOfIssue',
          type: 'radio',
          componentProps: {
            options: createOptions(options, langSelected),
          },
        },
      ],
      ({ serviceDetails }) => ({
        serviceDetails: {
          reasonOfIssue: serviceDetails?.reasonOfIssue,
        },
      }),
    )
  }

export const reasonIfHasValidPassport = reasonOfIssue({
  hasValidPassport: true,
})
export const reasonIfHasNoValidPassport = reasonOfIssue({
  hasValidPassport: false,
})
