import React, { useContext, useState } from 'react'
import { useTheme, Box, Typography, Card } from '@mui/material/'
import Layout600 from '../Layouts/Layout600'
import GlobalContext from '../../GlobalContext'
import { useNavigate } from 'react-router-dom'
import CookiePolicy from 'pages/components/CookiePolicy'
import vocabulary from 'vocabulary/vocabulary'

export default function CookiePolicy600() {
  const { langSelected } = useContext(GlobalContext)
  const [stateMenu, setMenu] = useState(false)
  const navigate = useNavigate()

  function setStateMenu(val) {
    setMenu(val)
  }

  const theme = useTheme()

  return (
    <Layout600
      stateMenu={stateMenu}
      setStateMenu={setStateMenu}
      langSelected={langSelected}
      theme={theme}
      menu='true'
    >
      <Box sx={{ mt: 8 }}>
        <Typography variant='h1Small' component='p' sx={{ mx: 2 }}>
          {vocabulary.cookiePolicy[langSelected]}
        </Typography>
        <CookiePolicy />
      </Box>
    </Layout600>
  )
}
