import React, { useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Divider,
  Grid,
  useMediaQuery,
  Box,
  Typography,
  Container,
} from '@mui/material'
import { RequestDetails } from './__Details/RequestDetails'
import { RequestSummary } from './__Summary/RequestSummary'
import { RequestDocuments } from './__Documents/RequestDocuments'
import { RequestControls } from './__Controls/RequestControls'
import { RequestTabs } from './__Tabs/RequestTabs'
import GlobalContext from 'GlobalContext'
import { formatDateToDDMMYYYY } from 'utils/formatDateToDDMMYYYY'
import { vocabulary } from 'vocabulary/vocabularyRequest'
import Loading from 'pages/Loading'
import { getTranslation } from 'pages/helpers/workflowStepSchema'
import { RequestEditButton } from './__EditButton/RequestEditButton'
import { getTitleAndSubtitle } from './hooks/getTitleAndSubtitle'

const documents = [
  { label: 'Документ 1', value: 'Описание документа 1' },
  { label: 'Документ 2', value: 'Описание документа 2' },
]

export const Request = () => {
  const [tabIndex, setTabIndex] = React.useState(0)
  const { state, isLoading, langSelected } = useContext(GlobalContext)
  const { id } = useParams()
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))
  const isLargeDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const navigate = useNavigate()

  if (isLoading) {
    return <Loading />
  }

  const { requestData, requestStatus, createdAt, appointment } =
    state.userRequests?.filter(request => request.id === id)[0] || {}

  if (!requestData) {
    navigate('/home')

    return null
  }

  const data = JSON.parse(requestData)
  console.log(state)

  const handleTabChange = newIndex => {
    setTabIndex(newIndex)
  }

  const summary = getSummary({
    requestData: data,
    requestStatus,
    createdAt,
    appointment,
    langSelected,
  })
  const details = getDetails(data)

  const namings = getTitleAndSubtitle(data, langSelected)

  return (
    <Container
      sx={{ pt: { xs: 3, md: 6 }, px: { xs: 2, sm: 4, md: 15, lg: 3 } }}
    >
      <RequestControls />

      <Box sx={{ mt: { xs: 2, md: 3, xl: 5 } }}>
        <Typography variant='h5'>
          {vocabulary.application[langSelected]} № {id.slice(-4)}
        </Typography>
        {isLargeDesktop ? <Divider sx={{ mt: 3 }} /> : null}
      </Box>

      <Grid
        container
        columnSpacing={{ xs: 2, sm: 1, md: 1, lg: 15 }}
        columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={12}
          lg='auto'
          sx={{ pt: { lg: 5, xs: 2 } }}
        >
          <RequestTabs
            aria-label='application tabs'
            selected={tabIndex}
            onChange={handleTabChange}
            items={vocabulary.tabs.map(
              getTranslation(langSelected === 0 ? 'ru' : 'en'),
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={10}
          lg={6}
          sx={{
            px: 4,
            pt: { lg: 5, xs: 4 },
          }}
        >
          {tabIndex === 0 && (
            <RequestSummary title={namings.summary.title} content={summary} />
          )}
          {tabIndex === 1 && (
            <RequestDetails
              title={namings.details.title}
              subtitle={namings.details.subtitle}
              details={details}
            />
          )}
          {tabIndex === 2 && <RequestDocuments documents={documents} />}
        </Grid>

        {/* {isDesktop ? (
          <Grid item md lg sx={{ pt: { lg: 5, md: 4 } }}>
            <RequestEditButton>
              {vocabulary.edit[langSelected]}
            </RequestEditButton>
          </Grid>
        ) : null} */}
      </Grid>
    </Container>
  )
}

const getSummary = ({
  requestData,
  requestStatus,
  createdAt,
  langSelected,
  appointment,
}) => {
  const { country, city } = requestData

  return [
    {
      label: vocabulary.сreatedDate[langSelected],
      value: formatDateToDDMMYYYY(createdAt),
    },
    {
      label: vocabulary.consulation[langSelected],
      value: getConsulation({ country, city }, langSelected),
    },
    {
      label: vocabulary.tariff[langSelected],
      value: appointment
        ? vocabulary.tariffValue.withAppointment[langSelected]
        : vocabulary.tariffValue.withoutAppointment[langSelected],
    },
    { label: vocabulary.sumToPaid[langSelected], value: '80 $' },
    { label: vocabulary.paymentStatus[langSelected], value: 'Не оплачено' },
    {
      label: vocabulary.requestStatus[langSelected],
      value: `${vocabulary.statusValue[requestStatus][langSelected]}`,
    },
  ]
}

const getDetails = ({ serviceDetails }) =>
  serviceDetails
    ? Object.keys(serviceDetails).map(key => ({
        label: key,
        value: serviceDetails[key],
      }))
    : []

const getConsulation = ({ country, city }, langSelected) => {
  if (!country && !city) {
    return vocabulary.notChosen[langSelected]
  }

  let text = `${country || ''}`

  if (city) {
    text += `, ${city}`
  }

  return text
}
