import vocabulary from 'vocabulary/vocabularyConsent'
import { createStepSchema } from 'pages/helpers/workflowStepSchema'

export const requestRightsAreBeingTransferred = langSelected => {
  const { title, additionalInfo } =
    vocabulary.workflow.requestRightsAreBeingTransferred

  return createStepSchema(title[langSelected], [
    {
      name: 'serviceDetails.rightsAreBeingTransferred',
      type: 'text-field',
      componentProps: {
        placeholder: additionalInfo[langSelected],
      },
    },
  ])
}
