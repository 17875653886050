import {
  createOptions,
  createStepSchema,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyConsent'

export const requestConsentType = langSelected => {
  const { title, options, selectPlaceholder, additionalInfo } =
    vocabulary.workflow.requestConsentType

  return createStepSchema(
    title[langSelected],
    [
      {
        name: 'additionalServiceType',
        type: 'select',
        componentProps: {
          label: selectPlaceholder[langSelected],
          options: createOptions(options, langSelected),
        },
      },
      {
        name: 'serviceDetails?.comment',
        type: 'text-field',
        componentProps: {
          placeholder: additionalInfo[langSelected],
        },
      },
    ],
    ({ additionalServiceType, serviceDetails }) => ({
      additionalServiceType,
      serviceDetails: { comment: serviceDetails?.comment },
    }),
  )
}
