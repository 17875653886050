import * as React from 'react'
import { Button, Typography, Box, FormControl } from '@mui/material/'
import GlobalContext from 'GlobalContext'

import crossClose from 'images/icon/cross-close.png'

import vocabulary from 'vocabulary/vocabulary'
import { useLoginFlowClose } from 'pages/helpers/useLoginFlow'

export default function CheckYourMail() {
  const { langSelected } = React.useContext(GlobalContext)

  const closeForm = useLoginFlowClose()

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <FormControl
        sx={{ mx: '16px', display: 'flex', flexDirection: 'column' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '76px',
          }}
        >
          <Box sx={{ cursor: 'pointer' }} onClick={closeForm}>
            <img src={crossClose} alt='cross' width={'24px'} />
          </Box>
        </Box>

        <Typography component='p' variant='titleLarge' sx={{ mb: 4 }}>
          {vocabulary.checkYourMail[langSelected]}
        </Typography>
        <Typography component='p' variant='bodySmall' sx={{}}>
          {vocabulary.checkYourMailSent[langSelected]}
        </Typography>

        <Button
          variant='outlined'
          size='medium'
          sx={{ width: '156px', height: '40px', mt: 4 }}
          color='blueButton'
          onClick={closeForm}
        >
          <Typography
            variant='labelLarge'
            sx={{ textTransform: 'none', ml: 1 }}
          >
            {vocabulary.understand[langSelected]}
          </Typography>
        </Button>
      </FormControl>
    </Box>
  )
}
