import { FileText, Buildings, Stamp } from '@phosphor-icons/react'
import passportImage from '../images/passport-black.png'
import marriageRingsImage from '../images/marriage-black.png'
const vocabularyFaq = {
  faq: ['Часто задаваемые вопросы', 'FAQ'],
  text1: ['Выберите тему:', 'Choose the topic:'],
  typesOfFaq: [
    ['О работе Consul.online', 'About the work of Consul.online'],
    ['Доверенности', 'Powers of Attorney'],
    ['Загранпаспорт', 'Foreign Passport'],
    ['Согласия, заявления', 'Consents, Applications'],
    ['Справки', 'Certificates'],
    ['Документы для юр. лиц', 'Documents for Legal Entities'],
    ['Гражданство', 'Citizenship'],
    ['Услуги ЗАГС', 'Registry Office Services'],
    ['Легализация документов', 'Legalization of documents'],
  ],
  icons: [
    <FileText size={24} weight='light' />,
    <img
      src={passportImage}
      alt='passport'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <FileText size={24} weight='light' />,
    <Stamp size={24} weight='light' />,
    <FileText size={24} weight='light' />,
    <Buildings size={24} weight='light' />,
    <img
      src={passportImage}
      alt='passport'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <img
      src={marriageRingsImage}
      alt='passport'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <Stamp size={24} weight='light' />,
  ],
}
export default vocabularyFaq
