import { createStepSchema } from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyOther'

const requestDocuments = ({ title, additionalInfo }, langSelected) => {
  return createStepSchema(title[langSelected], [
    {
      name: 'details',
      type: 'text-field',
      componentProps: {
        placeholder: additionalInfo[langSelected],
      },
    },
  ])
}

export const requestDocumentsDisponible = langSelected =>
  requestDocuments(vocabulary.workflow.requestDocumentsDisponible, langSelected)

export const requestDocumentToLegalize = langSelected =>
  requestDocuments(vocabulary.workflow.requestDocumentToLegalize, langSelected)
