import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyCitizenship'

export const requestPersonWhoRenounceCitizenship = langSelected => {
  const { title, options } =
    vocabulary.workflow.requestPersonWhoRenounceCitizenship

  return createStepSchema(title[langSelected], [
    {
      name: 'serviceDetails.who',
      type: 'radio',
      componentProps: {
        options: createOptions(options, langSelected),
      },
    },
  ])
}
