import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyLegalEntities'

export const requestApplicationType = langSelected => {
  const { title, options, additionalInfo } =
    vocabulary.workflow.requestApplicationType

  return createStepSchema(
    title[langSelected],
    [
      {
        name: 'documentType',
        type: 'radio-with-additional-text',
        componentProps: {
          options: createOptions(options, langSelected),
          placeholder: additionalInfo[langSelected],
        },
      },
    ],
    ({ additionalServiceType, serviceDetails }) => ({
      documentType: {
        chosen: additionalServiceType,
        text: serviceDetails?.comment,
      },
    }),
    ({ documentType }) => ({
      additionalServiceType: documentType.chosen,
      serviceDetails:
        options.at(-1)[1] === documentType.chosen
          ? { comment: documentType.text }
          : {},
    }),
  )
}
