import * as React from 'react'
import { ServiceInfoModal } from './__Modal/ServiceInfoModal'
import { itemRenderer } from './itemRenderer'
import { useClearWorkflowSearchParams } from 'pages/helpers/useClearWorkflowSearchParams'

export default function ServiceInfo({ onSubmit, onClose, content }) {
  const clearSearhParams = useClearWorkflowSearchParams()

  const handleSubmit = event => {
    event.preventDefault()
    clearSearhParams()
    onSubmit?.()
  }

  const handleClose = () => {
    clearSearhParams()

    onClose?.()
  }

  return (
    <ServiceInfoModal onClose={handleClose} submit={handleSubmit}>
      {content.map((item, index) => itemRenderer(item, index))}
    </ServiceInfoModal>
  )
}
