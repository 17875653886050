import React from 'react'
import { FormControlLabel } from '@mui/material'
import { Switch as StyledSwitch } from 'components/Switch/Switch'

export const Switch = ({ title, value = false, onChange }) => (
  <FormControlLabel
    control={<StyledSwitch checked={value} onChange={onChange} />}
    label={title}
    labelPlacement='start'
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      py: 1,
      pl: 1,
      m: 0,
    }}
  />
)
