import vocabulary from 'vocabulary/vocabularyCitizenship'
import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'

const requestCitizenshipInfo = period => {
  return langSelected => {
    const { title, options } = vocabulary.workflow.requestCitizenshipInfo

    return createStepSchema(title[langSelected], [
      {
        name: 'service',
        type: 'radio',
        componentProps: {
          options: createOptions(options[period], langSelected),
        },
      },
    ])
  }
}

export const requestCitizenshipInfoForPreviousPeriod =
  requestCitizenshipInfo('previousPeriod')
export const requestCitizenshipInfoForCurrentPeriod =
  requestCitizenshipInfo('nowadays')
