const vocabularyUserAgreement = [
  [
    {
      title: 'Общие положения',
      content: [
        '1.1. Компания «ICC Consul» LLP (KZ), далее именуемая «Оператор», предлагает услуги посредством сайта https://consul.online (далее «Сайт»).',
        '1.2. Настоящее Пользовательское соглашение (далее «Договор» или «Оферта»), является официальным предложением Оператора любому Пользователю сайта (далее «Пользователь»), принять его условия для получения доступа к предлагаемым услугам.',
        '1.3. Акцептом (безусловным принятием) условий настоящего Договора (моментом заключения Договора) считается факт оплаты Пользователем услуг, выбранных Пользователем на сайте.',
        '1.4. Осуществляя акцепт настоящей Оферты в порядке, определённом в п. 1.3 настоящего Договора, Пользователь соглашается со всеми условиями Договора в том виде, в каком они изложены в тексте настоящего Договора, а также со всеми условиями реализации функций Оператора.',
        '1.5. Заключая настоящий Договор, Пользователь понимает значение своих действий и способен руководить ими, не находится под влиянием заблуждения, обмана, насилия, угрозы. Договор заключен Пользователем добровольно, с предварительным полным ознакомлением с условиями настоящей Оферты, порядка оказания услуг изложенных на Сайте, содержание которых Пользователю понятно.',
      ],
    },
    {
      title: 'Предмет Договора',
      content: [
        '2.1. Оператор предоставляет Пользователю доступ к сайту для оказания следующих услуг:',
        [
          'подготовка проектов документов для Пользователя и их последующее размещение на консульском портале',
          'запись Пользователя на приём в Консульство',
          'предоставление консультационных услуг, в том числе в целях получения Пользователем консульских услуг',
        ],
        'Под «Консульством» Оператор и Пользователь понимают консульские учреждения Российской Федерации и консульские отделы дипломатических представительств Российской Федерации в стране нахождения Пользователя. Пользователь использует функционал сайта в соответствии с правилами и регуляциями, установленными Оператором.',
        '2.2. Пользователь принимает условия Договора и обязуется их соблюдать.',
        '2.3. Оплата услуги от Пользователя на сайте или любым другим способом является подтверждением его согласия с условиями настоящего Договора. Пользователь, завершивший процесс оплаты, считается принявшим оферту Оператора без каких-либо оговорок.',
        '2.4. Оператор обеспечивает доступ к полному тексту Публичного договора на платформе, который Пользователь обязан прочитать до начала оплаты услуг. Предоставление данных Пользователя Оператору означает полное и безоговорочное принятие Пользователем всех условий настоящего Договора.',
      ],
    },
    {
      title: 'Права и обязанности сторон',
      content: [
        '3.1. Оператор обязуется предоставить доступ к Сайту в рамках технических возможностей и соблюдения конфиденциальности информации Пользователя.',
        '3.2. Пользователь обязуется использовать Сайт только в законных целях и не предпринимать действий, нарушающих работу сайта или права других пользователей.',
      ],
    },
    {
      title: 'Условия использования сайта',
      content: [
        '4.1. Пользователь имеет право на доступ к различным услугам Сайта. Каждый запрос на услуги, предоставляемые через Сайт, рассматривается как акцепт от Пользователя, которую Оператор может принять или отклонить в соответствии с внутренними правилами и доступностью услуг.',
        '4.2. Акцепт Пользователя считается принятым Оператором с момента подтверждения обработки запроса через Сайт. Детали и условия исполнения услуги регулируются спецификацией запроса и подтверждением от Оператора.',
        '4.3. Все права на товарные знаки, авторские права, права на базы данных и другие права интеллектуальной собственности на содержимое Сайта (включая его организацию, макет и исходный код программного обеспечения), принадлежат Оператору. Пользователю запрещается полностью или частично копировать, изменять, распространять, использовать или воспроизводить содержимое Сайта без предварительного письменного разрешения Оператора, кроме случаев личного использования при условии сохранения всех авторских прав и других уведомлений, содержащихся в них.',
        '4.4. Запрещаются любые действия, мешающие работе Сайта или создающие чрезмерную нагрузку на его инфраструктуру, включая загрузку файлов, заражённых вирусами, а также любые попытки получить несанкционированный доступ к технической инфраструктуре Сайта.',
      ],
    },
    {
      title: 'Разрешение споров',
      content: [
        '5.1. Все споры и разногласия, возникающие из данного Договора, стороны стараются решать путём переговоров.',
        '5.2. Если спор не может быть урегулирован переговорами, он подлежит рассмотрению в соответствующем суде по месту регистрации Оператора.',
        '5.3. Оператор не несёт ответственности за последствия, связанные с предоставлением Пользователем недостоверных, искажённых, с истёкшим сроком сведений.',
      ],
    },
    {
      title: 'Права и обязанности Оператора',
      content: [
        '6.1. Оператор при оказании Услуг руководствуется нормативно-правовыми актами в соответствии с действующим применимым законодательством.',
        '6.2. Оператор оказывает Услуги только после проверки всех данных предоставленных Пользователем.',
        '6.3. Оператор может отказать в оказании Услуг в случае непредоставления необходимых данных Пользователем, предусмотренных соответствующим разделом на Сайте.',
        '6.4. Оператор имеет право по своему усмотрению изменять тарифы на оказание Услуг, а также настоящий Договор (Оферту), либо отзывать их в соответствии с условиями настоящего Договора.',
        '6.5. Оператор не оказывает Пользователю Услуги, указанные в заявке, но не оплаченные Пользователем.',
        '6.6. Оператор обязуется соблюдать высокие стандарты профессионализма и этики при оказании услуг и во взаимодействии с Пользователями, а также защищать конфиденциальность и личные данные Пользователей в соответствии с применимым законодательством.',
      ],
    },
    {
      title: 'Заключительные положения',
      content: [
        '7.1. Договор вступает в силу с момента его акцепта Пользователем и действует на неопределённый срок.',
        '7.2. Исполнитель не принимает на себя никаких иных условий, не обозначенных в Договоре.',
      ],
    },
  ],

  [
    {
      title: 'General Provisions',
      content: [
        "1.1. ICC Consul LLP (KZ), hereinafter referred to as the 'Operator', offers services via the https://consul.online website (hereinafter referred to as the 'Website').",
        "1.2. This User Agreement (hereinafter referred to as the Agreement or Offer) is an official offer from the Operator to any User of the Website (hereinafter referred to as the User) to accept its terms in order to gain access to the offered services.",
        '1.3. Acceptance (unconditional acceptance) of the terms of this Agreement (the moment of the conclusion of the Agreement) is considered to be the fact of payment by the User for the services selected by the User on the Website.',
        "1.4. By accepting this Offer in the manner defined in clause 1.3 of this Agreement, the User agrees with all the terms of the Agreement in the form in which they are stated in the text of this Agreement, as well as with all the conditions for the implementation of the Operator's functions.",
        '1.5. By entering into this Agreement, the User understands the significance of their actions and is capable of managing them, is not under the influence of delusion, fraud, violence, threat. The Agreement is concluded by the User voluntarily, with prior full acquaintance with the terms of this Offer, the details of rendering services on the Website, the content of which is clear to the User.',
      ],
    },
    {
      title: 'Subject of the Agreement',
      content: [
        '2.1. The Operator provides the User with access to the Website for the provision of the following services:',
        [
          'preparation of draft documents for the User and their subsequent submission to the consulate portal',
          'scheduling an appointment at the Consulate for the User',
          'provision of consulting services, including those for obtaining consular services by the User',
        ],
        "Under the 'Consulate'the Operator and the User understand the consular institutions of the Russian Federation and the consular departments of the diplomatic missions of the Russian Federation in the country of residence of the User. The User uses the functionality of the Website in accordance with the rules and regulations established by the Operator.",
        '2.2. The User accepts the terms of the Agreement and undertakes to comply with them.',
        "2.3. The payment for the service by the User on the Website or in any other way shall be the confirmation of their agreement with the terms of this Agreement. The User, having completed the payment process, is considered to have accepted the Operator's Offer without any reservations.",
        "2.4. The Operator provides access to the full text of the Public Agreement on the platform, which the User must read before starting to pay for services. Providing the User's data to the Operator means the full and unconditional acceptance by the User of all the terms of this Agreement.",
      ],
    },
    {
      title: 'Rights and Obligations of the Parties',
      content: [
        "3.1. The Operator undertakes to provide access to the Website within the technical capabilities and keeping the confidentiality of the User's information.",
        '3.2. The User undertakes to use the Website only for lawful purposes and not to take actions that disrupt the operation of the Website or the rights of other Users.',
      ],
    },
    {
      title: 'Terms of Use of the Website',
      content: [
        '4.1. The User has the right to access various services of the Website. Each request for services sent through the Website is considered as the acceptance from the User, which the Operator can accept or reject in accordance with the internal rules and the availability of services.',
        "4.2. The acceptance by the User is considered to be accepted by the Operator from the moment of confirmation of the request processing through the Website. The details and conditions for the provision of the service are regulated by the specification of the request and confirmation from the Operator.",
        '4.3. All rights to trademarks, copyrights, database rights, and other intellectual property rights on the content of the Website (including its organization, layout, and source code of the software) belong to the Operator. The User is prohibited from fully or partially copying, modifying, distributing, using, or reproducing to the content of the Website without the prior written permission of the Operator, except for cases of personal use, provided that all copyright and other notices contained therein are retained.',
        '4.4. Any actions that interfere with the operation of the Website or create an excessive load on its infrastructure are prohibited, including uploading files infected with viruses, as well as any attempts to gain unauthorized access to the technical infrastructure of the Site.',
      ],
    },
    {
      title: 'Dispute Resolution',
      content: [
        '5.1. The parties will try to resolve all disputes and disagreements arising from this Agreement, through negotiations.',
        "5.2. If the dispute cannot be resolved through negotiations, it is subject to consideration in the appropriate court at the location of the Operator's registration.",
        '5.3. The Operator is not responsible for the consequences associated with the provision by the User of false, distorted, expired information.',
      ],
    },
    {
      title: 'Rights and Obligations of the Operator',
      content: [
        '6.1. The Operator, when providing Services, is guided by regulatory legal acts in accordance with the applicable legislation.',
        '6.2. The Operator provides Services only after verifying all the data provided by the User.',
        '6.3. The Operator may refuse to provide Services if the necessary data is not provided by the User, as stipulated in the relevant section on the Website.',
        '6.4. The Operator has the right, at its discretion, to change the tariffs for the provision of Services, as well as this Agreement (Offer), or to withdraw from them in accordance with the terms of this Agreement.',
        '6.5. The Operator does not provide the User with the Services specified in the application, but not paid for by the User.',
        '6.6. The Operator undertakes to maintain high standards of professionalism and ethics in the provision of services and in interaction with Users, as well as to protect the confidentiality and personal data of Users in accordance with applicable legislation.',
      ],
    },
    {
      title: 'Final Provisions',
      content: [
        '7.1. The present Agreement comes into force from the moment of its acceptance by the User and is valid indefinitely.',
        '7.2. The Operator does not accept any other conditions not specified in the Agreement.',
      ],
    },
  ],
]
export default vocabularyUserAgreement
