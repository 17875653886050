import {
  createOptions,
  createStepSchema,
} from 'pages/helpers/workflowStepSchema'
import vocabulary from 'vocabulary/vocabularyConsent'

export const requestApplicationType = langSelected => {
  const { title, options, additionalInfo } =
    vocabulary.workflow.requestApplicationType

  return createStepSchema(
    title[langSelected],
    [
      {
        name: 'additionalServiceType',
        type: 'radio-with-additional-text',
        componentProps: {
          options: createOptions(options, langSelected),
          placeholder: additionalInfo[langSelected],
        },
      },
    ],
    ({ additionalServiceType, serviceDetails }) => ({
      additionalServiceType: {
        chosen: additionalServiceType,
        text: serviceDetails?.comment,
      },
    }),
    ({ additionalServiceType }) => ({
      additionalServiceType: additionalServiceType.chosen,
      serviceDetails:
        options.at(-1)[1] === additionalServiceType.chosen
          ? { comment: additionalServiceType.text }
          : {},
    }),
  )
}
