import vocabulary, {
  whoNeedsPassportOptions,
} from 'vocabulary/vocabularyPassport'
import { getTranslation } from 'pages/helpers/workflowStepSchema'
import { whoNeedsPassport } from './whoNeedsPassport'
import { passportVariant } from './passportVariant'
import { childHasPassport } from './childHasPassport'
import { adultHasPassport } from './adultHasPassport'
import {
  reasonIfHasNoValidPassport,
  reasonIfHasValidPassport,
} from './reasonOfPassportIssue'
import {
  citizenshipConfirmationForInitialIssue,
  citizenshipConfirmationForOtherReasons,
} from './citizenshipConfirmation'

const FINAL_STEP = 9

export const getSteps = langSelected => {
  return [
    {
      step: 1,
      ...whoNeedsPassport(langSelected),
      nextStep: 2,
      progress: 20,
    },
    {
      step: 2,
      ...passportVariant(langSelected),
      nextStep: (currentStepValues, dataToSend) => {
        const [me, childUnder14, childAgedFrom14to18] =
          whoNeedsPassportOptions.map(getTranslation('en'))

        const { whoNeeds } = dataToSend.serviceDetails || {}

        if (!whoNeeds) {
          return FINAL_STEP
        }

        return whoNeeds === childUnder14 ? 3 : 4
      },
      progress: 30,
    },
    {
      step: 3,
      ...childHasPassport(langSelected),
      progress: 40,
      nextStep: ({ serviceDetails }) => {
        const childHasPassport = serviceDetails?.childHasPassport

        if (!childHasPassport) {
          return FINAL_STEP
        }

        return childHasPassport === getTranslation('en')(vocabulary.yes) ? 5 : 6
      },
    },
    {
      step: 4,
      ...adultHasPassport(langSelected),
      progress: 40,
      nextStep: ({ serviceDetails = {} }) => {
        const { hasForeignPassport, hasInternalPassport } = serviceDetails

        if (!hasForeignPassport && !hasInternalPassport) {
          return FINAL_STEP
        }

        if (
          [hasForeignPassport, hasInternalPassport].includes(
            getTranslation('en')(vocabulary.yes),
          )
        ) {
          return 5
        }

        return 6
      },
    },
    {
      step: 5,
      progress: 70,
      ...reasonIfHasValidPassport(langSelected),
      nextStep: FINAL_STEP,
    },
    {
      step: 6,
      progress: 70,
      ...reasonIfHasNoValidPassport(langSelected),
      nextStep: ({ serviceDetails = {} }) => {
        const { reasonOfIssue } = serviceDetails

        if (!reasonOfIssue) {
          return FINAL_STEP
        }

        const { optionsHasNoValidPassport } =
          vocabulary.workflow.reasonOfPassportIssue
        const initialIssue = getTranslation('en')(optionsHasNoValidPassport[0])

        if (reasonOfIssue === initialIssue) {
          return 7
        }

        return 8
      },
    },
    {
      step: 7,
      progress: 80,
      ...citizenshipConfirmationForInitialIssue(langSelected),
      nextStep: FINAL_STEP,
    },
    {
      step: 8,
      progress: 80,
      ...citizenshipConfirmationForOtherReasons(langSelected),
      nextStep: FINAL_STEP,
    },
  ]
}
