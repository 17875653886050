import React from 'react'
import { v4 as uuid } from 'uuid'
import { Typography, Box } from '@mui/material'
import { RequestItem } from '../__Item/RequestItem'

export const RequestSummary = ({ title, subtitle, content }) => (
  <>
    <Typography variant='h6'>{title}</Typography>
    {subtitle ? <Typography variant='subtitle1'>{subtitle}</Typography> : null}
    <Box sx={{ marginTop: 2 }}>
      {content.map(info => (
        <RequestItem {...info} mt={{ xs: 3, lg: 4 }} key={uuid()} />
      ))}
    </Box>
    {/* <Box mt={2} display='flex' justifyContent='center'>
      <Button variant='contained' color='primary'>
        Продолжить оформление
      </Button>
    </Box> */}
  </>
)
