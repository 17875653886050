export const vocabulary = {
  title: ['ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ', 'PRIVACY POLICY'],
  pageContent: [
    { type: 'title', text: ['1. Общие положения', '1. General Provisions'] },
    {
      type: 'paragraph',
      text: [
        'Настоящая политика обработки персональных данных составлена в соответствии с требованиями закона Республики Казахстан от 21 мая 2013 года № 94–V «О персональных данных и их защите» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных Компания «ICC Consul» LLP (KZ) (далее — Оператор):',
        'Their personal data processing policy has been drawn up in accordance with the requirements of the Law of the Republic of Kazakhstan dated May 21, 2013 No. 94-V “On Personal Data and Their Protection” and defines the procedure for processing personal data and measures to ensure the security of personal data “of ICC Consul” LLP (KZ) (hereinafter referred to as the Operator):',
      ],
    },
    { type: 'paragraph', text: ['БИН: 240340024145', 'BIN: 240340024145'] },
    {
      type: 'paragraph',
      text: [
        'Юридический адрес: Казахстан, город Астана, район Сарыарка, ул Бейбитшилик, зд. 14, офис 1507',
        'Legal address: 14, Beibitshilik str., office 1507, Saryarka district, Astana,Kazakhstan'
      ],
    },
    {
      type: 'paragraph',
      text: ['Телефон: +48 698 143 637', 'Phone: +48 698 143 637'],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.',
        'The Operator sets its primary goal and condition for its activities to comply with the rights and freedoms of individuals and citizens when processing their personal data, including protecting the rights to privacy, personal and family secrets.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях сайта https://consul.online (далее — Сайт).',
        "This Operator's policy regarding the processing of personal data (hereinafter referred to as the Policy) applies to all information that the Operator can receive about visitors to the https://consul.online Website (hereinafter referred to as the Website).",
      ],
    },
    {
      type: 'title',
      text: ['2. Термины и определения', '2. Terms and Definitions'],
    },
    {
      type: 'paragraph',
      text: [
        'Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники;',
        'Automated processing of personal data — processing of personal data using computing technology;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);',
        'Blocking of personal data — temporary cessation of personal data processing (except for in cases where processing is necessary to clarify personal data);',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://consul.online;',
        'Website — a collection of graphic and informational materials, as well as software for computers and databases that ensure their availability on the Internet at the network address https://consul.online;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;',
        'Information system of personal data — a set of personal data contained in databases and ensuring their processing by information technologies and technical means;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Консульство — консульские учреждения Российской Федерации и консульские отделы дипломатических представительств Российской Федерации в стране нахождения Пользователя;',
        "Consulate — consular institutions of the Russian Federation and consular departments of the diplomatic missions of the Russian Federation in the User's country;",
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Консульский портал — официальный информационный интернет-портал Консульского департамента Министерства иностранных дел Российской Федерации, включающий в себя https://www.kdmid.ru/, https://www.mid.ru/, https://zp.midpass.ru/ и их поддомены',
        'Consulate portal — the official information internet portal of the Consular Department of the Ministry of Foreign Affairs of the Russian Federation, including https://www.kdmid.ru/, https://www.mid.ru/, https://zp.midpass.ru/ and their subdomains',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;',
        'Depersonalization of personal data — actions that make it impossible to determine without additional information the affiliation of personal data to a specific User or other subject of personal data;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;',
        'Processing of personal data — any action (operation) or a set of actions (operations) performed using automation tools or without using such tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;',
        'Operator — a state body, municipal body, legal or natural person, independently or jointly with other persons organizing and (or) carrying out the processing of personal data, as well as determining the purposes of processing personal data, the composition of personal data to be processed, actions (operations) performed with personal data;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Персональные данные — любая информация, относящаяся прямо или косвенно к определённому или определяемому Пользователю Сайта;',
        'Personal data — any information relating directly or indirectly to a specific or identifiable User of the Website;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Пользователь — это любое физическое или юридическое лицо, которое посещает сайт, регистрируется на нем или использует предлагаемые на нем услуги;',
        'User — any individual or legal entity that visits the Website, registers on it, or uses the services offered on it;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Предоставление персональных данных — действия, направленные на раскрытие персональных данных определённому лицу или определённому кругу лиц;',
        'Provision of personal data — actions aimed at disclosing personal data to a specific person or a specific group of persons;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределённому кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;',
        'Distribution of personal data — any actions aimed at disclosing personal data to an indefinite circle of persons (transfer of personal data) or familiarizing an unlimited circle of persons with personal data, including the disclosure of personal data in the media, placement in information and telecommunication networks, or providing access to personal data in any other way;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;',
        'Cross-border transfer of personal data — the transfer of personal data to the territory of a foreign state to a government body of a foreign state, a foreign individual, or a foreign legal entity;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.',
        'Destruction of personal data — any actions resulting in the irreversible destruction of personal data with no possibility of further restoration of the content of personal data in the personal data information system and (or) resulting in the destruction of material carriers of personal data.',
      ],
    },
    {
      type: 'title',
      text: ['3. Какие данные обрабатываются', '3. What Data is Processed'],
    },
    {
      type: 'paragraph',
      text: [
        'Пользователь предоставляет Оператору свои персональные данные путём заполнения специальной формы оформления заявки на оказание услуги или в разделе Личный кабинет Пользователя на Сайте, а также через официальные каналы связи с Оператором (email, телефон, мессенджеры для мгновенного обмена сообщениями WhatsApp или Telegram).',
        "The User provides the Operator with their personal data by filling out a special application form for the provision of services or in the User's Personal Account section on the Website, as well as through official communication channels with the Operator (email, phone, instant messaging applications such as WhatsApp or Telegram).",
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор может запрашивать и использовать следующие персональные данные Пользователя:',
        'The Operator may request and use the following personal data of the User:',
      ],
    },
    {
      type: 'list',
      items: [
        ['имя, фамилия, отчество', 'name, surname, second name'],
        ['номер телефона', 'phone number'],
        ['адрес электронной почты', 'email address'],
        ['паспортные данные', 'passport details'],
        ['номера СНИЛС, ИНН', 'SNILS and TIN numbers'],
        ['дата и место рождения', 'date and place of birth'],
        [
          'гражданство и обстоятельства его приобретения',
          'citizenship and circumstances of its acquisition',
        ],
        ['биографические сведения', 'biographical information'],
        [
          'адрес регистрации места жительства',
          'residential registration address',
        ],
        [
          'сведения о семейном положении, данные документов ЗАГС',
          'marital status information, civil registry documents data',
        ],
        [
          'сведения о местах работы и учёбы (название организации, её адрес, должность, период)',
          'information about places of work and study (organization name, address, position, period)',
        ],
        [
          'сведения об образовании (образовательное учреждение, время обучения, присвоенная квалификация, учёная степень)',
          'education information (educational institution, study period, qualification awarded, academic degree)',
        ],
        ['сведения о доходах', 'income information'],
        ['сведения о банковских счетах', 'bank account information'],
        [
          'данные военного билета, удостоверения ветерана боевых действий, приписного удостоверения, справки о прохождении службы',
          'military ID data, combat veteran ID, military service registration certificate, service record certificate',
        ],
        [
          'данные, которые автоматически передаются в процессе просмотра и при посещении страниц Сайта: IP адрес, информация из cookies, информация о браузере, время доступа, адрес посещаемой страницы, реферер (адрес предыдущей страницы)',
          'data automatically transmitted during the viewing and visiting of Website pages: IP address, cookie information, browser information, access time, page address, referrer (previous page address)',
        ],
      ],
    },
    {
      type: 'paragraph',
      text: [
        'и аналогичные сведения о детях, супругах Пользователя и других физических лицах, фигурирующих в документах.',
        "and similar information about the User's children, spouses, and other individuals appearing in the documents.",
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.',
        'The above data is collectively referred to as Personal Data in the context of this Policy.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор использует персональные данные Пользователя только в том объёме, который ему необходим для оказания запрошенной услуги.',
        "The Operator uses the User's personal data only to the extent necessary to provide the requested service.",
      ],
    },
    {
      type: 'title',
      text: ['4. Цель обработки данных', '4. Purpose of Data Processing'],
    },
    {
      type: 'paragraph',
      text: [
        'В зависимости от услуг, которые Пользователь заказывает через сайт, его персональные данные могут обрабатываться для следующих целей:',
        'Depending on the services that the User orders through the Website, their personal data may be processed for the following purposes:',
      ],
    },
    {
      type: 'list',
      items: [
        [
          'регистрация и идентификация Пользователя на Сайте, информирование Пользователя о статусе оформленной им заявки на оказание услуг Оператором или подтверждение факта оплаты услуги посредством отправки электронных писем',
          'registration and identification of the User on the Website, informing the User about the status of their application for services with the Operator or confirming the fact of payment for the service by sending emails',
        ],
        [
          'подготовка проектов документов для Пользователя и их последующее размещение на Консульском портале',
          'preparation of draft documents for the User and their subsequent submission to the Consulate portal',
        ],
        [
          'запись Пользователя на приём в Консульство',
          'Scheduling an appointment at the Consulate to the User',
        ],
        [
          'предоставление консультационных услуг, в том числе в целях получения Пользователем консульских услуг',
          'provision of consulting services, including those for obtaining consular services by the User',
        ],
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества Сайта и его содержания. Более подробно о том, как собираются и обрабатываются эти данные описано в Политике использования файлов cookie (https://consul.online/cookie-policy)',
        'Depersonalized Users’ data collected using internet statistics services is used to gather information about Users’ actions on the Website, improve the quality of the Website and its content. More details on how this data is collected and processed are described in the Cookie Use Policy (https://consul.online/cookie-policy).',
      ],
    },
    { type: 'title', text: ['5. Правовые основания', '5. Legal Basis'] },
    {
      type: 'paragraph',
      text: [
        'Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на Сайте, либо через через официальные каналы связи с Оператором (email, телефон, мессенджеры для мгновенного обмена сообщениями WhatsApp или Telegram). Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает своё согласие с данной Политикой.',
        "The Operator processes the User's personal data only if it is filled out and/or submitted by the User independently through special forms located on the Website, or through official communication channels with the Operator (email, phone, instant messaging applications such as WhatsApp or Telegram). By filling out the appropriate forms and/or submitting their personal data to the Operator, the User expresses their consent to this Policy.",
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).',
        "The Operator processes depersonalized data about the User if it is allowed in the User's browser settings (cookie storage is enabled and JavaScript technology is used).",
      ],
    },
    {
      type: 'title',
      text: ['6. Правила обработки данных', '6. Data Processing Rules'],
    },
    {
      type: 'paragraph',
      text: [
        'Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путём реализации правовых, организационных и технических мер, необходимых для выполнения в полном объёме требований действующего законодательства в области защиты персональных данных.',
        'The security of personal data processed by the Operator is ensured through the implementation of legal, organizational, and technical measures necessary to fully comply with the requirements of the current legislation in the field of personal data protection.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.',
        'The Operator ensures the security of personal data and takes all possible measures to prevent unauthorized access to personal data.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'В целях достижения целей, описанных выше, Оператор может передавать персональные данные следующим третьим лицам:',
        'For the purposes described above, the Operator may transfer personal data to the following third parties:',
      ],
    },
    {
      type: 'list',
      items: [
        [
          'Консульство (в целях записи Пользователя на приём или размещения документа Пользователя на Консульском портале)',
          "Consulate (for the purpose of scheduling an appointment for the User or submitting the User's document on the Consulate portal)",
        ],
        [
          'Сервис интернет-статистики Google Analytics (в целях сбора информации о действиях Пользователей на Сайте, улучшения качества Сайта и его содержания)',
          'Internet statistics service Google Analytics (for the purpose of collecting information about User actions on the Website, improving the quality of the Website and its content)',
        ],
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор осуществляет передачу данных только в ситуациях, когда это необходимо для выполнения законных обязательств, возложенных на него, и в той мере, в какой такая передача является обязательной.',
        'The Operator transfers data only in the situations where it is necessary to fulfill legal obligations imposed on it, and to the extent that such transfer is mandatory.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Срок обработки персональных данных 3 (три) месяца.',
        'The period of personal data processing is 3 (three) months.',
      ],
    },
    {
      type: 'title',
      text: [
        '7. Трансграничная передача данных',
        '7. Cross-Border Transfer of Personal Data',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надёжная защита прав субъектов персональных данных.',
        'Before starting the cross-border transfer of personal data, the Operator must ensure that the foreign state to which the personal data is to be transferred provides reliable protection of the rights of personal data subjects.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.',
        'The cross-border transfer of personal data to foreign states that do not meet the above requirements can only be carried out with the written consent of the personal data subject to the cross-border transfer of their personal data and/or the execution of a contract to which the personal data subject is a party.',
      ],
    },
    { type: 'title', text: ['8. Права Пользователя', '8. User Rights'] },
    {
      type: 'paragraph',
      text: [
        'Пользователь обладает следующими правами в отношении обработки его персональных данных:',
        'The User has the following rights regarding the processing of their personal data:',
      ],
    },
    {
      type: 'collapsed',
      title: ['Удаление', 'Removing'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право запросить удаление его персональных данных, если выполняется одно из следующих условий:',
            'The User has the right to request the deletion of their personal data if one of the following conditions is met:',
          ],
        },
        {
          type: 'list',
          items: [
            [
              'данные больше не нужны для целей, для которых они были собраны или обработаны',
              'the data is no longer needed for the purposes for which it was collected or processed',
            ],
            [
              'Пользователь отзывает своё согласие, если обработка его данных не основана на другом правовом основании',
              'The User withdraws their consent if the processing of their data is not based on another legal basis',
            ],
            [
              'Пользователь возражает против обработки, и нет законных оснований для продолжения обработки',
              'The User objects to the processing, and there are no legal grounds for continuing the processing',
            ],
            [
              'данные были обработаны незаконно',
              'the data was processed unlawfully',
            ],
          ],
        },
      ],
    },
    {
      type: 'collapsed',
      title: ['Доступ', 'Access'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право получить подтверждение о том, обрабатываются ли персональные данные, касающиеся его, и, если да, доступ к следующей информации:',
            'The User has the right to obtain confirmation as to whether personal data concerning them is being processed, and, if so, access to the following information:',
          ],
        },
        {
          type: 'list',
          items: [
            ['цели обработки', 'the purposes of the processing'],
            [
              'категории данных, которые обрабатываются',
              'the categories of data being processed',
            ],
            [
              'получатели или категории получателей, которым данные были переданы или будут переданы',
              'the recipients or categories of recipients to whom the data has been or will be disclosed',
            ],
            [
              'если возможно, предполагаемый период хранения персональных данных или, если это не возможно, критерии, использованные для определения такого периода',
              'if possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period',
            ],
          ],
        },
      ],
    },
    {
      type: 'collapsed',
      title: ['Исправление', 'Correction'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право исправить неточные персональные данные, касающиеся его. Учитывая цели обработки, он также имеет право дополнить неполные персональные данные, в том числе путём предоставления дополнительного заявления. Если это необходимо, Пользователю следует приложить документацию, подтверждающую неточность или неполноту данных, подлежащих обработке.',
            'The User has the right to correct inaccurate personal data concerning them. Considering the purposes of the processing, the User also has the right to supplement incomplete personal data, including by providing an additional statement. If necessary, the user should attach documentation confirming the inaccuracy or incompleteness of the data to be processed.',
          ],
        },
      ],
    },
    {
      type: 'collapsed',
      title: ['Ограничение', 'Restriction'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право запросить ограничение обработки его данных в следующих случаях:',
            'The User has the right to request the restriction of processing of their data in the following cases:',
          ],
        },
        {
          type: 'list',
          items: [
            [
              'пока Оператор проверяет точность персональных данных Пользователя, если Пользователь оспорил их точность',
              "while the Operator verifying the accuracy of the User's personal data if the User has contested their accuracy",
            ],
            [
              'в случае, если Пользователь считает обработку незаконной, возражал против удаления данных и, вместо этого, запрашивает ограничение их использования',
              'if the User believes the processing is unlawful, opposed the deletion of data, and instead requests the restriction of their use',
            ],
            [
              'если Оператор больше не нуждается в данных Пользователя для целей обработки, но они необходимы Пользователю для подачи, осуществления или защиты претензий',
              "if the Operator no longer needs the User's data for processing purposes, but they are required by the User for the establishment, exercise, or defense of legal claims",
            ],
            [
              'пока проверяется, превалируют ли законные основания Оператора над основаниями Пользователя, после возражения против обработки',
              "while it is being verified whether the Operator's legitimate grounds override those of the User, following the User's objection to processing",
            ],
          ],
        },
      ],
    },
    {
      type: 'collapsed',
      title: ['Портируемость', 'Portability'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право получить персональные данные, касающиеся его, которые он предоставил Оператору, в структурированном, широко используемом и машиночитаемом формате, и передать их другому ответственному за обработку без препятствий со стороны Оператора, когда обработка основана на согласии и осуществляется с помощью автоматизированных средств. При осуществлении права на портируемость данных Пользователь имеет право на то, чтобы персональные данные передавались непосредственно от одного ответственного за обработку к другому, если это технически возможно. Это право на портируемость не применяется к обработке, которая необходима для выполнения задачи, выполняемой в общественных интересах или при осуществлении публичных полномочий. Право на портируемость не распространяется на данные, которые Оператор вывел из данных, полученных непосредственно из использования предоставленных услуг.',
            'The User has the right to receive personal data concerning them, which the User has provided to the Operator, in a structured, commonly used, and machine-readable format, and to transmit it to another data controller without hindrance from the Operator, where the processing is based on consent and is carried out by automated means. When exercising the right to data portability, the User has the right to have the personal data transmitted directly from one data controller to another, where technically feasible. This right to data portability does not apply to the processing necessary for the performance of a task carried out in the public interest or in the exercise of official authority. The right to data portability does not apply to the data that the Operator has derived from the data obtained directly from the use of the provided services.',
          ],
        },
      ],
    },
    {
      type: 'collapsed',
      title: ['Возражение', 'Objection'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право возражать против обработки его персональных данных, включая профилирование, в любое время. Если он решит воспользоваться этим правом, Оператор прекратит обработку его персональных данных, если не сможет доказать законные основания для обработки, которые превалируют над интересами Пользователя, правами и свободами, или для подачи, осуществления или защиты претензий.',
            'The User has the right to object to the processing of their personal data, including profiling, at any time. If the User decides to exercise this right, the Operator will cease processing their personal data unless the Operator can demonstrate compelling legitimate grounds for the processing which override the interests, rights, and freedoms of the User or for the establishment, exercise, or defense of legal claims.',
          ],
        },
      ],
    },
    {
      type: 'collapsed',
      title: ['Автоматизированные решения', 'Automated Decisions'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Субъект данных имеет право не быть объектом решения, основанного исключительно на автоматизированной обработке, включая профилирование, которое имеет юридические последствия или аналогично существенно влияет на него.',
            'The data subject has the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning them or similarly significantly affects them.',
          ],
        },
      ],
    },
    {
      type: 'title',
      text: [
        '9. Реализация прав Пользователя',
        '9. User Rights Implementation',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Для реализации любого из упомянутых выше прав Пользователь может заполнить форму обратной связи с Оператором, представленную по ссылке, а также получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных.',
        'To exercise any of the aforementioned rights, the User can fill out the feedback form with the Operator, provided via the link, and also receive any explanations on questions regarding the processing of their personal data.',
      ],
    },
    {
      type: 'title',
      text: ['10. Заключительные положения', '10. Final Provisions'],
    },
    {
      type: 'paragraph',
      text: [
        'В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены её новой версией.',
        'Any changes to the personal data processing policy by the Operator will be reflected in this document. The policy is valid indefinitely until it is replaced with a new version.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://consul.online/privacy-policy.',
        'The current version of the Policy is freely available on the Internet at https://consul.online/privacy-policy.',
      ],
    },
  ],
}
