import serviceAxios from './serviceAxios'

export async function search(query, lang) {
  const apiUrl = '/public/search'

  let response

  try {
    response = await serviceAxios.post(apiUrl, {
      query,
      lang: lang === 0 ? 'ru' : 'en',
    })
  } catch (error) {
    console.error('[!] Error:', error)
  }

  return response?.data ?? { searchResults: [] }
}
