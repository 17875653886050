import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import LeftDrawerDesktopScreen from '../components/LeftDrawer/LeftDrawerDesktopScreen'
import Footer947 from '../components/Footer/Footer947'
import WorkFlowMain from '../components/Workflow/WorkFlowMain'
import SpeedDialButton from 'pages/components/SpeedDialButton'
import { Page } from 'components/Page/Page'

export default function Layout947({ children }) {
  const theme = useTheme()

  return (
    <Page>
      <LeftDrawerDesktopScreen resolution={'947'}>
        <SpeedDialButton />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.primary.surface,
            ml: 13,
            mt: 13,
            flexGrow: 1,
            minHeight: '80vh',
          }}
        >
          {children}
        </Box>
        <WorkFlowMain resolution='big' />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: `104px`,
          }}
        >
          <Footer947 />
        </Box>
      </LeftDrawerDesktopScreen>
    </Page>
  )
}
