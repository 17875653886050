import React, { useContext } from 'react'
import Login from './Login.js'
import SignUp from './SignUp.js'
import ForgetPassword from './ForgetPassword.js'
import CheckYourMail from './CheckYourMail.js'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow.jsx'
import GlobalContext from 'GlobalContext.js'

const openedModalWindow = {
  login: Login,
  signup: SignUp,
  forgetPassword: ForgetPassword,
  checkYourMail: CheckYourMail,
}

export default function LoginFlow() {
  const {
    state: { loginFlow },
  } = useContext(GlobalContext)
  const { isOpened, window } = loginFlow
  const Component = openedModalWindow[window]

  return (
    <DialogModalWindow
      open={isOpened}
      disableEnforceFocus
      height='650px'
      width='400px'
      minHeight='auto'
      sx={{ zIndex: 2500 }}
    >
      {Component ? <Component /> : null}
    </DialogModalWindow>
  )
}
