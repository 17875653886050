import { useContext } from 'react'
import { IntlMessageFormat } from 'intl-messageformat'
import { Grid, Typography } from '@mui/material'
import GlobalContext from 'GlobalContext'
import { vocabulary } from 'vocabulary/vocabularyRequest'
import { formatDateToDDMMYYYY } from 'utils/formatDateToDDMMYYYY'

const blackList = ['index', 'serviceName', 'documentsUploaded']

const renderDisabled = key => blackList.includes(key)

const years = {
  en: '{num, plural, one {# year} other {# years}}',
  ru: '{num, plural, one {# год} few {# года} many {# лет} other {# года}}',
}

function getLocalizedYearString(number, langSelected) {
  const locale = langSelected === 0 ? 'ru' : 'en'

  const msg = new IntlMessageFormat(years[locale], locale)

  return msg.format({ num: number })
}

export const RequestItem = ({ label, value, ...rootProps }) => {
  const { langSelected } = useContext(GlobalContext)

  if (renderDisabled(label)) {
    return null
  }

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2 }} {...rootProps}>
      <Grid item xs={6} sm md lg>
        {label ? (
          <Typography variant='titleSmall' component='h5'>
            {getLabel({ label, value }, langSelected)}
          </Typography>
        ) : null}
      </Grid>

      <Grid
        item
        xs={6}
        sm={8}
        md={8}
        lg={6}
        sx={{
          mt: rootProps.direction === 'column' ? 1 : 0,
        }}
      >
        {renderValue({ label, value }, langSelected)}
      </Grid>
    </Grid>
  )
}

const getLabel = ({ label, value }, langSelected) => {
  const tranlsatedLabel = vocabulary.labels[label]?.[langSelected] || label

  if (Array.isArray(value)) {
    return `${tranlsatedLabel} (${value.length}):`
  }

  return `${tranlsatedLabel}:`
}

const renderValue = ({ label, value }, langSelected) => {
  if (Array.isArray(value)) {
    return value.map((item, subIndex) => (
      <Typography variant='bodySmall' key={subIndex} component='p'>
        {`${subIndex + 1}. ${item}`}
      </Typography>
    ))
  }

  if (value === 'Yes' || value === 'No') {
    value = value === 'Yes'
  }

  if (typeof value === 'boolean') {
    const text = value
      ? vocabulary['yes'][langSelected]
      : vocabulary['no'][langSelected]

    return (
      <Typography variant='bodySmall' component='p'>
        {text}
      </Typography>
    )
  }

  if (typeof value === 'object' && value !== null) {
    if (label === 'timePoa') {
      const renderValue = value.years
        ? getLocalizedYearString(value.years, langSelected)
        : `${vocabulary.until[langSelected]} ${formatDateToDDMMYYYY(value.customDate)}`

      return (
        <Typography variant='bodySmall' component='p'>
          {renderValue}
        </Typography>
      )
    }

    return 'unknown value'
  }

  return (
    <Typography variant='bodySmall' component='p'>
      {value}
    </Typography>
  )
}
