import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'
import docImage from '../images/icon/document-black.png'
import houseImage from '../images/icon/house-image.png'
import buildingImage from '../images/icon/building-black.png'
import carImage from '../images/icon/car-image.png'
import stampBlackImage from '../images/icon/stamp-black.png'
import coinImage from '../images/icon/coin-image.png'
import hammerImage from '../images/icon/hammer.png'
import courtImage from '../images/icon/court-black.png'
import closeImage from '../images/icon/close-cross.png'

const vocabularyPowerOfAttorney = {
  powersOfAttorney: ['доверенности', 'Powers of Attorney'],
  desc1: [
    'Чтобы провести из-за рубежа сделку с недвижимостью, решить вопросы ЖКХ или строительства дома, потребуется доверенность — на человека в России, который и будет заниматься этим вопросом.',
    'To conduct a real estate transaction from abroad, resolve housing and utilities issues, or build a house, a Power of Attorney will be required. This document will authorize a person in Russia to handle these matters on your behalf.',
  ],
  desc2: [
    'Для того, чтобы сделать такую доверенность, не обязательно возвращаться в РФ: документ можно получить в ближайшем к вам Российском консульстве. Однако сотрудники консульства не занимаются составлением текстов документов, только заверяют заранее подготовленные в специальном формате проекты документов.',
    "To arrange a Power of Attorney, it's not necessary to return to Russia: the document can be obtained at the nearest Russian consulate. However, consulate staff do not draft documents; they only certify prepared draft documents in a special format.",
  ],
  desc3: [
    'Мы поможем вам подготовить проект доверенности, проконсультируем по поводу записи на приём в консульство или запишем вас сами, и разместим готовый проект документа в нужном формате на консульском портале.',
    'We will assist you in preparing a draft of the Power of Attorney, provide consultation regarding scheduling an appointment at the consulate, or schedule an appointment for you. Additionally, we will upload the finalized draft document in the required format to the consulate portal.',
  ],
  necessaryDocList: [
    'Какие документы потребуются?',
    'What documents will be required?',
  ],
  baseDocList: [
    'Базовый набор документов зависит от того, какой правовой статус имеет доверитель (от чьего имени оформляется доверенность):',
    'The basic set of documents depends on the legal status of the principal (on whose behalf the Power of Attorney is being issued):',
  ],
  userTypes: [
    {
      userName: [
        'Физическое лицо старше 18 лет',
        'Individual over 18 years old',
      ],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ',
          'Russian internal passport and/or Russian passport',
        ],
        [
          'Адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
          'Address of residence abroad in Russian (if there is no registration in Russia)',
        ],
        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian internal passport of the authorized person, including the registration (or representative - to whom entrusted)',
        ],
      ],
    },
    {
      userName: [
        'По доверенности от другого лица',
        'The Power of Attorney is issued by another person',
      ],
    },
    {
      userName: ['Ребёнок младше 14 лет', 'The child is under 14 years old.'],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ родителя (или законного представителя)',
          'Internal passport of the Russian Federation and/or Russian international passport of the parent (or legal representative)',
        ],
        [
          'Свидетельство о рождении ребенка (если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык)',
          'Birth certificate of the child (if the document is of foreign origin, it must have an apostille and a notarized translation into Russian).',
        ],
        [
          'Загранпаспорт РФ ребенка',
          'Foreign passport of the child (if applicable).',
        ],
        [
          'Адрес места жительства родителя и ребенка за рубежом на русском языке (если отсутствует прописка в России)',

          'Address of residence of the parent and child abroad in Russian language (if there is no registration in Russia).',
        ],
        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian internal passport of the authorized representative, including registration (or representative - the person trusted).',
        ],
      ],
      comments: [
        [
          'Присутствие ребенка при оформлении доверенности не требуется.',
          'Child appearance is not required',
        ],
      ],
    },
    {
      userName: [
        'Ребёнок возрастом от 14 до 18 лет',
        'The child is over 14 years old.',
      ],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ родителя (или законного представителя)',
          'Internal passport of the Russian Federation and/or Russian international passport of the parent (or legal representative)',
        ],
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ ребенка',
          'Russian domestic passport and/or Russian international passport of the child.',
        ],
        [
          'Свидетельство о рождении ребенка (если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык)',
          'Birth certificate of the child (if the document is of foreign origin, it must have an apostille and a notarized translation into Russian).',
        ],
        [
          'Загранпаспорт РФ ребенка',
          'Foreign passport of the child (if applicable).',
        ],
        [
          'Адрес места жительства родителя и ребенка за рубежом на русском языке (если отсутствует прописка в России)',

          'Address of residence of the parent and child abroad in Russian language (if there is no registration in Russia).',
        ],
        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian internal passport of the authorized representative, including registration (or representative - the person trusted).',
        ],
      ],
      comments: [
        [
          'Присутствие ребенка и родителя обязательно.',
          'Child and parent appearance is required',
        ],
      ],
    },
    {
      userName: ['Индивидуальный предприниматель', 'Self contractor'],
      desc: [
        [
          'Выписка из ЕГРИП',
          'Extract from the Unified State Register of Individual Entrepreneurs (EGRI)',
        ],
        [
          'Свидетельство ОГРН, ИНН',
          'Certificate of State Registration Number (OGRN), Taxpayer Identification Number (INN)',
        ],
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ доверителя',
          'Russian domestic passport and/or Russian international passport of the attorney',
        ],
        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian domestic passport of the attorney, including the place of residence (or representative - the person who is entrusted)."',
        ],
      ],
    },
    {
      userName: ['Юридическое лицо', 'Legal entity'],
      desc: [
        [
          'Устав Организации и его изменения, если имели место быть',
          'Charter of the Organization and its amendments, if any',
        ],
        [
          'Выписка из ЕГРЮЛ',
          'Extract from the Unified State Register of Legal Entities (EGRUL)',
        ],
        [
          'Свидетельство ОГРН, ИНН, КПП',
          'Certificate of State Registration Number (OGRN), Taxpayer Identification Number (INN), Tax Registration Reason Code (KPP)',
        ],
        [
          'Решение о назначении директора',
          'Decision on the appointment of the director',
        ],
        [
          'Приказ о назначении директора',
          'Order on the appointment of the director',
        ],
        ['Паспортные данные директора', 'Passport details of the director'],
        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian domestic passport of the attorney, including the place of residence (or representative - the person who is entrusted)',
        ],
      ],
    },
    {
      userName: [
        'Учредитель (участник, акционер)',
        'Founder (participant, shareholder)',
      ],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ учредителя (участника, акционера)',
          'Russian domestic passport and/or foreign passport of the founder (participant, shareholder)',
        ],
        [
          'СНИЛС (если имеется)',
          'Individual insurance account number (SNILS) (if available)',
        ],
        [
          'Устав Организации и его изменения, если имели место быть',
          'Charter of the Organization and its amendments, if any',
        ],
        [
          'Выписка из ЕГРЮЛ',
          'Extract from the Unified State Register of Legal Entities (EGRUL)',
        ],

        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian domestic passport of the attorney, including the place of residence (or representative - the person who is entrusted)',
        ],
      ],
    },
    {
      userName: ['Иностранный гражданин', 'Foreign national citizen'],
    },
  ],
  infoPowerOfAttorney1: [
    'Для оформления нотариальных документов приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
    'For processing notarial documents, priority is given to the domestic passport. In case the domestic passport has not been obtained or has expired, a valid foreign passport will be required. If both passports are available, it is necessary to bring both passports to the consulate.',
  ],
  infoPowerOfAttorney2: [
    'Присутствие представителя (или поверенного — того, кому доверяют) для оформления доверенности не требуется.',
    'The presence of a representative (or attorney - the one who is granted right) is not required to execute a Power of Attorney.',
  ],
  typesOfPowersOfAttorney: [
    'виды доверенностей',
    'TYPES OF POWERS OF ATTORNEY',
  ],
  orderOfRegistration: ['Порядок Оформления', 'Steps to Obtain'],
  orderDescription: [
    [
      {
        headerText: 'Оформите заявку',
        cardText:
          'Опишите, что должно содержаться в доверенности. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Подготовка текста документа',
        cardText:
          'Наш юрист свяжется с вами, обсудит детали, подготовит проект доверенности и разместит его на портале КД МИД России. Вы получите 6-значный код, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Получение готового документа',
        cardText:
          'В день записи приходите в консульство с паспортом и шестизначным кодом для заверения подготовленной доверенности',
        cardImage: stampImage,
      },
    ],
    [
      {
        headerText: 'Submit an Application',
        cardText:
          'Describe what should be included in the Power of Attorney. You can send us the required documents either simultaneously with the application or directly to the lawyer after consulting with him/her.',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparation of the Document Text',
        cardText:
          'Our lawyer will contact you, discuss the details, prepare a draft Power of Attorney, and upload it to the portal of the Consular Department of the Ministry of Foreign Affairs of Russia. You will receive a 6-digit code, which you will have to take with you to the consulate. The service completion time is 2 working days from the moment of payment.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Scheduling an appointment at the consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
        cardImage: calendarImage,
      },
      {
        headerText: 'Receipt of the Ready Document',
        cardText:
          'On the day of the appointment, come to the consulate with your passport and the six-digit code to have the prepared Power of Attorney certified',
        cardImage: stampImage,
      },
    ],
  ],
  prices: ['ТАРИФЫ И ЦЕНЫ', 'Tariffs and Prices'],
  priceWithoutAppointment: [
    {
      header: 'Без записи в консульство',
      list: [
        'подготовим проект доверенности',
        'разместим документ на портале КД МИД России',
        'проконсультируем по перечню документов, необходимых для заверения',
        'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '60',
    },

    {
      header: 'Without an appointment at the consulate',
      list: [
        'we will prepare a draft Power of Attorney',
        'we will upload the document to the portal of the Consular Department of the Ministry of Foreign Affairs of Russia',
        'we will advise you on the list of documents required for certification',
        'we will advise on scheduling an appointment at the consulate on your own',
      ],
      value: '60',
    },
  ],
  priceWithAppointment: [
    {
      header: 'С записью в консульство',
      list: [
        'подготовим проект доверенности',
        'разместим документ на портале КД МИД России',
        'проконсультируем по перечню документов, необходимых для заверения',
        'запишем вас на приём в консульство',
      ],
      value: '80',
    },

    {
      header: 'With an appointment at the consulate',
      list: [
        'we will prepare a draft Power of Attorney',
        'we will upload the document to the portal of the Consular Department of the Ministry of Foreign Affairs of Russia',
        'we will advise you on the list of documents required for certification',
        'we  will schedule an appointment for you at the consulate',
      ],
      value: '80',
    },
  ],
  paymentProcessing1: [
    'Оплатить услугу возможно как во время оформления заявки на сайте, так и после консультации с юристом.',
    'It is possible to pay for the service both during the application process on the website and after consulting with the lawyer',
  ],
  paymentProcessing2: [
    'Мы принимаем к оплате на сайте международные банковские карты, Apple Pay и Google Pay. Оплата российскими банковскими картами, с использованием системы PayPal или в криптовалюте на сайте не производится, но возможна по запросу.',
    'We accept international bank cards, Apple Pay, and Google Pay for payment on the website. Payment with Russian bank cards, PayPal, or in cryptocurrency is not processed on the website but is possible upon request',
  ],
  faq: ['Часто задаваемые вопросы', 'Frequently Asked Questions'],
  faqDesc: [
    [
      {
        question:
          'Могу ли я оформить доверенность без моего личного присутствия?',
        answer: 'Нет, личное присутствие доверителя обязательно',
      },
      {
        question:
          'Что делать, если человек, на которого оформляется доверенность, находится в РФ?',
        answer:
          'Ничего страшного, присутствие поверенного для оформления доверенности не требуется. Достаточно предоставить его паспортные данные и информацию о прописке',
      },
      {
        question:
          'Нужно ли брать с собой ребёнка на приём в консульство, если доверенность оформляется от его имени?',
        answer:
          'Зависит от возраста ребёнка. Присутствие ребёнка до 14 лет не требуется. Дети в возрасте от 14 до 18 лет оформляют доверенности от своего имени, но с согласия одного из родителей, поэтому обязательно присутствие как ребёнка, так и родителя.',
      },
      {
        question:
          'Какой паспорт нужно предъявить для оформления нотариального документа?',
        answer:
          'Приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
      },
      {
        question:
          'Я — иностранный гражданин. Могу ли оформить доверенность на гражданина РФ?',
        answer: 'Да, конечно, это возможно.',
      },
      {
        question:
          'Возможно ли в Российском консульстве оформить доверенность для представления интересов в Казахстане или Белоруссии?',
        answer:
          'Да. В соответствии с Конвенцией о правовой помощи, участниками которой являются Россия и Казахстан, Беларусь, Таджикистан, Грузия и др., доверенность, оформленная в Консульстве России, принимается госорганами и учреждениями этих стран, без дополнительного удостоверения (апостиля)',
      },
      {
        question: 'На какой максимальный срок можно оформить доверенность?',
        answer:
          'Вы вправе оформить доверенность с любым сроком действия. Вместе c тем в доверенности должен быть указан конкретный срок её действия, удостоверение «бессрочных» доверенностей не допускается.',
      },
      {
        question: 'Можно ли в доверенности указать несколько доверенных лиц?',
        answer:
          'Да, доверенности могут быть оформлены от имени одного или нескольких лиц, на имя одного или нескольких лиц.',
      },
    ],

    [
      {
        question:
          'Can I arrange a Power of Attorney without my personal presence?',
        answer: "No, the principal's personal presence is mandatory.",
      },
      {
        question:
          'What should I do if the person for whom the Power of Attorney is being arranged is in Russia?',
        answer:
          'Nothing to worry about, the presence of the attorney to issue the Power of Attorney is not required. It is sufficient to provide their passport details and registration information.',
      },
      {
        question:
          'Do I need to bring my child to the consulate appointment if the Power of Attorney is being arranged on their behalf?',
        answer:
          'It depends on the age of the child. The presence of children under 14 years of age is not required. Children aged 14 to 18 arrange Powers of Attorney in their own name, but with the consent of one of the parents, so the presence of both the child and the parent is mandatory.',
      },
      {
        question:
          'Which passport do I need to present to arrange a notarized document?',
        answer:
          'Priority is given to the internal passport. If the internal passport has not been issued or has expired, a valid foreign passport will be required. If both passports are available, both passports must be taken to the consulate appointment.',
      },
      {
        question:
          'I am a foreign citizen. Can I arrange a Power of Attorney for a Russian citizen?',
        answer: 'Yes, of course, it is possible.',
      },
      {
        question:
          'Is it possible to arrange a Power of Attorney at the Russian consulate for representation in Kazakhstan or Belarus?',
        answer:
          'Yes. In accordance with the Convention on Legal Assistance, of which Russia, Kazakhstan, Belarus, Tajikistan, Georgia, and others are participants, a Power of Attorney issued at the Russian Consulate is accepted by the government agencies and institutions of these countries without additional certification (apostille).',
      },
      {
        question:
          'What is the maximum period for which a Power of Attorney can be issued?',
        answer:
          'You can issue Powers of Attorney with any validity. At the same time, a specific period of validity must be specified; the certification of "indefinite" Powers of Attorney is not allowed.',
      },
      {
        question: 'Can multiple attorneys be specified in a Power of Attorney?',
        answer:
          'Yes, Powers of Attorney can be issued on behalf of one or several persons, for one or several persons.',
      },
    ],
  ],
  faqList: [
    {
      userName: [
        'Могу ли я оформить доверенность без моего личного присутствия?',
        'Can I arrange a Power of Attorney without my personal presence?',
      ],
      desc: [
        [
          'Нет, личное присутствие доверителя обязательно',
          "No, the principal's personal presence is mandatory.",
        ],
      ],
    },
    {
      userName: [
        'Что делать, если человек, на которого оформляется доверенность, находится в РФ?',
        'What should I do if the person for whom the Power of Attorney is being arranged is located in Russia?',
      ],
      desc: [
        [
          'Ничего страшного, присутствие поверенного для оформления доверенности не требуется. Достаточно предоставить его паспортные данные и информацию о прописке',
          'Nothing to worry about, the presence of the attorney to arrange the Power of Attorney is not required. It is sufficient to provide their passport details and registration information.',
        ],
      ],
    },
    {
      userName: [
        'Нужно ли брать с собой ребёнка на приём в консульство, если доверенность оформляется от его имени?',
        'Do I need to bring my child to the consulate appointment if the Power of Attorney is being arranged on their behalf?',
      ],
      desc: [
        [
          'Зависит от возраста ребёнка. Присутствие ребёнка до 14 лет не требуется. Дети в возрасте от 14 до 18 лет оформляют доверенности от своего имени, но с согласия одного из родителей, поэтому обязательно присутствие как ребёнка, так и родителя.',
          'It depends on the age of the child. The presence of children under 14 years of age is not required. Children aged 14 to 18 arrange Powers of Attorney in their own name, but with the consent of one of the parents, so the presence of both the child and the parent is mandatory.',
        ],
      ],
    },
    {
      userName: [
        'Какой паспорт нужно предъявить для оформления нотариального документа?',
        'Which passport do I need to present to arrange a notarized document?',
      ],
      desc: [
        [
          'Приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
          'Priority is given to the internal passport. If the internal passport has not been issued or has expired, a valid foreign passport will be required. If both passports are available, both passports must be taken to the consulate appointment.',
        ],
      ],
    },
    {
      userName: [
        'Я — иностранный гражданин. Могу ли оформить доверенность на гражданина РФ?',
        'I am a foreign citizen. Can I arrange a Power of Attorney for a Russian citizen?',
      ],
      desc: [['Да, конечно, это возможно.', 'Yes, of course, it is possible.']],
    },
    {
      userName: [
        'Возможно ли в Российском консульстве оформить доверенность для представления интересов в Казахстане или Белоруссии?',
        'Is it possible to arrange a Power of Attorney at the Russian consulate for representation in Kazakhstan or Belarus?',
      ],
      desc: [
        [
          'Да. В соответствии с Конвенцией о правовой помощи, участниками которой являются Россия и Казахстан, Беларусь, Таджикистан, Грузия и др., доверенность, оформленная в Консульстве России, принимается госорганами и учреждениями этих стран, без дополнительного удостоверения (апостиля)',
          'Yes. In accordance with the Convention on Legal Assistance, of which Russia, Kazakhstan, Belarus, Tajikistan, Georgia, and others are participants, a Power of Attorney arranged at the Russian Consulate is accepted by the government agencies and institutions of these countries without additional certification (apostille).',
        ],
      ],
    },
    {
      userName: [
        'На какой максимальный срок можно оформить доверенность?',
        'What is the maximum period for which a Power of Attorney can be arranged?',
      ],
      desc: [
        [
          'Вы вправе оформить доверенность с любым сроком действия. Вместе c тем в доверенности должен быть указан конкретный срок её действия, удостоверение «бессрочных» доверенностей не допускается.',
          'A specific period of validity must be specified; the certification of "indefinite" Powers of Attorney is not allowed.',
        ],
      ],
    },
    {
      userName: [
        'Можно ли в доверенности указать несколько доверенных лиц?',
        'Can multiple attorneys be specified in a Power of Attorney?',
      ],
      desc: [
        [
          'Да, доверенности могут быть оформлены от имени одного или нескольких лиц, на имя одного или нескольких лиц.',
          'Yes, Powers of Attorney can be arranged on behalf of one or several persons, for one or several persons.',
        ],
      ],
    },
  ],
  askQuestions: ['Задать вопрос', 'Ask questions'],
  imagesTypesOfPoa: [
    docImage,
    houseImage,
    houseImage,
    houseImage,
    buildingImage,
    carImage,
    stampBlackImage,
    coinImage,
    coinImage,
    coinImage,
    stampBlackImage,
    hammerImage,
    courtImage,
    closeImage,
  ],
  typesOfPoa: [
    ['Генеральная', 'General'],
    ['На дарение недвижимости', 'Inheritance of Real Estate'],

    ['На покупку недвижимости', 'For the purchase of real estate'],
    ['На продажу недвижимости', 'For the sale of real estate'],
    ['На продажу доли общества', 'For the sale of a share in a company'],
    ['На машину', 'For a car'],
    ['На ведение наследственного дела', 'For handling inheritance matters'],
    ['На оформление пенсии', 'For processing pension documents'],
    [
      'На оформление материнского капитала',
      'For processing maternity capital documents',
    ],
    ['На управление банковскими счетами', 'For managing bank accounts'],
    [
      'На истребование и апостилирование документов',
      'For retrieval and apostilling of documents',
    ],
    ['На представительство в суде', 'For legal representation in court'],
    [
      'На представительство в госорганах',
      'For representation in government agencies',
    ],
    ['Распоряжение об отмене доверенности', 'Revocation of Power of Attorney'],
  ],
  PoatServicesInteractuation: [
    {
      text: [
        [
          'Официально понятия «генеральная» доверенность не существует. Как правило, под этим понимают доверенность на совершение самого широкого круга юридически значимых действий: распоряжение недвижимостью и транспортным средством, банковскими счетами, представительство в государственных организациях, и прочие конкретные полномочия, которые указываются в тексте документа',
          "The concept of a 'general' Power of Attorney does not officially exist. Typically, it refers to a Power of Attorney granting the broadest range of legally significant actions: managing real estate and vehicles, bank accounts, representing in government organizations, and other specific authorities specified in the document text.",
        ],
      ],
      list: null,
    },
    {
      text: null,
      list: {
        headers: [
          ['Какие полномочия может включать:', 'What powers may include:'],
          ['Дополнительно потребуются:', 'Additionally required:'],
        ],
        body: [
          [
            ['подписать договор дарения', 'Sign a donation agreement'],
            [
              'зарегистрировать договор и переход права собственности',
              'Register the contract and transfer property rights',
            ],
            [
              'представлять интересы доверителя в различных госучреждениях, подавать заявления и запрашивать от его имени документы',
              'Represent the interests of the principal in various government agencies, submit applications, and request documents on their behalf',
            ],
          ],
          [
            [' СНИЛС (если имеется)', 'SNILS (if available)'],
            ['документы на имущество', 'property documents'],
            ['паспортные данные одаряемого', 'personal data of the recipient'],
            [
              'разрешение органов опеки на сделку (если какая‑либо часть недвижимости принадлежит ребёнку)',
              'permission from guardianship authorities for the transaction (if any part of the property belongs to a child)',
            ],
            [
              'согласие супруга на дарение недвижимости (если имущество было приобретено в браке). Можно оформить одновременно с доверенностью',

              "spouse's consent to donate real estate (if the property was acquired during marriage). Can be issued simultaneously with the Power of Attorney",
            ],
            [
              'подтверждение родства доверителя и поверенного (для получения льгот на оплату консульского сбора)',
              'confirmation of relationship between the principal and the attorney (to receive discounts on consular fees)',
            ],
          ],
        ],
      },
    },
    {
      text: null,
      list: {
        headers: [
          ['Какие полномочия может включать:', 'What powers may include:'],
          ['Дополнительно потребуются:', 'Additionally required:'],
        ],
        body: [
          [
            [
              'представлять интересы доверителя в государственных органах',
              "representing the principal's interests in government agencies",
            ],

            [
              'заключать от имени доверителя договоры на покупку недвижимости',
              'entering into property purchase contracts on behalf of the principal',
            ],
            ['заключать кредитные договора', 'entering into loan agreements'],
          ],

          [
            ['СНИЛС (если имеется)', 'SNILS (if available)'],
            [
              'согласие супруга на покупку недвижимости (если доверитель состоит в браке). Можно оформить одновременно с доверенностью',
              "spouse's consent to purchase real estate (if the principal is married). Can be issued simultaneously with the Power of Attorney",
            ],
            [
              'подтверждение родства доверителя и поверенного (для получения льгот на оплату консульского сбора)',
              'confirmation of relationship between the principal and the attorney (to receive discounts on consular fees)',
            ],
          ],
        ],
      },
    },
    {
      text: null,
      list: {
        headers: [
          ['Какие полномочия может включать:', 'What powers may include:'],
          ['Дополнительно потребуются:', 'Additionally required:'],
        ],
        body: [
          [
            [
              'продать / обменять указанный объект недвижимости',
              'Sell / exchange the specified real estate object',
            ],

            ['получить деньги по сделке', 'Receive money for the transaction'],
            ['сдать в аренду', 'Rent out'],
            [
              'зарегистрировать недвижимость на себя или третьих лиц',
              'Register the real estate for oneself or third parties',
            ],
            [
              'представлять интересы доверителя в различных госучреждениях: Росреестр, МФЦ, банк, отдел по вопросам миграции МВД',
              'Represent the interests of the principal in various government agencies: Rosreestr, MFC, bank, migration department of the Ministry of Internal Affairs',
            ],
          ],
          [
            ['СНИЛС (если имеется)', 'SNILS (if available)'],
            ['документы на имущество', 'Documents for the property'],
            [
              'разрешение органов опеки на сделку (если какая‑либо часть недвижимости принадлежит ребёнку)',
              'Permission from the guardianship authorities for the transaction (if any part of the real estate belongs to a child)',
            ],
            [
              'согласие супруга на продажу недвижимости (если имущество было приобретено в браке). Можно оформить одновременно с доверенностью )',
              "Spouse's consent to sell the real estate (if the property was acquired during marriage). Can be processed simultaneously with the Power of Attorney",
            ],
            [
              'подтверждение родства доверителя и поверенного (для получения льгот на оплату консульского сбора)',
              'Confirmation of the relationship between the principal and the attorney (to obtain discounts on consular fees)',
            ],
          ],
        ],
      },
    },
    {
      text: null,
      list: {
        headers: [
          ['Какие полномочия может включать:', 'What powers may include:'],
          ['Дополнительно потребуются:', 'Additionally required:'],
        ],
        body: [
          [
            [
              'представлять интересы доверителя в различных госучреждениях, подавать заявления и запрашивать от его имени документы',
              'Represent the interests of the principal in various government agencies, submit applications, and request documents on their behalf',
            ],
            [
              'проводить от его имени переговоры и решать все возникающие спорные вопросы',
              'Conduct negotiations and resolve all arising disputes on their behalf',
            ],
            [
              'заключить и подписать от его имени договор на продажу доли в уставном капитале общества',
              'Conclude and sign, on their behalf, a contract for the sale of a share in the charter capital of the company',
            ],
            [
              'получить по данному договору деньги',
              'Receive payment under this contract',
            ],
          ],
          [
            ['СНИЛС (если имеется)', ['SNILS (if available)']],
            [
              'Согласия супруга на продажу доли общества (если юридическое лицо было создано или приобретено в браке). Можно оформить одновременно с доверенностью.',
              "Spouse's consent to the sale of the company's share (if the legal entity was created or acquired during marriage). Can be formalized simultaneously with the Power of Attorney.",
            ],
          ],
        ],
      },
    },
    {
      text: null,
      list: {
        headers: [
          ['Какие полномочия может включать:', 'What powers may include:'],
          ['Дополнительно потребуются:', 'Additionally required:'],
        ],
        body: [
          [
            [
              'управлять данным транспортным средством',
              'manage the specified vehicle',
            ],
            [
              'забирать машину со штрафстоянки',
              'retrieve the car from the impound lot',
            ],
            ['продавать автомобиль', 'sell the car'],
            [
              'получать финансовые средства от продажи',
              'receive financial proceeds from the sale',
            ],
            [
              'сдать в аренду, заложить или обменять указанный в документе автомобиль',
              'rent out, pawn, or exchange the specified car mentioned in the document',
            ],
            [
              'снимать и ставить его на учёт в ГИБДД',
              'take it off and put it back on the registration in the traffic police',
            ],
            [
              'получать дубликаты и вносить изменения в регистрационные документы',
              'receive duplicates and make changes to the registration documents',
            ],
            [
              'получать или менять номерные знаки',
              'receive or change license plates',
            ],
            ['проходить технический осмотр', 'pass a technical inspection'],
            [
              'оформлять страховку и получать страховое возмещение',
              'arrange insurance and receive insurance compensation',
            ],
          ],

          [
            ['СНИЛС (если имеется)', 'SNILS (if available)'],
            [
              'паспорт транспортного средства (ПТС) или электронный ПТС',
              'vehicle registration certificate (PTS) or electronic PTS',
            ],
            [
              'свидетельство о регистрации ТС',
              'vehicle registration certificate',
            ],
          ],
        ],
      },
    },
    {
      text: null,
      list: {
        headers: [
          ['Какие полномочия может включать:', 'What powers may include:'],
          ['Дополнительно потребуются:', 'Additionally required:'],
        ],
        body: [
          [
            [
              'подавать заявления в нотариальную контору на принятие наследство или отказ от его получения',
              'submit applications to a notary office for accepting inheritance or waiving its receipt',
            ],
            [
              'запрашивать и получать документы в государственных органах (ЗАГС, МВД, архивы), необходимые для наследственного дела, среди которых Свидетельство о праве на наследство',
              "request and obtain documents from state authorities (registrar's offices, Ministry of Internal Affairs, archives) necessary for the inheritance case, including the Certificate of Right to Inheritance",
            ],
            [
              'знакомиться с содержанием документа, когда доверенность выдаётся на оформление наследства по завещанию',
              'review the contents of the document when the Power of Attorney is issued for the inheritance formalization by will',
            ],
            [
              'принимать участие в разделе наследства, предусматривающем выплату (получение) денежной компенсации',
              'participate in the inheritance division, involving payment (receipt) of monetary compensation',
            ],
            [
              'регистрировать в государственных органах регистрации прав на наследуемое имущество',
              'register the inherited property rights in state registration authorities',
            ],
          ],

          [
            [
              'свидетельство о смерти или данные о наследодателе: ФИО умершего, дата смерти, адрес регистрации места жительства на момент смерти',
              "death certificate or deceased's data: full name of the deceased, date of death, address of residence registration at the time of death",
            ],
          ],
        ],
      },
    },
    {
      text: null,
      list: {
        headers: [
          ['Какие полномочия может включать:', 'What powers may include:'],
          ['Дополнительно потребуются:', 'Additionally required:'],
        ],
        body: [
          [
            ['получать пенсионные выплаты', 'receive pension payments'],
            [
              'представлять интересы доверителя в Пенсионном фонде, подавать от его имени заявления о назначении (восстановлении) пенсионных выплат',
              'represent the interests of the principal at the Pension Fund, submit applications on their behalf for the appointment (reinstatement) of pension payments',
            ],
            [
              'получать документы, необходимых для оформления пенсионных выплат, в частности, пенсионного удостоверения',
              'obtain documents necessary for processing pension payments, including a pension certificate',
            ],
            [
              'открыть персональный пенсионный банковский счёт для доверителя',
              'open a personal pension bank account for the principal',
            ],
          ],
          [
            ['СНИЛС (если имеется)', 'SNILS (if available)'],
            [
              'данные банковского счета (если уже открыт)',
              'bank account details (if already opened)',
            ],
          ],
        ],
      },
    },
    {
      text: null,
      list: {
        headers: [
          ['Какие полномочия может включать:', 'What powers may include:'],
          ['Дополнительно потребуются:', 'Additionally required:'],
        ],
        body: [
          [
            [
              'представлять интересы доверителя в Пенсионном фонде',
              'represent the interests of the principal in the Pension Fund',
            ],
            [
              'подавать от его имени заявления о выдаче Государственного Сертификата на материнский (семейный) капитал',
              'submit applications on their behalf for the issuance of the State Certificate for Maternal (Family) Capital',
            ],
            [
              'получить от его имени Государственного Сертификата на материнский (семейный) капитал',
              'receive the State Certificate for Maternal (Family) Capital on their behalf',
            ],
          ],

          [['СНИЛС (если имеется)', 'SNILS (if available)']],
        ],
      },
    },
    {
      text: null,
      list: {
        headers: [
          ['Какие полномочия может включать:', 'What powers may include:'],
        ],
        body: [
          [
            [
              'открывать / закрывать счета доверителя в банке',
              'opening/closing accounts for the principal at the bank',
            ],
            [
              'заключать и расторгать от имени доверителя договора с банками',
              'entering into and terminating agreements with banks on behalf of the principal',
            ],
            [
              'переводить денежные средства со счета доверителя на иной банковский счёт',
              "transferring funds from the principal's account to another bank account",
            ],
            [
              'вносить и снимать денежные средства со счета',
              'depositing and withdrawing funds from the account',
            ],
            [
              'получать выплаты, компенсации и проценты по вкладам',
              'receiving payments, compensations, and interest on deposits',
            ],
          ],
        ],
      },
    },
    {
      text: [
        [
          'Дополнительно могут потребоваться документы на объект сделки (недвижимость, земельный участок, организация и другие) или уточняющая информация про документы, которые планируются к истребованию.',
          'Additionally, documents related to the transactional object (real estate, land plot, organization, etc.) may be required, or clarifying information about the documents planned to be obtained.',
        ],
      ],
      list: {
        headers: [
          [
            'Данный вид доверенности будет полезен в случае:',
            'This type of Power of Attorney will be useful in the following cases:',
          ],
          ['Какие полномочия может включать:', 'What powers can it include:'],
        ],
        body: [
          [
            [
              'сбора документов на получение ВНЖ или гражданства другой страны',
              'Collecting documents for obtaining a residence permit or citizenship in another country.',
            ],
            [
              'омологации документов об образовании',
              'Apostilling educational documents.',
            ],
            [
              'подготовке к сделке с недвижимостью или земельным участком, находящихся в РФ, и в других случаях',
              'Preparing for a real estate transaction or land plot located in the Russian Federation, and in other cases.',
            ],
          ],
          [
            [
              'представлять интересы доверителя в разных государственных учреждениях, например, БТИ, ЕИРЦ, ЗАГС, ДЕЗ, земельный комитет, регистрационная служба, кадастровая палата, Министерство Образования, нотариальная контора, подавать в них от его имени заявления и получать документы: выписки, справки и дубликаты',
              'Representing the interests of the principal in various government institutions, such as BTI, EIRC, ZAGS, DEZ, Land Committee, registration office, cadastral chamber, Ministry of Education, notary office, submitting applications on their behalf, and obtaining documents: extracts, certificates, and duplicates.',
            ],
            [
              'регистрировать и подавать документы',
              'registering and submitting documents,',
            ],
            [
              'оплачивать тарифы, пошлины, сборы',
              'paying tariffs, fees, and charges.',
            ],
          ],
        ],
      },
    },
    {
      text: null,
      list: {
        headers: [
          [
            'Данный вид доверенности позволяет представлять интересы в суде:',
            'This type of Power of Attorney allows representing interests in court:',
          ],
          ['Дополнительно потребуются:', 'Additionally required:'],
        ],
        body: [
          [
            ['по гражданским делам', 'In civil cases'],
            ['по уголовным делам', 'In criminal cases'],
            ['по административным делам', 'In administrative cases'],
            [
              'по делам, рассматриваемым арбитражным судом (полномочия: отзыв и подписание искового заявления, заключение мирового соглашения и другие)',
              'In cases considered by the arbitration court (authorities: withdrawal and signing of the statement of claim, conclusion of a settlement agreement, and others)',
            ],
            [
              'в службе судебных приставов (полномочия: обжалование действий или бездействия судебных приставов, обжалование постановлений постановлений и другие)',
              'In the bailiff service (authorities: appealing actions or inactions of bailiffs, appealing resolutions, and others)          ',
            ],
          ],

          [['СНИЛС (если имеются)', 'SNILS (if available)']],
        ],
      },
    },
    {
      text: null,
      list: {
        headers: [
          ['Какие полномочия может включать:', 'What powers may include:'],
          ['Дополнительно потребуются:', 'Additionally required:'],
        ],
        body: [
          [
            [
              'действовать от имени доверителя в государственных ведомствах, организациях и службах: ГИБДД, ФССП, ЗАГС, страховых компаниях, Социальном фонде, суде, правоохранительных органах, банках и так далее',
              'Act on behalf of the principal in state authorities, organizations, and services: traffic police, Federal Bailiff Service, Registry Office, insurance companies, Social Security Fund, court, law enforcement agencies, banks, and so on.',
            ],
            [
              'подавать заявления о государственной регистрации прав или сделок',
              'Submit applications for state registration of rights or transactions.',
            ],
            [
              'распоряжаться зарегистрированными в государственных реестрах правами',
              'Dispose of rights registered in state registers.',
            ],
            [
              'представлять интересов ИП в налоговой инспекции',
              'Represent the interests of an individual entrepreneur in the tax office.',
            ],
            [
              'представлять интересы в исполнительном производстве',
              'Represent interests in enforcement proceedings.',
            ],
          ],
          [['СНИЛС (если имеются)', 'SNILS (if available)']],
        ],
      },
    },
    {
      text: [
        [
          'Доверитель вправе отменить уже выданную доверенность в любой момент (исключением является только безотзывная доверенность, которую оформляют предприниматели).',
          'The principal has the right to revoke a Power of Attorney that has already been issued at any time  (with the exception of irrevocable Powers of Attorney, which are issued by entrepreneurs).',
        ],
        [
          'Запись об аннулировании документа вносится в единый электронный реестр доверенностей, которым вправе воспользоваться любой гражданин.',
          "The record of the document's annulment is entered into the Unified Electronic Register of Powers of Attorney, which can be accessed by any citizen.",
        ],
      ],
      list: null,
    },
  ],
  buttonFormalize: ['Оформить', 'To formalize'],
  buttonAsk: ['Спросить', 'Ask a question'],
  whichTypes: [
    'Какая доверенность вам необходима?',
    'What Power of Attorney do you need?',
  ],

  typeOfPoa: ['Вид доверенности', 'Type of power of attorney'],
  chooseFromList: ['Выберите из списка', 'Choose from the list'],
  describeService: [
    'Или опишите своими словами, какая доверенность вам нужна',
    'Or describe in your own words what type of Power of Attorney you need.',
  ],
  whoGives: ['Кто доверяет?', 'Who trusts?'],
  issuerStatus: ['Статус доверителя', "Trustor's status"],
  addMoreTruster: ['Добавить ещё доверителя', 'Add another trustor'],
  trustersList: [
    ['первый доверитель', 'first trustor'],
    ['второй доверитель', ' second trustor'],
    ['третий доверитель', 'third trustor'],
    ['четвёртый доверитель ', ' fourth trustor'],
    ['пятый доверитель', 'fifth trustor'],
  ],
  toWhomGives: ['Кому доверяет?', 'To whom trust? '],
  representativeStatus: ['Статус представителя', 'Representative status'],
  addAnotherRepresentative: [
    'Добавить ещё представителя',
    'Add another representative',
  ],
  representativeList: [
    ['Родственник', 'Relative'],
    ['Другое физическое лицо', 'Another individual'],
    [
      'Индивидуальный предприниматель',
      'Sole proprietor / Individual entrepreneur',
    ],
    ['Юридическое лицо', 'Legal entity'],
  ],
  representativeCountList: [
    ['Первый представитель', 'First representative'],
    ['Второй представитель', 'Second representative'],
    ['Третий представитель', 'Third representative'],
    ['Четвёртый представитель', 'Fourth representative'],
    ['Пятый представитель', 'Fifth representative'],
  ],
  whatTrust: ['Что доверяет сделать?', '  What does he trust to do?'],
  powers: ['Полномочия', 'Authority'],
  powersDescription: [
    'Например, распоряжаться квартирой и машиной, управлять банковскими счетами, получить документы в госучреждениях и поставить на них апостиль',
    'For example, managing an apartment and a car, handling bank accounts, obtaining documents from government agencies, and getting them apostilled.',
  ],
  powerAbove: [
    'Опишите, какие действие вы хотите разрешить производить от вашего лица',
    'Describe which actions you want to authorize on your behalf',
  ],
  withAtorneyRights: ['С правом передоверия', 'With the right of attorney'],
  poatValid: [
    'На какой срок оформляется доверенность?',
    'For what term is the Power of Attorney issued?',
  ],
  yearsOfPoat: ['Укажите количество лет:', 'Specify the number of years:'],
  poatEndDate: [
    'Или точную дату окончания действия доверенности:',
    "Or the exact date of the Power of Attorney's expiration:",
  ],
  endDate: ['Дата окончания', 'End date'],
  provideContact: [
    'Укажите свои контактные данные',
    'Please provide your contact information',
  ],
  userName: ['Имя', 'Name'],
  enterYourName: ['Введите ваше имя', 'Enter your name'],
  userPhone: ['Телефон', 'Phone'],
  enterYourPhone: ['Введите ваш телефон', 'Enter your phone'],
  email: ['Электронная почта', 'E-mail'],
  imAgree: [
    'Я даю своё согласие на обработку персональных данных и соглашаюсь с условиями',
    'I consent to the processing of personal data and agree to the terms of the',
  ],
  imAgreePrivicyPolicy: ['политики конфиденциальности', 'privacy policy'],
  documentUpload: ['Загрузите документы', 'Upload documents'],
  documentUploadText1: [
    'Ниже приведен примерный список документов, который потребуется предоставить для оформления доверенности.',
    'Below is an approximate list of documents that will be required to issue a Power of Attorney.',
  ],
  documentUploadText2: [
    'Вы можете загрузить их сейчас или отправить юристу после консультации с ним (в таком случае нажмите кнопку Пропустить).',
    'You can upload them now or send them to the lawyer after consulting with him (in this case, click the Skip button).',
  ],
  documentList: ['Список документов:', 'Document list:'],
  documentListArray: [
    {
      passport: [
        'Внутренний паспорт РФ',
        'Internal passport of the Russian Federation',
      ],
      desc: [
        'Скан разворота с фото и страницы с регистрацией',
        'A scan of the spread with the photo and the page with the registration.',
      ],
    },
    {
      passport: ['Загранпаспорт', 'International passport'],
      desc: ['Скан разворота с фото', 'A scan of the spread with the photo.'],
    },
    {
      passport: [
        'Внутренний паспорт или загранпаспорт представителя',
        'Internal passport or foreign passport of the representative.',
      ],
      desc: [null, null],
    },
  ],
}

export default vocabularyPowerOfAttorney
