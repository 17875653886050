import vocabulary from 'vocabulary/vocabularyOther'
import {
  createStepSchema,
  createOptions,
} from 'pages/helpers/workflowStepSchema'

export const requestServiceType = langSelected => {
  const { title, options, additionalInfo } =
    vocabulary.workflow.requestServiceType

  return createStepSchema(
    title[langSelected],
    [
      {
        name: 'service',
        type: 'radio-with-additional-text',
        componentProps: {
          options: createOptions(options, langSelected),
          placeholder: additionalInfo[langSelected],
        },
      },
    ],
    ({ service, serviceDetails }) => {
      return {
        service: {
          chosen: service,
          text: serviceDetails?.comment,
        },
      }
    },
    ({ service }) => ({
      service: service.chosen,
      serviceDetails:
        options.at(-1)[1] === service.chosen ? { comment: service.text } : {},
    }),
  )
}
