import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import GlobalContext from 'GlobalContext.js'
import WorkflowStep from 'components/Workflow/WorkflowStep.jsx'
import CountrySelection from '../Workflow/CountrySelection.js'
import CertificateInfo from './DialogCertificates/CertificateInfo.js'
import Payment from '../Workflow/Payment.js'
import GetUserDetails from '../Workflow/GetUsersDetails.js'
import RequestSuccessSent from '../Workflow/RequestSuccessSent.js'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow.jsx'
import { getSteps } from './DialogCertificates/CertificatesSteps.js'
import vocabularyCertificates from 'vocabulary/vocabularyCertificates.js'
import CheckoutForm from '../Workflow/CheckoutForm.js'

export default function DialogCertificates() {
  const { state, langSelected } = React.useContext(GlobalContext)
  const price = {
    withAppointment: vocabularyCertificates.priceWithAppointment[1].value,
    withoutAppointment: vocabularyCertificates.priceWithoutAppointment[1].value,
  }
  const open = state.dialogService.section === 'certificates'
  const currentStep = state.dialogService.step

  const steps = getSteps(langSelected)

  return (
    <DialogModalWindow open={open}>
      {currentStep === -1 ? <CertificateInfo /> : null}
      {currentStep === 0 ? <CountrySelection height='auto' /> : null}
      {steps.map(workflow =>
        workflow.step === currentStep ? (
          <WorkflowStep key={uuidv4()} {...workflow} />
        ) : null,
      )}
      {currentStep === 6 ? <GetUserDetails height='auto' /> : null}
      {currentStep === 7 ? <Payment price={price} /> : null}
      {currentStep === 8 ? <CheckoutForm price={price} /> : null}
      {currentStep === 9 ? <RequestSuccessSent height='auto' /> : null}
    </DialogModalWindow>
  )
}
