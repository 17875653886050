import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Typography, Box } from '@mui/material/'
import GlobalContext from 'GlobalContext'
import squareImg from 'images/brown-square.png'

export const List = ({ items, title, index }) => {
  const { langSelected } = React.useContext(GlobalContext)

  return (
    <Box>
      {title ? (
        <Typography
          variant='titleSmall'
          component='div'
          sx={{ mt: index === 0 ? null : '20px' }}
        >
          {title[langSelected]}
        </Typography>
      ) : null}

      {/* Check if I could use custom list here */}
      {items.map(val => (
        <React.Fragment key={uuidv4()}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mt: index === 0 ? '20px' : '16px',
              alignItems: 'flex-start',
            }}
          >
            <img
              src={squareImg}
              alt='logo'
              width={20}
              style={{ marginRight: '16px' }}
            />
            <Typography variant='bodySmall'>{val[langSelected]}</Typography>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  )
}
