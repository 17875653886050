import Layout600 from '../Layouts/Layout600'
import { PrivacyPolicy } from 'pages/components/PrivacyPolicy/PrivacyPolicy'

export default function PrivacyPolicy600() {
  return (
    <Layout600>
      <PrivacyPolicy />
    </Layout600>
  )
}
