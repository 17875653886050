import * as React from 'react'
import {
  Button,
  Typography,
  Box,
  LinearProgress,
  Stack,
  Grid,
} from '@mui/material/'

import dayjs from 'dayjs'
import { useTheme } from '@mui/material/styles'
import GlobalContext from '../../../../GlobalContext'
import leftArrow from '../../../../images/icon/arrow-left.png'
import crossClose from '../../../../images/icon/cross-close.png'
import vocabularyWorkflow from '../../../../vocabulary/vocabularyWorkflow'
import vocabularyPowerOfAttorney from '../../../../vocabulary/vocabularyPowerOfAttorney'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

export default function DialogPoaWorkflow6() {
  const theme = useTheme()
  const { langSelected, GlobalActions, state } = React.useContext(GlobalContext)
  const [years, setYears] = React.useState(0)
  const [customDate, setCustomDate] = React.useState(dayjs().startOf('day'))
  console.log('CUSTOM DATE', customDate)
  const [selected, setSelected] = React.useState(false)

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box sx={{ mx: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '76px',
          }}
        >
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              GlobalActions.setDialogServiceOpen({
                step: 5,
                index: state.dialogService.index,
              })
            }}
          >
            <img src={leftArrow} alt='left arrow' width={'24px'} />
          </Box>

          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              GlobalActions.setDialogServiceOpen(false)
              GlobalActions.clearDataToSend()
            }}
          >
            <img src={crossClose} alt='cross' width={'24px'} />
          </Box>
        </Box>

        <Typography component='p' variant='titleLarge' sx={{ mb: '32px' }}>
          {vocabularyPowerOfAttorney.poatValid[langSelected]}
        </Typography>

        <Typography component='p' variant='titleSmall' sx={{ my: '32px' }}>
          {vocabularyPowerOfAttorney.yearsOfPoat[langSelected]}
        </Typography>

        <Grid container spacing={0}>
          <Grid
            item
            xs={2}
            onClick={() => {
              if (years > 0) {
                setYears(years - 1)
              }
            }}
          >
            <Typography
              variant='h2Large'
              component='div'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
                py: 1,
              }}
            >
              -
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ border: '1px solid #000' }}>
            <Typography
              variant='h2Small'
              component='div'
              sx={{ display: 'flex', justifyContent: 'center', py: 1 }}
            >
              {years}
            </Typography>
          </Grid>
          <Grid
            onClick={() => {
              setSelected(true)
              setYears(years + 1)
            }}
            item
            xs={2}
          >
            <Typography
              variant='h2Large'
              component='div'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
                py: 1,
              }}
            >
              +
            </Typography>
          </Grid>
        </Grid>

        <Typography component='p' variant='titleSmall' sx={{ my: '32px' }}>
          {vocabularyPowerOfAttorney.poatEndDate[langSelected]}
        </Typography>

        <DatePicker
          label={vocabularyPowerOfAttorney.endDate[langSelected]}
          value={customDate}
          onChange={newValue => {
            setSelected(true)
            setCustomDate(newValue)
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              sx: {
                width: '100%',

                '& .MuiOutlinedInput-root': {
                  borderRadius: '5px',
                  color: theme.palette.primary.onSurface,
                },
              },
            },
          }}
          format='DD/MM/YYYY'
        />
      </Box>
      <Box>
        <Stack>
          <LinearProgress
            color='progressBar'
            variant='determinate'
            value={60}
            sx={{ backgroundColor: theme.palette.primary.primaryContainer }}
          />
        </Stack>
        <Box
          sx={{
            pb: '16px',
            pt: '24px',
            mt: '8px',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            variant='text'
            size='medium'
            disabled={selected}
            sx={{ border: 'none', height: '40px' }}
            color='blueButton'
            onClick={() => {
              GlobalActions.setDialogServiceOpen({
                index: state.dialogService.index,
                step: 7,
              })
              GlobalActions.setDataToSend({
                serviceDetails: {
                  timePoa: { years: years, customDate: customDate },
                  documentsUploaded: null,
                },
              })
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyWorkflow.skip[langSelected]}
            </Typography>
          </Button>

          <Button
            variant='text'
            size='medium'
            disabled={!selected}
            sx={{
              border: 'none',
              height: '40px',
              mr: '16px',
              '&.Mui-disabled': {
                border: 'none', // Remove border when the button is disabled
              },
            }}
            color='blueButton'
            onClick={() => {
              GlobalActions.setDialogServiceOpen({
                index: state.dialogService.index,
                step: 7,
              })
              GlobalActions.setDataToSend({
                serviceDetails: {
                  timePoa: { years: years, customDate: customDate },
                  documentsUploaded: null,
                },
              })
            }}
          >
            <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
              {vocabularyWorkflow.forward[langSelected]}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
