import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Title } from './__Title/ServiceInfoTitle'
import { List } from './__List/ServiceInfoList'
import { Paragraph } from './__Paragraph/ServiceInfoParagraph'
import { Link } from './__Link/ServiceInfoLink'
import { ServiceInfoAccordion as Accordion } from './__Accordion/ServiceInfoAccordion'

const componentsByType = {
  title: Title,
  list: List,
  paragraph: Paragraph,
  collapsed: Accordion,
  link: Link,
}

export function itemRenderer(item, index) {
  const Component = componentsByType[item.type]

  return <Component key={uuidv4()} {...item} index={index} />
}
