import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import GlobalContext from 'GlobalContext.js'
import CountrySelection from 'pages/components/Workflow/CountrySelection.js'
import Payment from 'pages/components/Workflow/Payment.js'
import GetUserDetails from 'pages/components/Workflow/GetUsersDetails.js'
import RequestSuccessSent from 'pages/components/Workflow/RequestSuccessSent.js'
import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'
import ConsentInfo from './ConsentInfo'
import WorkflowStep from 'components/Workflow/WorkflowStep'
import { getSteps } from './steps/index'
import vocabularyConsent from 'vocabulary/vocabularyConsent'
import CheckoutForm from 'pages/components/Workflow/CheckoutForm'

export function Workflow() {
  const { state, langSelected } = React.useContext(GlobalContext)
  const open = state.section === 'consent'
  const { step: currentStep } = state.dialogService
  const workflowSteps = getSteps(langSelected)
  const price = {
    withAppointment: vocabularyConsent.priceWithAppointment[1].value,
    withoutAppointment: vocabularyConsent.priceWithoutAppointment[1].value,
  }

  return (
    <DialogModalWindow open={open}>
      {/* Remove height prop after using the dialog modal window everywhere */}
      {currentStep === -1 ? <ConsentInfo /> : null}
      {currentStep === 0 ? <CountrySelection height='auto' /> : null}
      {workflowSteps.map(({ step, ...workflowSchema }) =>
        currentStep === step ? (
          <WorkflowStep key={uuidv4()} {...workflowSchema} />
        ) : null,
      )}
      {currentStep === 10 ? <GetUserDetails height='auto' /> : null}
      {currentStep === 11 ? <Payment price={price} /> : null}
      {currentStep === 12 ? <CheckoutForm price={price} /> : null}
      {currentStep === 13 ? <RequestSuccessSent height='auto' /> : null}
    </DialogModalWindow>
  )
}
