import { useTheme } from '@mui/material/styles'
import LeftDrawerDesktopScreen from '../components/LeftDrawer/LeftDrawerDesktopScreen'
import Footer1239 from '../components/Footer/Footer1239'
import Box from '@mui/material/Box'
import WorkFlowMain from '../components/Workflow/WorkFlowMain'
import SpeedDialButton from 'pages/components/SpeedDialButton'
import { Page } from 'components/Page/Page'

export default function Layout1239({ children }) {
  const theme = useTheme()

  return (
    <Page>
      <LeftDrawerDesktopScreen resolution={'1239'}>
        <SpeedDialButton />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.primary.surface,
            ml: `104px`,
            flexGrow: 1,
            mt: `104px`,
            minHeight: '75vh',
          }}
        >
          {children}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: `104px`,
          }}
        >
          <WorkFlowMain resolution='big' />
          <Footer1239 />
        </Box>
      </LeftDrawerDesktopScreen>
    </Page>
  )
}
