import { Box, Paper, Typography, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useOnHover } from 'hooks/useOnHover'

export const SearchResult = ({ link, title, meta, active, onHover }) => {
  const { elementRef } = useOnHover(onHover)

  return (
    <Button
      fullWidth
      ref={elementRef}
      component={Link}
      underline='hover'
      sx={{
        cursor: 'pointer',
        p: 0,
        textTransform: 'none',
      }}
      {...link}
    >
      <Paper
        elevation={1}
        sx={{
          px: 2,
          py: 1,
          borderRadius: '6px',
          width: 1,
          backgroundColor: active ? 'primary.primaryContainer' : undefined,
        }}
      >
        <Typography variant='labelLarge'>{title}</Typography>
        <Box>
          {meta.map(({ text, type }) => {
            const sx =
              type === 'mark'
                ? { backgroundColor: 'primary.tertiaryContainer' }
                : {}

            return (
              <Typography variant='bodySmall' sx={sx}>
                {text}
              </Typography>
            )
          })}
        </Box>
      </Paper>
    </Button>
  )
}
