import * as React from 'react'
import { Typography, Box, LinearProgress, Stack } from '@mui/material/'
import { useTheme } from '@mui/material/styles'
import GlobalContext from 'GlobalContext'
import leftArrow from 'images/icon/arrow-left.png'
import crossClose from 'images/icon/cross-close.png'
import vocabularyWorkflow from 'vocabulary/vocabularyWorkflow'
import { Button } from 'components/Button/Button'

export function WorkflowContainer({
  onSkip,
  onConfirm,
  onClose,
  children,
  canMoveForward,
  footer,
  progress = 0,
  onBackward,
}) {
  const { GlobalActions, state } = React.useContext(GlobalContext)

  const handleClose = () => {
    GlobalActions.setDialogServiceOpen(false)

    onClose?.()
  }

  const handleBackward = () => {
    if (state.dialogService.history.length) {
      GlobalActions.setDialogServicePreviousStep()
    } else {
      GlobalActions.setDialogServiceOpen(false)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: '1 0 auto',
        maxWidth: '100%',
      }}
    >
      <Box sx={{ mx: '16px' }}>
        <Header onClose={handleClose} onBackward={handleBackward} />
        {children}
      </Box>
      <Box>
        {progress ? <ProgressBar value={progress} /> : null}
        {footer ? (
          footer
        ) : (
          <Footer
            onConfirm={onConfirm}
            onSkip={onSkip}
            canMoveForward={canMoveForward}
          />
        )}
      </Box>
    </Box>
  )
}

export const Header = ({ onClose, onBackward, step }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '76px',
      }}
    >
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={step === 0 ? onClose : onBackward}
      >
        <img src={leftArrow} alt='left arrow' width={'24px'} />
      </Box>

      <Box sx={{ cursor: 'pointer' }} onClick={onClose}>
        <img src={crossClose} alt='cross' width={'24px'} />
      </Box>
    </Box>
  )
}

export const Footer = ({ onConfirm, onSkip, canMoveForward }) => {
  const hideFooter = !onConfirm && !onSkip
  const { langSelected } = React.useContext(GlobalContext)

  if (hideFooter) {
    return null
  }

  return (
    <Box
      sx={{
        pb: '16px',
        pt: '24px',
        mt: '8px',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
      }}
    >
      {onSkip ? (
        <Button
          type='primary'
          variant='text'
          disabled={canMoveForward}
          onClick={onSkip}
        >
          <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
            {vocabularyWorkflow.skip[langSelected]}
          </Typography>
        </Button>
      ) : null}

      {onConfirm ? (
        <Button
          variant='text'
          sx={{ mr: '16px' }}
          onClick={onConfirm}
          disabled={!canMoveForward}
        >
          <Typography variant='labelLarge' sx={{ textTransform: 'none' }}>
            {vocabularyWorkflow.forward[langSelected]}
          </Typography>
        </Button>
      ) : null}
    </Box>
  )
}

export const ProgressBar = ({ value }) => {
  const theme = useTheme()

  return (
    <Stack>
      <LinearProgress
        color='progressBar'
        variant='determinate'
        value={value}
        sx={{ backgroundColor: theme.palette.primary.primaryContainer }}
      />
    </Stack>
  )
}
