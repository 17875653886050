import React from 'react'
import { v4 as uuid } from 'uuid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material/styles'

export const Select = ({
  options,
  onChange,
  value = '',
  label,
  placeholder,
  helperText,
  sx,
}) => {
  const theme = useTheme()

  return (
    <TextField
      value={value}
      placeholder={placeholder}
      helperText={helperText}
      onChange={onChange}
      sx={{
        minWidth: 1,
        height: 56,
        borderRadius: 0,
        '& .MuiOutlinedInput-root': {
          borderRadius: '5px',
          color: theme.palette.primary.onSurface,
        },

        '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
          color: theme.palette.primary.onSurfaceVariant,
          opacity: 1,
        },
        ...sx,
      }}
      variant='outlined'
      margin='dense'
      select
      label={label}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        },
      }}
    >
      {options?.map(({ value, label }) => (
        <MenuItem
          key={uuid()}
          sx={{ display: 'flex', alignItems: 'center' }}
          value={value}
        >
          {label}
        </MenuItem>
      ))}
    </TextField>
  )
}
